import { createTheme } from '@mui/material/styles';
import darkScrollbar from '@mui/material/darkScrollbar';
import { grey, amber } from '@mui/material/colors';
export const lightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: amber[600],
            light: '#ffd54f',
            dark: '#ffb300',
            contrastText: '#666',
        },
        secondary: {
            main: '#9c27b0',
            light: '#ba68c8',
            dark: '#7b1fa2',
            contrastText: '#292828',
        },
        common: {
            black: '#000',
            white: '#fff',
        },
        // Defina outras cores conforme necessário
        background: {
            default: '#fff',
            paper: '#d1d5db',
        },
        text: {
            primary: '#292828',
            secondary: '#333',
        },
        action: {
            selected: '#292828',
        },
        grey: {
            '100': '#d1d5db',
            '200': '#f5f5f5',
            '300': '#d1d5db',
            '400': 'rgba(245,245,245,0.5)',
            '500': 'rgba(61,61,63,0.6)',
            'A100': 'rgba(61,61,63,0.5)',
            '50': '#fff',
            '600': '#333',
            '700': '#333',
            'A400': '#333',
        },
    },
    tableHeader: {
        background: '#f0f0f0',
    },
    tableRow: {
        background: {
            even: '#ffffff',
            odd: '#fafafa',
        },
        hover: '#f3f3f3',
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    ...darkScrollbar({
                        track: grey[200],
                        thumb: grey[400],
                        active: grey[400],
                    }),
                    scrollbarWidth: 'thin',
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white', // Substitua pela cor desejada
                },
            },
        },
        MuiTableContainer: {},
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.1)', // Customize the boxShadow property
                },
            },
        },
        MuiSpeedDialAction: {
            styleOverrides: {
                staticTooltip: {
                    color: '#FFC807'
                },
                staticTooltipLabel: {
                    color: '#FFC807'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '20px !important', // Define a borda do botão
                    '&.MuiButton-contained': {
                        // This targets the contained variant specifically
                        color: '#131314', // Sets font color to black for contained variant
                    },
                    '&.MuiButton-contained.MuiButton-containedError':
                    {
                        color: '#ffffff !important', // Cor branca para botões contained com a propriedade color='error'
                    },
                    '&.MuiButton-contained.MuiButton-containedInfo':
                    {
                        color: '#ffffff !important', // Cor branca para botões contained com a propriedade color='error'
                    },
                },
            },
        },
    },
});
