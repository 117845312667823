
import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { deleteAsync, putAsync, useConfirmContext } from '@nplan'
interface IUseUserActions {
    handleResetPasswordAsync: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
    handleDeactivateUserAsync: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
}
export const useUserActions = (): IUseUserActions => {
    const confirmAsync = useConfirmContext();

    const handleResetPasswordAsync = useCallback(async (
        event: React.MouseEvent<HTMLButtonElement>,
    ): Promise<void> => {
        const id = parseInt(event.currentTarget.dataset.id as string);
        const user = event.currentTarget.dataset.name as string;
        const confirmed = await confirmAsync({
            description: (
                <React.Fragment>
                    <Typography>
                        <Trans>
                            Deseja gerar uma nova senha para o usuário{' '}
                            <strong> {user} </strong>?
                        </Trans>
                    </Typography>
                    <Typography>
                        <Trans>Uma nova senha será enviada por email.</Trans>
                    </Typography>
                </React.Fragment>
            ),
        });
        if (confirmed) await putAsync(`identity/users/${id}/reset-password`);
    }, [confirmAsync]);

    const handleDeactivateUserAsync = useCallback(async (
        event: React.MouseEvent<HTMLButtonElement>,
    ): Promise<void>  => {
        const id = parseInt(event.currentTarget.dataset.id as string);
        const user = event.currentTarget.dataset.name as string;
        const confirmed = await confirmAsync({
            description: (
                <React.Fragment>
                    <Typography>
                        <Trans>
                            Deseja realmente desativar o usuário{' '}
                            <strong>{user}</strong>?
                        </Trans>
                    </Typography>
                </React.Fragment>
            ),
        });
        if (confirmed) {
            await deleteAsync(`identity/users/${id}`);
        }
    },[confirmAsync]);

    return { handleResetPasswordAsync, handleDeactivateUserAsync };
};
