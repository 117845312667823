import React from 'react';
import { Box, Table, TableContainer } from '@mui/material';
import {
    Header,
    Body,
    ContextMenu,
} from './components';
import { TableBodySkeleton, useContextMenu } from '@nplan';
import { useColumnData } from './hooks/useColumnData';

export const TableEditionColumn: React.FC = (): JSX.Element => {
    const {
        columns,
        handleRefreshDataAsync,
        loading,
        aligns,
        filterTypes,
        viewTypes,
    } = useColumnData();
    const { 
        position, 
        handleOpenMenu, 
        handleCloseMenu 
    } = useContextMenu();
    return (
        <Box padding={ 2 }>
            <ContextMenu
                position={ position }
                onClose={ handleCloseMenu }
                onRefreshDataAsync={ handleRefreshDataAsync }
            />
            <TableContainer
                sx={ {
                    minHeight: 130,
                    height: 'calc(100vh - 80px)',
                    display: 'flex',
                    flexDirection: 'column'
                } }
                onContextMenu={ handleOpenMenu }
            >
                <Table sx={ { whiteSpace: 'nowrap' } }>
                    <Header />
                    {loading ? (
                        <TableBodySkeleton rows={ 20 } cols={ 27 } height={13} />
                    ) : (
                        <Body
                            columns={ columns }
                            viewTypes={ viewTypes }
                            aligns={ aligns }
                            filterTypes={ filterTypes }
                            onRefreshData={ handleRefreshDataAsync }
                        />
                    )}
                </Table>
            </TableContainer>
        </Box>
    );
};
