import React from 'react';
import {
  SidebarContext,
  SidebarContextProps,
} from '../components/SidebarContext';

export const useSidebar = (): SidebarContextProps | undefined => {
  const context = React.useContext(SidebarContext);

  return context;
};
