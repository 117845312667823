import { Box, Button, CardContent, Table, TableContainer, Typography } from '@mui/material';
import {
    Pagination,
    TableBodySkeleton,
    useContextMenu,
    useDialog,
    useFilter,
    usePagination,
    useVariant,
} from '@nplan';
import { Body, ContextMenu, Header, ManageUser } from './components';
import { useUserData } from './hooks/';
import { SummaryBody } from './components/Summary/SummaryBody';
import { Trans } from '@lingui/macro';

export const User: React.FC = (): JSX.Element => {
    const variant = useVariant();
    const {
        users,
        roles,
        loading,
        handleRefreshDataAsync,
    } = useUserData();

    const {
        id,
        open,
        handleCloseDialog,
        handleOpenDialog
    } = useDialog();

    const {
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage
    } = usePagination();

    const {
        position,
        handleOpenMenu,
        handleCloseMenu
    } = useContextMenu();

    const {
        filteredData,
        filteredDataLength,
        filterValue,
        handleChangeFilterValue
    } = useFilter<User>(
        users,
        page,
        rowsPerPage,
    );
    const handleSupportClick = () => {
        window.location.href = 'mailto:support@nplan.com.br';
    };
    return (
        <Box padding={2}>
            <ManageUser
                open={open}
                onClose={handleCloseDialog}
                userId={id}
                roles={roles}
            />
            <ContextMenu
                position={position}
                onClose={handleCloseMenu}
                onRefreshDataAsync={handleRefreshDataAsync}
                onAddUser={handleOpenDialog}
            />
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-evenly',
                gap: '2rem'
                
            }}>
                <CardContent sx={{
                    width: '75%',
                    marginBottom: '2rem',
                    display: 'flex',
                }}>
                    <Table sx={{ whiteSpace: 'nowrap' }} size='medium'>
                        {loading
                            ? <TableBodySkeleton rows={3} cols={4} />
                            : <SummaryBody users={users}/>
                        }
                    </Table>
                </CardContent>
                <Box sx={{ gap: '0.5rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '-2rem', marginRight: '4rem' }}>
                    <Typography variant='h6'><Trans>Precisa de ajuda?</Trans></Typography>
                    <Button variant={variant} onClick={handleSupportClick}><Trans>Falar com o suporte</Trans></Button>
                </Box>
            </Box>
            <TableContainer
                sx={{
                    minHeight: 130,
                    height: 'calc(100vh - 130px)',
                    display: 'flex',
                    flexDirection: 'column',
                }}
                onContextMenu={handleOpenMenu}
            >
                <Table sx={{ whiteSpace: 'nowrap' }} size='medium'>
                    <Header
                        filter={filterValue}
                        onChangeFilterValue={handleChangeFilterValue}
                    />
                    {loading ? (
                        <TableBodySkeleton rows={13} cols={11} />
                    ) : (
                        <Body
                            users={filteredData}
                            roles={roles}
                            onEditUserClick={handleOpenDialog}
                        />
                    )}
                </Table>
            </TableContainer>
            <Pagination
                count={filteredDataLength}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
            />
        </Box>
    );
};
