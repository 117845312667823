import { t } from '@lingui/macro';
import { useCallback, useEffect, useState } from 'react';
import { SelectedAxisOptions } from 'redux/types';
import { Dictionary } from 'types/Dictionary';
import { IGenericOptions } from 'types/Generics';
import { createDictionaryRange } from 'utils/dictionaryHelper';
import { IFilter, IFilterSegmentation, ISegmentationSummaryInfo, stockDataFilterCriteria } from '../types/IFilters';
import { IInventorySegmentationData } from '../types/IInventorySegmentationData';

interface IUseInventorySummary {
    segmentationInventory: IInventorySegmentationData[]
    selectedFilters: {
        selectedCompanyFilter: IGenericOptions
        selectedSalesChannelFilter: IGenericOptions
        selectedSecondaryFilter: IGenericOptions
        selectedStockGroupFilter: IGenericOptions
        selectedAxis: SelectedAxisOptions
    }
    dataValidation: {
        companyExists: boolean;
        classificationVolumeExists: boolean;
        classificationComplexityExists: boolean;
        classificationUncertaintyExists: boolean;
        salesChannelExists: boolean;
        stockGroupExists: boolean;
    }
}

const useSegmentationInfo = ({ segmentationInventory, selectedFilters, dataValidation }: IUseInventorySummary) => {
    const { selectedCompanyFilter, selectedSalesChannelFilter, selectedSecondaryFilter, selectedStockGroupFilter, selectedAxis } = selectedFilters;

    const [summaryFilter, setSummaryFilter] = useState<IFilter>({} as IFilter);
    const [segmentationFilter, setSegmentationFilter] = useState<IFilterSegmentation>({} as IFilterSegmentation);
    const [inventorySegmentationInfo, setSegmentationSummaryInfo] = useState<ISegmentationSummaryInfo | undefined>({} as ISegmentationSummaryInfo);

    const filterByMainFilters = useCallback(() => {
        const filteredData: {
            segmentationStockGroup: IInventorySegmentationData[];
            segmentationCompany: IInventorySegmentationData[];
            segmentationSales: IInventorySegmentationData[];
        } = {
            segmentationStockGroup: [],
            segmentationCompany: [],
            segmentationSales: [],
        };
        if (!dataValidation.stockGroupExists || selectedStockGroupFilter.id === 0) {
            filteredData.segmentationStockGroup = segmentationInventory;
        } else {
            filteredData.segmentationStockGroup = segmentationInventory.filter(c => c.stockGroupId === selectedStockGroupFilter.id);
        }

        if (!dataValidation.companyExists || selectedCompanyFilter.id === 0) {
            filteredData.segmentationCompany = filteredData.segmentationStockGroup;
        } else {
            filteredData.segmentationCompany = filteredData.segmentationStockGroup.filter(c => c.company?.id === selectedCompanyFilter.id);
        }

        if (!dataValidation.salesChannelExists || selectedSalesChannelFilter.id === 0) {
            filteredData.segmentationSales = filteredData.segmentationCompany;
        } else {
            filteredData.segmentationSales = filteredData.segmentationCompany.filter(c => c.salesChannelId === selectedSalesChannelFilter.id);
        }
        return filteredData;
    }, [dataValidation.companyExists, dataValidation.salesChannelExists, dataValidation.stockGroupExists, segmentationInventory, selectedCompanyFilter.id, selectedSalesChannelFilter.id, selectedStockGroupFilter.id]);


    const filterByAxisFilter = useCallback((segmentationFilter: IFilterSegmentation, filterIdKey: string | undefined) => {
        let segmentationAxisFilter: IInventorySegmentationData[] | undefined = segmentationFilter.filteredBySalesChannel;

        if (selectedSecondaryFilter.id !== 0 && filterIdKey) {
            segmentationAxisFilter = segmentationAxisFilter?.filter((c: any) => c[filterIdKey] === selectedSecondaryFilter.id);
        }

        return { segmentationAxisFilter };
    }, [selectedSecondaryFilter.id]);

    const createLines = useCallback((stockMatrix: any, yAxisIdKey: string | undefined, xAxisClassificationName: string | undefined, segmentationFilter: IFilterSegmentation) => {
        const filteredSegmentationData = segmentationFilter.filteredByAxisFilter;
        const numberOfLines = stockMatrix?.result.y as number;
        const possibilities = stockMatrix.possibilities;
        const validPossibilities = possibilities?.y.filter((value: any) => parseInt(value) <= numberOfLines).length;


        if (!filteredSegmentationData) return;
        if (!validPossibilities) return;

        const segmentationDataLinesSortedByYAxis: Dictionary<IInventorySegmentationData[]> = {};

        for (let i = 0; i < validPossibilities; i++) {
            segmentationDataLinesSortedByYAxis[possibilities.y[i]] = filteredSegmentationData.filter((c: any) => c[yAxisIdKey as string] == possibilities.y[i]);
        }

        const segmentationDataLinesSortedByYAxisNotNull: Dictionary<IInventorySegmentationData[]> = {};

        Object.keys(segmentationDataLinesSortedByYAxis).forEach((key) => {
            const array = segmentationDataLinesSortedByYAxis[key];
            if (array.length > 0) {
                segmentationDataLinesSortedByYAxisNotNull[key] = array;
            }
        });

        const segmentationDataLinesSortedByXAxis: Dictionary<IInventorySegmentationData[]> = {};
        for (const key in segmentationDataLinesSortedByYAxisNotNull) {
            const sortedLines = segmentationDataLinesSortedByYAxisNotNull[key].slice().sort((a: any, b: any) => {
                const priorityA = a[xAxisClassificationName]?.priority || 0;
                const priorityB = b[xAxisClassificationName]?.priority || 0;
                return priorityA - priorityB;
            });
            segmentationDataLinesSortedByXAxis[key] = sortedLines;
        }

        return { segmentationDataLinesSortedByXAxis };
    }, []);


    const segmentationSummaryHelper = useCallback((
        segmentationDataLinesSortedByXAxis: Dictionary<IInventorySegmentationData[] | undefined>,
        axisData: any
    ) => {
        if (segmentationDataLinesSortedByXAxis === undefined) return;
        const numberOfLines = Object.keys(segmentationDataLinesSortedByXAxis).length;

        const columnOptionsDictionary: any = {};
        const rowOptionsDictionary: any = {};

        for (const key in segmentationDataLinesSortedByXAxis) {
            const line = segmentationDataLinesSortedByXAxis[key];

            if (line && line.length > 0) {
                const xAxisData = (line[0] as any)[axisData?.yAxis.classificationName as string];
                const xAxisId = (line[0] as any)[axisData?.yAxis.idKey as string];

                columnOptionsDictionary[key] = { xAxisData, xAxisId };

                for (const key2 in line) {
                    const yAxisData = (line[key2] as any)[axisData?.xAxis.classificationName as string];
                    const yAxisId = (line[key2] as any)[axisData?.xAxis.idKey as string];
                    rowOptionsDictionary[key2] = { yAxisData, yAxisId };
                }
            }
        }

        const segmentationDataLinesArray: IInventorySegmentationData[][] = [];

        for (const key in segmentationDataLinesSortedByXAxis) {
            segmentationDataLinesArray.push(segmentationDataLinesSortedByXAxis[key] || []);
        }
        const rowOptions: any[] = Object.values(columnOptionsDictionary).map((option: any) => option.xAxisData);

        const columnOptions: any[] = [];
        // Object.values(rowOptionsDictionary)
        //     .filter((option: any) => option.yAxisData !== null)
        //     .map((option: any) => option.yAxisData.name);

        if (rowOptions.length === 0) {
            rowOptions.push(t`Geral`);
        }
        const result: ISegmentationSummaryInfo = {
            columnOptions: columnOptions,
            rowOptions: rowOptions,
            numberOfLines: numberOfLines,
            dataLinesArray: segmentationDataLinesArray.filter((c) => c !== undefined),
        };
        return result;
    }, []);


    const applyAxesCriterias = useCallback((axes: {
        yAxis: string;
        xAxis: string;
        filter: string;
    }) => {

        const stockDataSearchCriteriasData = {
            yAxis: axes.yAxis,
            xAxis: axes.xAxis,
            filter: axes.filter,
        };


        return { stockDataSearchCriteriasData };

    }, []);

    const registerFiltersInformation = useCallback((summaryFilter: IFilter) => {

        let filterIdKey: string;
        let filterName: string;
        let filterClassificationName: string;
        switch (summaryFilter.stockDataSearchCriteriasData.filter) {
            case stockDataFilterCriteria.complexity:
                filterIdKey = 'classificationComplexityId';
                filterName = stockDataFilterCriteria.complexity;
                filterClassificationName = 'classificationComplexity';
                break;
            case stockDataFilterCriteria.uncertainty:
                filterIdKey = 'classificationUncertaintyId';
                filterName = stockDataFilterCriteria.uncertainty;
                filterClassificationName = 'classificationUncertainty';
                break;
            case stockDataFilterCriteria.volume:
                filterIdKey = 'classificationVolumeId';
                filterName = stockDataFilterCriteria.volume;
                filterClassificationName = 'classificationVolume';
                break;
            case stockDataFilterCriteria.customer:
                filterIdKey = 'customerGroupId';
                filterName = stockDataFilterCriteria.customer;
                filterClassificationName = 'customerGroup';
                break;
            default:
                return;
        }


        let classificationTypeYPriority: string;
        let classificationTypeYId: string;
        let classificationTypeYName: stockDataFilterCriteria;
        let classificationNameTypeYName: string;

        let classificationTypeXPriority: string;
        let classificationTypeXId: string;
        let classificationTypeXName: stockDataFilterCriteria;
        let classificationNameTypeXName: string;


        switch (summaryFilter.stockDataSearchCriteriasData.yAxis) {
            case stockDataFilterCriteria.uncertainty:
                classificationTypeYPriority = 'classificationUncertaintyPriority';
                classificationTypeYId = 'classificationUncertaintyId';
                classificationTypeYName = stockDataFilterCriteria.uncertainty;
                classificationNameTypeYName = 'classificationUncertainty';
                break;
            case stockDataFilterCriteria.volume:
                classificationTypeYPriority = 'classificationVolumePriority';
                classificationTypeYId = 'classificationVolumeId';
                classificationTypeYName = stockDataFilterCriteria.volume;
                classificationNameTypeYName = 'classificationVolume';
                break;
            case stockDataFilterCriteria.complexity:
                classificationTypeYPriority = 'classificationComplexityPriority';
                classificationTypeYId = 'classificationComplexityId';
                classificationTypeYName = stockDataFilterCriteria.complexity;
                classificationNameTypeYName = 'classificationComplexity';
                break;
            case stockDataFilterCriteria.customer:
                classificationTypeYPriority = 'customerGroupPriority';
                classificationTypeYId = 'customerGroupId';
                classificationTypeYName = stockDataFilterCriteria.customer;
                classificationNameTypeYName = 'customerGroup';
                break;
            default:
                return;
        }
        switch (summaryFilter.stockDataSearchCriteriasData.xAxis) {
            case stockDataFilterCriteria.uncertainty:
                classificationTypeXPriority = 'classificationUncertaintyPriority';
                classificationTypeXId = 'classificationUncertaintyId';
                classificationTypeXName = stockDataFilterCriteria.uncertainty;
                classificationNameTypeXName = 'classificationUncertainty';
                break;
            case stockDataFilterCriteria.volume:
                classificationTypeXPriority = 'classificationVolumePriority';
                classificationTypeXId = 'classificationVolumeId';
                classificationTypeXName = stockDataFilterCriteria.volume;
                classificationNameTypeXName = 'classificationVolume';
                break;
            case stockDataFilterCriteria.complexity:
                classificationTypeXPriority = 'classificationComplexityPriority';
                classificationTypeXId = 'classificationComplexityId';
                classificationTypeXName = stockDataFilterCriteria.complexity;
                classificationNameTypeXName = 'classificationComplexity';
                break;
            case stockDataFilterCriteria.customer:
                classificationTypeXPriority = 'customerGroupPriority';
                classificationTypeXId = 'customerGroupId';
                classificationTypeXName = stockDataFilterCriteria.customer;
                classificationNameTypeXName = 'customerGroup';
                break;
            default:
                return;
        }

        const result = {
            xAxis: { idKey: classificationTypeXId, priorityKey: classificationTypeXPriority, name: classificationTypeXName, classificationName: classificationNameTypeXName },
            yAxis: { idKey: classificationTypeYId, priorityKey: classificationTypeYPriority, name: classificationTypeYName, classificationName: classificationNameTypeYName },
            filter: { idKey: filterIdKey, name: filterName, classificationName: filterClassificationName }
        };
        return result;

    }, []);

    const createStockPolicyMatrix = useCallback((summaryFilter: IFilter) => {
        const segmentationDataByComplexityId = createDictionaryRange(segmentationInventory, x => Number(x.classificationComplexityId));
        const segmentationDataByUncertaintyId = createDictionaryRange(segmentationInventory, x => Number(x.classificationUncertaintyId));
        const segmentationDataByVolumeId = createDictionaryRange(segmentationInventory, x => Number(x.classificationVolumeId));
        const segmentationDataByCustomerGroupId = createDictionaryRange(segmentationInventory, x => Number(x.customerGroupId));

        const xAxis = summaryFilter.stockDataSearchCriteriasData.xAxis;
        const yAxis = summaryFilter.stockDataSearchCriteriasData.yAxis;
        const filter = summaryFilter.stockDataSearchCriteriasData.filter;

        let xAxisLength: number;
        let yAxisLength: number;
        let filterLength: number;
        let xPossibilities: string[];
        let yPossibilities: string[];
        let filterPossibilities: string[];

        switch (xAxis) {
            case stockDataFilterCriteria.complexity:
                xAxisLength = parseInt(Object.keys(segmentationDataByComplexityId)[Object.keys(segmentationDataByComplexityId).length - 1]);
                xPossibilities = Object.keys(segmentationDataByComplexityId);
                break;
            case stockDataFilterCriteria.uncertainty:
                xAxisLength = parseInt(Object.keys(segmentationDataByUncertaintyId)[Object.keys(segmentationDataByUncertaintyId).length - 1]);
                xPossibilities = Object.keys(segmentationDataByUncertaintyId);
                break;
            case stockDataFilterCriteria.volume:
                xAxisLength = parseInt(Object.keys(segmentationDataByVolumeId)[Object.keys(segmentationDataByVolumeId).length - 1]);
                xPossibilities = Object.keys(segmentationDataByVolumeId);
                break;
            case stockDataFilterCriteria.customer:
                xAxisLength = parseInt(Object.keys(segmentationDataByCustomerGroupId)[Object.keys(segmentationDataByCustomerGroupId).length - 1]);
                xPossibilities = Object.keys(segmentationDataByCustomerGroupId);
                break;
            default:
                return;
        }
        switch (yAxis) {
            case stockDataFilterCriteria.complexity:
                yAxisLength = parseInt(Object.keys(segmentationDataByComplexityId)[Object.keys(segmentationDataByComplexityId).length - 1]);
                yPossibilities = Object.keys(segmentationDataByComplexityId);
                break;
            case stockDataFilterCriteria.uncertainty:
                yAxisLength = parseInt(Object.keys(segmentationDataByUncertaintyId)[Object.keys(segmentationDataByUncertaintyId).length - 1]);
                yPossibilities = Object.keys(segmentationDataByUncertaintyId);
                break;
            case stockDataFilterCriteria.volume:
                yAxisLength = parseInt(Object.keys(segmentationDataByVolumeId)[Object.keys(segmentationDataByVolumeId).length - 1]);
                yPossibilities = Object.keys(segmentationDataByVolumeId);
                break;
            case stockDataFilterCriteria.customer:
                yAxisLength = parseInt(Object.keys(segmentationDataByCustomerGroupId)[Object.keys(segmentationDataByCustomerGroupId).length - 1]);
                yPossibilities = Object.keys(segmentationDataByCustomerGroupId);
                break;
            default:
                return;
        }
        switch (filter) {
            case stockDataFilterCriteria.complexity:
                filterLength = parseInt(Object.keys(segmentationDataByComplexityId)[Object.keys(segmentationDataByComplexityId).length - 1]);
                filterPossibilities = Object.keys(segmentationDataByComplexityId);
                break;
            case stockDataFilterCriteria.uncertainty:
                filterLength = parseInt(Object.keys(segmentationDataByUncertaintyId)[Object.keys(segmentationDataByUncertaintyId).length - 1]);
                filterPossibilities = Object.keys(segmentationDataByUncertaintyId);
                break;
            case stockDataFilterCriteria.volume:
                filterLength = parseInt(Object.keys(segmentationDataByVolumeId)[Object.keys(segmentationDataByVolumeId).length - 1]);
                filterPossibilities = Object.keys(segmentationDataByVolumeId);
                break;
            case stockDataFilterCriteria.customer:
                filterLength = parseInt(Object.keys(segmentationDataByCustomerGroupId)[Object.keys(segmentationDataByCustomerGroupId).length - 1]);
                filterPossibilities = Object.keys(segmentationDataByCustomerGroupId);
                break;
            default:
                return;
        }
        if (!xAxisLength || !yAxisLength || !filterLength) {
            return {
                x: 0,
                y: 0,
                z: 0
            };
        }
        const result = {
            x: xAxisLength,
            y: yAxisLength,
            z: filterLength
        };
        const possibilities = {
            x: xPossibilities,
            y: yPossibilities,
            z: filterPossibilities
        };

        return { result, possibilities };

    }, [segmentationInventory]);

    useEffect(() => {
        const summaryFilter: IFilter = {} as IFilter;
        const segmentationFilter: IFilterSegmentation = {} as IFilterSegmentation;


        const { segmentationCompany, segmentationSales, segmentationStockGroup } = filterByMainFilters();

        segmentationFilter.filteredByStockGroup = segmentationStockGroup;
        segmentationFilter.filteredByCompany = segmentationCompany;
        segmentationFilter.filteredBySalesChannel = segmentationSales;

        const { stockDataSearchCriteriasData } = applyAxesCriterias(selectedAxis);
        summaryFilter.stockDataSearchCriteriasData = stockDataSearchCriteriasData;


        const axisObjectJSON = localStorage.getItem('axisObject');
        if (axisObjectJSON) {
            summaryFilter.stockDataSearchCriteriasData = JSON.parse(axisObjectJSON);
        }

        const axisData = registerFiltersInformation(summaryFilter);
        const stockMatrix = createStockPolicyMatrix(summaryFilter);


        summaryFilter.axisData = axisData;
        summaryFilter.stockMatrix = stockMatrix?.result;
        summaryFilter.axisPossibilities = stockMatrix?.possibilities;



        const { segmentationAxisFilter } = filterByAxisFilter(segmentationFilter, axisData?.filter.idKey);
        segmentationFilter.filteredByAxisFilter = segmentationAxisFilter;

        const lines = createLines(stockMatrix, axisData?.yAxis.idKey, axisData?.xAxis.classificationName, segmentationFilter);


        let segmentationHelper: ISegmentationSummaryInfo | undefined = {} as ISegmentationSummaryInfo;

        if (lines && lines.segmentationDataLinesSortedByXAxis) {
            segmentationHelper = segmentationSummaryHelper(lines.segmentationDataLinesSortedByXAxis, axisData);
        } else {
            console.error('mainDataLinesSortedByXAxis or segmentationDataLinesSortedByXAxis is undefined');
        }


        setSegmentationSummaryInfo(segmentationHelper);
        setSegmentationFilter(segmentationFilter);
        setSummaryFilter(summaryFilter);

    }, [applyAxesCriterias, createLines, selectedAxis, createStockPolicyMatrix, filterByAxisFilter, filterByMainFilters, registerFiltersInformation, segmentationSummaryHelper]);
    return { inventorySegmentationInfo, segmentationFilter, summaryFilter, applyAxesCriterias };


};

export default useSegmentationInfo;
