import { useCallback, useEffect, useRef, useState } from 'react';
import { useHubContext, getAsync, putAsync } from '@nplan';

interface IUseNotificationDataReturn {
    notifications: AppNotification[];
    totalUnreaded: number;
    handleRefreshNotificationsAsync: () => Promise<void>;
    handleLoadMoreNotificationsAsync: () => Promise<void>;
    handleMarkAsReadAsync: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
    handleMarkAllAsReadAsync: () => Promise<void>;
}

interface INotificationData {
    totalUnreaded: number;
    notifications: AppNotification[];
}

export const useNotificationData = (): IUseNotificationDataReturn => {
    const [notifications, setNotifications] = useState<AppNotification[]>([]);
    const [page, setPage] = useState<number>(0);
    const [totalUnreaded, setTotalUnreaded] = useState<number>(0);

    const { hubConnection } = useHubContext();

    const reading = useRef<boolean>(false);

    const handleRefreshNotificationsAsync = useCallback(async (): Promise<void> => {
        if (reading.current) return;
        reading.current = true;
        const response = await getAsync<{ totalUnreaded: number; notifications: AppNotification[];}>('app-notifications');
        if (response.type === 'success' && response.data) {
            setNotifications(response.data.notifications);
            setTotalUnreaded(response.data.totalUnreaded);
            setPage(0);
        }
        reading.current = false;
    }, []);

    const handleLoadMoreNotificationsAsync = useCallback(async (): Promise<void> => {
        if (reading.current) return;
        reading.current = true;
        const response = await getAsync<INotificationData>(`app-notifications/${page + 1}`);
        if (
            response.type === 'success' &&
            response.data &&
            response.data.notifications.length > 0
        ) {
            const { notifications, totalUnreaded } = response.data;
            setNotifications((prevNotifications) => {
                const existingIds = new Set(prevNotifications.map((notif) => notif.id));
                const newNotifications = notifications.filter(
                    (notif) => !existingIds.has(notif.id),
                );
                return [...prevNotifications, ...newNotifications];
            });
            setTotalUnreaded(totalUnreaded);
            setPage((currentPage) => currentPage + 1);
        }
        reading.current = false;
    }, [page]);

    const handleMarkAsReadAsync = useCallback(async (
        event: React.MouseEvent<HTMLButtonElement>
    ): Promise<void> => {
        const id = event.currentTarget.dataset.id;
        if (id) {
            const response = await putAsync<string>(`app-notifications/${id}/read`);
            if (response.type === 'success') {
                setNotifications((prevNotifications) => {
                    const notification = prevNotifications.find((notif) => notif.id === parseInt(id));
                    if (notification) {
                        notification.readed = true;
                    }
                    return [...prevNotifications];
                });
            }
        }
    }, []);

    const handleMarkAllAsReadAsync = useCallback(async (): Promise<void> => {
        const response = await putAsync<string>(`app-notifications/read-all`);
        if (response.type === 'success') {
            handleRefreshNotificationsAsync();
        }
    }, [handleRefreshNotificationsAsync]);

    useEffect(() => {
        handleRefreshNotificationsAsync();
    }, [handleRefreshNotificationsAsync]);

    useEffect(() => {
        if (hubConnection) {
            hubConnection.on('RefreshNotifications', handleRefreshNotificationsAsync);
            return () => hubConnection.off('RefreshNotifications', handleRefreshNotificationsAsync);
        }
    }, [handleRefreshNotificationsAsync, hubConnection]);
    
    return {
        notifications,
        totalUnreaded,
        handleRefreshNotificationsAsync,
        handleLoadMoreNotificationsAsync,
        handleMarkAsReadAsync,
        handleMarkAllAsReadAsync,
    };
};
