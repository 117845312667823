
import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';
import { useConfirmContext, deleteAsync } from '@nplan';
import React from 'react';

interface IUseRoleActionsReturn {
    handleDeleteRoleAsync: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
}
export const useRoleActions = (): IUseRoleActionsReturn => {
    const confirm = useConfirmContext();
    const handleDeleteRoleAsync = async (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        const id = parseInt(event.currentTarget.dataset.id as string);
        const role = event.currentTarget.dataset.name as string;
        const confirmed = await confirm({
            description: (
                <React.Fragment>
                    <Typography>
                        <Trans>
                            Deseja realmente deletar o grupo{' '}
                            <strong>{role}</strong>?
                        </Trans>
                    </Typography>
                </React.Fragment>
            ),
        });
        if (confirmed) {
            await deleteAsync(`roles/${id}`);
        }
    };

    return { handleDeleteRoleAsync };
};
