import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useDataGridContext } from '../../../../providers/hooks';
import { useCallback } from 'react';

interface ISelectEditionProps {
    defaultValue: string;
    onClose: () => void;
    onSave: (value: any) => void;
    nullable: boolean;
    nullText: string | null;
    columnId: number;
}
export const SelectEditor: React.FC<ISelectEditionProps> = ({
    defaultValue,
    onClose,
    onSave,
    nullable,
    nullText,
    columnId

}): JSX.Element => {
    const handleSave = useCallback((event: SelectChangeEvent<string>) => {
        onSave(event.target.value);
    },[onSave]);
    const {
        foreignFields
    } = useDataGridContext();
    if(!foreignFields) return <></>;
    return (
        <Select
            defaultValue={defaultValue ?? 'null'}
            fullWidth
            defaultOpen
            sx={{
                height: 35,
            }}
            inputProps={{
                sx: {
                    heigth: 35,
                },
            }}
            onClose={onClose}
            onChange={handleSave}
        >
            {nullable && (
                <MenuItem key={-1} value={'null'}>
                    {nullText ?? 'NULL'}
                </MenuItem>
            )}
            {Object
                .entries(foreignFields[columnId])
                .sort((a, b) => a[1].localeCompare(b[1]))
                .map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                        {value}
                    </MenuItem>
                ))
            }
        </Select>
    );
};
