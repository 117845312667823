import { createContext, CSSProperties, UIEventHandler } from 'react';
import { IScroll } from '../hooks/useScroll';
import { NumberOperator, TextOperator } from '@nplan';
import { SelectChangeEvent } from '@mui/material';



export interface IDataGridProps {
    scroll: IScroll;
    onScrollChange: UIEventHandler<HTMLDivElement>;
    gridTemplateColumns: CSSProperties['gridTemplateColumns'];
    gridTemplateRows: CSSProperties['gridTemplateRows'];
    columns: Column[];
    allColumns: Column[];
    rows: any[];
    allRows: any[];
    width: CSSProperties['width'];
    onContainerResize: (target: HTMLDivElement) => void;
    onTableIdChange: (id: number | null) => void;
    table: Table | undefined;
    foreignFields: ForeignFields | null;
    onFilterColumnSignalChange: (column: Column, signal: TextOperator | NumberOperator) => void;
    onFilterColumnValueChange: (column: Column, value: any) => void;
    getDistinctValuesForColumn: (field: string) => any[];
    onSortColumnChange: (id: number) => void;
    onRefreshDataAsync: () => Promise<void>;
    maxIndex: number;
    editiorAll: boolean;
    onEditiorAllChange: () => void;
    currentIdsFiltered: any[];
    buttons: Button[];
    onCallProcedureAsync: (event: React.MouseEvent<HTMLButtonElement | HTMLLIElement>) => Promise<void>;

    requiredFilterOptions: RequiredFilterOptions[];
    requiredFilterValue: string | null;
    onRequiredFilterChange: (event: SelectChangeEvent<string>) => void;
    onResetRequiredFilter: () => void;
    loading: boolean;
    getGroups: GroupInfo[];
}
export const DataGrid = createContext<IDataGridProps | null>(null);