import { FC } from 'react';
import { useCellData } from './hooks/useCellData';
import { Editor } from './Editor';
import { Render } from './Render';
export interface ICell {
    columnKey: keyof Column;
    column: Column;
    type: 'string' | 'checkbox' | 'color' | 'select' | 'textarea';
    selects: Align[] | ViewType[] | FilterType[];
}

export const Cell: FC<ICell> = ({
    columnKey,
    column,
    type,
    selects,
}) => {
    const {
        editing,
        value,
        handleEditingTrue,
        handleChangeInput,
        handleSave,
        handleCancel,
        handleChangeCheckbox,
        handleChangeSelect,
    } = useCellData(columnKey, column);

    return editing ? (
        <Editor
            type={type}
            value={value}
            onChangeInput={handleChangeInput}
            onChangeCheckbox={handleChangeCheckbox}
            onChangeSelect={handleChangeSelect}
            onSave={handleSave}
            onCancel={handleCancel}
            selects={selects}
        />
    ) : (
        <Render
            onEditingTrue={handleEditingTrue}
            type={type}
            value={value}
            selects={selects}
            columnKey={columnKey}
        />
    );
};
