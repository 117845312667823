import { embedDashboard } from '@superset-ui/embedded-sdk';
import {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { getAsync, postAsync } from '@nplan';

export const useReportViewData = () => {
    const { id } = useParams();
    const [config, setConfig] = useState<ReportConfig>();
    const [report, setReport] = useState<NReport>();
    const [dashId, setDashId] = useState<string>();
    const ref = useRef<HTMLElement>(null);

    const fetchGuestTokenAsync = useCallback(async (): Promise<string> => {
        if(!dashId) return '';
        const response = await postAsync<string>(`report-token/${dashId}`,);
        if (
            response.type === 'success' &&
            response.data
        ) {
            return response.data;
        }
        return '';
    }, [dashId]);

    useEffect(() => {
        (async () => {
            if (report === undefined) return;
            if (report.typeId !== 1) {
                setConfig(undefined);
                return;
            } else {
                setDashId(report.url);
            }
            const response = await getAsync<ReportConfig>(`reports-config`,);
            if (
                response.type === 'success' &&
                response.data
            ) {
                setConfig(response.data);
            }
        })();
    }, [report]);

    useEffect(() => {
        (async () => {
            const response = await getAsync<NReport>(`reports/${id}`);
            if (response.type === 'success') {
                setReport(response.data);
            }
        })();
    }, [id]);

    useEffect(() => {
        (async () => {
            if (!config) return;
            if (!dashId) return;
            if (ref.current === null) return;
            await embedDashboard({
                id: dashId,
                supersetDomain: config.url,
                mountPoint: ref.current,
                fetchGuestToken: fetchGuestTokenAsync,
            });
            const container = ref.current;
            const iframe = container
                ?.childNodes[0] as HTMLIFrameElement;
            if (iframe) {
                iframe.style.height = '100%';
                iframe.style.width = '100%';
            }
        })();
    }, [config, fetchGuestTokenAsync, ref, dashId]);
    
    return { ref, report };
};
