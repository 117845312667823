import { Box, Typography } from '@mui/material';
import { getJustifyContent } from 'pages/table-data/utils';
import React, { memo } from 'react';
import { getContrastColor } from 'utils';

interface IColorRenderProps {
    row: any;
    column: Column;
}

export const ColorRender: React.FC<IColorRenderProps> = ({
    row,
    column,
}: IColorRenderProps): JSX.Element => {
    return (
        <Box
            sx={{
                height: '100%',
                width: column.width,
                maxWidth: column.width,
                display: 'flex',
                alignItems: 'center',
                padding: '0 5px',
                boxSizing: 'border-box',
                justifyContent: getJustifyContent(column.alignId),
            }}
            title={row[column.field]}
        >
            {/* Renderiza um Switch para controlar o val        or booleano */}
            <Box
                sx={{
                    backgroundColor: row[column.field] as string,
                    borderRadius: 5,
                    width: '70px',
                    height: 25,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    color={getContrastColor(row[column.field] as string)}
                    fontSize={13}
                    fontWeight="bold"
                >
                    {row[column.field]?.toUpperCase()}
                </Typography>
            </Box>
        </Box>
    );
};

const MemoizedColorRender = memo(ColorRender);
export default MemoizedColorRender;
