import { useCallback, useState } from 'react';

export interface ContainerSize {
    x: number;
    y: number;
}
interface IUseContainerSizer {
    containerSize: ContainerSize;
    handleContainerSize: (target: HTMLDivElement) => void;
}

export const useContainerSizer = (): IUseContainerSizer => {
    const [containerSize, setContainerSize] = useState<ContainerSize>({ x: 0, y: 0 });
    const handleContainerSize = useCallback((target: HTMLDivElement) => {
        const { clientHeight, clientWidth } = target;
        setContainerSize({ x: clientWidth, y: clientHeight });
    },[]);
    return { containerSize, handleContainerSize };
};