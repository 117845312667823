import { i18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import { TableCell, TableHead, TableRow, TextField } from '@mui/material';
import React from 'react';

interface IHeaderProps {
    onFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    filter: string;
}

const columns: string[] = [
    'Título',
    'Banco',
    'Esquema',
    'Tabela',
    'Grupo',
    'Inserir',
    'Deletar',
    'Parâmetro',
    'Filtro Obrigatório',
];

export const Header: React.FC<IHeaderProps> = ({
    onFilterChange,
    filter,
}: IHeaderProps): JSX.Element => {
    return (
        <TableHead>
            <TableRow
                sx={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 10,
                    bgcolor: 'background.paper',
                }}
            >
                {columns.map((column) => (
                    <TableCell key={column}>
                        <Trans>
                            {i18n.t(column)}
                        </Trans>
                    </TableCell>
                ))}
                <TableCell colSpan={5}>
                    <TextField
                        placeholder={t`Filtrar`}
                        fullWidth
                        onChange={onFilterChange}
                        value={filter}
                    />
                </TableCell>
            </TableRow>
        </TableHead>
    );
};
