import { SelectChangeEvent } from '@mui/material';
import { useEffect, useState, useCallback } from 'react';
import { getAsync, putAsync } from 'services';

interface IUseMenuPermissionReturn {
    menuPermissions: MenuPermission[];
    menus: Menu[];
    handleChangeMenuPermission: (event: SelectChangeEvent<number[]>) => void;
    handleSaveMenuPermission: () => Promise<void>;
}
export const useMenuPermission = (
    id: number, 
    open: boolean,
    onClose: () => void
): IUseMenuPermissionReturn => {
    const [menuPermissions, setMenuPermissions] = useState<MenuPermission[]>([]);
    const [menus, setMenus] = useState<Menu[]>([]);
    const handleChangeMenuPermission = (event: SelectChangeEvent<number[]>) => {
        const menuIds = event.target.value as number[];
        const newMenuPermissions = menus
            .filter((menu) => menuIds.includes(menu.id))
            .map((menu) => ({
                roleId: id,
                menuId: menu.id,
                id: 0,
            }));
        setMenuPermissions(newMenuPermissions);
    };
    const handleSaveMenuPermission = useCallback(async (): Promise<void> => {
        const res = await putAsync(`menu-permissions/role/${id}`, menuPermissions);
        if(res.type === 'success') onClose();
    }, [id, menuPermissions, onClose]);

    useEffect(() => {
        if(!open) return;
        (async () => {
            const [resMenuPermissions, resMenus] = await Promise.all([
                getAsync<MenuPermission[]>(`/menu-permissions/role/${id}`),
                getAsync<Menu[]>('/menus'),
            ]);
            setMenuPermissions(resMenuPermissions.data ?? []);
            setMenus(resMenus.data ?? []);
        })();
    }, [id, open]);
    return {
        menuPermissions,
        menus,
        handleChangeMenuPermission,
        handleSaveMenuPermission
    };
};