import { useAppSelector } from "redux/hooks";
import React from "react";
import { AttributeList } from "redux/types";
import DropDownTreeSelectorContainer from "../components/DropDownTreeSelectorContainer";
import { FilterCriteriaToSelector } from "../FilterCriteria";
import { Box } from "@mui/material";
import { AttributesInfoBox } from "./AttributesInfoBoxContainer";
import { FilterCriteria } from "../FilterCriteria/types";


export function AttributesDropDownContainer() {
    const selectedAttributesToShow = useAppSelector(
        (state) => state.filter.planningGrid.selectedAttributesToShow
    );
    return (
        <>
            {selectedAttributesToShow.map((value: AttributeList, index) => {
                return (
                    <Box sx={{ position: "relative" }} key={value.label}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", gap: '2rem' }}>
                            <DropDownTreeSelectorContainer
                                key={value.label}
                                filterCriteria={(FilterCriteria as any)[value.label]}
                                selector={FilterCriteriaToSelector(
                                    (FilterCriteria as any)[value.label]
                                )}
                                placeholder={value.value}
                            />
                            <AttributesInfoBox filterCriteria={(FilterCriteria as any)[value.label]} />
                        </Box>
                    </Box>
                );
            })}
        </>
    );
}

export const AttributesDropDownContainerMemoized = React.memo(
    AttributesDropDownContainer
);
