import { Trans } from '@lingui/macro';
import { Box, Typography } from '@mui/material';

export const Header = () => {
    return (
        <Box height={40} sx={{ height: '100%', width: '100%' }}>
            <Typography
                variant={'h6'}
                whiteSpace={'nowrap'}
                bgcolor={'primary.main'}
                textAlign={'center'}
                padding={2}
                color={'black'}
            >
                <Trans>Histórico de edição de dados</Trans>
            </Typography>
        </Box>
    );
};
