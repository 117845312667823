import React, { useState } from 'react';
import { Popover, Box, Typography, Button, useTheme } from '@mui/material';
import { IoOptions } from 'react-icons/io5';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setSelectedAxis } from 'redux/Filters/reducer';
import { Trans, t } from '@lingui/macro';
import { optionsToLanguage } from 'locales/helper';
import { TooltipSimplesComponent } from 'components/TippyComponent';
import { IFilter, stockDataFilterCriteria } from 'pages/inventory-policy/types/IFilters';
import { basicButtonStyle } from 'pages/inventory-policy/styles';
import { stockAxesEnum } from 'pages/inventory-policy/utils/translatedFilters';
import DropDownSelector from './GenericSelector';
interface AxesProps {
    xAxis: string;
    yAxis: string;
    filter: string;
}
interface AxisSelectorProps {
    filter: IFilter
}

const AxisSelector: React.FC<AxisSelectorProps> = ({ filter }: AxisSelectorProps): JSX.Element => {
    const axes = useAppSelector(state => state.filter.inventory.selectedAxis);
    const axisOptions = useAppSelector(state=>state.filter.inventory.axisOptions);

    const dispatch = useAppDispatch();
    const { axisData } = filter;
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const [axesObject, setAxesObject] = useState<AxesProps>(axes as AxesProps);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // Define common options
    const commonOptions = [
        { id: 1, name: stockDataFilterCriteria.volume, code: stockDataFilterCriteria.volume },
        { id: 2, name: stockDataFilterCriteria.complexity, code: stockDataFilterCriteria.complexity },
        { id: 3, name: stockDataFilterCriteria.uncertainty, code: stockDataFilterCriteria.uncertainty },
    ];

    // Initialize axesOptions based on the condition
    const axesOptions = [
        {
            name: stockAxesEnum.xAxis,
            options: commonOptions.concat(axisOptions.Customer.length > 1 ? [{ id: 4, name: stockDataFilterCriteria.customer, code: stockDataFilterCriteria.customer }] : []),
        },
        {
            name: stockAxesEnum.yAxis,
            options: commonOptions,
        },
        {
            name: stockAxesEnum.filter,
            options: commonOptions,
        },
    ];



    function handleAxes(optionId: number, axes: string) {
        let updatedAxesObject;  // Declare the variable outside the switch block
        switch (axes) {
            case 'xAxis':
                updatedAxesObject = axesOptions[0].options.find(option => option.id === optionId);
                setAxesObject({ ...axesObject, xAxis: updatedAxesObject?.name as string });
                break;
            case 'yAxis':
                updatedAxesObject = axesOptions[1].options.find(option => option.id === optionId);
                setAxesObject({ ...axesObject, yAxis: updatedAxesObject?.name as string });
                break;
            case 'filter':
                updatedAxesObject = axesOptions[2].options.find(option => option.id === optionId);
                setAxesObject({ ...axesObject, filter: updatedAxesObject?.name as string });
                break;
            default:
                break;
        }
    }

    const handleApplyFilters = () => {
        const { filter, xAxis, yAxis } = axesObject;

        if (
            filter === xAxis ||
            filter === yAxis ||
            xAxis === yAxis ||
            filter === null ||
            filter === undefined ||
            xAxis === null ||
            xAxis === undefined ||
            yAxis === null ||
            yAxis === undefined
        ) {
            toast.warn(t`Os eixos não podem ter o mesmo valor, ou serem nulos!`, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            return;
        } else {
            const axisObject = {
                filter,
                xAxis,
                yAxis
            };

            const axisObjectJSON = JSON.stringify(axisObject);
            localStorage.setItem('axisObject', axisObjectJSON);

            dispatch(setSelectedAxis({ axisOptions: axisObject }));


            toast.success(t`Os eixos foram alterados com sucesso`, {
                position: 'bottom-center',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            setAnchorEl(null);
        }
    };




    // const handleSelectChange = (event, option) => {
    //     setSelectedOptions({
    //         ...selectedOptions,
    //         [option]: event.target.value,
    //     });
    // };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined; '';
    return (
        <div >
            <TooltipSimplesComponent onClickFunction={handleClick as any} tooltipText={t`Alterar Eixos`} >
                <IoOptions size={20} />
            </TooltipSimplesComponent>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}

            >
                <Box
                    data-testid="axis-selector"
                    p={2}
                    sx={{
                        display: 'flex', flexDirection: 'column', gap: 2,
                        borderColor: theme.palette.background.paper,
                        border: '1px solid'

                    }}>

                    {axesOptions.map(options => {
                        const enumKey = Object.keys(stockAxesEnum).find(key => (stockAxesEnum as any)[key] === options.name);
                        const axisDataByAxis = (axisData as any)[enumKey!];
                        const axisName = axisDataByAxis.name;
                        const selectedFilterArray = options.options.map(c => {
                            if (c.name === axisName) {
                                return c;
                            } else {
                                return;
                            }
                        });
                        const selectedFilter = (selectedFilterArray.filter(c => c)[0]);
                        return (
                            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }} key={options.name}>
                                <Typography variant='body2' whiteSpace={'nowrap'} width={100}>{optionsToLanguage(options.name)}</Typography>
                                <DropDownSelector
                                    axisName={optionsToLanguage(enumKey!) as string}
                                    handleSelect={handleAxes}
                                    filterOptions={options.options}
                                    selectedFilter={selectedFilter!}
                                />
                            </Box>
                        );
                    })}

                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, marginTop: '0.5rem' }}>
                        <Button sx={basicButtonStyle} onClick={() => handleApplyFilters()}><Typography variant='body2'><Trans>Aplicar Filtros</Trans></Typography></Button>
                        <Button sx={basicButtonStyle} onClick={() => handleClose()}><Typography variant='body2'><Trans>Cancelar</Trans></Typography></Button>
                    </Box>
                </Box>
            </Popover>
        </div>
    );

};

export default AxisSelector;
