import { CSSProperties } from "react";

export const getTextAlign = (alignId: number): CSSProperties['textAlign'] => {
    switch (alignId) {
        case 1:
            return 'left';
        case 2:
            return 'center';
        case 3:
            return 'right';
        default:
            return 'left';
    }
}