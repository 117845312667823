import { t } from '@lingui/macro';
import { getAsync, putAsync, useDataContext, useHubContext } from '@nplan';
import { PlanningGridData } from 'pages/planning-grid/types/PlanningGrid';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { recalculateItemidsByStockStatus, setChartSelectedItems, setFiniteButtonLoading, setPeriods, setPlanningGrid, setPlanningGridOperationResourcesRedux, setReduxSelectedResources } from 'redux/Filters/reducer';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { IItem } from 'types/data/IItem';
import { ICapacity, IPlanningGrid, IPlanningGridOperationResource } from 'types/scenario';
import { IPeriod } from 'types/scenarios/IPeriod';

interface IUseOccupationData {
    resources: Resource[];
    selectedResources: number[];
    planningGridOperationResources: IPlanningGridOperationResource[];
    capacities: ICapacity[];
    setupGrouped: boolean;
    onlyAllocated: boolean
    allocatedResources: Resource[]
    handleSelectResource: (event: number[]) => void;
    handleSelectWorkCenter: (event: number[]) => void;
    handleSetupGrouped: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleOnlyAllocated: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleFiniteScenarioAsync: (event: React.MouseEvent<HTMLButtonElement>) => void;
    handleRefreshRefreshPGOR: () => Promise<void>
    groupedResources: any[]
    handleTextSearch: (event: any) => void
    handleResourcesByItemId: (selectedItemId: number | undefined) => void
    items: IItem[]
}

export const useOccupationData = (open?: boolean): IUseOccupationData => {
    const itemIdsByResourceId = useAppSelector(state => state.filter.planningGrid.itemIdByResourceId);
    const selectedItemIds = useAppSelector(state => state.filter.global.chartSelectedItemIds);
    const globalItems = useAppSelector(state => state.filter.global.items);
    const globalResources = useAppSelector(state => state.filter.planningGrid.selectedResources);
    const dispatch = useAppDispatch();
    const { scenario } = useDataContext();
    const { hubConnection } = useHubContext();
    const [items, setItems] = useState<IItem[]>([]);
    // const { handleRefreshPlanning } = usePlanningGrid(scenario)
    const [resources, setResources] = useState<Resource[]>([]);
    const [allocatedResources, setAllocatedResources] = useState<Resource[]>([]);
    const [setupGrouped, setSetupGrouped] = useState<boolean>(false);
    const [capacities, setCapacities] = useState<ICapacity[]>([]);
    const [selectedResources, setSelectedResources] = useState<number[]>([]);
    const [onlyAllocated, setOnlyAllocated] = useState<boolean>(false);
    const [groupedResources, setGroupedResources] = useState([]);
    const [text, setText] = useState('');
    const [planningGridOperationResources, setPlanningGridOperationResources] = useState<IPlanningGridOperationResource[]>([]);

    const handleResourcesByItemId = useCallback((selectedItemId: number | undefined) => {
        if (selectedItemId === undefined) return;
        dispatch(setChartSelectedItems([selectedItemId]));
        const resourceIds: number[] = [];
        // Ensure selectedItemId is defined before proceeding
        if (selectedItemId !== undefined) {
            for (const [resourceIdStr, itemIds] of Object.entries(itemIdsByResourceId)) {
                const resourceId = parseInt(resourceIdStr);
                // Check if any itemIds in the current resource match the selectedItemId
                if (itemIds.includes(selectedItemId)) {
                    resourceIds.push(resourceId);
                }
            }
        }
        dispatch(setReduxSelectedResources({ selectedResources: resourceIds }));
        setSelectedResources(resourceIds);
    }, [dispatch, itemIdsByResourceId]);


    useEffect(() => {
        if (open && selectedItemIds.length === globalItems.length) {
            setSelectedResources([]);
            dispatch(setReduxSelectedResources({ selectedResources: [] }));
        }
    }, [selectedItemIds.length, globalItems.length, open, dispatch]);

    useEffect(() => {
        (async () => {

            const [resource, item] = await Promise.all([
                getAsync<Resource[]>('/resource'),
                getAsync<IItem[]>('/items')
            ]);
            if (item.type === 'success' && item.data) {
                setItems(item.data);
            }
            if (resource.type === 'success' && resource.data) {
                setResources(resource.data.filter(c => c.active));
            }
        })();
    }, []);

    const handleTextSearch = useCallback((searchText: string) => {
        setText(searchText);
        return;
    }, []);

    const handleGroupedResources = useCallback((resources: Resource[]) => {
        const workcenterIds = new Set(); // Set to store unique workcenterIds
        const usedResources = onlyAllocated ? allocatedResources : resources;

        const groupedResources = usedResources.reduce((accumulator: any, resource) => {
        // If workcenter is null, push the resource into the 'All' group
            if (!resource.workcenter) {
                const allGroupIndex = accumulator.findIndex((group: any) => group.workcenterId === t`todas`);
                if (allGroupIndex === -1) {
                    accumulator.push({
                        workcenterId: t`todas`,
                        workcenterName: t`Todas`,
                        resources: [resource]
                    });
                } else {
                    accumulator[allGroupIndex].resources.push(resource);
                }
                return accumulator;
            }

            // Check if workcenterId is already added
            if (!workcenterIds.has(resource.workcenter.id)) {
                workcenterIds.add(resource.workcenter.id); // Add workcenterId to Set
                accumulator.push({
                    workcenterId: resource.workcenter.id,
                    workcenterName: resource.workcenter.name,
                    resources: [resource] // Initialize resources array with the resourceId
                });
            } else {
            // Find the corresponding group and push the resourceId
                const groupIndex = accumulator.findIndex((group: any) => group.workcenterId === resource.workcenter?.id);
                if (groupIndex !== -1) {
                    accumulator[groupIndex].resources.push(resource);
                }
            }
            return accumulator;
        }, []);

        if (text === '') {
            setGroupedResources(groupedResources);
            return;
        }
        const result = groupedResources.filter((groupResource: any) =>
            groupResource.resources.some((resource: any) =>
                resource.name.toLowerCase().includes(text.toLowerCase())
            )
        );
        setGroupedResources(result);
    }, [allocatedResources, onlyAllocated, text]);

    useEffect(() => {
        if (resources.length > 0) { handleGroupedResources(resources); }
    }, [handleGroupedResources, resources]);

    useEffect(() => {
        if (!open) {     
            setSelectedResources([]); 
            dispatch(setReduxSelectedResources({ selectedResources: [] }));

        }
    }, [dispatch, open]);

    useEffect(() => {
        if (scenario?.id === undefined) return;
        const scenarioId = scenario.id;
        (async () => {
            const [
                responseCapacities,
                responsePlanningGridOperationResources,
            ] = await Promise.all([
                getAsync<ICapacity[]>(`/capacity/${scenarioId}`),
                getAsync<IPlanningGridOperationResource[]>(
                    `/planningGridOperationResource/${scenarioId}`,
                ),
            ]);
            if (responsePlanningGridOperationResources.type === 'success' && responsePlanningGridOperationResources.data) {
                const planningGridOperationResourceOrderedByPeriodId = responsePlanningGridOperationResources.data.sort((a, b) => a.periodId - b.periodId);

                setPlanningGridOperationResources(planningGridOperationResourceOrderedByPeriodId);
                dispatch(setPlanningGridOperationResourcesRedux({ planningGridOperationResource: planningGridOperationResourceOrderedByPeriodId }));
            }
            if (
                responseCapacities.type === 'success' &&
            responseCapacities.data
            ) {
                setCapacities(responseCapacities.data);
            } else {
                setCapacities([]);
            }
        })();
    }, [scenario?.id]);

    const handleSelectResource = useCallback(
        (event: number[]) => {
            const validationValue = event[event.length - 1];
            if (validationValue === -2) {
            // Deselect all
                setSelectedResources([]);
                return;
            }
            if (validationValue === -1) {
            // Select all
                setSelectedResources(groupedResources.flatMap((c: any) => c.resources.map((c: any) => c.id)));
                return;
            }
            if (selectedResources.includes(validationValue)) {
            // Remove the value if it already exists
                setSelectedResources(selectedResources.filter(resource => resource !== validationValue));
            } else {
            // Add the value if it doesn't exist
                setSelectedResources([...selectedResources, validationValue]);
            }
        },
        [groupedResources, selectedResources]
    );

    const handleSelectWorkCenter = useCallback((event: number[]) => {

        // Find the group whose workcenterId matches the event
        const selectedGroup = groupedResources.find((group: any) => group.workcenterId === event) as any;

        if (selectedGroup) {
        // Extract resourceIds from the selected group
            const resourceIds = selectedGroup.resources.map((resource: any) => resource.id);

            // Check if all resourceIds from the selected group are already in selectedResources
            const allResourcesSelected = resourceIds.every((id: number) => selectedResources.includes(id));

            if (allResourcesSelected) {
            // Remove all resourceIds from selectedResources
                const updatedSelectedResources = selectedResources.filter((id: number) => !resourceIds.includes(id));
                setSelectedResources(updatedSelectedResources);
            } else {
            // Filter out resourceIds already in selectedResources
                const newResourceIds = resourceIds.filter((id: number) => !selectedResources.includes(id));
                // Merge newResourceIds with selectedResources, avoiding duplicates
                const updatedSelectedResources = Array.from(new Set([...selectedResources, ...newResourceIds]));
                setSelectedResources(updatedSelectedResources);
            }
        } else {
            return null;
        }
    }, [groupedResources, selectedResources]);

    const handleSetupGrouped = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSetupGrouped(event.target.checked);
    }, []);

    const handleOnlyAllocated = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setOnlyAllocated(event.target.checked);
    }, []);

    const handleRefreshRefreshPGOR = useCallback(async () => {

        if (scenario?.id === undefined) return;
        const response = await getAsync<IPlanningGridOperationResource[]>(
            `/planningGridOperationResource/${scenario.id}`,
        );

        if (response.type === 'success' && response.data) {
            const planningGridOperationResourceOrderedByPeriodId = response.data.sort((a, b) => a.periodId - b.periodId);
            setPlanningGridOperationResources(planningGridOperationResourceOrderedByPeriodId);
            dispatch(setPlanningGridOperationResourcesRedux({ planningGridOperationResource: planningGridOperationResourceOrderedByPeriodId }));
        }
    }, [dispatch, scenario?.id]);

    const updatePlanningGrid = useCallback(async () => {
        if (!scenario) return;

        // Show toast indicating that the process has started
        toast.info(t`Atualizando os dados de planejamento, por favor, aguarde...`, {
            position: 'top-right',
            autoClose: false, // Do not auto close, let it stay until the API call finishes
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
        });

        let hasMoreData = true;
        let page = 1;
        let plannings: IPlanningGrid[] = [];
        while (hasMoreData) {
            const resPlanning = await getAsync<IPlanningGrid[]>(`PlanningGrid/${scenario.id}?page=${page}`, { maxBodyLength: Infinity, maxContentLength: Infinity });
            if (resPlanning.type === 'success' && resPlanning.data && resPlanning.data.length > 0) {
                plannings = [...plannings, ...resPlanning.data];
                page++;
            } else {
                hasMoreData = false;
            }
        }


        const [planningPeriods] = await Promise.all(
            [
                getAsync<IPeriod[]>(`Period/planning-period/${scenario.id}`),
            ]);
        if (planningPeriods.type === 'success' && planningPeriods.data) {
            dispatch(setPeriods({ periods: planningPeriods.data, periodTypeId: scenario.periodTypeId }));
        }


        // Close the initial toast
        toast.dismiss();



        dispatch(setPlanningGrid({ planningGrid: plannings as PlanningGridData[] }));
        dispatch(recalculateItemidsByStockStatus());
        dispatch(setFiniteButtonLoading({ value: false }));
        // Show toast indicating that the process has finished
        toast.success(t`Dados atualizados com sucesso`, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
        });
    }, []);

    const handleFiniteScenarioAsync = useCallback(async (event: React.MouseEvent<HTMLButtonElement>) => {
        if (hubConnection === null) return;
        if (scenario?.id === undefined) return;
        const name = event.currentTarget.name;
        const params: ExecutionParameter[] = [
            {
                id: 0,
                name: 'ScenarioId',
                value: scenario.id.toString(),
            },
        ];
        dispatch(setFiniteButtonLoading({ value: true }));
        const response = await putAsync(`ButtonProcedure/${name}`, params);
        if (response.type === 'success') {
            hubConnection.on('RefreshPGOR', handleRefreshRefreshPGOR);
            hubConnection.on('RefreshPlanningGrid', () => {
                updatePlanningGrid();
                hubConnection?.off('RefreshPlanningGrid');
            });
            hubConnection.on('FinishPGOR', () => {
                hubConnection?.off('RefreshPGOR');
                hubConnection?.off('FinishPGOR');
            });
        }
    }, [handleRefreshRefreshPGOR, hubConnection, scenario.id, updatePlanningGrid],
    );

    const handleResourcesWithData = useCallback((planningGridOperationResources: IPlanningGridOperationResource[], resources: Resource[]) => {
        const resourcesIdsWithAllocation = new Set(planningGridOperationResources.map(c => c.resourceId));

        const filteredResources = resources.filter(resource => resourcesIdsWithAllocation.has(resource.id));

        setAllocatedResources(filteredResources);
    }, []);

    const selectedResourcesMemo = useMemo(() => {
        console.log(globalResources, selectedResources);
        
        return globalResources.length > 0 ? globalResources : selectedResources;
    },[globalResources, selectedResources]);

    useEffect(() => {
        handleResourcesWithData(planningGridOperationResources, resources);
    }, [handleResourcesWithData, planningGridOperationResources, resources]);
    return {
        resources,
        selectedResources: selectedResourcesMemo,
        handleResourcesByItemId,
        capacities,
        planningGridOperationResources,
        setupGrouped,
        handleSelectResource,
        handleSetupGrouped,
        handleFiniteScenarioAsync,
        handleRefreshRefreshPGOR,
        allocatedResources,
        handleOnlyAllocated,
        onlyAllocated,
        groupedResources,
        handleSelectWorkCenter,
        handleTextSearch,
        items
    };
};
