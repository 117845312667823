import { useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getAsync, useHubContext } from '@nplan';



interface IUseColumnDataReturn {
    columns: Column[];
    aligns: Align[];
    filterTypes: FilterType[];
    viewTypes: ViewType[];
    handleRefreshDataAsync: () => Promise<void>;
    loading: boolean;
}

export const useColumnData = (): IUseColumnDataReturn => {
    const { id } = useParams();
    const [columns, setColumns] = useState<Column[]>([]);
    const [aligns, setAligns] = useState<Align[]>([]);
    const [filterTypes, setFilterTypes] = useState<FilterType[]>([]);
    const [viewTypes, setViewTypes] = useState<ViewType[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const { hubConnection } = useHubContext();
    const handleRefreshDataAsync = useCallback(async (): Promise<void> => {
        const resColumns = await getAsync<Column[]>(`columns/table/${id}`);
        if (
            resColumns.type === 'success' &&
            resColumns.data !== undefined
        ) {
            setColumns(resColumns.data);
        }
    }, [id]);

    useEffect(() => {
        (async () => {
            const [resAligns, resFilterType, resViewType] =
                await Promise.all([
                    getAsync<Align[]>('aligns'),
                    getAsync<FilterType[]>('filter-types'),
                    getAsync<Group[]>('view-types'),
                ]);
            if (
                resAligns.type === 'success' &&
                resAligns.data
            ) {
                setAligns(resAligns.data);
            }
            if (
                resFilterType.type === 'success' &&
                resFilterType.data
            ) {
                setFilterTypes(resFilterType.data);
            }
            if (
                resViewType.type === 'success' &&
                resViewType.data
            ) {
                setViewTypes(resViewType.data);
            }
        })();
    }, []);

    useEffect(() => {
        setLoading(true);
        handleRefreshDataAsync().then(() => setLoading(false));
    }, [handleRefreshDataAsync]);

    useEffect(() => {
        if(hubConnection){
            hubConnection.on(`RefreshColumns${id}`, handleRefreshDataAsync);
            return () => hubConnection.off(`RefreshColumns${id}`, handleRefreshDataAsync);
        }
    }, [handleRefreshDataAsync, hubConnection, id]);
    return {
        columns,
        aligns,
        filterTypes,
        viewTypes,
        loading,
        handleRefreshDataAsync,
    };
};