import { Badge } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    },
    '& .MuiBadge-badge-online': {
        backgroundColor: '#44b700',
        color: '#44b700',
    },
    '& .MuiBadge-badge-offline': {
        backgroundColor: '#B0B0B0',
        color: '#B0B0B0',
    },
}));