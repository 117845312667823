import { Trans } from '@lingui/macro';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { useDataContext } from '@nplan';
import { OccupationMemo } from 'pages';
import { memo, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { resetFilters, setFiniteButtonLoading } from 'redux/Filters/reducer';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { PlanningGridChartContainerMemoized } from './containers/PlanningGridChartContainer';
import { PlanningGridContainerMemoized } from './containers/PlanningGridContainer';
import usePlanningGrid from './hooks/Grid/usePlanningGrid';

export const PlanningGridContainer: React.FC = memo((): JSX.Element => {
    const { stockStatus, scenario } = useDataContext();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const chartVisible = useAppSelector(state => state.filter.planningGrid.chartVisible);

    const {
        planningGrid,
        periods,
        isWeekly,
        loading,
        planningGridRowOptions,
        handleReloadItemPlanningGrid,
    } = usePlanningGrid(scenario);

    const scenarioMemo = useMemo(() => {
        return scenario;
    }, [scenario]);
  

    const complexPeriods = useAppSelector(
        (state) => state.filter.planningGrid.complexPeriods,
    );
    const [occulationOpen, setOccupationOpen] = useState(false);
    const handleOccupation = () => {
        setOccupationOpen(!occulationOpen);
    };


    useEffect(() => {
        dispatch(resetFilters());
        setOccupationOpen(false);
        dispatch(setFiniteButtonLoading({ value: false }));
    }, [dispatch, scenarioMemo]);


    if (!scenarioMemo) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', margin: '0 auto' }}>
                <Box sx={{ gap: '0.5rem', display: 'flex', flexDirection: 'column', border: '1px solid', borderColor: theme.palette.primary.main, padding: '1rem', borderRadius: '0.5rem' }}>
                    <Typography variant="h6">
                        <Trans>
                            Não existem cenários!
                        </Trans>
                    </Typography>
                    <Button onClick={() => navigate('/scenarios')}>Criar Cenário</Button>
                </Box>
            </Box>
        );
    }
    return (
        <Box
            sx={{
                position: 'relative',
                height: 'calc(100% - 10px)',
                display: 'flex',
                flexDirection: 'column',
                padding: 1,
                overflow: 'auto',
                zIndex: 999
            }}
            gap={2}
        >
            {
                chartVisible
                    ? <Box sx={{ position: 'relative', height: '450px' }}>
                        <PlanningGridChartContainerMemoized
                            planningGrid={planningGrid}
                            periods={periods}
                            isWeekly={isWeekly}
                            loading={loading}
                            scenario={scenarioMemo}
                            handleReloadItemPlanningGrid={handleReloadItemPlanningGrid}
                        />
                    </Box>
                    : null
            }

            <OccupationMemo
                open={occulationOpen}
                onOccupationIconClick={handleOccupation}
                // items={items}
                periods={periods}
            />
            <PlanningGridContainerMemoized
                occupationOpen={occulationOpen}
                scenario={scenarioMemo}
                periods={periods}
                isWeekly={isWeekly}
                loading={loading}
                planningGridRowOptions={planningGridRowOptions}
                complexPeriods={complexPeriods}
                stockStatus={stockStatus}
                onReloadItemPlanningGrid={handleReloadItemPlanningGrid}
                onOccupationIconClick={() => setOccupationOpen(prev => !prev)}
            />
        </Box>
    );
});
