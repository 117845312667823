import { Trans } from '@lingui/macro';
import { Box, Button, Toolbar, Typography } from '@mui/material';
import React, { MouseEvent } from 'react';
import { SendData } from './SendData';
import ScenarioDropDownSelector from 'pages/planning-grid/components/ScenarioDropDownSelector';
import { useColorModeContext } from '@nplan';
import { ScenarioSelector } from './ScenarioSelector';

interface ITableToolbarProps {
    onCreateScenarioClick: (event: MouseEvent<HTMLButtonElement>) => void;
    onConfirmIntegrationClick: (event: MouseEvent<HTMLButtonElement>) => void;
    onConfirmGenerateOrdersClick: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const TableToolbar: React.FC<ITableToolbarProps> = ({
    onCreateScenarioClick,
    onConfirmIntegrationClick,
    onConfirmGenerateOrdersClick,
}: ITableToolbarProps): JSX.Element => {

    const { buttonVariant } = useColorModeContext();
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                padding: '10px',
                background: 'background.default',
            }}
        >
            <Box sx={{ flex: '1 1 100%', display: 'flex' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                        variant="h4"
                        id="tableTitle"
                        component="div"
                    >
                        <Trans>Cenários</Trans>
                    </Typography>
                </Box>

            </Box>

            <Box sx={{ display: 'flex', gap: 1, width: '100%', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', gap: '1rem', flex: 1, justifyContent: 'end' }}>
                    <ScenarioSelector />
                    <SendData />
                    <Button
                        color="primary"
                        sx={{
                            borderRadius: '15px',
                            padding: '10px',
                            whiteSpace: 'nowrap',
                        }}
                        variant={buttonVariant}
                        onClick={onConfirmIntegrationClick}
                        data-id={0}
                    >
                        <Trans>Integrar Dados</Trans>
                    </Button>
                    <Button
                        data-testid="create-scenario-button"
                        color="primary"
                        sx={{
                            borderRadius: '15px',
                            padding: '10px',
                            whiteSpace: 'nowrap',
                        }}
                        variant={buttonVariant}
                        onClick={onCreateScenarioClick}
                        data-id={0}
                    >
                        <Trans>Gerar Cenário</Trans>
                    </Button>
                    <Button
                        color="primary"
                        sx={{
                            borderRadius: '15px',
                            padding: '10px',
                            whiteSpace: 'nowrap',
                        }}
                        variant={buttonVariant}
                        onClick={onConfirmGenerateOrdersClick}
                    >
                        <Trans>Gerar Ordens</Trans>
                    </Button>

                </Box>
            </Box>
        </Toolbar>
    );
};
