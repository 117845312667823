import { Box, ListItemIcon,  Menu, MenuItem } from '@mui/material';
import { Icons } from 'utils/icons';
import { Trans } from '@lingui/macro';
import { useCallback } from 'react';
import { useDataContext } from '@nplan';

interface IContextMenuProps {
    position: MenuPosition | null;
    onClose: () => void;
    onAddReport: (event: React.MouseEvent<HTMLButtonElement> | number) => void;
    onManageConfig: (event: number) => void;
}

export const ContextMenu: React.FC<IContextMenuProps> = ({
    onClose,
    position,
    onAddReport,
    onManageConfig,
}): JSX.Element => {
    const { onRefreshReportsAsync } = useDataContext();

    const handleRefreshData = useCallback(() => {
        onRefreshReportsAsync();
        onClose();
    }, [onRefreshReportsAsync, onClose]);

    const handleAddReport = useCallback(() => {
        onAddReport(0);
        onClose();
    },[onAddReport, onClose]);

    const handleManageConfig = useCallback(() => {
        onManageConfig(0);
        onClose();
    }, [onManageConfig, onClose]);

    return (
        <Menu
            anchorReference='anchorPosition'
            anchorPosition={
                position 
                    ? { top: position.y, left: position.x } 
                    : undefined
            }
            open={Boolean(position)}
            onClose={onClose}
        >
            <Box>
                <MenuItem onClick={handleRefreshData}>
                    <ListItemIcon>
                        <Icons.Refresh size={20} />
                    </ListItemIcon>
                    <Trans>Atualizar Dados</Trans>
                </MenuItem>
                <MenuItem onClick={handleAddReport}>
                    <ListItemIcon>
                        <Icons.AddTable size={20} />
                    </ListItemIcon>
                    <Trans>Adicionar Relatório</Trans>
                </MenuItem>
                <MenuItem onClick={handleManageConfig}>
                    <ListItemIcon>
                        <Icons.Settings size={20} />
                    </ListItemIcon>
                    <Trans>Editar Configurações</Trans>
                </MenuItem>
            </Box>
        </Menu>
    );
};
