import { CSSProperties, memo, useCallback, useState } from 'react';
import {
    BooleanRender,
    DefaultRender,
    ColorRender,
    DateRender
} from './cell-render';
import {
    BooleanEditor,
    DecimalEditor,
    NumberEditor,
    SelectEditor,
    TextEditor,
} from './editor';
import { useTableDataActions } from '../hooks/useTableDataActions';
import { DateEditor } from './editor/DateEditor';
import { ColorEditor } from './editor/ColorEditor';
import { DateTimeEditor } from './editor/DateTimeEditor';

interface RowCellProps {
    style: CSSProperties;
    column: Column;
    row: any;
}

const RowCell: React.FC<RowCellProps> = ({
    style,
    column,
    row,
}): JSX.Element => {
    const [editing, setEditing] = useState<boolean>(false);
    const { handleUpdateValue } = useTableDataActions();
    const handleEditOn = useCallback(() => {
        if(column.editable) {
            setEditing(true);
        }
    },[column.editable]);
    const handleEditOff = useCallback(() => {
        setEditing(false);
    },[]);
    const handleSave = useCallback(async (value: any) => {
        await handleUpdateValue(value, column.id, row);
        setEditing(false);
    },[handleUpdateValue, column.id, row]);
    return editing ? (
        <div style={style}>
            {column.foreignKey && (
                <SelectEditor
                    defaultValue={row[column.field]}
                    onClose={handleEditOff}
                    onSave={handleSave}
                    columnId={column.id}
                    nullable={column.enableNull}
                    nullText={column.nullText}
                />
            )}
            {column.foreignKey === false && column.viewTypeId === 1 && (
                <BooleanEditor
                    defaultValue={row[column.field]}
                    onClose={handleEditOff}
                    onSave={handleSave}
                />
            )}
            {column.foreignKey === false && column.viewTypeId === 2 && (
                <ColorEditor
                    defaultValue={row[column.field]}
                    onClose={handleEditOff}
                    onSave={handleSave}
                />
            )}
            {column.foreignKey === false && column.viewTypeId === 3 && (
                <DateEditor
                    defaultValue={row[column.field]}
                    onClose={handleEditOff}
                    onSave={handleSave}
                />
            )}
            {column.foreignKey === false && column.viewTypeId === 4 && (
                <DateTimeEditor
                    defaultValue={row[column.field]}
                    onClose={handleEditOff}
                    onSave={handleSave}
                />
            )}
            {column.foreignKey === false && column.viewTypeId === 5 && (
                <DecimalEditor
                    defaultValue={row[column.field]}
                    onClose={handleEditOff}
                    onSave={handleSave}
                />
            )}

            {column.foreignKey === false && column.viewTypeId === 7 && (
                <NumberEditor
                    defaultValue={row[column.field]}
                    onClose={handleEditOff}
                    onSave={handleSave}
                />
            )}
            {column.foreignKey === false && column.viewTypeId === 8 && (
                <TextEditor
                    defaultValue={row[column.field]}
                    onClose={handleEditOff}
                    onSave={handleSave}
                />
            )}
        </div>
    ) : (
        <div style={style} onDoubleClick={handleEditOn}>
            {column.viewTypeId === 1 
                ? <BooleanRender row={row} column={column} />
                : [3,4].includes(column.viewTypeId)
                    ? <DateRender row={row} column={column} />
                    : column.viewTypeId === 2 
                        ?  <ColorRender row={row} column={column}/>
                        :  <DefaultRender row={row} column={column} /> }
        </div>
    );
};

const MemoizedRowCell = memo(RowCell);
export { MemoizedRowCell as RowCell };
