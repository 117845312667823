import { t } from "@lingui/macro"

export const optionsToLanguage = (option: string | null | undefined) => {
    if (option === 'All' || option === `Todas`) {
        return t`Todas`
    }
    if (option === 'Eixo X') {
        return t`Eixo X`
    }
    if (option === 'Eixo Y') {
        return t`Eixo Y`
    }
    if (option === 'Filtro') {
        return t`Filtro`
    }
    if (option === 'Complexity') {
        return t`Complexidade`
    }
    if (option === 'Uncertainty') {
        return t`Incerteza`
    }
    if (option === 'Volume') {
        return t`Volume`
    }
    if (option === 'Customer') {
        return t`Cliente`
    }
    if (option === 'Item Code') {
        return t`Código do Item`
    }
    if (option === 'Status with Period') {
        return t`Status com Período`
    }
    if (option === 'Level') {
        return t`Nível`
    }
    if (option === 'Company') {
        return t`Empresa`
    }
    if (option === 'Planner') {
        return t`Planejador`
    }
    if (option === 'Stock Group') {
        return t`Grupo de Estoque`
    }
    // Months
    if (option === 'January') {
        return t`Janeiro`
    }
    if (option === 'February') {
        return t`Fevereiro`
    }
    if (option === 'March') {
        return t`Março`
    }
    if (option === 'April') {
        return t`Abril`
    }
    if (option === 'May') {
        return t`Maio`
    }
    if (option === 'June') {
        return t`Junho`
    }
    if (option === 'July') {
        return t`Julho`
    }
    if (option === 'August') {
        return t`Agosto`
    }
    if (option === 'September') {
        return t`Setembro`
    }
    if (option === 'October') {
        return t`Outubro`
    }
    if (option === 'November') {
        return t`Novembro`
    }
    if (option === 'December') {
        return t`Dezembro`
    }
    // If none of the above, return the original option
    return option as string;
}
export const occupationOptionsToLanguage = (option: string | null | undefined) => {

    if (option === `Item`) {
        return t`Item`
    }
    if (option === `Complexidade`) {
        return t`Complexidade`
    }
    if (option === `Incerteza`) {
        return t`Incerteza`
    }
    if (option === `Volume`) {
        return t`Volume`
    }
    if (option === `Empresa`) {
        return t`Empresa`
    }
    if (option === `Planejador`) {
        return t`Planejador`
    }
    if (option === `Grupo de Estoque`) {
        return t`Grupo de Estoque`
    }
    if (option === `Atributo 01`) {
        return t`Atributo 01`
    }
    if (option === `Atributo 02`) {
        return t`Atributo 02`
    }
    if (option === `Atributo 03`) {
        return t`Atributo 03`
    }
    if (option === `Atributo 04`) {
        return t`Atributo 04`
    }
    if (option === `Atributo 05`) {
        return t`Atributo 05`
    }
    if (option === `Atributo 06`) {
        return t`Atributo 06`
    }
    if (option === `Atributo 07`) {
        return t`Atributo 07`
    }
    if (option === `Atributo 08`) {
        return t`Atributo 08`
    }
    if (option === `Atributo 09`) {
        return t`Atributo 09`
    }
    if (option === `Atributo 10`) {
        return t`Atributo 10`
    }
    if (option === `Tag 01`) {
        return t`Tag 01`
    }
    if (option === `Tag 02`) {
        return t`Tag 02`
    }
    if (option === `Tag 03`) {
        return t`Tag 03`
    }

    // If none of the above, return the original option
    return option as string;
}