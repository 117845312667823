import { useLingui } from '@lingui/react';
import { TableCell, TableHead, TableRow } from '@mui/material';

const TableCellNoBorder = (props: any) => {
    return <TableCell sx={ { borderBottom: 0 } } { ...props } />;
};

const HeaderCell = ({ text, ...props }: { text: string, [x: string]: any }) => {
    const { i18n } = useLingui();
    return (
        <TableCellNoBorder { ...props } title={text}>
            {i18n.t(text)}
        </TableCellNoBorder>
    );
};


const firstHeaderCells = [
    // Primeira linha
    { text: 'Visualização', colSpan: 15 },
    { text: 'Configurações', colSpan: 6 },
    { text: 'Definições de Chave Estrangeira', colSpan: 5 },
    { text: 'Ações', colSpan: 3 },
];
const secondHeaderCells = [
    // Segunda linha
    {
        text: 'Campo',
        colSpan: 1,
        style: {
            position: 'sticky',
            left: 0,
            zIndex: 9,
            width: 300,
            maxWidth: 300,
            minWidth: 300,
            textOverflow: 'ellipsis',
            overFlow: 'hidden',
            bgcolor: 'background.paper',
        },
    },
    {
        text: 'Título',
        colSpan: 1,
        style: {
            position: 'sticky',
            left: 300,
            minWidth: 160,
            zIndex: 9,
            bgcolor: 'background.paper',
        },
    },
    { text: 'Largura', colSpan: 1 },
    { text: 'Alinhamento', colSpan: 1, style: { minWidth: 130 } },
    { text: 'Tipo de Visualização', colSpan: 1 },
    { text: 'Tipo de Filtro', colSpan: 1 },
    { text: 'Casas Decimais', colSpan: 1 },
    { text: 'Texto Null', colSpan: 1 },
    { text: 'Grupo', colSpan: 1 },
    { text: 'Prefixo', colSpan: 1 },
    { text: 'Sufixo', colSpan: 1 },
    { text: 'Tooltip', colSpan: 1 },
    { text: 'Ordem', colSpan: 1 },
    // { text: 'Cor de Fundo', colSpan: 1 },
    // { text: 'Cor do Texto', colSpan: 1 },
    {
        text: 'Editável',
        colSpan: 1,
        borderLeft: '1px solid transparent',
    },
    { text: 'Oculto', colSpan: 1 },
    { text: 'Permite Null', colSpan: 1 },
    { text: 'Ordenador Padrão', colSpan: 1 },
    { text: 'Editor em Massa', colSpan: 1 },
    { text: 'Fixado a esquerda', colSpan: 1 },

    {
        text: 'Chave Estrangeira',
        colSpan: 1,
        borderLeft: '1px solid transparent',
    },
    { text: 'Esquema', colSpan: 1, style: { minWidth: 160 } },
    { text: 'Tabela', colSpan: 1, style: { minWidth: 160 } },
    { text: 'Coluna', colSpan: 1, style: { minWidth: 160 } },
    { text: 'Coluna de Visualização', colSpan: 1 },
    { text: '', colSpan: 1, borderLeft: '1px solid transparent' }, // Célula vazia
];

export const Header: React.FC = (): JSX.Element => {
    return (
        <TableHead>
            <TableRow sx={ { position: 'sticky', top: 0, zIndex: 10, bgcolor: 'background.paper' } }>
                { firstHeaderCells.map((cell, index) => (
                    <HeaderCell key={ index } text={ cell.text } colSpan={ cell.colSpan } />
                )) }
            </TableRow>
            <TableRow sx={ { position: 'sticky', top: 56, zIndex: 9, bgcolor: 'background.paper' } }>
                { secondHeaderCells.map((cell, index) => (
                    <HeaderCell key={ index } text={ cell.text } colSpan={ cell.colSpan } sx={cell.style} />
                )) }
            </TableRow>
        </TableHead>
    );
};
