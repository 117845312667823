import React, { useCallback } from 'react';
import { TokenResponse, useGoogleLogin } from '@react-oauth/google';
import LoginIcon from 'assets/images/google/google.png';
import { Box, Button, Typography } from '@mui/material';
import { postAsync, useUserContext } from '@nplan';

interface IGoogleProps {
    onChangeLoading: (value: boolean) => void;
}

export const Google: React.FC<IGoogleProps> = ({ onChangeLoading }): JSX.Element => {
    const { onLoginAsync } = useUserContext();
    const handleLogin = useCallback(async (tokenResponse: Omit<TokenResponse, 'tokenType'>) => {
        onChangeLoading(true);
        const res = await postAsync<object, string>('auth/google', {
            token: tokenResponse.access_token,
        });
        if (res.type === 'success' && res.data) {
            onChangeLoading(false);
            onLoginAsync(res.data);
        }
        onChangeLoading(false);
    }, [onLoginAsync, onChangeLoading]);
    const googleLogin = useGoogleLogin({
        onSuccess: handleLogin,
    });
    return (
        <Button
            onClick={() => googleLogin()}
            sx={{
                ':hover': {
                    boxShadow: 'initial',
                },
            }}
        >
            <Box
                padding={2}
                width={60}
                display={'flex'}
                justifyContent={'center'}
                flexDirection={'column'}
                height={55}
                alignItems={'center'}
                overflow={'hidden'}
                paddingX={6}
            >
                <img
                    src={LoginIcon}
                    alt='Google icon'
                    style={{
                        width: '40px',
                        padding: 5,
                    }}
                />
                <Typography
                    variant='subtitle2'
                    textTransform={'none'}
                    color={'text.primary'}
                >
                    Google
                </Typography>
            </Box>
        </Button>
    );
};
