import { Box, Skeleton, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { ISupplyStrategy } from 'types/system/ISupplyStrategy';
import useInventorySummary from '../hooks/useInventoryData';
import useSegmentationInfo from '../hooks/useSegmentationInfo';
import { IInventorySummaryInfo, ISegmentationSummaryInfo } from '../types/IFilters';
import { IInventorySegmentationData } from '../types/IInventorySegmentationData';
import { IInventorySummaryData } from '../types/IInventorySummaryData';
import { isNotEmptyObject } from '../utils/isNotEmptyObject';
import InventoryPolicyHeader from './Header/InventoryPolicyHeader';
import InventorySummaryBody from './InventorySummary/Body';


interface InventoryPolicyProps {
    segmentationData: IInventorySegmentationData[]
    summaryData: IInventorySummaryData[]
    stockStrategy: ISupplyStrategy[]
    segmentationUpdate: () => Promise<void>
    isProcedureRunning: boolean
    handleInventoryRecalculate: (name: string) => Promise<void>
    inventoryLoading: boolean
    isProcedureCompleted: boolean
}

export const InventoryPolicy: React.FC<InventoryPolicyProps> = ({ isProcedureCompleted, inventoryLoading, handleInventoryRecalculate, segmentationUpdate, isProcedureRunning, segmentationData, summaryData, stockStrategy: stockStrategyOptions }: InventoryPolicyProps): JSX.Element => {
    const theme = useTheme();
    const { selectedCompanyFilter, selectedSalesChannelFilter, selectedStockGroupFilter } = useAppSelector(state => state.filter.inventory);
    const [isLoading, setIsLoading] = useState(true);
    const dataValidation = useAppSelector(state => state.filter.inventory.dataValidation);
    const selectedAxis = useAppSelector(state => state.filter.inventory.selectedAxis);
    const selectedSecondaryFilter = useAppSelector(state => state.filter.inventory.selectedSecondaryFilter);
    const { inventorySegmentationInfo, summaryFilter } = useSegmentationInfo({ segmentationInventory: segmentationData, selectedFilters: { selectedCompanyFilter, selectedSalesChannelFilter, selectedSecondaryFilter, selectedStockGroupFilter, selectedAxis }, dataValidation });
    const { inventorySummaryInfo, summaryHeaderData } = useInventorySummary({ stockInventory: summaryData, summaryFilter, dataValidation, selectedFilters: { selectedCompanyFilter, selectedSalesChannelFilter, selectedSecondaryFilter, selectedStockGroupFilter, selectedAxis } });

    useEffect(() => {
        if (isNotEmptyObject(inventorySegmentationInfo) && isNotEmptyObject(inventorySummaryInfo) && summaryHeaderData) {
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    }, [inventorySegmentationInfo, inventorySummaryInfo, summaryHeaderData]);

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
                <Skeleton sx={{ width: '100%', height: '100%' }} variant="rectangular" animation="wave" />
            </Box>
        ); 
    }
    return (
        <Box sx={{
            display: 'flex', flexDirection: 'column', width: '100%', height: 'calc(100% - 5px)',
            borderRadius: '8px',
            overflow: 'hidden',
            margin: '0.12rem auto'
        }}>
            <InventoryPolicyHeader
                inventoryLoading={inventoryLoading}
            />
            <InventorySummaryBody
                filter={summaryFilter}
                inventorySummaryInfo={inventorySummaryInfo as IInventorySummaryInfo}
                segmentationSummaryInfo={inventorySegmentationInfo as ISegmentationSummaryInfo}
                stockStrategyOptions={stockStrategyOptions}
                summaryHeaderData={summaryHeaderData}
                segmentationUpdate={segmentationUpdate}
                isProcedureRunning={isProcedureRunning}
                isProcedureCompleted={isProcedureCompleted}
                handleInventoryRecalculate={handleInventoryRecalculate}
            />
        </Box>
    );
};