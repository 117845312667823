import { Trans, t } from '@lingui/macro';
import { Box, CircularProgress, Paper, Typography, useTheme } from '@mui/material';
import { useDataContext } from '@nplan';
import ScenarioDropDownSelector from 'pages/planning-grid/components/ScenarioDropDownSelector';
import { useAppSelector } from 'redux/hooks';
import { IGenericOptions } from 'types/Generics';
import FilterDropDownSelector from '../Selectors/FilterDropDownSelector';
import FilterDropDownPeriodSelector from '../Selectors/PeriodSelector';
import StockTypeSelector from '../Selectors/StockTypeSelector';


interface InventoryPolicyHeaderProps {
    inventoryLoading: boolean
}
const InventoryPolicyHeader = ({ inventoryLoading }: InventoryPolicyHeaderProps) => {
    const theme = useTheme();
    const { periodOptions, selectedPeriodFilter, selectedCompanyFilter, selectedSalesChannelFilter, selectedStockGroupFilter, dataValidation, stockGroupOptions, companyOptions, salesChannelOptions } = useAppSelector(state => state.filter.inventory);
    const { scenarios } = useDataContext();
    const selectorStyles = {
        flex: 1,
        borderRadius: '8px',
        backgroundColor: theme.palette.background.default
    };
    const stockTypeOptions: IGenericOptions[] = [
        { id: 1, code: 'Opening Stock', name: t`Estoque Inicial` },
        { id: 2, code: 'Closing Stock', name: t`Estoque Final` },
    ];
    return (
        <Paper sx={{ padding: '0.3rem 1rem ', backgroundColor: theme.palette.background.default, textAlign: 'center', color: theme.palette.text.primary, display: 'flex', justifyContent: 'start' }}>
            <Box sx={{ flex: 1, display: 'flex', maxWidth: '50%', justifyContent: 'start', gap: '1rem' }}>
                {dataValidation.stockGroupExists && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.1rem', height: 'fit-content' }}
                        data-testid="selectedStockGroupFilter">
                        <Typography variant="body2"><Trans>Grupo de Estoque</Trans></Typography>
                        <Box sx={selectorStyles}>
                            <FilterDropDownSelector selectedFilter={selectedStockGroupFilter} filterOptions={stockGroupOptions} selectedFilterName={'selectedStockGroupFilter'} />
                        </Box>
                    </Box>
                )}
                {dataValidation.companyExists && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.1rem', height: 'fit-content' }}
                        data-testid="selectedCompanyFilter">
                        <Typography variant="body2"><Trans>Organização</Trans></Typography>
                        <Box sx={selectorStyles}>
                            <FilterDropDownSelector
                                data-testid="selectedCompanyFilterSelector"
                                selectedFilter={selectedCompanyFilter} filterOptions={companyOptions} selectedFilterName={'selectedCompanyFilter'} />
                        </Box>
                    </Box>
                )}
                {dataValidation.salesChannelExists && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.1rem', height: 'fit-content' }}
                        data-testid="selectedSalesChannelFilter">

                        <Typography variant="body2"><Trans>Mercado</Trans></Typography>
                        <Box sx={selectorStyles}>
                            <FilterDropDownSelector
                                data-testid="selectedSalesChannelFilterSelector"
                                selectedFilter={selectedSalesChannelFilter} filterOptions={salesChannelOptions} selectedFilterName={'selectedSalesChannelFilter'} />
                        </Box>
                    </Box>
                )}
            </Box>
            {scenarios.length > 0 && (
                <Box sx={{ display: 'flex', justifyContent: 'start', gap: '0.1rem', height: 'fit-content', flexDirection: 'column', alignItems: 'start' }} >
                    <Typography variant="body2"><Trans>Cenário</Trans></Typography>
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.1rem', height: 'fit-content' }}
                            data-testid="selectedScenario">
                            <ScenarioDropDownSelector />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.1rem', height: 'fit-content' }}
                            data-testid="selectedPeriod">
                            {periodOptions.length > 0 &&
                                <FilterDropDownPeriodSelector periodOptions={periodOptions} selectedPeriod={selectedPeriodFilter} />
                            }
                        </Box>
                        <Box sx={{ display: 'flex', gap: '0.1rem', height: 'fit-content', alignItems: 'center' }}
                            data-testid="stockType">
                            <StockTypeSelector selectOptions={stockTypeOptions} />
                            {inventoryLoading ? <CircularProgress
                                color="primary"
                                size={28}
                            /> : null}
                        </Box>

                    </Box>
                </Box>
            )
            }
        </Paper >
    );
};

export default InventoryPolicyHeader;
