import { useEffect, useState } from 'react';
import { getAsync } from '@nplan';

interface IUseTableReturn {
    table: Table | undefined
}
export const useTable = (tableid: number | null): IUseTableReturn => {
    const [table, setTable] = useState<Table | undefined>();
    useEffect(() => {
        (async () => {
            if (tableid) {
                const response = await getAsync<Table>(`tables/${tableid}`);
                setTable(response.data);
            }
        })();
    }, [tableid]);
    return { table };
};