import React from 'react';
import {
    Box,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Trans } from '@lingui/macro';
import NBg from 'assets/images/neoone-ff319e80.png';
import {
    useVisiblePassword, 
    useLogin 
} from './hooks';
import { 
    MdOutlineVisibilityOff, 
    MdOutlineVisibility 
} from 'react-icons/md';
import { useVariant } from 'utils';

interface ILoginFormProps {
    loading: boolean;
    onChangeLoading: (value: boolean) => void;
}
export const LoginForm: React.FC<ILoginFormProps> = ({
    loading,
    onChangeLoading,
}) => {
    const variant = useVariant();
    
    const {
        handleChangeForm,
        handleGetTokenAsync,
    } = useLogin(onChangeLoading);

    const {
        showPassword,
        handleShowPassword,
        handleHiddenPassword
    } = useVisiblePassword();

    const theme = useTheme();

    return (
        <React.Fragment>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingTop: 5,
                }}
            >
                <img
                    src={NBg}
                    alt="neo"
                    style={{ width: '80%', height: '100%' }}
                />
            </Box>
            <Box
                sx={{
                    width: '100%',
                    height: 210,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 2,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'column',
                    }}
                >
                    <Typography variant="caption">
                        <Trans>Usuário ou email</Trans>
                    </Typography>
                    <TextField
                        name="username"
                        id='username'
                        variant="standard"
                        fullWidth
                        onChange={handleChangeForm}
                        InputLabelProps={{ shrink: true }}
                        size="medium"
                        inputProps={{
                            'data-testid': 'username',
                        }}
                        sx={{
                            backgroundColor: theme.palette.background.default,
                            '& input': {
                                caretColor: theme.palette.background.paper,
                                WebkitBoxShadow: `0 0 0 30px ${theme.palette.background.default} inset !important`,
                                WebkitTextFillColor: theme.palette.text.primary,
                            },
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'column',
                    }}
                >
                    <Typography variant="caption">
                        <Trans>Senha</Trans>
                    </Typography>
                    <TextField
                        name="password"
                        id='password'
                        variant="standard"
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        onChange={handleChangeForm}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                            'data-testid': 'password',
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onMouseDown={handleShowPassword}
                                        onMouseLeave={handleHiddenPassword}
                                        aria-label={
                                            showPassword
                                                ? 'Esconder senha'
                                                : 'Mostrar senha'
                                        }
                                    >
                                        {showPassword ? (
                                            <MdOutlineVisibilityOff />
                                        ) : (
                                            <MdOutlineVisibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            backgroundColor: theme.palette.background.default,
                            '& input': {
                                caretColor: theme.palette.background.paper,
                                WebkitBoxShadow: `0 0 0 30px ${theme.palette.background.default} inset !important`,
                                WebkitTextFillColor: theme.palette.text.primary,
                            },
                        }}
                    />
                </Box>
                <LoadingButton
                    id='loginSubmit'
                    variant={variant}
                    color="primary"
                    sx={{ width: 125, height: 37 }}
                    onClick={handleGetTokenAsync}
                    loading={loading}
                >
                    <Trans>Entrar</Trans>
                </LoadingButton>
            </Box>
        </React.Fragment>
    );
};
