import { useCallback, useEffect, useState } from 'react';
import { getAsync, putAsync } from '@nplan';

interface IUseTablePermissionData {
    tablePermissions: TablePermission[];
    tables: Table[];
    handleEditTablePermission: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    handleSaveTablePermission: () => Promise<void>;
}
export const useTablePermissionData = (
    id: number, 
    open: boolean,
    onClose: () => void
): IUseTablePermissionData => {
    const [tablePermissions, setTablePermissions] = useState<TablePermission[]>([]);
    const [tables, setTables] = useState<Table[]>([]);

    const handleEditTablePermission = useCallback((
        event: React.ChangeEvent<HTMLInputElement>, 
        checked: boolean
    ): void => {
        const nameprop = event.target.name as keyof TablePermission;
        const id = Number(event.target.dataset.id);
        const newTablePermissions = tablePermissions.map((tablePermission) => {
            if(tablePermission.id === id) {
                return {
                    ...tablePermission,
                    [nameprop]: checked
                };
            }
            return tablePermission;
        });
        setTablePermissions(newTablePermissions);
    },[tablePermissions]);

    const handleSaveTablePermission = useCallback(async (): Promise<void> => {
        const res = await putAsync(`table-permissions`, tablePermissions);
        if(res.type === 'success') onClose();
    }, [onClose, tablePermissions]);

    useEffect(() => {
        if(!open) return;
        (async () => {
            const [resTablePermission, resTables] = await Promise.all([
                getAsync<TablePermission[]>(`table-permissions/role/${id}`),
                getAsync<Table[]>('tables')
            ]);
            if(resTablePermission && resTablePermission.data) setTablePermissions(resTablePermission.data);
            if(resTables && resTables.data) setTables(resTables.data);
        })();
    }, [id, open]);

    return {
        tablePermissions,
        tables,
        handleEditTablePermission,
        handleSaveTablePermission
    };
};