import { useEffect, useState } from 'react';
import { getAsync } from 'services/api';

interface IUseItemDataReturn {
    items: Item[];
}

export const useItemData = (): IUseItemDataReturn => {
    const [items, setItems] = useState<Item[]>([]);
    useEffect(() => {
        (async () => {
            const response = await getAsync<Item[]>(`items`);
            if (response.type === 'success' && response.data) {
                setItems(response.data);
            }
        })();
    }, []);
    return { items };
};
