import { ContextMenu, Grid, Log, ManageData } from './components';
import { DataGridProvider } from './providers';
import { useContextMenu, useDialog } from '@nplan';
import { Box } from '@mui/material';
import { useRef } from 'react';

export const TableEditionData: React.FC = (): JSX.Element => {
    const { 
        open: manageDataOpen, 
        id: manageDataId, 
        handleOpenDialog: handleOpenManageDataDialog, 
        handleCloseDialog: handleCloseManageDataDialog 
    } = useDialog();

    const { 
        position, 
        handleCloseMenu, 
        handleOpenMenu 
    } = useContextMenu();

    const { 
        open: logOpen, 
        handleCloseDialog: handleCloseLogDialog, 
        handleOpenDialog: handleOpenLogDialog 
    } = useDialog();

    const ref = useRef<HTMLDivElement>(null);
    
    return (
        <DataGridProvider>
            <Box
                sx={{
                    height: '100%',
                    width: '100%',
                    padding: 2,
                }}
                onContextMenu={handleOpenMenu}
                ref={ref}
            >
                <ContextMenu
                    position={position}
                    onClose={handleCloseMenu}
                    onAddData={handleOpenManageDataDialog}
                    onOpenLog={handleOpenLogDialog}
                />
                <ManageData
                    open={manageDataOpen}
                    onClose={handleCloseManageDataDialog}
                    dataId={manageDataId}
                />
                <Log
                    open={logOpen}
                    onClose={handleCloseLogDialog}
                    anchorEl={ref.current}
                />
                <Grid onEditData={handleOpenManageDataDialog} onCloseManageDataDialog={handleCloseManageDataDialog}/>
            </Box>
        </DataGridProvider>
    );
};
