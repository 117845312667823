import { SelectChangeEvent } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { getAsync } from '@nplan';
import { useParams } from 'react-router-dom';


export const useRequiredFilter = (table?: Table) => {
    const { id } = useParams();
    const [requiredFilterValue, setRequiredFilterValue] = useState<string | null>(null);
    const [requiredFilterOptions, setRequiredFilterOptions] = useState<RequiredFilterOptions[]>([]);

    
    const handleChangeRequiredFilter = useCallback((event: SelectChangeEvent<string>) => {
        setRequiredFilterValue(event.target.value);
    }, []);
    const handleResetRequiredFilter = useCallback(() => {
        setRequiredFilterValue(null);
    }, []);


    useEffect(() => {
        
        (async () => {
            if (table && table.requiredFilter) {
                setRequiredFilterValue(null);
                const response = await getAsync<RequiredFilterOptions[]>(`tables/${table.id}/required-filter`);
                if (response.data) {
                    setRequiredFilterOptions(response.data.sort((a, b) => Number(b.id) - Number(a.id)));
                }
            }
        })();
    }, [table]);

    useEffect(() => {
        handleResetRequiredFilter();
    }, [handleResetRequiredFilter, id]);
    return {
        requiredFilterValue,
        requiredFilterOptions,
        handleChangeRequiredFilter,
        handleResetRequiredFilter
    };
};