import React, { forwardRef } from 'react'
import { FixedSizeList, ListOnScrollProps } from 'react-window'
import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import GridCollapsedHeaderCol from './GridCollapsedHeaderCol';

interface Props {
    itemCount: number
    itemSize: number
    onScroll?: ((props: ListOnScrollProps) => any)
    itemData?: any
    height: number
}
const GridCollapsedHeaderColumnContainer = forwardRef((props: Props, ref: any) => {
    const { height, itemCount, itemSize, itemData, onScroll } = props

    return (
        <AutoSizer>
            {(size: Size) => (
                <FixedSizeList
                    className="no-scrollbars"
                    height={height}
                    itemSize={itemSize}
                    width={size.width}
                    layout="horizontal"
                    itemCount={itemCount}
                    onScroll={onScroll}
                    ref={ref}
                    itemData={itemData}
                >
                    {GridCollapsedHeaderCol}
                </FixedSizeList>)}
        </AutoSizer>
    )
})

export default GridCollapsedHeaderColumnContainer
