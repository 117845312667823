import { t } from '@lingui/macro';

export const getTimePassed = (someDate: string): string => {
    const startDate = new Date(someDate);
    const currentDate = new Date();

    const timeDifference =
        currentDate.getTime() - startDate.getTime();

    const milliseconds = Math.floor(timeDifference);
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
        return t`${days} dias atrás`;
    } else if (hours > 0) {
        return t`${hours} horas atrás`;
    } else if (minutes > 0) {
        return t`${minutes} minutos atrás`;
    } else if (seconds > 0) {
        return t`${seconds} segundos atrás`;
    } else {
        return t`Agora`;
    }
};