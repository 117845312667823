import { t } from '@lingui/macro';
import {
    Box,
    CircularProgress,
    IconButton,
    Input,
    TableBody,
    TableCell,
    TableRow,
} from '@mui/material';
import { InfoTooltipWithCopyText } from 'pages/planning-grid/components/PlanningGrid/components/TooltipComponent';
import { 
    ChangeEvent,
    useCallback, 
    useEffect, 
    useState 
} from 'react';
import { dateOptions, getAsync } from '@nplan';
import { Icons } from 'utils/icons';
import { useScenarioActions } from '../hooks/useScenarioActions';
import { useLingui } from '@lingui/react';

interface IBodyProps {
    visibleRows: Scenario[];
    onEditScenarioClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Body: React.FC<IBodyProps> = ({
    visibleRows,
    onEditScenarioClick,
}: IBodyProps): JSX.Element => {

    const [scenarioStatus, setScenarioStatus] = useState<ScenarioStatus[]>([]);
    const [editedName, setEditedName] = useState<string | null>('');
    const [editedRowId, setEditedRowId] = useState<number | null>(null);
    const { i18n } = useLingui();
    const {
        handleChangeScenarioStatusId,
        handleDeleteScenario,
        handleCloneScenario,
        handleRenameScenarioAsync,
        deletingState
    } = useScenarioActions();

    const getStatus = useCallback((
        id: number
    ): ScenarioStatus | undefined => {
        return scenarioStatus?.find((s) => s.id === id);
    }, [scenarioStatus]);

    const handleDoubleClick = useCallback((rowId: number) => {
        setEditedRowId(rowId);
        const row = visibleRows.find(
            (row) => row.id === rowId,
        );
        if (row) {
            setEditedName(row.name);
        }
    }, [visibleRows]);

    const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setEditedName(event.target.value);
    },[]);

    const handleBlur = useCallback((id: number) => {
        setEditedRowId(null);
        if (editedName !== null) {
            handleRenameScenarioAsync(id, editedName);
        }
    },[editedName, handleRenameScenarioAsync]);

    useEffect(() => {
        (async () => {
            const response = await getAsync<ScenarioStatus[]>('scenario-status');
            if (response.data)  {
                setScenarioStatus(response.data);
            }
        })();
    }, []);
    
    return (
        <TableBody data-testid={`scenario-body`}>
            {visibleRows.map((row) => {
                return (
                    <TableRow hover key={row.id} sx={{ cursor: 'pointer' }} data-testid={`scenario-row`}>
                        <TableCell onDoubleClick={() => handleDoubleClick(row.id)} data-testid={`scenario-row-name`}
                            data-name={row.name}
                        >
                            {editedRowId === row.id ? (
                                <Input
                                    size='small'
                                    sx={{
                                        fontSize: '14px',
                                    }}
                                    type='text'
                                    value={editedName ?? ''}
                                    onChange={handleChange}
                                    onBlur={() =>
                                        handleBlur(row.id)
                                    }
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            handleBlur(row.id);
                                        }
                                    }}
                                    autoFocus
                                />
                            ) : (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '0.3rem',
                                        alignItems: 'center',
                                    }}
                                    
                                >
                                    <InfoTooltipWithCopyText
                                        tooltipText={row.notes as string}
                                    />
                                    {row.name}
                                </Box>
                            )}
                        </TableCell>
                        <TableCell align="center" data-testid={`scenario-row-status`}>
                            <Box
                                sx={{
                                    color: 'white',
                                    borderRadius: '25px',
                                    padding: '5px',
                                    backgroundColor: getStatus(row.scenarioStatusId)?.color,
                                }}
                            >
                                { getStatus(row.scenarioStatusId)?.name } 
                            </Box>
                        </TableCell>
                        <TableCell align='left'>
                            {row.createdBy}
                        </TableCell>
                        <TableCell align='left'>
                            {new Date(row.createdOn!)
                                .toLocaleString(i18n.locale, dateOptions)
                                .replace(',', '')}
                        </TableCell>
                        <TableCell align='left'>
                            {new Date(row.executionDate)
                                .toLocaleString(i18n.locale, dateOptions)
                                .replace(',', '')}
                        </TableCell>
                        <TableCell align='left'>
                            {new Date(row.lastUpdatedOn!)
                                .toLocaleString(i18n.locale, dateOptions)
                                .replace(',', '')}
                        </TableCell>
                        <TableCell align='left'>
                            {new Date(row.startDate)
                                .toLocaleDateString(i18n.locale)
                                .replace(',', '')}
                        </TableCell>
                        <TableCell align='left'>
                            {new Date(row.endDate)
                                .toLocaleDateString(i18n.locale)
                                .replace(',', '')}
                        </TableCell>
                        <TableCell align='left'>
                            {new Date(row.frozenEndDate)
                                .toLocaleDateString(i18n.locale)
                                .replace(',', '')}
                        </TableCell>
                        <TableCell
                            padding='none'
                            align={'center'}
                            title={
                                row.scenarioStatusId === 1
                                    ? t`Editar`
                                    : t`Detalhes`
                            }
                        >
                            <IconButton
                                color={'warning'}
                                size='medium'
                                onClick={onEditScenarioClick}
                                data-id={row.id}
                            >
                                {row.scenarioStatusId === 1 
                                    ? <Icons.Edit />
                                    : <Icons.View />
                                }
                            </IconButton>
                        </TableCell>
                        <TableCell
                            padding='none'
                            align={'center'}
                            title={
                                row.scenarioStatusId === 4
                                    ? t`Firmar`
                                    : t`Planejar`
                            }
                        >
                            <IconButton
                                color={
                                    row.scenarioStatusId === 4
                                        ? 'success'
                                        : 'info'
                                }
                                sx={{
                                    padding: '0 5px',
                                }}
                                disabled={row.scenarioStatusId < 4}
                                onClick={handleChangeScenarioStatusId}
                                data-id={row.id}
                            >
                                {row.scenarioStatusId === 4 
                                    ? <Icons.Locked />
                                    : <Icons.Unlocked />
                                }
                            </IconButton>
                        </TableCell>
                        <TableCell
                            padding='none'
                            align={'center'}
                            title={t`Excluir`}
                        >
                            <IconButton
                                color={'error'}
                                disabled={row.scenarioStatusId === 3}
                                onClick={handleDeleteScenario}
                                data-id={row.id}
                                data-name={row.name}
                                data-testid={`scenario-row-delete`}
                            >
                                {deletingState.id === row.id ? <CircularProgress size={20} color='error' /> : <Icons.Delete />} 
                            </IconButton>
                        </TableCell>
                        <TableCell
                            padding='none'
                            align={'center'}
                            title={t`Clonar`}
                        >
                            <IconButton
                                color={'info'}
                                disabled={![4,5].includes(row.scenarioStatusId)}
                                onClick={handleCloneScenario}
                                data-id={row.id}
                                data-name={row.name}
                            >
                                <Icons.Clone />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                );
            })}
        </TableBody>
    );
};
