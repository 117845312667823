import { useCallback, useState } from 'react';

interface IUsePagination {
    page: number;
    rowsPerPage: number;
    handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
    handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}
export const usePagination = (): IUsePagination => {
    const [page, setPage] = useState(0);

    const [rowsPerPage, setRowsPerPage] = useState(25);
    
    const handleChangePage = useCallback((
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ): void => {
        event?.preventDefault();
        setPage(newPage);
    }, []);

    const handleChangeRowsPerPage = useCallback((
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ): void => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }, []);

    return { 
        page, 
        rowsPerPage, 
        handleChangePage, 
        handleChangeRowsPerPage 
    };
};
