import { Box, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { Icons } from 'utils/icons';
import { useDataContext } from '@nplan';
import { t } from '@lingui/macro';

export interface IContextMenuProps {
    position: MenuPosition | null;
    onClose: () => void;
}

export const ContextMenu: React.FC<IContextMenuProps> = ({
    onClose,
    position,
}): JSX.Element => {
    const { onRefreshScenariosAsync } = useDataContext();
    const handleRefreshScenariosClick = () => {
        onRefreshScenariosAsync();
        onClose();
    };
    return (
        <Menu
            anchorReference="anchorPosition"
            anchorPosition={
                position 
                    ? { top: position.y, left: position.x } 
                    : undefined
            }
            open={Boolean(position)}
            onClose={onClose}
        >
            <Box>
                <MenuItem onClick={handleRefreshScenariosClick}>
                    <ListItemIcon>
                        <Icons.Refresh size={20} />
                    </ListItemIcon>
                    {t`Atualizar dados`}
                </MenuItem>
            </Box>
        </Menu>
    );
};
