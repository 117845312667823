import { ReactNode, useCallback, useState } from 'react';
import { DataGrid } from './context';
import { 
    useScroll, 
    useTable, 
    useRequiredFilter, 
    useContainerSizer, 
    useColumns, 
    useForeignKeys, 
    useRows, 
    useButtons, 
    useEditorAll 
} from './hooks';


interface IDataGridProviderProps {
    children: ReactNode;
}

export const DataGridProvider: React.FC<IDataGridProviderProps> = ({
    children,
}) => {
    const [tableId, setTableId] = useState<number | null>(null);
    
    const { 
        scroll, 
        handleScroll 
    } = useScroll();

    const { 
        handleContainerSize, 
        containerSize 
    } = useContainerSizer();

    const { 
        table 
    } = useTable(tableId);

    const {
        requiredFilterValue,
        requiredFilterOptions,
        handleChangeRequiredFilter,
        handleResetRequiredFilter,
    } = useRequiredFilter(table);

    const {
        getTemplateColumns,
        getVisibleColumns,
        getWidth,
        handleFilterColumnSignal,
        handleFilterColumnValue,
        cols,
        handleSortColumn,
        maxIndex,
        getGroups
    } = useColumns(
        scroll.left, 
        containerSize.x,
    );

    const { 
        foreignFields 
    } = useForeignKeys();
    
    const {
        getTemplateRows,
        getVisibleRows,
        allRows,
        currentIdsFiltered,
        loading,
        getDistinctValuesForColumn,
        handleRefreshDataAsync,
    } = useRows(
        scroll.top,
        containerSize.y,
        cols,
        foreignFields,
        requiredFilterValue,
        table,
    );
    const { 
        buttons, 
        handleCallProcedureAsync 
    } = useButtons();

    const { 
        editiorAll, 
        handleEditorAll 
    } = useEditorAll();

    const handleTableId = useCallback(async (id: number | null) => {
        setTableId(id);
    },[]);
    
    return (
        <DataGrid.Provider
            value={{
                scroll,
                gridTemplateColumns: getTemplateColumns,
                gridTemplateRows: getTemplateRows,
                allColumns: cols,
                columns: getVisibleColumns,
                width: getWidth,
                rows: getVisibleRows,
                allRows,
                table,
                foreignFields,
                maxIndex,
                editiorAll,
                currentIdsFiltered,
                buttons: buttons,
                loading,
                requiredFilterValue,
                requiredFilterOptions,
                onRequiredFilterChange: handleChangeRequiredFilter,
                onResetRequiredFilter: handleResetRequiredFilter,
                onContainerResize: handleContainerSize,
                onTableIdChange: handleTableId,
                onFilterColumnSignalChange: handleFilterColumnSignal,
                onFilterColumnValueChange: handleFilterColumnValue,
                onSortColumnChange: handleSortColumn,
                getDistinctValuesForColumn,
                onRefreshDataAsync: handleRefreshDataAsync,
                onEditiorAllChange: handleEditorAll,
                onCallProcedureAsync: handleCallProcedureAsync,
                onScrollChange: handleScroll,
                getGroups
                
            }}
        >
            {children}
        </DataGrid.Provider>
    );
};
