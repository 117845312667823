import {
    Dialog,
    DialogContent,
    DialogTitle,
    Box,
    TextField,
    FormControlLabel,
    Switch,
    Button,
} from '@mui/material';
import { Trans, t } from '@lingui/macro';
import { useManageRole } from './hooks/useManageRole';
import { LoadingButton } from '@mui/lab';

interface IManageRoleProps {
    open: boolean;
    onClose: () => void;
    roleId: number;
}
export const ManageRole: React.FC<IManageRoleProps> = ({
    open,
    onClose,
    roleId
}): JSX.Element => {
    const {
        role,
        handleInputChange,
        handleSwitchChange,
        handleSaveAsync,
        loading,
    } = useManageRole(
        roleId, 
        open,
        onClose, 
    );
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
            <DialogTitle textAlign={'center'}>
                {role.id === 0 ? (
                    <Trans>Adicionar Grupo</Trans>
                ) : (
                    <Trans>Editar Grupo</Trans>
                )}
            </DialogTitle>
            <DialogContent>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={2}
                    minHeight={200}
                >
                    <TextField
                        value={role.name}
                        variant="standard"
                        label={t`Nome`}
                        name="name"
                        onChange={handleInputChange}
                        disabled={loading}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={role.admin}
                                name="admin"
                                onChange={handleSwitchChange}
                                disabled={loading}
                            />
                        }
                        label={t`Admin`}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                    }}
                >
                    <LoadingButton
                        variant="outlined"
                        onClick={handleSaveAsync}
                        loading={loading}
                    >
                        <Trans>Salvar</Trans>
                    </LoadingButton>
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={onClose}
                    >
                        <Trans>Cancelar</Trans>
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
