import { useHubContext } from 'providers';
import { useCallback, useEffect, useState } from 'react';
import { getAsync } from 'services';

export const useUserOnline = () => {
    const { hubConnection } = useHubContext();
    const [users, setUsers] = useState<{name: string, avatar: string}[]>([]);
    const handleRefreshUsersOnlineAsync = useCallback(async () => {
        const usersOnline = await getAsync<{name: string, avatar: string}[]>('/users/online');
        if(usersOnline.data) setUsers(usersOnline.data);
        setTimeout(handleRefreshUsersOnlineAsync, 1000 * 60);
    }, []);
    useEffect(() => {
        handleRefreshUsersOnlineAsync();
    }, [handleRefreshUsersOnlineAsync]);

    useEffect(() => {
        if (hubConnection) {
            hubConnection.on('RefreshUsersOnline', handleRefreshUsersOnlineAsync);
            return () => hubConnection.off('RefreshUsersOnline', handleRefreshUsersOnlineAsync);
        }
    }, [handleRefreshUsersOnlineAsync, hubConnection]);
    return {
        users
    };
};