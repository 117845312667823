import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    InputLabel,
    ListItemText,
    ListSubheader,
    MenuItem,
    Select,
    Switch,
    TextField,
    Toolbar,
    useTheme
} from '@mui/material';
import { TooltipSimplesComponent } from 'components/TippyComponent';
import { occupationOptionsToLanguage } from 'locales/helper';
import { isNotEmptyObject } from 'pages/inventory-policy/utils/isNotEmptyObject';
import { memo, useEffect, useState } from 'react';
import { setSelectedOccupationProperty } from 'redux/Filters/reducer';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { IResource } from 'types/data';
import { IItem } from 'types/data/IItem';
import { useVariant } from 'utils';
import { Icons } from 'utils/icons';

interface IHeaderProps {
    resources: IResource[];
    selectedResources: number[];
    setupGrouped: boolean;
    onlyAllocated: boolean;
    handleSelectResource: (event: number[]) => void;
    handleSelectWorkCenter: (event: number[]) => void;
    handleSetupGrouped: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleOnlyAllocated: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleFiniteScenario: (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => void;
    groupedResources: any[]
    onOccupationIconClick: () => void
    handleTextSearch: (event: any) => void
}

const Header: React.FC<IHeaderProps> = ({
    handleFiniteScenario,
    handleSelectResource,
    handleSetupGrouped,
    resources,
    selectedResources,
    setupGrouped,
    onOccupationIconClick,
    handleOnlyAllocated,
    onlyAllocated,
    groupedResources,
    handleSelectWorkCenter,
    handleTextSearch,

}: IHeaderProps): JSX.Element => {
    const variant = useVariant();
    const theme = useTheme();
    const { i18n } = useLingui();
    const [searchText, setSearchText] = useState('');
    const finiteButtonLoading = useAppSelector(state => state.filter.planningGrid.finiteButtonLoading);
    const dispatch = useAppDispatch();
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            handleTextSearch(searchText);
        }, 300);

        return () => clearTimeout(timeoutId);
    }, [handleTextSearch, searchText]);

    enum ItemKeys {
        id = 'id',
        classificationComplexityId = 'classificationComplexityId',
        classificationUncertaintyId = 'classificationUncertaintyId',
        classificationVolumeId = 'classificationVolumeId',
        companyId = 'companyId',
        plannerId = 'plannerId',
        stockGroupId = 'stockGroupId',
        itemAttribute01Id = 'itemAttribute01Id',
        itemAttribute02Id = 'itemAttribute02Id',
        itemAttribute03Id = 'itemAttribute03Id',
        itemAttribute04Id = 'itemAttribute04Id',
        itemAttribute05Id = 'itemAttribute05Id',
        itemAttribute06Id = 'itemAttribute06Id',
        itemAttribute07Id = 'itemAttribute07Id',
        itemAttribute08Id = 'itemAttribute08Id',
        itemAttribute09Id = 'itemAttribute09Id',
        itemAttribute10Id = 'itemAttribute10Id',
        tag01Id = 'tag01Id',
        tag02Id = 'tag02Id',
        tag03Id = 'tag03Id',
    }

    const keyTranslations: Record<ItemKeys, string> = {
        id: t`Item`,
        classificationComplexityId: t`Complexidade`,
        classificationUncertaintyId: t`Incerteza`,
        classificationVolumeId: t`Volume`,
        companyId: t`Empresa`,
        plannerId: t`Planejador`,
        stockGroupId: t`Grupo de Estoque`,
        itemAttribute01Id: t`Atributo 01`,
        itemAttribute02Id: t`Atributo 02`,
        itemAttribute03Id: t`Atributo 03`,
        itemAttribute04Id: t`Atributo 04`,
        itemAttribute05Id: t`Atributo 05`,
        itemAttribute06Id: t`Atributo 06`,
        itemAttribute07Id: t`Atributo 07`,
        itemAttribute08Id: t`Atributo 08`,
        itemAttribute09Id: t`Atributo 09`,
        itemAttribute10Id: t`Atributo 10`,
        tag01Id: t`Tag 01`,
        tag02Id: t`Tag 02`,
        tag03Id: t`Tag 03`,
    };
    const itemProperties = useAppSelector(state => state.filter.global.itemProperties);
    // Filter ItemKeys based on itemProperties
    const filteredKeys = Object.keys(itemProperties)
        .filter(key => {
            const itemKey = itemProperties[key];
            // Check if itemProperties[itemKey] is an object and not empty
            if (isNotEmptyObject(itemKey)) return key;
        });


    // Generate select options
    const selectOptions = filteredKeys.map(key => (
        <MenuItem key={key} value={ItemKeys[key]}>{occupationOptionsToLanguage(keyTranslations[key])}</MenuItem>
    ));

    const [selectedKey, setSelectedKey] = useState<ItemKeys | ''>('');

    const handleChange = (event: React.ChangeEvent<{ value: keyof IItem }>) => {
        dispatch(setSelectedOccupationProperty({ attribute: event.target.value }));
        setSelectedKey(event.target.value as ItemKeys);
    };

    return (
        <Toolbar
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: 50,
                width: '100%',
                position: 'sticky',
                top: 0,
                backgroundColor: 'background.default',
                padding: 2,
                zIndex: 1,
            }}
        >
            <FormControl
                variant="standard"
                sx={{
                    minWidth: 300,
                    maxWidth: 300,
                    marginRight: 2,
                }}
            >
                <InputLabel id="select-occupation-resource">
                    <Trans>Recurso</Trans>
                </InputLabel>
                <Select
                    variant="standard"
                    MenuProps={{
                        autoFocus: false,
                        anchorOrigin: { vertical: 'center', horizontal: 'right' },
                        sx: {
                            marginLeft: '2.5rem'
                        }
                    }}
                    multiple
                    labelId="select-occupation-resource"
                    label={t`Recurso`}
                    value={selectedResources}
                    renderValue={(selected) =>
                        resources
                            .filter((f) => selected.includes(f.id))
                            .map((f) => f.name)
                            .join(', ')
                    }
                >
                    <ListSubheader
                        sx={{
                            margin: 0,
                        }}>
                        <TextField
                            size="medium"
                            // Autofocus on textfield
                            autoFocus
                            sx={{
                                padding: '0.5rem 0 ',
                            }}
                            placeholder={t`Pesquisar`}
                            fullWidth
                            value={searchText}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icons.Search />
                                    </InputAdornment>
                                )
                            }}
                            onChange={(e) => setSearchText(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key !== 'Escape') {
                                    // Prevents autoselecting item while typing (default Select behaviour)
                                    e.stopPropagation();
                                }
                            }}
                        />
                    </ListSubheader>
                    <Grid container spacing={0}>
                        <Grid item xs={true}> {/* Ocupa o espaço restante */}
                            <MenuItem 
                                sx={{ 
                                    height: '50px', 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    justifyContent: 'flex-start', 
                                    width: '100%' // Preenche o restante da largura
                                }} 
                                divider 
                                key="select-all" 
                                value={selectedResources.length === resources.length ? -2 : -1} 
                                onClick={() => handleSelectResource((selectedResources.length === resources.length ? [-2] : [-1]) as any)}
                            >
                                <Checkbox checked={selectedResources.length === resources.length} />
                                <ListItemText primary={t`Selecionar Todos`} />
                            </MenuItem>
                        </Grid>
                        <Grid item xs="auto"> {/* Apenas o necessário para o ícone */}
                            <MenuItem 
                                sx={{  
                                    height: '50px', 
                                    display: 'flex', 
                                    justifyContent: 'center', 
                                    ':hover': { 
                                        color: theme.palette.primary.main 
                                    } 
                                }} 
                                divider 
                                key="unselect-all" 
                                value={-2} 
                                onClick={() => handleSelectResource([-2])}
                            >
                                <Icons.Clear size={25} />
                            </MenuItem>
                        </Grid>
                    </Grid>

                    {groupedResources.map((group) => (
                        <div key={group.workcenterId}>
                            <MenuItem
                                onClick={() => handleSelectWorkCenter(group.workcenterId as any)} // Add this line
                                key={group.workcenterId} value={group.workcenterId}>
                                {group.workcenterName}
                            </MenuItem>
                            {group.resources.map((resource: IResource, index: any) => (
                                <MenuItem key={resource.id} value={resource.id}
                                    dense
                                    divider={index === group.resources.length - 1}
                                    style={{ paddingLeft: '10%' }} // Adjust the value as needed
                                    onClick={() => handleSelectResource([resource.id] as any)} // Add this line
                                >
                                    <Checkbox
                                        checked={selectedResources.indexOf(resource.id) > -1}
                                    />
                                    <ListItemText primary={resource.name} />
                                </MenuItem>
                            ))}
                        </div>
                    ))}
                </Select>

            </FormControl>

            <TooltipSimplesComponent
                onClickFunction={onOccupationIconClick}
                tooltipText={i18n.t('Visão de Ocupação')}
            >
                <Icons.Occupation
                    color={theme.palette.primary.main}
                    size={20}
                />
            </TooltipSimplesComponent>
            <Box
                sx={{
                    marginLeft: 'auto',
                }}
            >
                <FormControlLabel
                    control={
                        <Select
                            value={selectedKey}
                            size='small'
                            onChange={handleChange}
                            displayEmpty
                            sx={{
                                marginRight: '0.5rem'
                            }}
                        >
                            <MenuItem value="" disabled>
                                <Trans>Selecionar um Atributo</Trans>
                            </MenuItem>
                            <MenuItem value="id">
                                <Trans>Item</Trans>
                            </MenuItem>
                            {selectOptions}
                        </Select>
                    }
                    label={t`Agrupar`}
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={onlyAllocated}
                            onChange={handleOnlyAllocated}
                        />
                    }
                    label={t`Somente Alocados`}
                />

                <FormControlLabel
                    control={
                        <Switch
                            checked={setupGrouped}
                            onChange={handleSetupGrouped}
                        />
                    }
                    label={t`Agrupar setup`}
                />
  
                <Button
                    variant={variant}
                    name="finite"
                    onClick={handleFiniteScenario}
                    disabled={finiteButtonLoading}
                >
                    {
                        finiteButtonLoading
                            ? <CircularProgress size={25} color='error' />
                            : <Trans>Restringir</Trans>
                    }
                </Button>
            </Box>
        </Toolbar>
    );
};

const HeaderMemoized = memo(Header);
export { HeaderMemoized as Header };

