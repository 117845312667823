import React from 'react';
import { PlanningGridChart } from '../components/PlanningGridChart';
import { Box, LinearProgress, Skeleton, Typography, useTheme } from '@mui/material';
import { IPeriod } from 'types/scenarios/IPeriod';
import { IPlanningGrid } from 'types/scenarios/IPlanningGrid';
import { IScenario } from 'types/scenario';
import { Trans } from '@lingui/macro';

interface PlanningGridChartContainer {
    planningGrid: IPlanningGrid[]
    periods: IPeriod[]
    isWeekly: boolean
    loading: boolean
    scenario: IScenario | null
    handleReloadItemPlanningGrid: (itemId: number[]) => void
}
export function PlanningGridChartContainer({
    isWeekly,
    loading,
    periods,
    planningGrid,
    scenario,
    handleReloadItemPlanningGrid,

}: PlanningGridChartContainer) {
    const theme = useTheme();
    const skeletonArray = [];

    for (let i = 0; i <= 30; i += 1) {
        const randomPercentage = Math.floor(Math.random() * 151) + 300; // Generates a random value between 200 and 350
        skeletonArray.push(`${randomPercentage}px`);
    }

    if (loading) {
        return (
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="body1"
                    sx={{
                        fontWeight: 'bold',
                        color: theme.palette.text.secondary,
                    }}
                >
                    <Trans>Carregando cenário: {scenario?.name}</Trans>
                </Typography>

                <Box sx={{ width: '400px' }}>
                    <LinearProgress />
                </Box>
                <Box
                    sx={{
                        marginTop: '1rem',
                        width: '100%',
                        height: '350px',
                        overflow: 'hidden',
                        paddingLeft: '1rem',
                        display: 'flex',
                        gap: '2rem',
                        alignItems: 'end',
                    }}
                >
                    {skeletonArray.map((height, index) => (
                        <Skeleton
                            animation="pulse"
                            key={index}
                            sx={{
                                minWidth: '40px',
                                height: { height },
                                marginBottom: '-10rem',
                            }}
                        />
                    ))}
                </Box>
            </Box>
        );
    }
    return (
        <Box sx={{ height: '400px' }}>
            <PlanningGridChart
                planningGridData={planningGrid as any}
                periods={periods}
                scenario={scenario}
                isWeekly={isWeekly}
                handleReloadItemPlanningGrid={handleReloadItemPlanningGrid}

            />
        </Box>
    );
}

export const PlanningGridChartContainerMemoized = React.memo(PlanningGridChartContainer);
