import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';	
interface UserProfileProps {
    user: User | null | undefined;
    role: Role | null | undefined;
    onClick?: (event: number) => void;
}

export const UserProfile: React.FC<UserProfileProps> = ({ 
    user,
    role,
    onClick
}) => {
    const handleClick = () => {
        if(onClick) {
            onClick(user?.id || 1);
        }
    };
    return (
        <Box 
            sx={{ 
                width: 150, 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                cursor: 'pointer' 
            }}
            onClick={handleClick}
        >
            <Avatar 
                alt={'profile'} 
                src={user?.avatar} 
                sx={{ width: 100, height: 100 }}
            >
                {user && user.name.charAt(0) + user.lastName.charAt(0)}
            </Avatar>
            <Typography variant="body1" textAlign="center" sx={{ mt: 1 }}>
                {user && `${user.name} ${user.lastName}`}
            </Typography>
            <Typography variant="body2" textAlign="center" sx={{ mt: 1 }}>
                {role?.name}
            </Typography>
        </Box>
    );
};

