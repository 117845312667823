import { useContext } from 'react';
import { ConfirmContext } from '../context';
import { IConfirmOptions } from '..';

export const useConfirmContext = (): (options: IConfirmOptions) => Promise<boolean> => {
    const context = useContext(ConfirmContext);
    if (!context) {
        throw new Error('useConfirmContext must be used within a ConfirmProvider');
    }
    return context.confirmAsync;
};