import { t } from '@lingui/macro';
import { useCallback, useEffect } from 'react';
import { setInventoryFilters, setSelectedFilter } from 'redux/Filters/reducer';
import { useAppDispatch } from 'redux/hooks';
import { IInventorySegmentationData } from '../types/IInventorySegmentationData';
import { sortArrayByKey } from '../utils/sortArrayByKey';

const useFilterOptions = (segmentationInventory: IInventorySegmentationData[]) => {
    const dispatch = useAppDispatch();

    const getAxisOptions = useCallback(() => {
        const propertiesToCheck = [
            'customerGroup',
            'company',
            'classificationVolume',
            'classificationComplexity',
            'classificationUncertainty',
            'salesChannel',
            'stockGroup'
        ];

        const validations = propertiesToCheck.reduce((acc, property) => {
            acc[`${property}Exists`] = segmentationInventory.some(item => item[property] !== null && item[property] !== undefined);
            return acc;
        }, {});

        const axisOptions = {
            Company: getOptionsForProperty('company', segmentationInventory),
            Complexity: getOptionsForProperty('classificationComplexity', segmentationInventory),
            Volume: getOptionsForProperty('classificationVolume', segmentationInventory),
            Uncertainty: getOptionsForProperty('classificationUncertainty', segmentationInventory),
            Customer: getOptionsForProperty('customerGroup', segmentationInventory, true),
            StockGroup: getOptionsForProperty('stockGroup', segmentationInventory),
            SalesChannel: getOptionsForProperty('salesChannel', segmentationInventory)
        };

        

        return { axisOptions, validations };
    }, [segmentationInventory]);

    useEffect(() => {
        if (segmentationInventory && segmentationInventory.length > 0) {
            const { axisOptions, validations } = getAxisOptions();
            dispatch(setInventoryFilters({
                filters: {
                    companyOptions: axisOptions.Company,
                    dataValidation: validations as any,
                    salesChannelOptions: axisOptions.SalesChannel,
                    stockGroupOptions: axisOptions.StockGroup
                },
                axisOptions
            }));

            // Set selected filters
            dispatch(setSelectedFilter({
                selectedOption: axisOptions.StockGroup.find(option => option.id === 0) || axisOptions.StockGroup[0],
                selectorName: 'selectedStockGroupFilter'
            }));
            dispatch(setSelectedFilter({
                selectedOption: axisOptions.Company.length > 1 ? axisOptions.Company[1] : axisOptions.Company[0],
                selectorName: 'selectedCompanyFilter'
            }));
            dispatch(setSelectedFilter({
                selectedOption: axisOptions.SalesChannel.length > 1 ? axisOptions.SalesChannel[1] : axisOptions.SalesChannel[0],
                selectorName: 'selectedSalesChannelFilter'
            }));
        }
    }, [getAxisOptions, segmentationInventory, dispatch]);
};
export default useFilterOptions;

// Function to get options for a specific property
const getOptionsForProperty = (propertyName, inventoryData, isCustomerGroup = false) => {
    const optionsRaw = [
        (propertyName === 'salesChannel' || propertyName === 'company') && { id: 0, name: t`Total`, code: t`Total` },
        ...new Map(inventoryData.map(item => [item[propertyName]?.id, item[propertyName]])).values()
    ].filter(option => option !== null && option !== undefined);

    const translatedOptions = optionsRaw.map(option => ({
        id: option?.id,
        name: option?.name ? option.name : t`Geral`,
        code: option?.code ? option.code : t`Geral`,
        sequence: option?.sequence !== undefined ? option.sequence : option.id // Use id as fallback if sequence doesn't exist
    }));

    const filteredOptions = translatedOptions.filter(option => option !== null && option.id !== undefined);

    // Sort options
    let sortedOptions;
    sortedOptions = filteredOptions.sort((a, b) => a.sequence - b.sequence); // Sort by sequence
    if (isCustomerGroup) {
        sortedOptions = sortArrayByKey(sortedOptions, 'priority');
    }

    // If options are empty, add a default option
    if (sortedOptions.length === 0) {
        sortedOptions = [{ id: 0, name: t`Geral`, code: t`Geral` }];
    }

    return sortedOptions;
};

