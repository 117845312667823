import { memo, useEffect, useRef } from 'react';
import { useDataGridContext } from '../../providers/hooks';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { Body, Header } from './components';
import { useLingui } from '@lingui/react';
import { RequiredFilter } from './components/RequiredFilter';
import { VirtualBodySkeleton } from './components/VirtualBodySkeleton';
import { Groups } from './components/Groups';

interface IGridProps {
    onEditData: (id: number) => void;
    onCloseManageDataDialog: () => void;
}
const Grid: React.FC<IGridProps> = ({ onEditData, onCloseManageDataDialog }): JSX.Element => {
    const { id } = useParams();
    const { i18n } = useLingui();
    const {
        onScrollChange,
        gridTemplateColumns,
        gridTemplateRows,
        width,
        table,
        foreignFields,
        rows,
        columns,
        maxIndex,
        loading,
        onContainerResize,
        onTableIdChange,
        onFilterColumnSignalChange,
        onFilterColumnValueChange,
        getDistinctValuesForColumn,
        onSortColumnChange,
        getGroups
    } = useDataGridContext();
    const containerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (containerRef.current) {
            onContainerResize(containerRef.current);
            const containerRefCopy = containerRef.current;
            window.addEventListener('resize', () => {
                if (containerRefCopy) {
                    onContainerResize(containerRefCopy);
                }
            });
            return () => {
                window.removeEventListener('resize', () => {
                    if (containerRefCopy) {
                        onContainerResize(containerRefCopy);
                    }
                });
            };
        }
    }, [containerRef, onContainerResize]);
    useEffect(() => {
        try {
            const tableId = parseInt(id as string);
            onTableIdChange(tableId);
        } catch {
            onTableIdChange(null);
        }
    }, [id, onTableIdChange]);

    useEffect(() => {
        if(!table || rows.length === 0) return;
        if (table.parameterTable) {
            onEditData(rows[0][columns.find((column) => column.primaryKey)?.field ?? 'id']);
        } else {
            onCloseManageDataDialog();
        }
    }, [table, rows, onEditData, columns, onCloseManageDataDialog]);
    return (
        <div
            style={{
                width: '100%',
                overflow: 'hidden',
            }}
        >
            <RequiredFilter />
            <Typography height={30}  variant="h5" textAlign={'center'}>
                {i18n.t(table?.title ?? '')}
            </Typography>
            <div
                style={{
                    height: 'calc(100vh - 90px)',
                    width: '100%',
                    overflow: 'auto',
                }}
                onScroll={onScrollChange}
                ref={containerRef}
            >
                <div
                    style={{
                        gridTemplateColumns,
                        gridTemplateRows,
                        display: 'grid',
                        width,
                    }}
                >
                    <Groups
                        groups={getGroups}
                        maxIndex={maxIndex}
                    />
                    <Header
                        onFilterColumnSignalChange={onFilterColumnSignalChange}
                        onFilterColumnValueChange={onFilterColumnValueChange}
                        columns={columns}
                        foreignFields={foreignFields}
                        getDistinctValuesForColumn={getDistinctValuesForColumn}
                        onSortColumnChange={onSortColumnChange}
                        maxIndex={maxIndex}
                    />
                    {loading ? (
                        <VirtualBodySkeleton />
                    ) : (
                        <Body
                            rows={rows}
                            columns={columns}
                            maxIndex={maxIndex}
                            deleteData={table?.deleteData}
                            onEditData={onEditData}
                        />
                    )}
                    
                </div>
            </div>
        </div>
    );
};

const MemoizedGrid = memo(Grid);

export { MemoizedGrid as Grid };
