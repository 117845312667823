
const translatedLabels = {
    xAxis: `Eixo X`,
    yAxis: `Eixo Y`,
    filter: `Filtro`,
}

type stockAxesEnum = keyof typeof translatedLabels;

export const stockAxesEnum: { [key in stockAxesEnum]: string } = translatedLabels;
