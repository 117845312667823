import { ItemAttributesBundleData, ItemAttributesBundle } from "redux/Filters/types";
import { IAttributeList } from "types/system/IAttributeList";
import { createDictionary } from "utils/dictionaryHelper";

export const initializeAttributes = (payload: ItemAttributesBundleData, attributeData: IAttributeList[]): ItemAttributesBundle => {
    const productAttributes: ItemAttributesBundle = {};

    attributeData.forEach(({ code, id }) => {
        productAttributes[code] = createDictionary(
            payload[code] || [],
            x => x.id
        );
    });

    return productAttributes;
}