import { useState } from 'react';

const useFullScreen = () => {
    const [isLeftSideFullScreen, setLeftSideIsFullScreen] = useState<boolean>(false);
    const [isRightSideFullScreen, setRightSideIsFullScreen] = useState<boolean>(false);

    const handleFullScreen = (side: string) => {
        if (side === 'left') {
            setRightSideIsFullScreen(false);
            setLeftSideIsFullScreen(true);
        } else if (side === 'right') {
            setLeftSideIsFullScreen(false);
            setRightSideIsFullScreen(true);
        }
    };

    const handleExitFullScreen = () => {
        setRightSideIsFullScreen(false);
        setLeftSideIsFullScreen(false);
    }

    return {
        isLeftSideFullScreen,
        isRightSideFullScreen,
        handleFullScreen,
        handleExitFullScreen,
    };
};

export default useFullScreen;
