import { useCallback, useState, useEffect } from 'react';
import { getAsync, useHubContext } from '@nplan';



interface IUseUserDataReturn {
    users: User[];
    roles: Role[];
    loading: boolean;
    handleRefreshDataAsync: () => Promise<void>;
}


export const useUserData = (): IUseUserDataReturn => {
    const [users, setUsers] = useState<User[]>([]);
    const [roles, setRoles] = useState<Role[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const { hubConnection } = useHubContext();

    const handleRefreshDataAsync = useCallback(async (): Promise<void> => {
        const [resUser, resRoles] = await Promise.all([
            getAsync<User[]>('users'),
            getAsync<Role[]>('roles'),
        ]);
        const rolesData = resRoles.data;
        const usersData = resUser.data;
        if(rolesData && usersData) {
            setUsers(usersData);
            setRoles(rolesData);
        }
    }, []);

    useEffect(() => {
        setLoading(true);
        handleRefreshDataAsync().then(() => setLoading(false));
    }, [handleRefreshDataAsync]);

    useEffect(() => {
        if (hubConnection) {
            hubConnection.on('RefreshUsers', handleRefreshDataAsync);
            return () => hubConnection.off('RefreshUsers', handleRefreshDataAsync);
        }
    }, [hubConnection, handleRefreshDataAsync]);
    return { 
        users, 
        roles, 
        loading, 
        handleRefreshDataAsync 
    };
};