import React from 'react';
import {
    Box,
    Divider,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    Popover,
    Typography,
    useTheme,
} from '@mui/material';
import { useDataContext, Icons } from '@nplan';
import { t } from '@lingui/macro';

interface ISchedulerMenuProps {
    open: boolean;
    anchorEl: HTMLElement | null;
    onClose: () => void;
}

export const SchedulerMenu: React.FC<ISchedulerMenuProps> = ({
    open,
    anchorEl,
    onClose,
}) => {
    const {
        executions,
        onRefreshProceduresAsync,
        onCancelProcedureAsync
    } = useDataContext();

    const theme = useTheme();
    
    return (
        <Popover
            anchorEl={anchorEl}
            open={open}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            onClose={onClose}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxHeight: 400,
                    minHeight: 100,
                    overflow: 'hidden',
                    maxWidth: 550,
                    minWidth: 550,
                    backgroundColor: theme.palette.background.default
                }}
            >
                <List
                    sx={{
                        overflowY: 'auto',
                        maxHeight: 350,
                    }}
                >
                    {executions.map((execution) => (
                        <React.Fragment key={execution.id}>
                            <Box
                                key={execution.id}
                                sx={{ marginBottom: 2 }}
                            >
                                <ListItem>
                                    <ListItemText
                                        primary={execution.title}
                                        secondaryTypographyProps={{
                                            component: 'span',
                                        }}
                                        secondary={
                                            <>
                                                <Typography>
                                                    Início:{' '}
                                                    {new Date(
                                                        execution.startTime!,
                                                    ).toLocaleString()}{' '}
                                                </Typography>
                                                <Typography>
                                                    Última
                                                    Atualização:{' '}
                                                    {execution.lastUpdate &&
                                                        new Date(
                                                            execution.lastUpdate,
                                                        ).toLocaleString()}
                                                </Typography>
                                                <Typography>
                                                    Último Status:{' '}
                                                    {
                                                        execution.lastMessage
                                                    }
                                                </Typography>
                                            </>
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            color={'error'}
                                            disabled={
                                                execution.canceling
                                            }
                                            edge="end"
                                            aria-label="cancel"
                                            onClick={
                                                onCancelProcedureAsync
                                            }
                                            data-id={execution.id}
                                        >
                                            <Icons.Cancel />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                {/* Barra de progresso */}
                                <LinearProgress
                                    variant="determinate"
                                    value={execution.total}
                                    sx={{
                                        mr: 2,
                                        ml: 2,
                                    }}
                                />
                            </Box>
                            <Divider variant="middle" />
                        </React.Fragment>
                    ))}
                    {executions.length === 0 && (
                        <ListItem>
                            <ListItemText
                                sx={{ textAlign: 'center' }}
                                primary={t`Nenhuma tarefa sendo executada no momento`}
                            />
                        </ListItem>
                    )}
                </List>
                <ListItemButton
                    onClick={onRefreshProceduresAsync}
                    sx={{
                        textAlign: 'center',
                        color: 'primary.main',
                        cursor: 'pointer',
                        maxHeight: 50,
                        marginTop: 'auto',
                    }}
                >
                    <ListItemText primary={t`Atualizar`} />
                </ListItemButton>
            </Box>
        </Popover>
    );
};
