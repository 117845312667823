
export const booleanFilter = (
    row: any,
    column: Column,
) => {
    let value = row[column.field];
    if (value === null || value === undefined) return false;
    let filterValue = column.filterValue;
    if (filterValue === null || filterValue === undefined) return true;
    value = Boolean(value);
    filterValue = Boolean(filterValue);
    return value === filterValue;
};
