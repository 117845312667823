import { t } from '@lingui/macro';
import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import usePlanningGridStockValueAndColors from '../Grid/usePlanningGridStockValueAndColors';

export const toLowerFirstLetter = (string: string) => {
    return string.charAt(0).toLowerCase() + string.slice(1);
};

const useChartData = (summedSelectedPlanningGrids: any, periods: string[]) => {
    const { getColorByStockStatusType } = usePlanningGridStockValueAndColors();
    const getStockStatusColorMemoized: string[] = useMemo(() => {
        return summedSelectedPlanningGrids.map((x: any) => {
            return `${getColorByStockStatusType(x.stockStatus)}`;
        });
    }, [getColorByStockStatusType, summedSelectedPlanningGrids]);

    const theme = useTheme();
    const minStocksColor = 'rgba(241, 239, 187)',
        maxStockColor = 'rgb(51,221,255)',
        productionColor = 'rgba(9, 92, 236)',
        targetStocksColor = 'rgba(183, 243, 165)',
        safetyStockColor = 'rgba(248, 208, 201)',
        mpsColor = 'rgba(55, 126, 250,0.75)',
        demandColor = 'rgba(228, 114, 58,0.75)';


    const chartData = useMemo(() => {
        return {
            labels: periods,
            stacked: true,
            datasets: [
                {
                    order: 0,
                    type: 'line',
                    data: summedSelectedPlanningGrids.map((x: any) => x.closingStock),
                    label: t({ id: 'Estoque Projetado', comment: 'Label no topo do PlanningGridChart' }),
                    lineTension: 0.15,
                    cubicInterpolationMode: 'monotonic',
                    fill: false,
                    pointBackgroundColor: getStockStatusColorMemoized,
                    pointBorderColor: theme.palette.grey['A400'],
                    pointBorderWidth: 1,
                    borderWidth: 1.3,
                    pointStyle: 'triangle',
                    radius: 10,
                    dragData: false,
                    borderColor: theme.palette.grey[600],

                },
                {
                    order: 1,
                    type: 'bar',
                    label: t({ id: 'Programado', comment: 'Label no topo do PlanningGridChart' }),
                    data: summedSelectedPlanningGrids.map((x: any) => x.orders),
                    borderColor: ['rgba(163, 161, 251, 1)'],
                    borderWidth: 1,
                    pointHitRadius: 10,
                    pointRadius: 6,
                    pointHoverRadius: 4,
                    backgroundColor: productionColor,
                    pointBackgroundColor: 'rgba(239,83,80,0.8)',
                    pointBorderColor: 'rgba(0,0,0,0.6)',
                    pointHoverBackgroundColor: 'rgba(255, 255, 255, 1)',
                    pointHoverBorderColor: 'rgba(163, 161, 251, 1)',
                    dragData: false
                },
                {
                    order: 2,
                    type: 'line',
                    label: t({ id: 'Estoque de Segurança', comment: 'Label no topo do PlanningGridChart' }),
                    fill: true,
                    data: summedSelectedPlanningGrids.map((x: any) => x.safetyStock),
                    backgroundColor: safetyStockColor,
                    lineTension: 0.15,
                    radius: 0,
                    dragData: false,

                },
                {
                    order: 1,
                    type: 'bar',
                    fillColor: 'green',
                    label: t({ id: 'Plano', comment: 'Label no topo do PlanningGridChart' }),
                    data: summedSelectedPlanningGrids.map((x: any) => x.mps),
                    backgroundColor: mpsColor,
                    borderWidth: 1,
                    pointHitRadius: 5,
                    pointHoverRadius: 4,
                    pointHoverBackgroundColor: 'rgba(255, 255, 255, 1)',
                    pointHoverBorderColor: 'rgba(163, 161, 251, 1)',
                    dragData: true,

                },

                {
                    order: 1,
                    type: 'bar',
                    fillColor: 'orange',
                    label: t({ id: 'Demanda', comment: 'Label no topo do PlanningGridChart' }),
                    data: summedSelectedPlanningGrids.map((x: any) => x.demand),
                    backgroundColor: demandColor,
                    borderWidth: 1,
                    pointHitRadius: 5,
                    pointHoverRadius: 4,
                    pointHoverBackgroundColor: 'rgba(255, 255, 255, 1)',
                    pointHoverBorderColor: 'rgba(163, 161, 251, 1)',
                    dragData: false,
                },
                {
                    order: 3,
                    type: 'line',
                    label: t({ id: 'Ponto de Reposição', comment: 'Label no topo do PlanningGridChart' }),
                    fill: true,
                    data: summedSelectedPlanningGrids.map((x: any) => x.minStock),
                    backgroundColor: minStocksColor,
                    lineTension: 0.15,
                    radius: 0,
                    dragData: false,
                },
                {
                    order: 4,
                    type: 'line',
                    label: t({ id: 'Estoque Alvo', comment: 'Label no topo do PlanningGridChart' }),
                    fill: true,
                    data: summedSelectedPlanningGrids.map((x: any) => x.targetStock),
                    backgroundColor: targetStocksColor,
                    lineTension: 0.15,
                    radius: 0,
                    dragData: false,
                },
                {
                    order: 6,
                    type: 'line',
                    label: t({ id: 'Estoque Máximo', comment: 'Label no topo do PlanningGridChart' }),
                    data: summedSelectedPlanningGrids.map((x: any) => x.maxStock),
                    backgroundColor: theme.palette.grey[400],
                    lineTension: 0.15,
                    borderWidth: 0.5,
                    radius: 0,
                    dragData: false,
                    borderColor: theme.palette.grey[700],
                    // hidden: true
                },
            ],
        };
    }, [periods, summedSelectedPlanningGrids, getStockStatusColorMemoized, theme.palette.grey]);

    return { chartData };
};

export default useChartData;
