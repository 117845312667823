import React from 'react';
import { 
    AvatarGroup,
    Box, 
    IconButton 
} from '@mui/material';
import bgLogo from 'assets/images/neoone-ff319e80.png';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { IoSettingsOutline } from 'react-icons/io5';
import useMenu from './hooks/useMenu';
import {
    SettingsMenu,
    NotificationMenu,
    SchedulerMenu,
} from './components/';
import { MdAccessTime } from 'react-icons/md';
import { useDataContext } from '@nplan';
import { TooltipSimplesComponent, UserBadge } from '@nplan';
import { t } from '@lingui/macro';
import { useUserOnline } from './hooks/useUserOnline';

const NavbarMenu: React.FC = (): JSX.Element => {
    const { 
        totalUnreaded, 
        executions 
    } = useDataContext();

    const {
        open: settingsOpen,
        handleClose: onSettingsClose,
        handleOpen: onSettingsOpen,
        anchorEl: settingsAnchorEl,
    } = useMenu();

    const {
        open: notificationsOpen,
        handleClose: onNotificationsClose,
        handleOpen: onNotificationsOpen,
        anchorEl: notificationsAnchorEl,
    } = useMenu();

    const {
        open: schedulerNotificationsOpen,
        handleClose: onSchedulerNotificationsClose,
        handleOpen: onSchedulerNotificationsOpen,
        anchorEl: schedulerNotificationsAnchorEl,
    } = useMenu();

    const {
        users
    } = useUserOnline();

    return (
        <React.Fragment>
            
            <SettingsMenu
                open={settingsOpen}
                onClose={onSettingsClose}
                anchorEl={settingsAnchorEl}
            />
            <NotificationMenu
                open={notificationsOpen}
                onClose={onNotificationsClose}
                anchorEl={notificationsAnchorEl}
            />
            <SchedulerMenu
                open={schedulerNotificationsOpen}
                onClose={onSchedulerNotificationsClose}
                anchorEl={schedulerNotificationsAnchorEl}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: 'rgb(31, 41, 55)',
                    color: 'white',
                    height: 40,
                    paddingRight: 2,
                }}
            >
                <Box
                    sx={{
                        width: '80px',
                        height: '40%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'hidden',
                    }}
                >
                    <img
                        src={bgLogo}
                        style={{
                            width: `80%`,
                            height: '80%',
                        }}
                        alt='NPlan Logo'
                    />
                </Box>
                
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                >
                    <Box
                        sx={{
                            marginLeft: 1,
                            
                            position: 'relative',
                        }}
                        mr={2}
                    >
                        <AvatarGroup max={20}>
                            {users.map((user) => (
                                <UserBadge key={user.name} user={user.name} avatar={user.avatar} online={true}/>   
                            ))}
                        </AvatarGroup>
                        
                    </Box>
                    
                    <IconButton
                        color='inherit'
                        sx={{
                            marginLeft: 1,
                            cursor: 'pointer',
                            transition:
                                'transform 0.3s ease',
                            ':hover': {
                                color: '#daae0d',
                                transform:
                                    'translateY(-1px)',
                            },
                            position: 'relative',
                            ...(executions.length > 0 && {
                                '::after': {
                                    content: '""',
                                    position: 'absolute',
                                    top: 10,
                                    right: 8,
                                    width: '10px',
                                    height: '10px',
                                    backgroundColor: 'red',
                                    borderRadius: '50%',
                                    animation:
                                        'blink 1s infinite',
                                },
                            }),
                        }}
                        onClick={onSchedulerNotificationsOpen}
                    >
                        <TooltipSimplesComponent
                            tooltipText={t`Scheduler`}
                        >
                            <MdAccessTime />
                        </TooltipSimplesComponent>
                    </IconButton>
                    <IconButton
                        color='inherit'
                        sx={{
                            marginLeft: 1,
                            cursor: 'pointer',
                            transition:
                                'transform 0.3s ease',
                            ':hover': {
                                color: '#daae0d',
                                transform:
                                    'translateY(-1px)',
                            },
                            position: 'relative',
                            ...(totalUnreaded > 0 && {
                                '::after': {
                                    content: '""',
                                    position: 'absolute',
                                    top: 10,
                                    right: 8,
                                    width: '10px',
                                    height: '10px',
                                    backgroundColor: 'red',
                                    borderRadius: '50%',
                                    animation:
                                        'blink 1s infinite',
                                },
                            }),
                        }}
                        onClick={onNotificationsOpen}
                    >
                        <TooltipSimplesComponent
                            tooltipText={t`Notificações`}
                        >
                            <IoMdNotificationsOutline />
                        </TooltipSimplesComponent>
                    </IconButton>

                    <IconButton
                        color='inherit'
                        sx={{
                            marginLeft: 1,
                            cursor: 'pointer',
                            transition:
                                'transform 0.3s ease',
                            ':hover': {
                                color: '#daae0d',
                                transform:
                                    'translateY(-1px)',
                            },
                        }}
                        onClick={onSettingsOpen}
                    >
                        <TooltipSimplesComponent
                            tooltipText={t`Configurações`}
                        >
                            <IoSettingsOutline />
                        </TooltipSimplesComponent>
                    </IconButton>
                </Box>
            </Box>
        </React.Fragment>
    );
};

const MemoizedNavbarMenu = React.memo(NavbarMenu);
export { MemoizedNavbarMenu as NavbarMenu };
