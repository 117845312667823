
export const dateFilter = (
    row: any,
    column: Column,
) => {
    const value = row[column.field];
    if (value === null || value === undefined) return false;
    const filterValue = column.filterValue;
    if (!Array.isArray(filterValue) || filterValue.length === 0) return true;
    const formattedValue = new Date(value).toISOString().split('T')[0];
    return filterValue.includes(formattedValue);
};
