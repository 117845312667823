import { useState, MouseEvent, useCallback } from 'react';


interface IUseContextMenuReturn {
    handleOpenMenu: (event: MouseEvent<HTMLDivElement>) => void;
    handleCloseMenu: () => void;
    position: MenuPosition | null;
}
export const useContextMenu = (): IUseContextMenuReturn => {
    const [position, setPosition] = useState<MenuPosition | null>(null);

    const handleOpenMenu = useCallback((
        event: MouseEvent<HTMLDivElement>
    ): void => {
        event.preventDefault();
        setPosition({
            x: event.clientX,
            y: event.clientY,
        });
    }, []);

    const handleCloseMenu = useCallback(() => {
        setPosition(null);
    }, []);

    return { 
        position,
        handleOpenMenu, 
        handleCloseMenu, 
    };
};
