import { Trans, t } from '@lingui/macro';
import { Box, Button, Typography, useTheme } from '@mui/material';
import useInventoryPolicy from 'pages/inventory-policy/hooks/useInventoryPolicy';
import { selectedButtonStyle } from 'pages/inventory-policy/styles';
import { IFilter } from 'pages/inventory-policy/types/IFilters';
import React, { useEffect } from 'react';
import { setSelectedFilter } from 'redux/Filters/reducer';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { IGenericOptions } from 'types/Generics';
import { useVariant } from 'utils';



interface InventoryButtonGroupProps {
    filter: IFilter
}
const InventoryButtonGroup: React.FC<InventoryButtonGroupProps> = ({ filter }: InventoryButtonGroupProps): JSX.Element => {
    const theme = useTheme();
    const variant = useVariant();
    const { handleInventoryHealthManualUpdate } = useInventoryPolicy();
    const dispatch = useAppDispatch();
    const axisOptions = useAppSelector(state => state.filter.inventory.axisOptions);
    const filterOptions = (axisOptions)[filter.axisData?.filter.name as string];
    const selectedSecondaryFilter = useAppSelector(state => state.filter.inventory.selectedSecondaryFilter);
    const allOptions = { id: 0, code: '0', name: t`Todos` };
    useEffect(() => { dispatch(setSelectedFilter({ selectedOption: allOptions, selectorName: 'selectedSecondaryFilter' })); }, []);
    const handleSelect = (option: IGenericOptions) => {
        dispatch(setSelectedFilter({ selectedOption: option, selectorName: 'selectedSecondaryFilter' }));
        handleInventoryHealthManualUpdate(option.id);
    };
    const stockEditableTableValidation = useAppSelector(state => state.filter.inventory.isEditable);


    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 2, width: 'fit-content', p: '0.5rem', borderRadius: 2, }}>
            {stockEditableTableValidation === false ? (
                <Button
                    variant={variant}
                    onClick={() => handleSelect(allOptions)}
                    sx={(selectedSecondaryFilter.id === 0) 
                        ? selectedButtonStyle 
                        :{
                            backgroundColor: theme.palette.grey[300],
                            color: theme.palette.grey[200],
                            borderColor: theme.palette.grey[300],
                        }}
                >
                    <Typography variant='body2' sx={{ fontWeight: '500', fontSize: '0.7rem' }}>
                        <Trans> Todos</Trans>
                    </Typography>
                </Button>
            ) : null}
            {filterOptions && filterOptions.map((option: any) => (
                <Button
                    variant={variant}
                    onClick={() => handleSelect(option)}
                    key={option.id} 
                    sx={(option.id === selectedSecondaryFilter.id) 
                        ? selectedButtonStyle 
                        :{
                            backgroundColor: theme.palette.grey[300],
                            color: theme.palette.grey[200],
                            borderColor: theme.palette.grey[300],
                        }}
                >
                    <Typography variant='body2' sx={{ fontWeight: '500', fontSize: '0.7rem' }}>
                        {option.name}
                    </Typography>
                </Button>
            ))}
        </Box>
    );
};

export default InventoryButtonGroup;
