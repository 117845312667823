import { Box } from '@mui/material';
import { useHomeData } from './hooks';

export const Home: React.FC = (): JSX.Element => {
    const { ref } = useHomeData();
    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            ref={ref}
        />
    );
};
