import { useContext } from 'react';
import { DataGrid, IDataGridProps } from '../context/';


export * from './useButtons';
export * from './useColumns';
export * from './useEditorAll';
export * from './useForeignKeys';
export * from './useRequiredFilter';
export * from './useRows';
export * from './useScroll';
export * from './useSizer';
export * from './useTable';

export const useDataGridContext = (): IDataGridProps => {
    const context = useContext<IDataGridProps | null>(DataGrid);
    if (context === null) {
        throw new Error('Component must be wrapped with <DataGridProvider>');
    }
    return context;
};