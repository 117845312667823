import { Trans } from '@lingui/macro';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useDataContext } from 'providers';

export const ScenarioSelector:React.FC = ():JSX.Element => {
    const {
        scenarioId,
        scenarios,
        onChangeScenario
    } = useDataContext();
    return (
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="scenario-select-pg-label">
                <Trans>
                    Cenário
                </Trans>
            </InputLabel>
            <Select
                labelId="scenario-select-pg-label"
                id="scenario-select-pg"
                value={scenarioId ?? ''}
                onChange={onChangeScenario}
            >
                {scenarios
                    .filter((scenario) => scenario.scenarioStatusId === 4 || scenario.scenarioStatusId === 5)
                    .map((scenario) => (
                        <MenuItem value={scenario.id} key={scenario.id}>
                            {scenario.name}
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    );
};