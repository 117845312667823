
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-expect-error
import Main from 'neoone/Main';


export const NewOrderPage: React.FC = () => {
    return (
        <Main />
    );
};