import React, { useMemo, useState } from "react";
import DropdownTreeSelect, {
    TreeNode,
    TreeNodeProps,
} from "react-dropdown-tree-select";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { RootState } from "redux/store";
import { FilterCriteria } from "../FilterCriteria/types";
import { applyCriterias, filterBy } from "redux/Filters/reducer";
import DropdownTreeSelector from "./teste";

interface Props {
    filterCriteria: FilterCriteria;
    selector: (state: RootState) => TreeNodeProps;
    placeholder?: string | undefined;
}

function DropDownTreeSelectorContainer(props: Props) {
    const { filterCriteria, selector, placeholder } = props;

    const dispatch = useAppDispatch();

    const onChangeSearch = (currentNode: any, selectedNodes: TreeNode[]) => {
        dispatch(
            filterBy({
                filterCriteria: filterCriteria,
                searchList: selectedNodes.map((node) => ({
                    label: node.label,
                    id: node.value,
                })),
            })
        );
        dispatch(applyCriterias());
    };

    // Memoize the searchTree state
    const searchTree = useAppSelector(selector);
    const memoizedSearchTree = useMemo(() => searchTree, [searchTree]);


    return (
        // <DropdownTreeSelector
        //     data={searchTree} />
        <DropdownTreeSelect
            className="hide-selected-text dropdown-tree"
            inlineSearchInput={true}
            texts={{ placeholder: placeholder }}
            showDropdown={"default"}
            data={memoizedSearchTree}
            keepTreeOnSearch={true}
            keepChildrenOnSearch={true}
            onChange={onChangeSearch}
            keepOpenOnSelect
            showPartiallySelected

        />
    );
}

export default DropDownTreeSelectorContainer;
