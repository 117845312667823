import {
    Button,
    Switch,
    TableBody,
    TableCell,
    TableRow,
} from '@mui/material';
import { Trans } from '@lingui/macro';
import { Icons } from '@nplan';
import React from 'react';
import { useButtonActions } from '../hooks/useButtonActions';

interface IBodyProps {
    buttons: Button[];
    onEditButton: (event: React.MouseEvent<HTMLButtonElement>) => void;
}
export const Body: React.FC<IBodyProps> = ({
    buttons,
    onEditButton,
}): JSX.Element => {
    const { handleDeleteButtonAsync } = useButtonActions();
    return (
        <TableBody>
            {buttons.map((button) => (
                <TableRow hover key={button.id}>
                    <TableCell>{button.name}</TableCell>
                    <TableCell>{button.procedureSchema}</TableCell>
                    <TableCell>{button.procedureName}</TableCell>
                    <TableCell>
                        <Switch
                            checked={button.refreshOnFinish}
                            disabled
                        />
                    </TableCell>
                    <TableCell>
                        <Switch
                            checked={button.waitForResult}
                            disabled
                        />
                    </TableCell>
                    <TableCell>
                        <Button
                            variant="outlined"
                            startIcon={<Icons.Edit />}
                            data-id={button.id}
                            onClick={onEditButton}
                        >
                            <Trans>Editar</Trans>
                        </Button>
                    </TableCell>
                    <TableCell>
                        <Button
                            variant="outlined"
                            startIcon={<Icons.Delete />}
                            data-id={button.id}
                            data-name={button.name}
                            onClick={handleDeleteButtonAsync}
                            color="error"
                        >
                            <Trans>Excluir</Trans>
                        </Button>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    );
};
