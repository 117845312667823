import { useAppSelector } from 'redux/hooks'; // Adjust the import based on your Redux setup
import usePlanningGridStockValueAndColors from './usePlanningGridStockValueAndColors';
import { GlobalPlanningGridTargetType } from 'pages/planning-grid/types/PlanningGrid';

const useItemRowData = (itemIndex: number) => {
    const reduxState = useAppSelector((state) => state.filter);
    const { getStatusByStockStatusId } = usePlanningGridStockValueAndColors()
    const orderedItemId = reduxState.global.selectedItemIds[itemIndex]

    if (orderedItemId === undefined) return undefined;
    const initialStockPlanningGridStatus = getStatusByStockStatusId(reduxState.planningGrid.selectedPlanningGrid[orderedItemId][0].openingStockStatusId);
    const firstPlanningGridOpeningStock =
        reduxState.planningGrid.selectedPlanningGrid[orderedItemId][0].openingStock as number;
    const {
        minimumReorder,
        multipleReorder,
        minStock,
        targetStock,
        safetyStock,
        safetyDaysOfCover,
        targetDaysOfCover,
        minDaysOfCover,
        globalMinStockType,
        globalSafetyStockType,
        globalTargetStockType,
        openingStockStatusId,

        // description,
        // itemCode,
        // itemId,
        // tag01Id,
        // tag02Id,
        // tag03Id
    } = reduxState.planningGrid.selectedPlanningGrid[orderedItemId][0];



    const itemComplete = reduxState.global.itemsComplete.find(c => c.id === orderedItemId);
    const localTargetStockValue = globalTargetStockType === GlobalPlanningGridTargetType.DailyGlobalTargetValue ? targetDaysOfCover : targetStock;
    const localSafetyStockValue = globalSafetyStockType === GlobalPlanningGridTargetType.DailyGlobalTargetValue ? safetyDaysOfCover : safetyStock;
    const localMinStockValue = globalMinStockType === GlobalPlanningGridTargetType.DailyGlobalTargetValue ? minDaysOfCover : minStock;

    if (
        itemComplete?.code !== undefined &&
        itemComplete?.description !== undefined &&
        minimumReorder !== undefined &&
        multipleReorder !== undefined &&
        localTargetStockValue !== undefined &&
        localMinStockValue !== undefined
    ) {
        return {
            orderedItemId,
            globalMinStockType,
            globalTargetStockType,
            globalSafetyStockType,
            initialStockPlanningGridStatus,
            firstPlanningGridOpeningStock,
            minimumReorder,
            multipleReorder,
            localTargetStockValue,
            localSafetyStockValue,
            localMinStockValue,
            itemCode: itemComplete?.code,
            description: itemComplete?.description,
            itemId: itemComplete?.id,
            openingStockStatusId,
            tag01Id: itemComplete?.tag01Id,
            tag02Id: itemComplete?.tag02Id,
            tag03Id: itemComplete?.tag03Id,
            tooltip: itemComplete?.toolTip
        };
    }


    return undefined;
};

export default useItemRowData;
