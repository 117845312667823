import { Skeleton } from '@mui/material';
import React from 'react';
import { useAppSelector } from 'redux/hooks';
import { IPeriod } from 'types/scenarios/IPeriod';
import { IPlanningGridFilterOptions } from 'types/system/IPlanningGridFilterOptions';
import { IStockStatus } from 'types/system/IStockStatus';
import { PlanningGridContainerMemo } from '../components/PlanningGrid';
import { PeriodData } from '../types/PeriodData';

interface PlanningGridContainerProps {
    periods: IPeriod[];
    isWeekly: boolean;
    loading: boolean;
    planningGridRowOptions: IPlanningGridFilterOptions[];
    complexPeriods: PeriodData[] | undefined;
    stockStatus: IStockStatus[];
    onReloadItemPlanningGrid: (item: number[]) => void;
    onOccupationIconClick: () => void;
    scenario: Scenario | null
    occupationOpen?: boolean;
}
export function PlanningGridContainer({ occupationOpen, scenario, onOccupationIconClick, isWeekly, loading, periods, planningGridRowOptions, complexPeriods, stockStatus, onReloadItemPlanningGrid }: PlanningGridContainerProps) {
    const { selectedPlanningGrid, isExpandedPeriods } = useAppSelector(state => state.filter.planningGrid);

    if (loading) {
        return <>
            {[...Array(10)].map((_, rowIndex) => (
                <Skeleton key={rowIndex} variant="rectangular" width="100%" height={30} sx={{ marginTop: '1rem' }} />
            ))}
        </>; 
    }

    return (
        <PlanningGridContainerMemo
            planningGridRowOptions={planningGridRowOptions}
            isWeekly={isWeekly}
            completeStructuresByItemMestre={undefined}
            dynamicPeriodCount={isExpandedPeriods ? periods.length : complexPeriods?.length}
            periods={periods}
            complexPeriods={complexPeriods}
            stockStatus={stockStatus}
            planningGridData={selectedPlanningGrid}
            onReloadItemPlanningGrid={onReloadItemPlanningGrid}
            onOccupationIconClick={onOccupationIconClick}
            scenario={scenario}
            occupationOpen = {occupationOpen}
        />
    );
}

export const PlanningGridContainerMemoized = React.memo(PlanningGridContainer);
