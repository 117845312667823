import { t } from '@lingui/macro';
import { useCallback, useEffect, useState } from 'react';
import { SelectedAxisOptions } from 'redux/types';
import { Dictionary } from 'types/Dictionary';
import { IGenericOptions } from 'types/Generics';
import { IFilter, IInventorySummaryInfo } from '../types/IFilters';
import { IInventorySummaryData } from '../types/IInventorySummaryData';
import { aggregateDataByCompany } from '../utils/aggregateDataByCompany';
import { aggregateDataBySalesChannel } from '../utils/aggregateDataBySalesChannel';
import { aggregateDataWithAxisFilter } from '../utils/aggregateDataWithAxisFilter';
import { isNotEmptyObject } from '../utils/isNotEmptyObject';

interface IUseInventorySummary {
    stockInventory: IInventorySummaryData[];
    selectedFilters: {
        selectedCompanyFilter: IGenericOptions
        selectedSalesChannelFilter: IGenericOptions
        selectedSecondaryFilter: IGenericOptions
        selectedStockGroupFilter: IGenericOptions
        selectedAxis: SelectedAxisOptions
    }
    dataValidation: {
        companyExists: boolean;
        classificationVolumeExists: boolean;
        classificationComplexityExists: boolean;
        classificationUncertaintyExists: boolean;
        salesChannelExists: boolean;
        stockGroupExists: boolean;
    }
    summaryFilter: IFilter
}

const useInventorySummary = ({ stockInventory, selectedFilters, dataValidation, summaryFilter }: IUseInventorySummary) => {
    const { selectedCompanyFilter, selectedSalesChannelFilter, selectedSecondaryFilter, selectedStockGroupFilter, selectedAxis } = selectedFilters

    const [summaryHeaderData, setSummaryHeaderData] = useState<IInventorySummaryData[] | undefined>([] as IInventorySummaryData[])
    const [inventorySummaryInfo, setInventorySummaryInfo] = useState<IInventorySummaryInfo | undefined>({} as IInventorySummaryInfo)

    const filterByMainFilters = useCallback(() => {
        // INICIO FILTRO STOCKGROUP
        const mainStockGroup: IInventorySummaryData[] | undefined = [];
        if (!dataValidation.stockGroupExists) {
            mainStockGroup.push(...stockInventory);
        } else {
            mainStockGroup.push(...stockInventory.map((c, index) => ({ id: index + 1, ...c })).filter(c => c.stockGroupId === selectedStockGroupFilter.id));
        }
        // FIM FILTRO STOCKGROUP
        // INICIO FILTRO COMPANY
        const mainCompany: IInventorySummaryData[] | undefined = [];
        if (!dataValidation.companyExists) {
            mainCompany.push(...mainStockGroup as IInventorySummaryData[]);
        } else {
            if (selectedCompanyFilter.id === 0) {
                mainCompany.push(...(aggregateDataByCompany(mainStockGroup)?.map((c, index) => ({ id: index + 1, ...c })) as IInventorySummaryData[]));
            } else {
                mainCompany.push(...(mainStockGroup.map((c, index) => ({ id: index + 1, ...c })).filter(c => c.companyId === selectedCompanyFilter.id)));
            }
        }
        // FIM FILTRO COMPANY

        // INICIO FILTRO SALES CHANNEL
        const mainSales: IInventorySummaryData[] | undefined = [];

        if (!dataValidation.salesChannelExists) {
            mainSales.push(...mainCompany as IInventorySummaryData[]);
        } else {
            if (selectedSalesChannelFilter.id === 0) {
                mainSales.push(...(aggregateDataBySalesChannel(mainCompany)?.map((c, index) => ({ id: index + 1, ...c })) as IInventorySummaryData[]));
            } else {
                mainSales.push(...(mainCompany?.map((c, index) => ({ id: index + 1, ...c })).filter(c => c.salesChannelId === selectedSalesChannelFilter.id) as IInventorySummaryData[]));
            }
        }
        // FIM FILTRO SALES CHANNEL
        return { mainStockGroup, mainCompany, mainSales };
    }, [dataValidation.companyExists, dataValidation.salesChannelExists, dataValidation.stockGroupExists, selectedCompanyFilter.id, selectedSalesChannelFilter.id, selectedStockGroupFilter.id, stockInventory])

    const filterByAxisFilter = useCallback((filteredBySalesChannel: IInventorySummaryData[]) => {

        const mainFilteredBySalesChannel = filteredBySalesChannel
        let mainAxisFilter: IInventorySummaryData[] | undefined = []
        if (selectedSecondaryFilter.id === 0) {
            if (summaryFilter)
                mainAxisFilter = aggregateDataWithAxisFilter(mainFilteredBySalesChannel, summaryFilter, selectedCompanyFilter.id as number)
        } else {
            if (summaryFilter)

                mainAxisFilter = mainFilteredBySalesChannel?.filter((c: any) => c[summaryFilter.axisData?.filter.idKey as string] === selectedSecondaryFilter.id)
        }
        return { mainAxisFilter }
    }, [selectedCompanyFilter.id, selectedSecondaryFilter.id, summaryFilter])

    const createLines = useCallback((mainAxisFilter: IInventorySummaryData[] | undefined) => {
        const numberOfLines = summaryFilter.stockMatrix?.y as number
        const possibilities = summaryFilter.axisPossibilities
        const validPossibilities = possibilities?.y.filter(value => parseInt(value) <= numberOfLines).length

        const filteredMainData = mainAxisFilter

        if (!filteredMainData) return
        if (!validPossibilities) return
        const mainDataLines: Dictionary<IInventorySummaryData[]> = {}
        for (let i = 0; i < validPossibilities; i++) {
            mainDataLines[possibilities.y[i]] = filteredMainData.filter((c: any) => c[summaryFilter.axisData?.yAxis.idKey as string] == possibilities.y[i])
        }
        const mainDataLinesNotNull: Dictionary<IInventorySummaryData[]> = {}

        Object.keys(mainDataLines).forEach((key) => {
            const array = mainDataLines[key]
            if (array.length > 0) {
                mainDataLinesNotNull[key] = array
            }
        })
        const mainDataLinesSortedByYAxis: Dictionary<IInventorySummaryData[]> = {}

        for (const key in mainDataLinesNotNull) {
            const keyValue = Number(key) + 1
            mainDataLinesSortedByYAxis[keyValue] = mainDataLinesNotNull[key];
        }
        return { mainDataLinesSortedByYAxis }
    }, [summaryFilter.axisData?.xAxis.priorityKey, summaryFilter.axisData?.yAxis.idKey, summaryFilter.stockMatrix?.y])



    const inventorySummaryHelper = useCallback((
        mainDataLinesSortedByXAxis: Dictionary<IInventorySummaryData[] | undefined>,
    ) => {

        if (!mainDataLinesSortedByXAxis) return;

        const numberOfLines = Object.keys(mainDataLinesSortedByXAxis).length;

        const columnOptionsDictionary: any = {};
        const rowOptionsDictionary: any = {};

        for (const key in mainDataLinesSortedByXAxis) {
            const line = mainDataLinesSortedByXAxis[key];
            if (line && line.length > 0) {
                const xAxisData = (line[0] as any)[summaryFilter.axisData?.yAxis.classificationName as string];
                const xAxisId = (line[0] as any)[summaryFilter.axisData?.yAxis.idKey as string];

                columnOptionsDictionary[key] = { xAxisData, xAxisId };

                for (const key2 in line) {
                    const yAxisData = (line[key2] as any)[summaryFilter.axisData?.xAxis.classificationName as string];
                    const yAxisId = (line[key2] as any)[summaryFilter.axisData?.xAxis.idKey as string];
                    rowOptionsDictionary[key2] = { yAxisData, yAxisId };
                }
            }
        }
        const mainDataLinesArray: IInventorySummaryData[][] = [];
        for (const key in mainDataLinesSortedByXAxis) {
            const dataLinesForKey = mainDataLinesSortedByXAxis[key];
            if (dataLinesForKey !== undefined) {
                mainDataLinesArray.push(dataLinesForKey);
            }
        }

        const rowOptions: any[] = Object.values(columnOptionsDictionary).map((option: any) => option.xAxisData);
        const columnOptions: any[] = Object.values(rowOptionsDictionary)
            .filter((option: any) => option.yAxisData !== null)
            .map((option: any) => option.yAxisData)

        if (rowOptions.length === 0) {
            rowOptions.push(t`Geral`);
        }
        const result: IInventorySummaryInfo = {
            columnOptions: columnOptions,
            rowOptions: rowOptions,
            numberOfLines: numberOfLines,
            dataLinesArray: mainDataLinesArray.filter(c => c !== undefined).map(array => array.slice().sort(customSort)),
        }
        return result
    }, [])

    function customSort(a: any, b: any) {
        // Calculate total value for a and b
        const totalValueA = a.cycleStockValue + a.safetyStockValue;
        const totalValueB = b.cycleStockValue + b.safetyStockValue;

        // If total values are not zero, compare them
        if (totalValueA !== 0 || totalValueB !== 0) {
            return totalValueB - totalValueA; // Sort in descending order
        }

        // If total values are zero, compare itensQuantity
        if (a.itensQuantity !== 0 || b.itensQuantity !== 0) {
            return b.itensQuantity - a.itensQuantity; // Sort in descending order
        }

        // If itensQuantity are zero, compare IDs
        return a.itemStockGroupId - b.itemStockGroupId; // Sort in ascending order
    }


    useEffect(() => {
        if (!isNotEmptyObject(summaryFilter)) return
        const { mainSales } = filterByMainFilters();

        // setSummaryHeaderData(mainSales)

        const { mainAxisFilter } = filterByAxisFilter(mainSales)
        setSummaryHeaderData(mainAxisFilter)


        const lines = createLines(mainAxisFilter)
        let summaryHelper: IInventorySummaryInfo | undefined = {} as IInventorySummaryInfo

        if (lines)
            summaryHelper = inventorySummaryHelper(lines.mainDataLinesSortedByYAxis);

        setInventorySummaryInfo(summaryHelper)
    }, [createLines, selectedAxis, filterByAxisFilter, filterByMainFilters, inventorySummaryHelper, summaryFilter])
    return { inventorySummaryInfo, summaryHeaderData, summaryFilter }
};

export default useInventorySummary;
