import { useCallback, useEffect, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { 
    getAsync,
    postAsync,
    putAsync,
    toLocalISOString 
} from '@nplan';

interface IUseScenarioDataReturn {
    scenario: Scenario
    scenarioCriteriaValues: ScenarioCriteriaValue[] | undefined;
    scenarioParameterValues: ScenarioParameterValue[] | undefined;
    executionDateDialogOpen: boolean;
    saving: boolean;
    loading: boolean;
    getCriteria: (criteriaId: number) => ScenarioCriteria | undefined;
    getParameter: (parameterId: number) => ScenarioParameter | undefined;
    handleChangeInputScenario: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangeSelectScenario: (event: SelectChangeEvent<number>) => void;
    handleCriteriaSliderChange: (criteriaId: number, value: number) => void;
    handleParameterValueChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    handleCancelChangeExecutionDate: () => void;
    handleOpenExecutionDateDialog: () => void;
    handleSave: () => void;
}

const defaultScenario: Scenario = {
    id: 0,
    name: '',
    scenarioStatusId: 0,
    scenarioCategoryId: 1,
    periodTypeId: 1,
    startDate: new Date().toISOString().slice(0, 10),
    endDate: new Date(new Date().getFullYear() + 1, 0, 0),
    executionDate: toLocalISOString(new Date()),
    frozenEndDate: new Date().toISOString().slice(0, 10),
    isSummarizedDataUpToDate: false,
};

export const useScenarioData = (
    scenarioId: number, 
    open: boolean,
    onClose: () => void,
): IUseScenarioDataReturn => {

    const [saving, setSaving] = useState<boolean>(false);
    const [scenario, setScenario] = useState<Scenario>(defaultScenario);
    const [scenarioCriteria, setScenarioCriteria] = useState<ScenarioCriteria[]>();
    const [scenarioCriteriaValues, setScenarioCriteriaValues] = useState<ScenarioCriteriaValue[]>();
    const [scenarioParameterValues, setScenarioParameterValues] = useState<ScenarioParameterValue[]>();
    const [scenarioParameters, setScenarioParameters] = useState<ScenarioParameter[]>();
    const [executionDateDialogOpen, setExecutionDateDialogOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const isValidDate = (d: Date): boolean => {
        return d instanceof Date && !isNaN(d.getTime());
    };

    const getCriteria = useCallback((criteriaId: number) => {
        return scenarioCriteria?.find((criteria) => criteria.id === criteriaId);
    }, [scenarioCriteria]);

    const getParameter = useCallback((parameterId: number) => {
        return scenarioParameters?.find((parameter) => parameter.id === parameterId);
    }, [scenarioParameters]);

    const handleChangeInputScenario = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type } = event.target;
        if (type === 'date' && !isValidDate(new Date(value))) return;
        setScenario((prev) => {
            if (!prev) {
                // Lida com o cenário onde 'prev' é undefined
                return prev;
            }
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const handleChangeSelectScenario = (event: SelectChangeEvent<number>) => {
        const name = event.target.name;
        const value = event.target.value as number;
        setScenario((prev) => {
            if (!prev) {
                return prev;
            }
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const handleCriteriaSliderChange = (criteriaId: number, value: number) => {
        setScenarioCriteriaValues((prev) => {
            if (!prev) {
                return prev;
            }
            const criteriaValue = prev.find((criteriaValue) => criteriaValue.scenarioCriteriaId === criteriaId);
            if (criteriaValue) {
                criteriaValue.value = value;
            }
            return [...prev];
        });
    };

    const handleParameterValueChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setScenarioParameterValues((prev) => {
            if (!prev) {
                return prev;
            }
            const parameterValue = prev.find((parameterValue) => parameterValue.scenarioParameterId === Number(event.target.name));
            if (parameterValue) {
                parameterValue.value = checked;
            }
            return [...prev];
        });
    };

    const handleCancelChangeExecutionDate = () => {
        if (scenarioId === 0) {
            setScenario((prev) => {
                if (!prev) {
                    return prev;
                }
                return {
                    ...prev,
                    executionDate: new Date(),
                };
            });
        }
        setExecutionDateDialogOpen(false);
    };

    const handleOpenExecutionDateDialog = () => {
        setExecutionDateDialogOpen(true);
    };

    const handleSave = useCallback(async () => {
        setExecutionDateDialogOpen(false);
        setSaving(true);
        if (scenarioId === 0) {
            const scenarioToPut: any = {
                ...scenario,
                scenarioCriteriaValues,
                scenarioParameterValues,
            };
            const response = await postAsync<string>('/scenarios', scenarioToPut);
            if (response.type === 'success') {
                onClose();
            }
        } else {
            const [resScenario, resCriteria, resParameter] = await Promise.all([
                await putAsync<Scenario, string>('/scenarios', scenario),
                await putAsync<ScenarioCriteriaValue[]>('/scenario-criteria-values', scenarioCriteriaValues),
                await putAsync<ScenarioParameterValue[]>('/scenario-parameter-values', scenarioParameterValues)
            ]);
            if (resScenario.type === 'success' && resCriteria.type === 'success' && resParameter.type === 'success') {
                onClose();
            }
        }
        setSaving(false);
    }, [onClose, scenario, scenarioCriteriaValues, scenarioId, scenarioParameterValues]);

    useEffect(() => {
        (async () => {
            const [resCriteria, resParameter] = await Promise.all([
                getAsync<ScenarioCriteria[]>('scenario-criteria'),
                getAsync<ScenarioParameter[]>('scenario-parameters')
            ]);
            setScenarioCriteria(resCriteria.data);
            setScenarioParameters(resParameter.data);
        })();
    }, []);
    
    useEffect(() => {
        if(!open) return;
        setScenario(defaultScenario);
        if(scenarioId === 0) return;
        setLoading(true);
        (async () => {
            const [resScenario, resCriteria, resParamater] = await Promise.all([
                getAsync<Scenario>(`/scenarios/${scenarioId}`),
                getAsync<ScenarioCriteriaValue[]>(`scenario-criteria-values/scenario/${scenarioId}`),
                getAsync<ScenarioParameterValue[]>(`scenario-parameter-values/scenario/${scenarioId}`),
            ]);
            if (resScenario.type === 'success' && resScenario.data) {
                setScenario(resScenario.data);
            }
            setScenarioCriteriaValues(resCriteria.data);
            setScenarioParameterValues(resParamater.data);
            setLoading(false);
        })();
    }, [scenarioId, open]);

    useEffect(() => {
        if(!scenarioCriteria || !open) return;
        const scenarioCriteriaValues: ScenarioCriteriaValue[] = scenarioCriteria.map((criteria) => {
            return {
                id: 0,
                scenarioId: 0,
                scenarioCriteriaId: criteria.id,
                value: criteria.defaultValue
            };
        });
        setScenarioCriteriaValues(scenarioCriteriaValues);
    }, [scenarioCriteria, scenarioId, open]);

    useEffect(() => {
        if(!scenarioParameters || !open) return;
        const scenarioParameterValues: ScenarioParameterValue[] = scenarioParameters.map((parameter) => {
            return {
                id: 0,
                scenarioId: 0,
                scenarioParameterId: parameter.id,
                value: parameter.defaultValue
            };
        });
        setScenarioParameterValues(scenarioParameterValues);
    }, [scenarioParameters, scenarioId, open]);

    return {
        scenario,
        scenarioCriteriaValues,
        scenarioParameterValues,
        executionDateDialogOpen,
        saving,
        loading,
        getCriteria,
        getParameter,
        handleChangeInputScenario,
        handleChangeSelectScenario,
        handleCriteriaSliderChange,
        handleParameterValueChange,
        handleCancelChangeExecutionDate,
        handleSave,
        handleOpenExecutionDateDialog,
        
    };
};