import { useHubContext, getAsync } from '@nplan';
import { useCallback, useEffect, useState } from 'react';

interface IUseReportDataReturn {
    reports: NReport[];
    reportTypes: ReportType[];
    handleRefreshReportsAsync: () => Promise<void>;
}

export const useReportdata = (): IUseReportDataReturn => {

    const [reports, setReports] = useState<NReport[]>([]);
    const [reportTypes, setReportTypes] = useState<ReportType[]>([]);

    const { hubConnection } = useHubContext();

    const handleRefreshReportsAsync = useCallback(async () => {
        const [resReports, resReportTypes] = await Promise.all([
            await getAsync<NReport[]>('reports'),
            await getAsync<ReportType[]>('report-types'),
        ]);
        if (resReports.type === 'success' && resReports.data) {
            setReports(resReports.data);
        }
        if (resReportTypes.type === 'success' && resReportTypes.data) {
            setReportTypes(resReportTypes.data);
        }
    },[]);
    useEffect(() => {
        handleRefreshReportsAsync();
    }, [handleRefreshReportsAsync]);


    useEffect(() => {
        if(hubConnection){
            hubConnection.on('RefreshReports', handleRefreshReportsAsync);
            return () => hubConnection.off('RefreshReports', handleRefreshReportsAsync);
        }
    },[hubConnection, handleRefreshReportsAsync]);

    return {
        reports,
        reportTypes,
        handleRefreshReportsAsync,
    };
};
