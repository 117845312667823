import { Trans } from "@lingui/macro";
import { Typography } from "@mui/material";

export const NoData = () => {
    return (
        <Typography textAlign={'center'}>
            <Trans>
                Não há dados para exibir
            </Trans>
        </Typography>
    );
}