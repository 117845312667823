import { useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getAsync, useHubContext } from '@nplan';


export const useButtonData = () => {
    const { id } = useParams();
    const { hubConnection } = useHubContext();
    const [buttons, setButtons] = useState<Button[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const handleRefreshDataAsync = useCallback(async (): Promise<void> => {
        const res = await getAsync<Button[]>(`buttons/table/${id}`);
        if (res.type === 'success' && res.data) {
            setButtons(res.data);
        }
    }, [id]);
    useEffect(() => {
        handleRefreshDataAsync().then(() => setLoading(false));
    }, [handleRefreshDataAsync]);

    useEffect(() => {
        if(hubConnection){
            hubConnection.on(`RefreshButtons${id}`, handleRefreshDataAsync);
            return () => hubConnection.off(`RefreshButtons${id}`, handleRefreshDataAsync);
        }
    }, [handleRefreshDataAsync, hubConnection, id]);
    return {
        buttons,
        loading,
        handleRefreshDataAsync,
    };
};