
export const measureText = (text: string, font: string): number => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) return 0;
    if (font) {
        context.font = font;
    }
    return parseInt(context.measureText(text).width.toString());
};

