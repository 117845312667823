import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { TableHead, TableRow, TableCell, TableSortLabel, Box, useTheme } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

export interface HeadCell {
    disablePadding: boolean;
    id: keyof Scenario | 'edit' | `delete` | 'lock' | `unlock` | 'clone';
    label: string;
    alignLeft: boolean;
}
export type Order = 'asc' | 'desc';

const headCells: readonly HeadCell[] = [
    {
        id: 'name',
        alignLeft: true,
        disablePadding: true,
        label: t`Nome`,
    },
    {
        id: 'scenarioStatusId',
        alignLeft: true,
        disablePadding: false,
        label: t`Status`,
    },
    {
        id: 'createdBy',
        alignLeft: true,
        disablePadding: false,
        label: t`Criado Por`,
    },
    {
        id: 'createdOn',
        alignLeft: true,
        disablePadding: false,
        label: t`Data de Criação`,
    },
    {
        id: 'executionDate',
        alignLeft: true,
        disablePadding: false,
        label: t`Data de Execução`,
    },
    {
        id: 'lastUpdatedOn',
        alignLeft: true,
        disablePadding: false,
        label: t`Data de Modificação`,
    },
    {
        id: 'startDate',
        alignLeft: true,
        disablePadding: false,
        label: t`Data Inicial`,
    },
    {
        id: 'endDate',
        alignLeft: true,
        disablePadding: false,
        label: t`Data Final`,
    },
    {
        id: 'frozenEndDate',
        alignLeft: true,
        disablePadding: false,
        label: t`Data Congelado Final`,
    },
    {
        id: 'edit',
        alignLeft: false,
        disablePadding: false,
        label: '',
    },
    {
        id: 'delete',
        alignLeft: false,
        disablePadding: false,
        label: '',
    },
    {
        id: 'unlock',
        alignLeft: false,
        disablePadding: false,
        label: '',
    },
    {
        id: 'clone',
        alignLeft: false,
        disablePadding: false,
        label: '',
    },
];
export interface IHeaderProps {
    order: Order;
    orderBy: string;
    onOrderChange: () => void;
    onOrderByChange: (property: keyof Scenario) => void;
}

export const Header: React.FC<IHeaderProps> = ({ order, orderBy, onOrderByChange, onOrderChange }: IHeaderProps) => {
    const handleClickSort = (event: React.MouseEvent<unknown>) => { // Alterado o tipo de evento
        const property = (event.currentTarget as HTMLElement).dataset.id; // Lendo o data-id do evento
        if (!property) return;
        const key = property as keyof Scenario;
        if (key) {
            if (key === orderBy) { onOrderChange(); } else { onOrderByChange(key); }
        }
    };
    const theme = useTheme();
    const { i18n } = useLingui();
    return (
        <TableHead>
            <TableRow sx={{ position: 'sticky', top: 0, zIndex: 10, bgcolor: theme.tableHeader.background }}>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.alignLeft ? 'left' : 'center'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{
                            minWidth: 90
                        }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            hideSortIcon={true}
                            onClick={handleClickSort}
                            data-id={headCell.id}
                        >
                            {i18n.t(headCell.label)}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};