import { useCallback, useEffect, useState } from 'react';
import { IInventorySummaryData } from 'pages/inventory-policy/types/IInventorySummaryData';
import { validateAndReturnDivisionNumber } from '../utils/validateAndReturnDivisionNumber';
import { validateAndReturnAbreviation } from '../utils/validateAndReturnAbreviation';
import { useAppSelector } from 'redux/hooks';

interface StatisticsProps {
    totalItensQuantity: number;
    totalSafetyStockValue: string;
    averageSafetyStockDays: string;
    totalCycleStockValue: string;
    averageCycleStockDays: string;
    averageServiceLevelPerc: string;
    totalStockValue: string;
    totalStockDays: string;
}

const useInventorySummaryStatistics = (summaryHeaderData: IInventorySummaryData[] | undefined) => {
    const [statistics, setStatistics] = useState<StatisticsProps>({} as StatisticsProps);

    const calculateStatistics = useCallback(
        (arr: IInventorySummaryData[] | undefined) => {
            if (!arr) return;

            let totalItemsQuantity = 0;
            let totalSafetyStockValue = 0;
            let totalSafetyStockDaysWeighted = 0;
            let totalCycleStockValue = 0;
            let totalCycleStockDaysWeighted = 0;
            let totalServiceLevelPercWeighted = 0;
            let totalWeight = 0;
            for (let i = 0; i < arr.length; i++) {
                const cycleAndSafetyStockValue = arr[i].cycleStockValue + arr[i].safetyStockValue;
                const weight = cycleAndSafetyStockValue; // Use sum as weight
                totalItemsQuantity += isNaN(arr[i].itensQuantity) ? 0 : Number(arr[i].itensQuantity);
                totalSafetyStockValue += isNaN(arr[i].safetyStockValue) ? 0 : Number(arr[i].safetyStockValue);
                totalSafetyStockDaysWeighted += isNaN(arr[i].safetyStockDays) ? 0 : arr[i].safetyStockDays * weight;
                totalCycleStockValue += isNaN(arr[i].cycleStockValue) ? 0 : Number(arr[i].cycleStockValue);
                totalCycleStockDaysWeighted += isNaN(arr[i].cycleStockDays) ? 0 : arr[i].cycleStockDays * weight;
                totalServiceLevelPercWeighted += isNaN(arr[i].serviceLevelPerc) ? 0 : arr[i].serviceLevelPerc * weight;
                totalWeight += weight;
            }
            const averageSafetyStockDays = isNaN(totalSafetyStockDaysWeighted / totalWeight) ? 0 : (totalSafetyStockDaysWeighted / totalWeight);
            const averageCycleStockDays = isNaN(totalCycleStockDaysWeighted / totalWeight) ? 0 : (totalCycleStockDaysWeighted / totalWeight);
            const averageServiceLevelPerc = isNaN(totalServiceLevelPercWeighted / totalWeight) ? 0 : (totalServiceLevelPercWeighted / totalWeight);

            setStatistics({
                totalItensQuantity: totalItemsQuantity,
                totalSafetyStockValue: (totalSafetyStockValue / validateAndReturnDivisionNumber(totalSafetyStockValue)).toFixed(1) + validateAndReturnAbreviation(totalSafetyStockValue),
                averageSafetyStockDays: averageSafetyStockDays.toFixed(1),
                totalCycleStockValue: (totalCycleStockValue / validateAndReturnDivisionNumber(totalCycleStockValue)).toFixed(1) + validateAndReturnAbreviation(totalCycleStockValue),
                averageCycleStockDays: averageCycleStockDays.toFixed(1),
                averageServiceLevelPerc: averageServiceLevelPerc.toFixed(1),
                totalStockValue: ((totalCycleStockValue + totalSafetyStockValue) / validateAndReturnDivisionNumber(totalCycleStockValue + totalSafetyStockValue)).toFixed(1) + validateAndReturnAbreviation(totalCycleStockValue + totalSafetyStockValue),
                totalStockDays: (averageCycleStockDays + averageSafetyStockDays).toFixed(1),
            });
        },
        []
    );

    useEffect(() => {
        if (summaryHeaderData)
            calculateStatistics(summaryHeaderData);
    }, [calculateStatistics, summaryHeaderData]);

    return statistics;
};

export default useInventorySummaryStatistics;
