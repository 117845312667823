import { t } from '@lingui/macro';
import { useTheme } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useAppSelector } from 'redux/hooks';
import { IItem } from 'types/data/IItem';
import {
    ICapacity,
    IPeriod,
    IPlanningGridOperationResource,
} from 'types/scenario';
import colors from '../data/colors.json';
import { tooltipFormatter } from '../utils/';

export const useChartOptions = (
    periods: IPeriod[],
    capacitiesDefault: ICapacity[],
    planningGridOperationResourcesDefault: IPlanningGridOperationResource[],
    setupGrouped: boolean,
    resourceId: number,
    items: IItem[]
) => {
    const selectedOccupationProperty = useAppSelector(state => state.filter.planningGrid.selectedOccupationProperty);
    const itemProperties = useAppSelector(state => state.filter.global.itemProperties);


    const planningGridOperationResources = useMemo(() => {

        return planningGridOperationResourcesDefault.filter(
            (f) => f.resourceId === resourceId,
        );
    }, [planningGridOperationResourcesDefault, resourceId]);

    const capacities = useMemo(() => {
        return capacitiesDefault.filter((f) => f.resourceId === resourceId);
    }, [capacitiesDefault, resourceId]);

    const theme = useTheme();

    useEffect(() => {
        while (colors.length < items.length) {
            colors.push(colors[colors.length - 1]);
        }
    }, [items]);
    const toolTip = useMemo(
        () => ({
            tooltip: {
                formatter: tooltipFormatter,
                position: 'inside'
            },
        }),
        [],
    );
    const grid = useMemo(
        () => ({
            grid: {
                left: '5%',
                right: '5%',
                bottom: '20%',
                top: '5%',
                containLabel: false,
            },
        }),
        [],
    );
    const backgroundColor = useMemo(
        () => ({
            backgroundColor: theme.palette.background.default,
        }),
        [theme.palette.background.default],
    );
    const xAxis = useMemo(
        () => ({
            xAxis: {
                type: 'category',
                data: periods.map((p) => {
                    const date = new Date(p.startTime);
                    const formattedDate = date.toLocaleDateString(
                        navigator.language,
                        { month: 'short', day: '2-digit' },
                    );
                    const dayNumber = parseInt(formattedDate.slice(0, 2));
                    const month = formattedDate.slice(6, formattedDate.length - 1).toUpperCase();
                    return `${month} ${dayNumber}`;
                }),
                axisLabel: {
                    interval: 0,
                    color: theme.palette.text.primary
                },
            },
        }),
        [periods]
    );
    const yAxis = useMemo(
        () => ({
            yAxis: {
                type: 'value',
                axisLabel: {
                    color: theme.palette.text.primary
                },
            },
        }),
        [],
    );

    const setupGroupedSerie = useMemo(() => {
        if (!setupGrouped) return [];
        return [{
            name: 'Setup Total',
            type: 'bar',
            stack: 'total',
            emphasis: {
                focus: 'series',
            },
            barWidth: '80%',
            data: [
                ...periods.map((p) => {
                    const m = planningGridOperationResources
                        .filter((f) => f.periodId === p.id)
                        .reduce(
                            (acc, curr) => {
                                acc.setupTime += curr.setupTime;
                                return acc;
                            },
                            { setupTime: 0 },
                        );
                    if (m) {
                        return {
                            id: 0,
                            value: setupGrouped ? m.setupTime.toFixed(2) : 0,
                            itemStyle: {
                                color: '#000',
                            },
                            code: 'Setup Total',
                            description: 'Setup Total',
                            setup: m.setupTime,
                            periodStart: p.startTime,
                        };
                    }
                    return 0;
                }),
            ],
        }];
    }, [periods, planningGridOperationResources, setupGrouped]);
    const itemSeries = useMemo(
        () => ([
            ...items.filter(f => planningGridOperationResources.findIndex(fi => fi.itemId === f.id) > -1).flatMap((item, index) => {
                // Constrói a série de setup apenas se setupGrouped for false
                const setupSeries = setupGrouped
                    ? null
                    :
                    {
                        name: item.code,
                        type: 'bar',
                        stack: 'total',
                        emphasis: {
                            focus: 'series',
                        },
                        data: periods.map((p) => {
                            const m = planningGridOperationResources.find(
                                (f) =>
                                    f.itemId === item.id &&
                                    f.periodId === p.id,
                            );
                            return m
                                ? {
                                    id: item.id,
                                    value: m.setupTime.toFixed(2),
                                    itemStyle: {
                                        color: '#000',
                                    },
                                    code: item.code,
                                    type: 'item',
                                    description: item.description,
                                    setup: m.setupTime,
                                    process: m.processTime,
                                    total: m.setupTime + m.processTime,
                                    periodStart: p.startTime,
                                    mps: m.mpsQuantity,
                                    operationNo: m.operationNo,
                                }
                                : 0;
                        }),
                    };
                const processSeries = {
                    name: item.code,
                    type: 'bar',
                    stack: 'total',
                    large: true,
                    sampling: 'average',
                    emphasis: {
                        focus: 'series',
                    },
                    barWidth: '80%',
                    data: periods.map((p) => {
                        // Agrupando e somando os tempos de processo por período e tipo de produção
                        const aggregatedData = planningGridOperationResources
                            .filter((f) => f.itemId === item.id && f.periodId === p.id)
                            .reduce((acc, curr) => {
                                if (!acc[curr.productionTypeId]) {
                                    acc[curr.productionTypeId] = {
                                        processTime: 0,
                                        setupTime: 0,
                                        mpsQuantity: 0,
                                        name: curr.productionType!.name 
                                    };
                                }
                                acc[curr.productionTypeId].processTime += curr.processTime;
                                acc[curr.productionTypeId].setupTime += curr.setupTime;
                                acc[curr.productionTypeId].mpsQuantity += curr.mpsQuantity;
                                return acc;
                            }, {});
                        // Calculando a diferença de tempo de processo entre os tipos de produção
                        const processTime1 = aggregatedData[1] ? aggregatedData[1].processTime : 0;
                        const processTime2 = aggregatedData[2] ? aggregatedData[2].processTime : 0;
                        const processTimeDiff = processTime1 - processTime2;
    
                        // Valores de MPS por tipo de produção
                        const mpsQuantity1 = aggregatedData[1] ? aggregatedData[1].mpsQuantity : 0;
                        const mpsQuantity2 = aggregatedData[2] ? aggregatedData[2].mpsQuantity : 0;

                        // Valores de setupTime por tipo de produção
                        const setupTime1 = aggregatedData[1] ? aggregatedData[1].setupTime : 0;
                        const setupTime2 = aggregatedData[2] ? aggregatedData[2].setupTime : 0;
                            


                        // Determinando os tipos de produção presentes
                        const productionTypes = Object.keys(aggregatedData).map(Number);
                        let productionTypeLabel;
                        if (productionTypes.length === 1) {
                            productionTypeLabel = aggregatedData[productionTypes[0]].name;
                        } else {
                            const typeNames = productionTypes.map(ptId => aggregatedData[ptId].name);
                            productionTypeLabel = typeNames.join(' & ');
                        }
    
                        // Determine if to show decal based on production type
                        const showDecal = productionTypes.includes(2) || productionTypes.length > 1;
    
                        // Retornando a estrutura de dados agregada com os valores calculados
                        return {
                            id: item.id,
                            value: processTime1 + processTime2, // Soma total dos tempos de processo
                            itemStyle: {
                                color: colors[selectedOccupationProperty === 'id' ? index % colors.length : (item as any)[selectedOccupationProperty as any]!],
                                ...(showDecal && {
                                    decal: {
                                        symbol: 'rect',
                                        symbolSize: 2,
                                        color: 'rgba(0, 0, 0, 0.4)',
                                        dashArrayX: 20,
                                        dashArrayY: 2,
                                        rotation: 90,
                                    }
                                })
                            },
                            code: item.code,
                            type: 'item',
                            description: item.description,
                            processTimeDescription: `${processTime1.toFixed(2)},${processTime2.toFixed(2)}`,
                            setup: aggregatedData[1]?.setupTime + aggregatedData[2]?.setupTime || 0,
                            process: processTime1 + processTime2,
                            total: (aggregatedData[1]?.setupTime || null) + (aggregatedData[2]?.setupTime || null) + processTime1 + processTime2,
                            periodStart: p.startTime,
                            mps: mpsQuantity1 + mpsQuantity2, // Soma total dos valores MPS
                            details: `Difference in process time: ${processTimeDiff.toFixed(2)}`,
                            operationType: 'Internal', // Presumindo que todos são internos aqui; ajuste conforme necessário
                            operationNo: 10, // Ajuste conforme necessário
                            property: getPropertyName(item, selectedOccupationProperty),
                            productionTypeId: 'Aggregated', // Indicamos que os dados foram agregados
                            productionType: productionTypeLabel, // Nova propriedade que indica o tipo de produção
                            mpsDetails: mpsQuantity2 >= 0 ? `${mpsQuantity1.toFixed(0)},${mpsQuantity2.toFixed(0)}` : `${mpsQuantity1.toFixed(0)}`, // Detalhes MPS para a tooltip
                            setupDetails: setupTime2 >= 0 ? `${setupTime1.toFixed(2)},${setupTime2.toFixed(2)}` : `${setupTime1.toFixed(2)}`, // Detalhes setupTime para a tooltip
                        };
                    }),
                };


                if (setupGrouped) { return [processSeries]; } else { return [setupSeries, processSeries]; }
            }),
        ]),
        [periods, items, planningGridOperationResources, setupGrouped, selectedOccupationProperty],
    );
    function getPropertyName(item: IItem, selectedOccupationProperty: string): string | undefined {
        if (selectedOccupationProperty === 'id') return String(item.id);
        const property = itemProperties[selectedOccupationProperty][(item as any)[selectedOccupationProperty]];
        if (!property) return t`Código do Item`;
        return property.name;
    }
    const capacitySeries = useMemo(
        () => ({
            name: 'Capacidade',
            type: 'line',
            lineStyle: {
                color: 'red',
                width: 2,
            },
            symbolSize: 15,
            symbol: 'diamond',
            data: periods.map((p) => {
                const m = capacities.find((f) => f.periodId === p.id);
                if (m) {
                    return {
                        id: m.id,
                        value: m.totalAvailableHours,
                        name: m.calendarTemplate.name,
                        efficiency: m.efficiency,
                        totalUnavailableHours: m.totalUnavailableHours,
                        itemStyle: {
                            color: 'red',
                        },  
                        periodStart: p.startTime,
                        periodId: m.periodId,
                        resourceId: m.resourceId

                    };
                } else {
                    return 0;
                }
            }),
            yAxisIndex: 0,
        }),
        [capacities, periods],
    );
    const option = useMemo(
        () => ({
            ...toolTip,
            ...backgroundColor,
            ...xAxis,
            ...yAxis,
            ...grid,
            series: [
                ...itemSeries,
                ...setupGroupedSerie,
                ...[capacitySeries],
            ],
            //...dataZoom,
        }),
        [
            backgroundColor,
            capacitySeries,
            //dataZoom,
            grid,
            itemSeries,
            setupGroupedSerie,
            toolTip,
            xAxis,
            yAxis,
        ],
    );
    return option;
};
