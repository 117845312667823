import { Trans } from '@lingui/macro';
import {
    Box,
    FormControl,
    MenuItem,
    Select,
    Typography,
    useTheme,
} from '@mui/material';
import { useDataContext } from '@nplan';
import React from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import { TiArrowUnsorted } from 'react-icons/ti';

const ScenarioDropDownSelector: React.FC = () => {
    const { scenarios, onChangeScenario, scenario } = useDataContext();
    const filteredScenarios = scenarios.filter(c => c.scenarioStatusId === 4 || c.scenarioStatusId === 5);
    const theme = useTheme();


    if (!scenario || scenarios.length === 0) {
        return <Box sx={{ display: 'flex', justifyContent: 'center', border: '1px solid', borderColor: 'divider', padding: '0.5rem', borderRadius: '0.5rem' }}>
            <Typography><Trans>Nenhum cenário</Trans></Typography>
        </Box>; 
    }
    return (
        <FormControl sx={{ width: 'fit-content' }}>
            <Select
                labelId="company-select-label"
                id="company-select"
                size="small"
                variant="outlined"

                inputProps={{
                    sx: {
                        paddingTop: '0.3rem',
                        paddingBottom: '0.1rem',
                        backgroundColor: theme.palette.background.default
                    }
                }}
                value={scenario === null ? scenarios[0].id : scenario.id}
                onChange={(e) => {
                    const selectedOption = scenarios.find((filter) => filter.id === e.target.value);
                    if (selectedOption) onChangeScenario(selectedOption.id);
                }}
                MenuProps={{ anchorOrigin: { vertical: 'bottom', horizontal: 'right' } }}
                IconComponent={TiArrowUnsorted}
            >
                {filteredScenarios.map((scenariomap: any) => (
                    <MenuItem key={scenariomap.id} value={scenariomap.id}>
                        <Box display="flex" alignItems="center" gap={'0.5rem'}>
                            <Typography variant="caption">{scenariomap.name}</Typography>
                            {scenario === null ? scenarios[0].id : scenario.id === scenariomap.id && (
                                <AiOutlineCheck fontSize="small" />
                            )}
                        </Box>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default ScenarioDropDownSelector;
