import { Skeleton } from '@mui/material';
import { TableBody, TableRow, TableCell } from '@mui/material';

interface ITableBodySkeletonProps {
    rows: number;
    cols: number;
    height?: number;
}
export const TableBodySkeleton: React.FC<ITableBodySkeletonProps> = ({
    rows,
    cols,
    height
}: ITableBodySkeletonProps) => {
    return (
        <TableBody>
            {[...Array(rows)].map((_, rowIndex) => (
                <TableRow key={rowIndex}>
                    {[...Array(cols)].map((_, colIndex) => (
                        <TableCell key={`${rowIndex} - ${colIndex}`}>
                            <Skeleton
                                variant="rectangular"
                                width="100%"
                                height={height ?? 38}
                            />
                        </TableCell>
                    ))}
                </TableRow>
            ))}
        </TableBody>
    );
};
