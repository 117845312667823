import { useAppDispatch, useAppSelector } from "redux/hooks";
import React, { useMemo } from "react";
import { FilterCriteria } from "pages/planning-grid/types/PlanningGrid";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { resetSpecificFilter } from "redux/Filters/reducer";
import { Icons } from "utils/icons";
import { Trans } from "@lingui/macro";

// Separate component for the Box below DropDownTreeSelectorContainer

interface InfoBoxProps {
    filterCriteria: FilterCriteria
}
export const AttributesInfoBox = React.memo(({ filterCriteria }: InfoBoxProps) => {
    const theme = useTheme();
    const dispatch = useAppDispatch()
    const searchCriteriasData = useAppSelector(state => state.filter.planningGrid.filterData.searchCriteriasData)
    const searchCriteriasDataMemo = useMemo(() => { return searchCriteriasData }, [searchCriteriasData])

    if (searchCriteriasDataMemo[filterCriteria] === undefined || searchCriteriasDataMemo[filterCriteria].length === 0)
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    border: '1px solid',
                    borderColor: theme.palette.primary.main,
                    backgroundColor: theme.palette.background.default,
                    padding: '0.5rem',
                    marginBottom: '0.5rem',
                    borderRadius: '10px',
                    maxHeight: '100px',
                    overflowY: 'auto',
                    minWidth: '150px'

                }}
            >
                <Typography><Trans>Nenhum filtro selecionado</Trans></Typography>
            </Box>)
    return (
        <Box sx={{ display: 'flex' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '0.5rem', ':hover': { cursor: 'pointer', filter: 'brightness(0.8)' } }} onClick={() => dispatch(resetSpecificFilter({ criteriaKey: filterCriteria }))}>
                <Icons.DeleteFilled size={20} color={theme.palette.primary.main} />
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    border: '1px solid',
                    borderColor: theme.palette.primary.main,
                    backgroundColor: theme.palette.background.default,
                    padding: '0.5rem',
                    marginBottom: '0.5rem',
                    borderRadius: '10px',
                    maxHeight: '100px',
                    overflowY: 'auto',
                    minWidth: '150px'
                }}
            >

                {searchCriteriasDataMemo[filterCriteria].map((criteria: any) => {
                    return (
                        <Typography>
                            {criteria.label}
                        </Typography>
                    )
                })}
            </Box>
        </Box>
    );
});
