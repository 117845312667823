import {
    Home,
    Login,
    Role,
    TableEdition,
    TableEditionButton,
    User,
    ScenarioContainer,
    TableEditionColumn,
    InventoryPolicyContainer,
    Report,
    ReportView,
    UserProvider,
    Layouts,
    TableEditionData,
    PlanningGridContainer,
    InfoPedidoPage,
    NewOrderPage,
    OrdersPage,
} from '@nplan';
import { Routes, Route } from 'react-router-dom';
import React from 'react';

export const AppRoutes: React.FC = (): JSX.Element => {
    return (
        <Routes>
            <Route
                path='*'
                element={
                    <UserProvider>
                        <Routes>
                            <Route path="/login" Component={Login} />
                            <Route
                                element={<Layouts />}
                                path='/'
                            >
                                <Route path='/' Component={Home} />
                                <Route path='/home' Component={Home} />
                                <Route path="/tables" Component={TableEdition} />
                                <Route path="/tables/:id/buttons" Component={TableEditionButton} />
                                <Route path="/tables/:id/columns" Component={TableEditionColumn} />
                                <Route path="/tables/:id/data" Component={TableEditionData} />
                                <Route path="/roles" Component={Role} />
                                <Route path="/users" Component={User} />
                                <Route path="/inventory-policy" Component={InventoryPolicyContainer} />
                                <Route path="/scenarios" Component={ScenarioContainer} />
                                <Route path="/planning" Component={PlanningGridContainer} />
                                <Route path="/reports" Component={Report} />
                                <Route path="/reports/:id" Component={ReportView} />
                                <Route path='/info-pedido/:id/' Component={InfoPedidoPage} />
                                <Route path='/new-order' Component={NewOrderPage} />
                                <Route path='/orders' Component={OrdersPage} />
                            </Route>
                        </Routes>
                    </UserProvider>
                }
            />
        </Routes>
    );
};
