import { Box, Typography, useTheme } from "@mui/material";
import Tippy from "@tippyjs/react";

interface ToolTipProps {
  label: string;
  tooltipText: string;
}

export function ToolTipComponentOnlyText({ label, tooltipText }: ToolTipProps) {
  const theme = useTheme()
  return (
    <Box sx={{ display: 'flex', gap: '0.5rem', justifyContent: 'justify-between', alignItems: 'center' }} >
      <Tippy
        delay={[200, 50]}
        animation="fade"
        offset={[10, 10]}
        content={
          <Typography
            sx={{ background: theme.palette.background.paper, borderRadius: '10px', padding: '0.5rem', fontSize: '0.7rem' }}
          >
            {tooltipText}
          </Typography>
        }
      >
        <Typography sx={{ fontSize: '0.8rem', cursor: 'help' }} >{label}</Typography>
      </Tippy>
    </Box>
  );
}
