import { t } from '@lingui/macro';
import { SpeedDial, SpeedDialAction, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { PiTreeStructureFill } from 'react-icons/pi';
import { resetCompleteStructure, setCompleteStrucutreIds } from 'redux/Filters/reducer';
import { useAppDispatch } from 'redux/hooks';
import { getAsync } from 'services';
import { Icons } from 'utils';

interface SpeedDialComponentProps {
    itemId: number | undefined;
    scenarioId: number | undefined;
    handleResourcesByItemId: (itemId: number) => void;
    occupationOpen: boolean;
}

const SpeedDialComponent = ({ occupationOpen, handleResourcesByItemId,itemId, scenarioId }: SpeedDialComponentProps) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const getCompleteStructure = async (itemId: string, filterTypeId: number, scenarioId: number | undefined) => {
        if (!scenarioId) return;
        const numberItemId = Number(itemId);
        const completeStructure = await getAsync(`/CompleteStructure/${numberItemId}/${filterTypeId}/${scenarioId}`);
        dispatch(setCompleteStrucutreIds({ completeStructure, filterTypeId, itemId: numberItemId }));
    };

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleResetFilters = () => {
        dispatch(resetCompleteStructure());
    };

    return (
        <Box sx={{ position: 'relative' }}>
            <SpeedDial
                ariaLabel="SpeedDial"
                icon={<PiTreeStructureFill size={20} />}
                FabProps={{
                    sx: {
                        width: '2rem',
                        height: '2rem',
                        backgroundColor: 'transparent',
                        ':hover': {
                            backgroundColor: 'transparent',
                            filter: 'brightness(0.9)',
                        },
                    },
                }}
                sx={{
                    width: 'fit-content',
                }}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
                direction="right"
            >
                <SpeedDialAction
                    key={t`Todos Itens Pai`}
                    icon={<Icons.DoubleArrowUp size={18} />}
                    tooltipTitle={t`Todos Itens Pai`}
                    onClick={() => {
                        getCompleteStructure(itemId?.toString() as string, 1, scenarioId);
                        handleClose();
                    }}
                    sx={{
                        height: '2rem',
                        width: '2rem',
                    }}
                />
                <SpeedDialAction
                    key={t`Item Pai`}
                    icon={<Icons.ArrowUp size={18} />}
                    tooltipTitle={t`Item Pai`}
                    onClick={() => {
                        getCompleteStructure(itemId?.toString() as string, 2, scenarioId);
                        handleClose();
                    }}
                    sx={{
                        height: '2rem',
                        width: '2rem',
                    }}
                />
                <SpeedDialAction
                    key={t`Item Filho`}
                    icon={<Icons.ArrowDown size={18} />}
                    tooltipTitle={t`Item Filho`}
                    onClick={() => {
                        getCompleteStructure(itemId?.toString() as string, 3, scenarioId);
                        handleClose();
                    }}
                    sx={{
                        height: '2rem',
                        width: '2rem',
                    }}
                />
                <SpeedDialAction
                    key={t`Todos Itens Filho`}
                    icon={<Icons.DoubleArrowDown size={18} />}
                    tooltipTitle={t`Todos Itens Filho`}
                    onClick={() => {
                        getCompleteStructure(itemId?.toString() as string, 4, scenarioId);
                        handleClose();
                    }}
                    sx={{
                        height: '2rem',
                        width: '2rem',
                    }}
                />
                <SpeedDialAction
                    key={t`Remover Filtros`}
                    icon={<Icons.Clear size={18} />}
                    tooltipTitle={t`Remover Filtros`}
                    onClick={handleResetFilters}
                    sx={{
                        height: '2rem',
                        width: '2rem',
                    }}
                />
            </SpeedDial>
            <Box
                sx={{
                    color: theme.palette.primary.main,
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    marginLeft: !open ? '4rem': '0',
                    display: open||!occupationOpen? 'none' : 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    ':hover': {
                        filter: 'brightness(0.9)',
                        color: theme.palette.primary.main,
                    },
                }}
                onClick={(event) => {
                    event.stopPropagation();
                    handleResourcesByItemId(itemId as number); // Assuming handleResourcesByItemId is defined elsewhere
                }}
            >
                <Icons.Factory size={25} />
            </Box>
        </Box>
    );
};

export default SpeedDialComponent;
