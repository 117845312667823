import { Trans } from '@lingui/macro';
import { Box, FormControl, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { ChangeEvent, FC, useMemo } from 'react';
import { ITableEditionLog } from 'types/log/ITableEditionLog';
import { IFilter } from '../hooks/useFilter';
import tryParseDateToISO from 'utils/tryParseDateToISO';

interface IFilterProps {
    logs: ITableEditionLog[];
    filter: IFilter;
    onFilterChange: (
        event: SelectChangeEvent<string[]> | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => void;
}
export const Filter: FC<IFilterProps> = ({ logs, filter, onFilterChange }) => {
    const users = useMemo(
        () => [...new Set(logs.map((log) => log.user))],
        [logs],
    );
    const columns = useMemo(
        () => [...new Set(logs.map((log) => log.column))],
        [logs],
    );
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
            }}
            gap={1}
            width={467}
        >
            <FormControl variant="standard">
                <Typography>
                    <Trans>Coluna</Trans>
                </Typography>
                <Select
                    variant="standard"
                    sx={{
                        width: 150,
                    }}
                    name="columns"
                    multiple
                    value={filter.columns}
                    onChange={onFilterChange}
                >
                    {columns.map((column) => (
                        <MenuItem key={column} value={column}>
                            {column}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl variant="standard">
                <Typography>
                    <Trans>Usuário</Trans>
                </Typography>
                <Select
                    variant="standard"
                    sx={{
                        width: 150,
                    }}
                    name={'users'}
                    value={filter.users}
                    onChange={onFilterChange}
                    multiple
                >
                    {users.map((column) => (
                        <MenuItem key={column} value={column}>
                            {column}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl>
                <Typography>
                    <Trans>Data</Trans>
                </Typography>
                <TextField
                    type="date"
                    value={tryParseDateToISO(filter.date) ?? ''}
                    onChange={onFilterChange}
                    variant="standard"
                    name="date"
                    sx={{
                        width: 150,
                        minWidth: 150,
                    }}
                />
            </FormControl>
        </Box>
    );
};
