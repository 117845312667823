import { useEffect, useState } from 'react';
import { getAsync } from '@nplan';

interface IUseLicenceInfo {
    licenceInfo: LicenceInfo | null;
}

export const useLicenceInfo = (): IUseLicenceInfo => {
    const [licenceInfo, setLicenceInfo] = useState<LicenceInfo | null>(null);

    useEffect(() => {
        (async () => {
            const res = await getAsync<LicenceInfo>('/licence');
            if (res.type === 'success' && res.data) {
                setLicenceInfo(res.data);
            }
        })();
    }, []);
    return { licenceInfo };
};
