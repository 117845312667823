import { useState, useEffect, useCallback } from 'react';
import { getAsync, postAsync, putAsync } from '@nplan';

const defaultRole: Role = {
    id: 0,
    name: '',
    admin: false,
};

interface IUseManageRoleReturn {
    role: Role;
    loading: boolean;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSwitchChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    handleSaveAsync: () => Promise<void>;
}

export const useManageRole = (
    roleId: number,
    open: boolean,
    onClose: () => void,
): IUseManageRoleReturn => {

    const [role, setRole] = useState<Role>(defaultRole);
    const [loading, setLoading] = useState(false);

    const handleInputChange = useCallback((
        event: React.ChangeEvent<HTMLInputElement>,
    ): void => {
        const { name, value } = event.target;
        setRole((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, []);

    const handleSwitchChange = useCallback((
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean,
    ): void => {
        const { name } = event.target;
        setRole((prev) => ({
            ...prev,
            [name]: checked,
        }));
    }, []);

    const handleSaveAsync = useCallback(async (): Promise<void> => {
        setLoading(true);
        const res =
            role.id === 0
                ? await postAsync('roles', role)
                : await putAsync(`roles`, role);
        if (res.type === 'success') {
            onClose();
        }
        setLoading(false);
    }, [role, onClose]);

    useEffect(() => {
        if(!open) return;
        setRole(defaultRole);
        (async () => {
            if (roleId > 0) {
                setLoading(true);
                const response = await getAsync<Role>(
                    `roles/${roleId}`,
                );
                if (response.type === 'success' && response.data) {
                    setRole(response.data);
                }
                setLoading(false);
            }
        })();
    }, [roleId, open]);

    return {
        role,
        loading,
        handleInputChange,
        handleSwitchChange,
        handleSaveAsync,
    };
};
