import { useState } from 'react';

interface IUseVisiblePasswordReturn {
    showPassword: boolean;
    handleShowPassword: () => void;
    handleHiddenPassword: () => void;
}

export const useVisiblePassword = (): IUseVisiblePasswordReturn => {
    const [showPassword, setShowPassword] = useState(false);
    const handleShowPassword = () => { 
        setShowPassword(true);
    };
    const handleHiddenPassword = () => {
        setShowPassword(false);
    };
    return  { 
        showPassword, 
        handleShowPassword, 
        handleHiddenPassword 
    };
};