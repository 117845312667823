import { Box, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { Icons } from '@nplan';
import { t } from '@lingui/macro';

interface IContextMenu {
    onClose: () => void;
    position: MenuPosition | null;
    onRefreshDataAsync: () => Promise<void>;
    onAddButton: (event: number) => void;
}
export const ContextMenu: React.FC<IContextMenu> = ({
    onClose,
    position,
    onRefreshDataAsync,
    onAddButton,
}): JSX.Element => {
    const handleRefreshData = () => {
        onRefreshDataAsync();
        onClose();
    };
    const handleAddButton = () => {
        onAddButton(0);
        onClose();
    };
    return (
        <Menu
            anchorReference='anchorPosition'
            anchorPosition={
                position
                    ? { top: position.y, left: position.x }
                    : undefined
            }
            open={Boolean(position)}
            onClose={onClose}
        >
            <Box>
                <MenuItem onClick={handleRefreshData}>
                    <ListItemIcon>
                        <Icons.Refresh size={20} />
                    </ListItemIcon>
                    {t`Atualizar dados`}
                </MenuItem>
                <MenuItem onClick={handleAddButton}>
                    <ListItemIcon>
                        <Icons.Add size={20} />
                    </ListItemIcon>
                    {t`Adicionar Botão`}
                </MenuItem>
            </Box>
        </Menu>
    );
};