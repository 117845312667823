
import { MouseEvent, memo, useState } from 'react';
import { AiOutlineCheckCircle, AiOutlineCloseCircle, AiOutlineMinusCircle } from 'react-icons/ai';

interface BooleanFilterProps {
    column: Column;
    onFilterColumnValueChange: (column: Column, value: any) => void;
}

const BooleanFilter: React.FC<BooleanFilterProps> = ({ column, onFilterColumnValueChange }) => {
    const [value, setValue] = useState<boolean | null>(null);
    const handleChange = (event: MouseEvent<SVGSVGElement>) => {
        const dataValue = event.currentTarget.getAttribute('data-value');
        if (dataValue === 'false') {
            setValue(false);
            onFilterColumnValueChange(column, false);
        } else if(dataValue === 'middle') {
            setValue(null);
            onFilterColumnValueChange(column, '');
        } else {
            setValue(true);
            onFilterColumnValueChange(column, true);
        }
    };
    return (
        <div style={ { width: '100%', height: '34px', display: 'flex', alignItems: 'center', justifyContent: 'center' } }>
            <div style={ { borderRadius: '25px', position: 'relative', border: '2px solid #95A5A6', minWidth: '80px', maxWidth: '90px', cursor: 'pointer' } }>
                <div style={ { display: 'flex', flexDirection: 'row', width: '100%', height: '100%' } }>
                    <AiOutlineCloseCircle
                        style={ { width: '30px', height: '25px', color: value === false ? 'red' : '#95A5A6' } }
                        onClick={handleChange}
                        data-value="false"
                    />
                    <AiOutlineMinusCircle
                        style={ { width: '30px', height: '25px', color: value === null ? 'black' : '#95A5A6' } }
                        onClick={ handleChange }
                        data-value="middle"
                    />
                    <AiOutlineCheckCircle
                        style={ { width: '30px', height: '25px', color: value === true ? 'green' : '#95A5A6' } }
                        onClick={ handleChange }
                        data-value="true"
                    />
                </div>
            </div>
        </div>
    );
};

const MemoizedBooleanFilter = memo(BooleanFilter);
export default MemoizedBooleanFilter;