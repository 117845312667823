import { 
    useCallback, 
    useEffect, 
    useState 
} from 'react';
import { 
    postAsync, 
    useUserContext 
} from '@nplan';

interface IUseLoginReturn {
    handleChangeForm: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleGetTokenAsync: () => Promise<void>;
}

interface ILoginData {
    username: string;
    password: string;
}
export const useLogin = (
    onChangeLoading: (value: boolean) => void
): IUseLoginReturn => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const { onLoginAsync } = useUserContext();

    const handleGetTokenAsync = useCallback(async () => {
        onChangeLoading(true);
        const res = await postAsync<ILoginData, string>(
            'auth/password',
            {
                username,
                password,
            },
        );
        if (res.type === 'success' && res.data) {
            onLoginAsync(res.data);
        }
        onChangeLoading(false);
    }, [username, password, onLoginAsync, onChangeLoading]);

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                handleGetTokenAsync();
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [username, password, handleGetTokenAsync]);

    const handleChangeForm = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === 'username') {
            setUsername(event.target.value);
        } else {
            setPassword(event.target.value);
        }
    };

    return { 
        handleChangeForm, 
        handleGetTokenAsync,
    };
};