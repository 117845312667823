import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import useItemRowData from 'pages/planning-grid/hooks/Grid/useItemRowDataHandler';
import usePlanningGridStockValueAndColors from 'pages/planning-grid/hooks/Grid/usePlanningGridStockValueAndColors';
import { GlobalPlanningGridTargetType } from 'pages/planning-grid/types/PlanningGrid';
import { useEffect, useMemo, useState } from 'react';
import { ListChildComponentProps } from 'react-window';
import { setItemRowSelectedProducts, setPlanningGridUnitValues } from 'redux/Filters/reducer';
import { PlanningGridUnitaryOrGlobalType } from 'redux/Filters/types';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { putAsync } from 'services/api';
import EditableInput from '../components/EditableInput';
import SpeedDialComponent from '../components/SpeedDialCompleteStructure';
import { InfoTooltipWithCopyText } from '../components/TooltipComponent';
import { selectItemImageCached } from '../FilterDrawer/FilterCriteria/selectors';
import { ImageContainer } from './ImageContainer';
import { Icons, useVariant } from 'utils';
import { useOccupationData } from 'pages/occupation/hooks';

// const globalPlanningGridTargetTypeInverter = (value: GlobalPlanningGridTargetType): GlobalPlanningGridTargetType => {
//     return ((value === GlobalPlanningGridTargetType.DailyGlobalTargetValue) || (value === GlobalPlanningGridTargetType.InBoxidualTargetValue)) ? GlobalPlanningGridTargetType.UnitaryGlobalTargetValue : GlobalPlanningGridTargetType.DailyGlobalTargetValue
// }

type UpdatedLocalItem = {
    changedByUser: boolean;
    isDayMinStockValue: boolean;
    isDaySafetyStockValue: boolean;
    isDayTargetStockValue: boolean;
    itemIds: number[];
    localMinStockValue: number;
    localSafetyStockValue: number;
    localTargetStockValue: number;
    minimumReorder: number;
    multipleReorder: number;
    scenarioId: number;
    lockedPeriodId: number | null;
};

const ItemRow = (props: ListChildComponentProps) => {
    const theme = useTheme();
    const scenario = props.data.scenario;
    const occupationOpen = props.data.occupationOpen;
    const { handleResourcesByItemId } = useOccupationData();
    const variant = useVariant();

    const dispatch = useAppDispatch();
    const selectedItemIds = useAppSelector(state => state.filter.global.chartSelectedItemIds);

    const selectedPlanningGrid = useAppSelector(state => state.filter.planningGrid.selectedPlanningGrid);
    const { getColorByStockStatusId } = usePlanningGridStockValueAndColors();

    // const toggleSize: (index: number, itemId: number) => void = props.data.toggleSize
    const planningGridRowOptions = useAppSelector(state => state.filter.planningGrid.planningGridRowOptions);

    const currentIndex = props.index;
    const cellSize: number = props.data.calculateCellSize(currentIndex);

    const currentItemRow = useItemRowData(currentIndex);

    const [_localItemRow, setLocalItemRow] = useState(currentItemRow !== undefined ? currentItemRow : {});

    const isSelected = useMemo(() => {
        return currentItemRow && selectedItemIds.includes(currentItemRow?.orderedItemId);
    }, [currentItemRow, selectedItemIds]);

    const handleOnSelectProduct = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        dispatch(setItemRowSelectedProducts({ ctrlKey: event.ctrlKey, shiftKey: event.shiftKey, itemId: currentItemRow?.orderedItemId }));
    };

    const [image, setImage] = useState<string | null>(null);
    useEffect(() => {
        (async () => {
            if (typeof currentItemRow?.itemCode === 'string') {
                const imageObjectURL = await selectItemImageCached(currentItemRow?.itemCode);
                if (imageObjectURL !== null) {
                    setImage(imageObjectURL);
                }
            }
        })();
    }, [currentItemRow?.itemCode]);

    const handleGlobalStockType = (type: GlobalPlanningGridTargetType | undefined) => {
        if (type === undefined) return;
        if (type === GlobalPlanningGridTargetType.DailyGlobalTargetValue) {
            return true;
        } else {
            return false;
        }
    };

    const handleInputs = async (id: string | undefined, value: { value: number | undefined, changedByUser: boolean }, itemId: number | undefined, isDay?: boolean | undefined,) => {
        if (id === undefined || value === undefined || itemId === undefined) return;
        let periodIdLocked: number | null = null;

        selectedPlanningGrid[itemId].forEach((planningGridByPeriod) => {
            if (planningGridByPeriod.locked) {
                periodIdLocked = planningGridByPeriod.periodId;
            } else {
                return;
            }
        });
        let result: UpdatedLocalItem;
        setLocalItemRow((prevLocalItemRow: any) => {
            let updatedLocalItemRow =
            {
                ...prevLocalItemRow,
                itemId: itemId,
                scenarioId: scenario?.id,
                changedByUser: value.changedByUser,
                isDayTargetStockValue: handleGlobalStockType(currentItemRow?.globalTargetStockType),
                isDaySafetyStockValue: handleGlobalStockType(currentItemRow?.globalSafetyStockType),
                isDayMinStockValue: handleGlobalStockType(currentItemRow?.globalMinStockType),
                localTargetStockValue: currentItemRow?.localTargetStockValue,
                localSafetyStockValue: currentItemRow?.localSafetyStockValue,
                localMinStockValue: currentItemRow?.localMinStockValue,
                minimumReorder: currentItemRow?.minimumReorder,
                multipleReorder: currentItemRow?.multipleReorder,
            };

            switch (id) {
                case 'targetStock':
                    updatedLocalItemRow = {
                        ...updatedLocalItemRow,
                        localTargetStockValue: value.value,
                        isDayTargetStockValue: isDay,
                    };
                    break;
                case 'safetyStock':
                    updatedLocalItemRow = {
                        ...updatedLocalItemRow,
                        localSafetyStockValue: value.value,
                        isDaySafetyStockValue: isDay,
                    };
                    break;
                case 'minStock':
                    updatedLocalItemRow = {
                        ...updatedLocalItemRow,
                        localMinStockValue: value.value,
                        isDayMinStockValue: isDay,
                    };
                    break;
                case 'minimumBatch':
                    updatedLocalItemRow = { ...updatedLocalItemRow, minimumReorder: value.value };
                    break;
                case 'multipleBatch':
                    updatedLocalItemRow = { ...updatedLocalItemRow, multipleReorder: value.value };
                    break;
                default:
                    return prevLocalItemRow;
            }

            result = {
                changedByUser: updatedLocalItemRow.changedByUser,
                isDayMinStockValue: updatedLocalItemRow.isDayMinStockValue,
                isDaySafetyStockValue: updatedLocalItemRow.isDaySafetyStockValue,
                isDayTargetStockValue: updatedLocalItemRow.isDayTargetStockValue,
                itemIds: [updatedLocalItemRow.itemId],
                localMinStockValue: updatedLocalItemRow.localMinStockValue,
                localSafetyStockValue: updatedLocalItemRow.localSafetyStockValue,
                localTargetStockValue: updatedLocalItemRow.localTargetStockValue,
                minimumReorder: updatedLocalItemRow.minimumReorder ?? 0,
                multipleReorder: updatedLocalItemRow.multipleReorder ?? 0,
                scenarioId: updatedLocalItemRow.scenarioId,
                lockedPeriodId: periodIdLocked
            };

            return updatedLocalItemRow;
        });
        await putAsync<any | string>(`/planninggrid`, result!);
        await props.data.onReloadItemPlanningGrid([itemId]);
    };


    const { i18n } = useLingui();
    return (
        <Box  data-testid="itemRow" key={currentIndex} style={props.style}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
            }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingRight: '1rem',
                width: '100%',
                borderTop: '1px solid',
                userSelect: 'none',
                backgroundColor: isSelected ? theme.palette.grey[300] : 'transparent',
                borderColor: theme.palette.grey[400],
                height: cellSize,

            }}
            onClick={event => handleOnSelectProduct(event)}
            >
                <Box sx={{
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    minWidth: 390,
                    gap: '1rem',
                    paddingBottom: '1rem'
                }}>
                    <Box sx={{ gap: '0.5rem', flexDirection: 'column', display: 'flex', justifyContent: 'space-between', alignItems: 'start', paddingLeft: '1rem', paddingTop: '0.5rem', marginTop: '0.3rem' }}>
                        <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center', justifyContent: 'center' }}>
                            <InfoTooltipWithCopyText tooltipText={currentItemRow?.tooltip} itemCode={ currentItemRow?.itemCode} />
                          

                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                            <Typography
                                sx={{
                                    fontWeight: 'bold',
                                    textAlign: 'start',
                                    fontSize: '0.9rem',
                                    marginBottom: '0.5rem',
                                    wordWrap: 'break-word',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                    WebkitLineClamp: 2,
                                    paddingLeft: '5px',
                                    maxWidth: '350px'

                                }}
                                title={currentItemRow?.description as string}
                            >
                                {currentItemRow?.description}
                            </Typography>
                        </Box>
        
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '2rem' }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '1rem',
                            paddingLeft: '1rem',
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                padding: '5px 0',
                                columnGap: '5px',
                            }}>
                                <ImageContainer itemCode={currentItemRow?.itemCode} imageSrc={image as string} tag01={currentItemRow?.tag01Id} tag02={currentItemRow?.tag02Id} tag03={currentItemRow?.tag03Id} />
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                fontSize: '0.75rem',
                                justifyContent: 'space-evenly',
                                alignItems: 'center',
                                gap: '0.3rem',
                                height: '100%',

                            }}>
                                <EditableInput
                                    textReactNode={<Trans>Estoque Alvo</Trans>}
                                    unit={currentItemRow?.globalTargetStockType === GlobalPlanningGridTargetType.UnitaryGlobalTargetValue ?
                                        t({ message: 'Un', comment: 'unidade/letra para simbolizar Unidades, ex: Un' })
                                        :
                                        t({ message: 'D', comment: 'unidade/letra para simbolizar Dias, ex: D' })}
                                    id='targetStock'
                                    inputValue={currentItemRow?.localTargetStockValue}
                                    // onExternalUserChange={(value) => updateItemTargetStock(value, orderedItemId)}
                                    delay={500}
                                    type='number'
                                    onExternalUserChange={(value) => handleInputs('targetStock', value, currentItemRow?.itemId, currentItemRow?.globalTargetStockType === GlobalPlanningGridTargetType.UnitaryGlobalTargetValue ? false : true)}
                                    onChangeUnit={() => { dispatch(setPlanningGridUnitValues({ itemId: currentItemRow?.itemId, type: PlanningGridUnitaryOrGlobalType.GlobalTargetStockType })); }}

                                />

                                <EditableInput
                                    textReactNode={<Trans>Ponto de Reposição</Trans>}
                                    unit={currentItemRow?.globalMinStockType === GlobalPlanningGridTargetType.UnitaryGlobalTargetValue ?
                                        t({ message: 'Un', comment: 'unidade/letra para simbolizar Unidades, ex: Un' })
                                        :
                                        t({ message: 'D', comment: 'unidade/letra para simbolizar Dias, ex: D' })}
                                    id='minStock'
                                    inputValue={currentItemRow?.localMinStockValue}
                                    // onExternalUserChange={(value) => updateItemMinStock(value, orderedItemId)}
                                    delay={500}
                                    type='number'
                                    onExternalUserChange={(value) => handleInputs('minStock', value, currentItemRow?.itemId, currentItemRow?.globalMinStockType === GlobalPlanningGridTargetType.UnitaryGlobalTargetValue ? false : true,)}
                                    onChangeUnit={() => { dispatch(setPlanningGridUnitValues({ itemId: currentItemRow?.itemId, type: PlanningGridUnitaryOrGlobalType.GlobalMinStockType })); }}

                                />
                                <EditableInput
                                    textReactNode={<Trans>Estoque Segurança</Trans>}
                                    unit={currentItemRow?.globalSafetyStockType === GlobalPlanningGridTargetType.UnitaryGlobalTargetValue ?
                                        t({ message: 'Un', comment: 'unidade/letra para simbolizar Unidades, ex: Un' })
                                        :
                                        t({ message: 'D', comment: 'unidade/letra para simbolizar Dias, ex: D' })}
                                    id='safetyStock'
                                    inputValue={currentItemRow?.localSafetyStockValue}
                                    // onExternalUserChange={(value) => updateItemTargetStock(value, orderedItemId)}
                                    delay={500}
                                    type='number'
                                    onExternalUserChange={(value) => handleInputs('safetyStock', value, currentItemRow?.itemId, currentItemRow?.globalSafetyStockType === GlobalPlanningGridTargetType.UnitaryGlobalTargetValue ? false : true)}
                                    onChangeUnit={() => { dispatch(setPlanningGridUnitValues({ itemId: currentItemRow?.itemId, type: PlanningGridUnitaryOrGlobalType.GlobalSafetyStockType })); }}
                                />
                                <EditableInput
                                    textReactNode={<Trans>Lote Mínimo</Trans>}
                                    id='minimumBatch'
                                    unit={t({ message: 'Un', comment: 'unidade/letra para simbolizar Unidades, ex: Un' })}
                                    inputValue={currentItemRow?.minimumReorder}
                                    onExternalUserChange={(value) => handleInputs('minimumBatch', value, currentItemRow?.itemId)}
                                    delay={500}
                                    type='number' />
                                <EditableInput
                                    textReactNode={<Trans>Lote Múltiplo</Trans>}
                                    id='multipleBatch'
                                    unit={t({ message: 'Un', comment: 'unidade/letra para simbolizar Unidades, ex: Un' })}
                                    inputValue={currentItemRow?.multipleReorder}
                                    onExternalUserChange={(value) => handleInputs('multipleBatch', value, currentItemRow?.itemId)}
                                    delay={500}
                                    type='number'
                                />
                            </Box>
                        </Box>
                        <Box sx={{   paddingLeft: '1rem', display: 'flex', alignItems: 'center', position: 'relative' }}>
                            <SpeedDialComponent itemId={currentItemRow?.itemId} scenarioId={scenario?.id}  handleResourcesByItemId={handleResourcesByItemId} occupationOpen={occupationOpen}/>
                            {/* <Box
                                onClick={(event) =>{
                                    event.stopPropagation();
                                    handleResourcesByItemId(currentItemRow?.itemId as number);
                                }}
                                sx={{
                                    posistion: 'absotule',
                                    display: occupationOpen ? 'inline-block': 'none',
                                    border: 'none',
                                    color: theme.palette.text.primary,
                                    background: 'transparent',
                                    margin: '0',
                                    marginInline: '1rem',
                                    padding: '0.3rem',
                                    borderRadius: '10px',
                                    width: 'fit-content',
                                    ':hover': {
                                        background: 'transparent',
                                        border: '0',
                                        outline: '0.5px solid',
                                        cursor: 'pointer',
                                    },
                                }}
                            >
                                <Icons.Factory size={20}/>
                            </Box> */}
                        </Box>
                    </Box>
                </Box>
                {
                    //calculateByInitialStockStatusType utilizar este metodo para calcular essa cor
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        whiteSpace: 'nowrap',
                        width: '100px',
                    }}
                    style={{ background: `${getColorByStockStatusId(currentItemRow?.openingStockStatusId)}`, minWidth: 100 }}
                    >
                        <Box>
                            <Typography sx={{ color: theme.palette.action.selected }}>{currentItemRow?.firstPlanningGridOpeningStock} {t({ message: 'Un', comment: 'unidade/letra para simbolizar Unidades, ex: Un' })}</Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ color: theme.palette.action.selected }}>{(currentItemRow?.initialStockPlanningGridStatus)}</Typography>
                        </Box>
                    </Box>

                }
                {
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        whiteSpace: 'nowrap',
                        justifyContent: 'space-evenly',
                        fontSize: '14px',
                        height: cellSize,
                    }}
                    >
                        {planningGridRowOptions.filter(c => c.initialStatus === true).sort((a, b) => a.sequence! - b.sequence!).map((option: any) => {
                            return (
                                <Typography key={option.id} fontSize={14}>{i18n.t(option.name)}</Typography>
                            );
                        })}
                    </Box>
                }
            </Box>

        </Box>
    );
};

export default ItemRow;


