import { SelectChangeEvent } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { putAsync } from '@nplan';

interface IUseCellDataReturn {
    editing: boolean;
    value: string | number | boolean | undefined;
    handleEditingTrue: () => void;
    handleEditingFalse: () => void;
    handleChangeInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangeCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangeSelect: (event: SelectChangeEvent<any>, child: React.ReactNode) => void;
    handleSave: () => Promise<void>;
    handleCancel: () => void;
}

export const useCellData = <K extends keyof Column>(columnKey: K, column: Column): IUseCellDataReturn => {
    const [editing, setEditing] = useState<boolean>(false);
    const [value, setValue] = useState<any>('');

    useEffect(() => {
        if (column && columnKey in column) {
            setValue(column[columnKey]);
        }
    }, [column, columnKey]);

    const handleEditingTrue = (): void => {
        setEditing(true);
    };

    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setValue(event.target.value);
    };

    const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setValue(event.target.checked);
    };


    const handleChangeSelect = (event: SelectChangeEvent<any>): void => {
        setValue(event.target.value);
    };

    const handleSave = useCallback(async (): Promise<void> => {
        if (column[columnKey] !== value) {
            column[columnKey] = value;
            await putAsync('columns', column);
        }
        setEditing(false);
    },[column, columnKey, value]);

    const handleCancel = useCallback((): void => {
        setValue(column[columnKey]);
        setEditing(false);
    }, [column, columnKey]);

    const handleEditingFalse = useCallback((): void => {
        setEditing(false);
    }, []);

    return {
        editing,
        value,
        handleEditingTrue,
        handleEditingFalse,
        handleChangeInput,
        handleChangeCheckbox,
        handleChangeSelect,
        handleSave,
        handleCancel
    };
};
