import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    useTheme,
} from '@mui/material';
import { useMenuPermission } from './hooks/useMenuPermission';
import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';

interface IManageMenuPermissionProps {
    open: boolean;
    onClose: () => void;
    id: number;
}

export const ManageMenuPermission: React.FC<IManageMenuPermissionProps> = ({ 
    open, 
    onClose, 
    id 
}): JSX.Element => {
    const theme = useTheme();
    const variant = theme.palette.mode === 'dark' ? 'outlined' : 'contained';
    const { 
        menuPermissions, 
        menus,
        handleChangeMenuPermission,
        handleSaveMenuPermission
    } = useMenuPermission(
        id,
        open,
        onClose,
    );
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>Permissões de Menus</DialogTitle>
            <DialogContent>
                <Select
                    fullWidth
                    variant='standard'
                    multiple
                    value={menuPermissions.map((menuPermission) => menuPermission.menuId)}
                    onChange={handleChangeMenuPermission}
                    multiline
                    sx={{
                        width: 300
                    }}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip 
                                    key={value} 
                                    label={i18n.t(menus.find(f => f.id === value)?.name ?? '')} 
                                />
                            ))}
                        </Box>
                    )}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 48 * 4.5 + 8,
                                width: 250,
                            },
                        },
                    }}
                >
                    {menus.map((menu) => (
                        <MenuItem key={menu.id} value={menu.id}>
                            {i18n.t(menu.name)}
                        </MenuItem>
                    ))}
                </Select>
            </DialogContent>
            <DialogActions>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: 2,
                        width: '100%',
                        flexDirection: 'column',

                    }}
                    gap={2}
                >
                    <Button
                        onClick={handleSaveMenuPermission}
                        color='primary'
                        variant={variant}
                    >
                        <Trans>
                            Salvar
                        </Trans>
                    </Button>
                    <Button
                        onClick={onClose}
                        color='error'
                        variant={variant}
                    >
                        <Trans>
                            Cancelar
                        </Trans>
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};
