import { IInventoryGenericOptions } from 'pages/inventory-policy/types/IInventoryGenericOptions';
import { InventoryHealthData } from 'pages/inventory-policy/types/IInventoryHealth';
import { SupplyStrategyEditedObjectsProps } from 'pages/inventory-policy/types/types';
import { CompleteStructure } from 'pages/planning-grid/components/PlanningGrid/components/SpeedDialCompleteStructure';
import { PeriodData } from 'pages/planning-grid/types/PeriodData';
import { FilterData, FixedFilterData, PlanningGridData } from 'pages/planning-grid/types/PlanningGrid';
import { AttributeList } from 'redux/types';
import { IPlanningGridOperationResource, IScenario } from 'types';
import { Dictionary } from 'types/Dictionary';
import { IGenericOptions } from 'types/Generics';
import { ICompany } from 'types/data/ICompany';
import { IItem } from 'types/data/IItem';
import { IPlanner } from 'types/data/IPlanner';
import { ITag01, ITag02, ITag03 } from 'types/data/ITags';
import { IPeriod } from 'types/scenarios/IPeriod';
import { IAttributeList } from 'types/system/IAttributeList';
import { IPeriodTypes } from 'types/system/IPeriodTypes';
import { IPlanningGridFilterOptions } from 'types/system/IPlanningGridFilterOptions';
import { IScenarioCriteria } from 'types/system/IScenarioCriteria';
import { IScenarioParameter } from 'types/system/IScenarioParameter';
import { IStockStatus } from 'types/system/IStockStatus';


export interface ItemAttributesBundle {
    [key: string]: Dictionary<any>;
}

export interface ItemAttributesBundleData {
    [key: string]: any[];
}

export interface TagsBundle {
    Tag01: Dictionary<ITag01>,
    Tag02: Dictionary<ITag02>,
    Tag03: Dictionary<ITag03>,
}
export interface TagsBundleData {
    Tag01: ITag01[],
    Tag02: ITag02[],
    Tag03: ITag03[],
}

export enum PlanningGridUnitaryOrGlobalType {
    GlobalMinStockType = 'globalMinStockType',
    GlobalSafetyStockType = 'globalSafetyStockType',
    GlobalTargetStockType = 'globalTargetStockType'
}

export interface FiltersInterface {
    planningGrid: {
        itemIdByResourceId: Dictionary<number[]>
        selectedResources:number[]
        chartVisible: boolean
        finiteButtonLoading: boolean
        selectedOccupationProperty: keyof IItem
        editableInputBorderColor: string
        completeStructureIds: number[]
        planningGridById: Dictionary<PlanningGridData[]>
        isCompleteStructureUsed: boolean
        completeStructureObject: {
            parentIds: CompleteStructure[],
            selectedId: number[],
            childIds: CompleteStructure[]
        }
        attributeListRaw: IAttributeList[]
        selectedPlanningGrid: Dictionary<PlanningGridData[]>
        planningGridOperationResource: Dictionary<IPlanningGridOperationResource[]>
        planningGridOperationResourceByPeriodId: Dictionary<IPlanningGridOperationResource[]>
        planningGridOperationResourceData: IPlanningGridOperationResource[]
        filteredPlanningGridItemIds: number[]
        attributeListData: AttributeList[],
        fixedFilterOptions: FixedFilterData[],
        selectedAttributesToShow: AttributeList[];
        selectedFiltersToShow: FixedFilterData[];
        itemPlannerById: Dictionary<IPlanner>;
        itemCompanyById: Dictionary<ICompany>;
        itemComplexityById: Dictionary<any>;
        itemVolumeById: Dictionary<any>;
        itemUncertaintyById: Dictionary<any>;
        itemStockGroupById: Dictionary<IStockGroup>;
        filterData: FilterData;
        itemAttributes: ItemAttributesBundle
        tags: TagsBundle
        minifiedPeriods: string[];
        complexPeriods: PeriodData[];
        periodsIdToIndex: Dictionary<number>;
        periods: IPeriod[];
        itemById: Dictionary<IItem>
        itemIdsByLevel: Dictionary<number[]>
        attributesInUse: string[]
        redrawFilters: number
        isExpandedPeriods: boolean
        planningGridRowOptions: IPlanningGridFilterOptions[]
        manualMpsObject: {
            editedMpsObject: {
                planningGridId: number,
                manualMps: number
            },
            itemId: number
        }
    },
    global: {
        itemCodeSelectedItemIds: number[]
        itemProperties: any
        scenarioCriterias: IScenarioCriteria[]
        scenarioParameters: IScenarioParameter[]
        scenariosRowsLocked: boolean
        items: number[]
        itemsComplete: IItem[]
        planningGridFilterOptions: IPlanningGridFilterOptions[]
        periodTypes: IPeriodTypes[]
        selectedItemIds: number[]
        chartSelectedItemIds: number[]
        stockStatus: IStockStatus[]
        scenario: IScenario
    },
    inventory: {
        dataValidation: {
            // customerGroupExists: boolean,
            companyExists: boolean,
            classificationVolumeExists: boolean,
            classificationComplexityExists: boolean,
            classificationUncertaintyExists: boolean,
            salesChannelExists: boolean,
            stockGroupExists: boolean,
        },
        inventoryHealth: InventoryHealthData[]
        axisOptions: any
        companyOptions: IInventoryGenericOptions[],
        stockGroupOptions: IInventoryGenericOptions[],
        salesChannelOptions: IInventoryGenericOptions[],
        periodOptions: IInventoryGenericOptions[],
        secondaryOptions: IInventoryGenericOptions[]
        selectedAxis: any
        selectedStockGroupFilter: IInventoryGenericOptions
        selectedCompanyFilter: IInventoryGenericOptions
        selectedSalesChannelFilter: IInventoryGenericOptions
        selectedSecondaryFilter: IInventoryGenericOptions
        selectedPeriodFilter: IInventoryGenericOptions
        isEditable: boolean
        inventoryHealthChartSelection: IGenericOptions
        inventoryPolicyEditedObjects: SupplyStrategyEditedObjectsProps[]
        stockTypeId: number
        loadingHealth: boolean
    }
}