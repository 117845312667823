import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Box, FormControl, Switch, Typography } from '@mui/material';
import { ChangeEvent } from 'react';

interface IParameterProps {
    scenarioParameterValue: ScenarioParameterValue[] | undefined;
    isNotEditable: boolean;
    getParameter: (id: number) => ScenarioParameter | undefined;
    onParameterValueChange: (
        event: ChangeEvent<HTMLInputElement>,
        checked: boolean,
    ) => void;
}
export const Parameter: React.FC<IParameterProps> = ({
    scenarioParameterValue,
    isNotEditable,
    getParameter,
    onParameterValueChange,
}: IParameterProps): JSX.Element => {
    const { i18n } = useLingui();
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ marginBottom: '1rem', fontWeight: 'bold' }}>
                <Trans>Parâmetros</Trans>
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '1',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid',
                    padding: '10px',
                    borderRadius: '8px',
                }}
            >
                {scenarioParameterValue?.map((parameter) => {
                    return (
                        <FormControl
                            key={parameter.scenarioParameterId}
                            sx={{
                                minWidth: 200,
                                // marginBottom: theme.spacing(2),
                                display: 'flex',
                                flex: '1',
                                gap: '0.2rem',
                            }}
                        >
                            <Box
                                display={'flex'}
                                // flexDirection={'column'}
                                justifyContent={'space-between'}
                                width={'500px'}
                                height={'100%'}
                                alignItems={'center'}
                                gap={'1rem'}
                            >
                                <Typography display={'flex'} flex={1}>
                                    {i18n.t(getParameter(parameter.scenarioParameterId)?.name ?? '')}
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Trans>Não</Trans>
                                    <Switch
                                        checked={parameter.value}
                                        onChange={onParameterValueChange}
                                        disabled={isNotEditable}
                                        data-parameter-id={
                                            parameter.scenarioParameterId
                                        }
                                        name={parameter.scenarioParameterId.toString()}
                                    />
                                    <Trans>Sim</Trans>
                                </Box>
                            </Box>
                        </FormControl>
                    );
                })}
            </Box>
        </Box>
    );
};
