import { ClickAwayListener, TextField } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';

interface INumberEditorProps {
    defaultValue: number;
    onClose: () => void;
    onSave: (value: any) => void;
}
export const NumberEditor: React.FC<INumberEditorProps> = ({
    defaultValue,
    onClose,
    onSave,
}): JSX.Element => {
    const [value, setValue] = useState<number | null>(null);
    const handleClickAway = useCallback(() => {
        if (defaultValue !== value) {
            onSave(value);
        }
        onClose();
    },[defaultValue, onClose, onSave, value]);
    const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Escape') {
            onClose();
            event.stopPropagation();
            event.preventDefault();
        } else if (event.key === 'Enter') {
            onSave(value === undefined ? "null" : value);
            onClose();
            event.stopPropagation();
            event.preventDefault();
        }
    },[onClose, onSave, value]);
    useEffect(() => {
        const parsed = parseInt(defaultValue?.toString());
        if(isNaN(parsed)) {
            setValue(null);
            return;
        }
        setValue(parsed);
    }, [defaultValue]);
    const handleChangeValue = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if(value === '') {
            setValue(null);
            return;
        }        
        const parsedValue = parseInt(value)
        if(isNaN(parsedValue)) {
            return;
        }
        setValue(parsedValue);
    },[])
    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <TextField
                value={value ?? ''}
                onChange={handleChangeValue}
                fullWidth
                autoFocus
                sx={{
                    height: 32,
                    padding: 0,
                }}
                inputProps={{
                    style: {
                        height: 32,
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingLeft: 5,
                    },
                }}
                onKeyDown={handleKeyDown}
            />
        </ClickAwayListener>
    );
};
