import { t } from '@lingui/macro';
import dayjs from 'dayjs';
import { IPeriod } from 'types/scenarios/IPeriod';


export const Months: (string)[] =
    [
        (`Janeiro`),
        (`Fevereiro`),
        (`Março`),
        (`Abril`),
        (`Maio`),
        (`Junho`),
        (`Julho`),
        (`Agosto`),
        (`Setembro`),
        (`Outubro`),
        (`Novembro`),
        (`Dezembro`),
    ];

export const translateMonth = (month: string) => {
    switch (month) {
        case 'Janeiro':
            return t`Janeiro`;
        case 'Fevereiro':
            return t`Fevereiro`;
        case 'Março':
            return t`Março`;
        case 'Abril':
            return t`Abril`;
        case 'Maio':
            return t`Maio`;
        case 'Junho':
            return t`Junho`;
        case 'Julho':
            return t`Julho`;
        case 'Agosto':
            return t`Agosto`;
        case 'Setembro':
            return t`Setembro`;
        case 'Outubro':
            return t`Outubro`;
        case 'Novembro':
            return t`Novembro`;
        case 'Dezembro':
            return t`Dezembro`;
        default:
            return '';
    }
};

export const createComplexPeriodDataMonthly = (periods: IPeriod[]) => {
    if (!periods) return [];
    const monthPeriods = [];
    let lastPeriodGroup = -1;
    let lastPeriodMonthNumber = -1;
    for (let i = 0; i < periods.length; i++) {
        const period = periods[i];
        const startDateTime = dayjs(String(period.startTime)).toDate();
        const endDateTime = dayjs(String(period.endTime)).toDate();
        const monthNumber = dayjs(String(period.startTime)).month();

        if (lastPeriodMonthNumber < 0) { lastPeriodMonthNumber = monthNumber; }

        let localMonthName = Months[lastPeriodMonthNumber];
        let localMonthNumber = lastPeriodMonthNumber;

        if (dayjs(period.startTime).date > dayjs(period.endTime).date) {
            localMonthNumber = lastPeriodMonthNumber - 1 < 0 ? 11 : lastPeriodMonthNumber - 1;
            localMonthName = Months[localMonthNumber];
        }
        const periodGroup = period.periodGroup;



        if (lastPeriodGroup !== periodGroup) {
            monthPeriods[periodGroup] = ({
                monthName: Months[lastPeriodMonthNumber],
                monthNumber: lastPeriodMonthNumber,
                group: period.periodGroup,
                rank: period.rank,
                yearNumber: startDateTime.getFullYear(),
                periods: [{
                    monthName: Months[dayjs(period.startTime).month()],
                    startDay: startDateTime.getDate(),
                    endDay: endDateTime.getDate()
                }]
            });
            lastPeriodMonthNumber = (lastPeriodMonthNumber + 1) % 12;
        } else {
            monthPeriods[periodGroup].periods.push({
                monthName: Months[dayjs(period.startTime).month()],
                startDay: startDateTime.getDate(),
                endDay: endDateTime.getDate()
            });
        }

        // if (periods[i].startTime === periods[i].endTime) {
        //     monthPeriods[periodGroup].periods.push({
        //         monthName: Months[localMonthNumber],
        //         startDay: dayjs(periods[i].startTime).date(),
        //         endDay: dayjs(periods[i].startTime).date()
        //     });
        //     continue;
        // }
        lastPeriodGroup = periodGroup;
    }
    return monthPeriods.filter(period => period !== undefined);
};
export const createComplexPeriodDataWeekly = (periods: IPeriod[]) => {
    if (!periods) return [];
    const complexPeriods = {};

    for (let i = 0; i < periods.length; i++) {

        const groupId = periods[i].periodGroup;

        // if (periods[i].startTime === periods[i].endTime) {
        //     complexPeriods[groupId].periods.push({
        //         monthName: Months[dayjs(periods[i].startTime).month()],
        //         startDay: dayjs(periods[i].startTime).date(),
        //         endDay: dayjs(periods[i].startTime).date()
        //     });
        //     continue;
        // }
        if (!complexPeriods[groupId]) {
            const groupDescription = periods[i].periodGroupDescription;
            const startDateTime = dayjs(periods[i].startTime); // Get start date time
            complexPeriods[groupId] = {
                monthName: Months[Number(groupDescription?.split('.')[1]) - 1],
                monthNumber: Number(groupDescription && groupDescription.split('.')[1]) - 1,
                group: groupId,
                yearNumber: startDateTime.year(), // Get year from start date time
                rank: periods[i].rank,
                periods: []
            };
        }

        const startDateTime = dayjs(periods[i].startTime);
        const endDateTime = dayjs(periods[i].endTime);
        const monthName = Months[startDateTime.month()];

        complexPeriods[groupId].periods.push({
            monthName: monthName,
            startDay: startDateTime.date(), // Accessing the day part using .date()
            endDay: endDateTime.date() // Accessing the day part using .date()
        });
    }

    // Convert object to array
    const complexPeriodsArray = Object.values(complexPeriods);

    return complexPeriodsArray as any[];
};
export const createMinifiedPeriods = (planningGridPeriodsData: any, isWeekly: boolean) => {

    let complexPeriods;
    if (isWeekly) { complexPeriods = createComplexPeriodDataWeekly(planningGridPeriodsData); } else { complexPeriods = createComplexPeriodDataMonthly(planningGridPeriodsData); }


    const newChartLabels: string[] = [];

    for (let i = 0; i < complexPeriods.length; i++) {
        const planningGridPeriodData = complexPeriods[i];
        for (let k = 0; k < planningGridPeriodData.periods.length; k++) {
            const period = planningGridPeriodData.periods[k];
            newChartLabels.push(`${period.monthName.slice(0, 3).toUpperCase()} ${period.startDay}`);
        }
    }
    return newChartLabels;
};
export const createComplexPeriodDataDaily = (periods: IPeriod[]) => {
    if (!periods) return [];
    const complexPeriods = {};
    for (let i = 0; i < periods.length; i++) {
        const groupId = periods[i].periodGroup;

        if (periods[i].startTime === periods[i].endTime) {
            complexPeriods[groupId].periods.push({
                monthName: Months[dayjs(periods[i].startTime).month()],
                startDay: dayjs(periods[i].startTime).date(),
                endDay: dayjs(periods[i].startTime).date()
            });
            continue;
        }
    }

    const complexPeriodsArray = Object.values(complexPeriods);

    return complexPeriodsArray as any[];
};
export const createMinifiedPeriodsFilters = (planningGridPeriodsData: any, isDaily: boolean) => {
    const newChartLabels: string[] = [];

    if (isDaily) {
        for (let i = 0; i < planningGridPeriodsData.length; i++) {
            const planningGridPeriodData = planningGridPeriodsData[i];
            for (let k = 0; k < planningGridPeriodData.periods.length; k++) {
                const period = planningGridPeriodData.periods[k];
                newChartLabels.push(`${period.startDay}`);
            }
        }
    }



    for (let i = 0; i < planningGridPeriodsData.length; i++) {
        const planningGridPeriodData = planningGridPeriodsData[i];
        for (let k = 0; k < planningGridPeriodData.periods.length; k++) {
            const period = planningGridPeriodData.periods[k];
            newChartLabels.push(`${period.monthName.slice(0, 3).toUpperCase()} ${period.startDay}`);
        }
    }
    return newChartLabels;
};