import { Trans } from '@lingui/macro';
import { TableCell, Button } from '@mui/material';
import React from 'react';
import { Icons, useColorModeContext } from '@nplan';
import { useNavigate } from 'react-router-dom';

interface IActionButtonsProps {
    table: Table;
    onEditTable: (event: React.MouseEvent<HTMLButtonElement>) => void;
    handleDeleteTable: (event: React.MouseEvent<HTMLButtonElement>) => void;
}
export const ActionButtons:React.FC<IActionButtonsProps> = ({
    table,
    onEditTable,
    handleDeleteTable,
}: IActionButtonsProps) => {
    const navigate = useNavigate();
    const { buttonVariant } = useColorModeContext();
    return (
        <React.Fragment>
            <TableCell>
                <Button
                    variant={buttonVariant}
                    startIcon={<Icons.Edit />}
                    data-id={table.id}
                    onClick={onEditTable}
                >
                    <Trans>Editar</Trans>
                </Button>
            </TableCell>
            <TableCell>
                <Button
                    variant={buttonVariant}
                    startIcon={<Icons.Table />}
                    data-id={table.id}
                    onClick={() => navigate(`${table.id}/data`)}
                >
                    <Trans>Dados</Trans>
                </Button>
            </TableCell>
            <TableCell>
                <Button
                    variant={buttonVariant}
                    startIcon={<Icons.Column />}
                    data-id={table.id}
                    onClick={() => navigate(`${table.id}/columns`)}
                >
                    <Trans>Colunas</Trans>
                </Button>
            </TableCell>
            <TableCell>
                <Button
                    variant={buttonVariant}
                    startIcon={<Icons.Button />}
                    data-id={table.id}
                    onClick={() => navigate(`${table.id}/buttons`)}
                >
                    <Trans>Botões</Trans>
                </Button>
            </TableCell>
            <TableCell>
                <Button
                    variant={buttonVariant}
                    startIcon={<Icons.Delete />}
                    data-id={table.id}
                    data-name={table.title}
                    onClick={handleDeleteTable}
                    color="error"
                >
                    <Trans>Excluir</Trans>
                </Button>
            </TableCell>
        </React.Fragment>
    );
};
