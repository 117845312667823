import { Box, Typography } from '@mui/material';

export const Footer = () => {
    return (
        <Box
            sx={{
                height: '50px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography whiteSpace={'nowrap'}>
                &copy; {new Date().getFullYear()} NEOOne
            </Typography>
        </Box>
    );
};
