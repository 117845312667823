import { t } from '@lingui/macro';
import { useDataGridContext } from '../../../providers/hooks/';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { deleteAsync, putAsync, useConfirmContext } from '@nplan';

export interface IRowUpdate {
    value: any;
    columnId: number;
    rowId: string;
}

export const useTableDataActions = () => {
    const confirm = useConfirmContext();
    
    const {
        editiorAll,
        allColumns,
        currentIdsFiltered,
        onEditiorAllChange,
        table,
    } = useDataGridContext();

    const handleBulkUpdateValue = useCallback(async (
        value: any, columnId: number
    ) => {
        const column = allColumns.find((f) => f.id === columnId);
        if (!column) {
            toast.error(t`Não foi possível identificar a coluna.`);
            return;
        }
        if(!column.editorAll) {
            toast.error(t`Não é permitido editar esta coluna em massa. Desligue a edição em massa primeiro e depois edite novamente.`);
            return;
        }
        const confirmed = await confirm({
            title: t`Confirmação`,
            description: t`Você tem certeza que deseja editar todos os dados? No total ${currentIdsFiltered.length} linhas serão afetadas.`,
        });
        if (!confirmed) {
            return;
        }
        const primaryKey = allColumns.find((f) => f.primaryKey);
        if (!primaryKey) {
            toast.error(
                t`Não foi possível identificar a chave primária da tabela. Por favor, entre em contato com o suporte.`,
            );
            return;
        }
        
        if ((value === null || value === 'null') && !column.enableNull) {
            toast.error(
                t`Não é possível salvar o valor NULL para esta coluna.`,
            );
            return;
        }
        await Promise.all(currentIdsFiltered.map(async (rowId) => {
            const dataToPut: IRowUpdate = {
                value: value,
                columnId: columnId,
                rowId: rowId,
            };
            await putAsync<IRowUpdate, string>('rows', dataToPut);
        }));
        onEditiorAllChange();
    }, [allColumns, confirm, currentIdsFiltered, onEditiorAllChange]);

    const handleUpdateValue = useCallback(async (
        value: any, columnId: number, row: any
    ) => {
        if(editiorAll) {
            handleBulkUpdateValue(value, columnId);
            return;
        }
        const dataToPut: IRowUpdate = {
            value: value,
            columnId: columnId,
            rowId: '',
        };
        const primaryKey = allColumns.find((f) => f.primaryKey);
        if (!primaryKey) {
            toast.error(
                t`Não foi possível identificar a chave primária da tabela. Por favor, entre em contato com o suporte.`,
            );
            return;
        }
        const column = allColumns.find((f) => f.id === columnId);
        if (!column) {
            toast.error(
                t`Não foi possível identificar a coluna. Por favor, entre em contato com o suporte.`,
            );
            return;
        }
        if ((value === null || value === 'null') && !column.enableNull) {
            toast.error(
                t`Não é possível salvar o valor NULL para esta coluna.`,
            );
            return;
        }
        dataToPut.rowId = row[primaryKey.field];
        await putAsync<IRowUpdate, string>('rows', dataToPut);

    }, [allColumns, editiorAll, handleBulkUpdateValue]);

    
    const handleDeleteRowAsync = useCallback(async (
        row: any,
    ) => {
        if(!table) return;
        const columnPK = allColumns.find((f) => f.primaryKey);
        if (!columnPK) {
            toast.error(t`Não foi possível identificar a chave primária da tabela.`);
            return;
        }
        const confirmed = await confirm({
            description: t`Você tem certeza que deseja deletar a linha?`,
        });
        if(!confirmed) return;
        const id = row[columnPK.field];
        if(!id) {
            toast.error(t`Não foi possível identificar a chave primária da linha.`);
            return;
        }	
        if (confirmed) {
            await deleteAsync(`rows/${id}/table/${table.id}`);
        }
    },[allColumns, confirm, table]);
    return { handleUpdateValue, handleDeleteRowAsync };
};