import { useState } from 'react';

export interface IUseSort {
    order: 'asc' | 'desc';
    orderBy: keyof Scenario;
    handleChangeOrder: () => void;
    handleChangeOrderBy: (property: keyof Scenario) => void;

}
export const useSort = (): IUseSort => {
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = useState<keyof Scenario>('id');
    const handleChangeOrder = () => {
        setOrder(order === 'asc' ? 'desc' : 'asc');
    };
    const handleChangeOrderBy = (property: keyof Scenario) => {
        setOrderBy(property);
        setOrder('desc');
    };
    return { order, orderBy, handleChangeOrder, handleChangeOrderBy };
};

