
import { useAppDispatch, useAppSelector } from "redux/hooks";
import React, { useMemo } from "react";
import DropdownTreeSelect, { TreeData, TreeNode } from "react-dropdown-tree-select";
import { setSelectedFilters, updateFixedCriteriaOptions } from "redux/Filters/reducer";
import { t } from "@lingui/macro";

export function FixedSelectorDropDownContainer() {
    const fixedFilters = useAppSelector(state => state.filter.planningGrid.fixedFilterOptions)
    const fixedFiltersMemo = useMemo(() => { return fixedFilters }, [fixedFilters])
    const dispatch = useAppDispatch()

    const localTreeData: TreeData = useMemo(() => {
        return fixedFiltersMemo.map((filter) => {
            return {
                label: filter.label,
                filterCriteria: filter.filterCriteria,
                value: filter.placeholder,
                checked: filter.checked
            }
        });
    }, [fixedFiltersMemo])
    const onFixedChangeSearch = (currentNode: any, selectedNodes: TreeNode[]) => {
        dispatch(updateFixedCriteriaOptions({ selectedFixedCriteria: currentNode }));
        dispatch(setSelectedFilters());
    };
    return (
        <>
            <DropdownTreeSelect
                inlineSearchInput={true}
                className="hide-selected-text dropdown-select dropdown-tree"
                texts={{ placeholder: t`Filtros` }}
                showDropdown={'default'}
                data={localTreeData}
                keepTreeOnSearch={true}
                keepChildrenOnSearch={true}
                onChange={onFixedChangeSearch}
            />
        </>
    )
}

export const FixedSelectorDropDownContainerMemoized = React.memo(FixedSelectorDropDownContainer)
