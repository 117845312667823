import { Trans, t } from '@lingui/macro';
import { Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import React, { memo, useMemo, useState } from 'react';
import 'react-dropdown-tree-select/dist/styles.css';
import { resetFilters } from 'redux/Filters/reducer';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { TooltipSimplesComponent } from '../../../../../components/TippyComponent';
import { AttributesDropDownContainerMemoized } from './containers/AttributesDropDownContainer';
import { AttributesSelectorDropDownContainerMemoized } from './containers/AttributesSelectorDropDownContainer';
import { FixedCriteriasDropDownContainerMemoized } from './containers/FixedCriteriasDropDownContainer';
import { FixedSelectorDropDownContainerMemoized } from './containers/FixedSelectorDropDownContainer';
import { PlanningGridRowSelectionDropdownMenuMemoized } from './containers/PlanningRowOptionsTreeSelector';
import { useVariant,Icons } from '@nplan';


export default function FilterDrawer() {
    const variant = useVariant();
    const redrawFilters = useAppSelector(state => state.filter.planningGrid.redrawFilters);
    const searchCriteriaData = useAppSelector(state => state.filter.planningGrid.filterData.searchCriteriasData);

    const searchCriteriaDataMemo = useMemo(() => {
        let isFiltered = false;
        for (const value of Object.values(searchCriteriaData)) {
            if (value === undefined) continue;
            if (value.length > 0) {
                isFiltered = true;
            }
        }
        return isFiltered;
    }, [searchCriteriaData]);

    const dispatch = useAppDispatch();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const toggleDrawer = (open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent
    ) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setOpen(open);
    };

    return (
        <Box >
            <Box
                sx={{ ':hover': { cursor: 'pointer', filter: 'brightness(0.8)' }, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
                onClick={toggleDrawer(true)}
            >
                <TooltipSimplesComponent tooltipText={t`Filtros`}>
                    <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Box
                            sx={{
                                display: searchCriteriaDataMemo ? 'block' : 'none',
                                position: 'absolute',
                                top: -5, right: -5,
                                background: 'red',
                                borderRadius: '50%', width: '12px', height: '12px'
                            }}>

                        </Box>
                        <Icons.Filter size={20} color={theme.palette.primary.main} />
                    </Box>

                </TooltipSimplesComponent>

            </Box>
            <SwipeableDrawer
                anchor='right'
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                key={redrawFilters}
            >
                <Box sx={{ display: 'flex', overflow: 'auto', flexDirection: 'column', padding: '1rem', width: '500px', height: '100%', maxHeight: '100%', background: theme.palette.grey[300] }}>
                    <Typography variant='h4'><Trans>Filtros</Trans></Typography>

                    <Button variant={variant} sx={{ width: '50%', margin: '0 auto' }} onClick={() => dispatch(resetFilters())}><Trans><Typography variant='button'>Remover Filtros</Typography></Trans></Button>
                    <Box sx={{ padding: '1rem', display: 'flex', gap: '2rem', justifyContent: 'center' }}>
                        <PlanningGridRowSelectionDropdownMenuMemoized />
                        <FixedSelectorDropDownContainerMemoized />
                        <AttributesSelectorDropDownContainerMemoized />
                    </Box>
                    <FixedCriteriasDropDownContainerMemoized />
                    <AttributesDropDownContainerMemoized />
                </Box>
            </SwipeableDrawer>
        </Box>
    );
}


export const PlanningGridPanelMemoized = memo(FilterDrawer);