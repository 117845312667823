import { t } from '@lingui/macro';
import { FixedFilterData } from 'pages/planning-grid/types/PlanningGrid';
import { TreeNodeProps } from 'react-dropdown-tree-select';
import { FiltersInterface } from 'redux/Filters/types';
import { Dictionary } from 'types/Dictionary';
import { IItemAttributes } from 'types/data/IItemAttributes';
import { IPlanner } from 'types/data/IPlanner';
import { IPeriod } from 'types/scenarios/IPeriod';
import { FilterCriteria, StockStatusDictionary } from './types';
import { ICompany } from 'types/data/ICompany';

export const updateAllItemIdsByStockStatus = (reduxState: FiltersInterface): Record<string, number[]> => {
    const stockStatusArray = reduxState.global.stockStatus;
    const planningGrids = reduxState.planningGrid.selectedPlanningGrid;
    const stockStatusById: Record<number, string> = {};
    const localItemIdsByStockStatus: Record<string, Set<number>> = {};
    for (const status of stockStatusArray) {
        localItemIdsByStockStatus[status.name!] = new Set();
    }

    for (const status of stockStatusArray) {
        stockStatusById[status.id] = status.name!;
    }

    const result: Record<string, number[]> = {};

    for (const [keyItemId, planningGridsData] of Object.entries(planningGrids)) {
        const itemId = Number(keyItemId);
        for (let i = 0; i < planningGridsData.length; i++) {
            const stockStatusId = planningGridsData[i].closingStockStatusId;
            // Check if the stockStatusId is valid and exists in stockStatusById
            if (Object.prototype.hasOwnProperty.call(stockStatusById, stockStatusId as number)) {
                const stockStatus = (stockStatusById as any)[stockStatusId as number];
                // Initialize the array if it doesn't exist in the result object
                if (!Object.prototype.hasOwnProperty.call(result, stockStatus)) {
                    result[stockStatus] = [];
                }
                // Add itemId to the corresponding stockStatus array
                if (!localItemIdsByStockStatus[stockStatus].has(itemId)) {
                    localItemIdsByStockStatus[stockStatus].add(itemId);
                    result[stockStatus].push(itemId);
                }
            }
        }
    }
    return result;
};

export const updateAllItemIdsByStockStatusByPeriod = (reduxState: FiltersInterface): Record<string, Record<number, number[]>> => {
    const stockStatusArray = reduxState.global.stockStatus;
    const planningGrids = reduxState.planningGrid.selectedPlanningGrid;

    const localItemIdsByStockStatus: Record<string, Record<number, Set<number>>> = {};
    const returnType: Record<string, Record<number, number[]>> = {};

    // Initialize localItemIdsByStockStatus and returnType based on stockStatusArray
    stockStatusArray.forEach((status) => {
        const name = status.name as keyof StockStatusDictionary<Dictionary<Set<number>>>;
        localItemIdsByStockStatus[name] = {};
        returnType[name] = {};
    });

    for (const [keyItemId, planningGridsData] of Object.entries(planningGrids)) {
        const itemId = Number(keyItemId);

        for (let i = 0; i < planningGridsData.length; i++) {
            const localPlanningGrid = planningGridsData[i];
            const stockStatus = stockStatusArray.find(status => status.id === localPlanningGrid.closingStockStatusId);

            if (stockStatus) {
                const name = stockStatus.name as keyof StockStatusDictionary<Dictionary<Set<number>>>;

                if (!localItemIdsByStockStatus[name][localPlanningGrid.periodId]) {
                    localItemIdsByStockStatus[name][localPlanningGrid.periodId] = new Set();
                }

                if (!localItemIdsByStockStatus[name][localPlanningGrid.periodId].has(itemId)) {
                    localItemIdsByStockStatus[name][localPlanningGrid.periodId].add(itemId);
                }
            }
        }
    }

    // Convert localItemIdsByStockStatus to returnType
    stockStatusArray.forEach((status) => {
        const name = status.name as keyof StockStatusDictionary<Dictionary<Set<number>>>;
        const periodIdMap = localItemIdsByStockStatus[name];

        for (const [periodId, itemIdsSet] of Object.entries(periodIdMap)) {
            returnType[name][Number(periodId)] = Array.from(itemIdsSet.values());
        }
    });
    return returnType;
};

export const createItemPlannerSearchTree = (
    itemIdsByItemPlannerIds: Dictionary<number[]>,
    itemPlannerById: Dictionary<IPlanner>,
): TreeNodeProps => {

    const attributeTree: TreeNodeProps[] = [];
    let totalItems = 0;

    for (const [itemPlannerId, itemIds] of Object.entries(itemIdsByItemPlannerIds)) {
        if (itemIdsByItemPlannerIds[Number(itemPlannerId)] !== undefined) {
            totalItems += itemIds.length;
            attributeTree.push({
                label: `${itemPlannerById[Number(itemPlannerId)].name} (${itemIds.length})`,
                value: `${itemPlannerById[Number(itemPlannerId)].id}`,
            });
        }
    }
    return {
        label: t({ message: `Todos (${totalItems})` }),
        value: 'All',
        children: attributeTree
    };
};

export const createCompanySearchTree = (
    itemIdsByCompanyId: Dictionary<number[]>,
    itemCompanyById: Dictionary<ICompany>,
): TreeNodeProps => {
    const attributeTree: TreeNodeProps[] = [];
    let totalItems = 0;

    for (const [itemCompanyId, itemIds] of Object.entries(itemIdsByCompanyId)) {
        if (itemIdsByCompanyId[Number(itemCompanyId)] !== undefined) {
            totalItems += itemIds.length;
            attributeTree.push({
                label: `${itemCompanyById[Number(itemCompanyId)].name} (${itemIds.length})`,
                value: `${itemCompanyById[Number(itemCompanyId)].id}`,
            });
        }
    }
    return {
        label: t({ message: `Todos (${totalItems})` }),
        value: 'All',
        children: attributeTree
    };
};

export const createItemComplexitySearchTree = (
    itemIdsByItemComplexityIds: Dictionary<number[]>,
    itemComplexityById: Dictionary<any>,
): TreeNodeProps => {
    const attributeTree: TreeNodeProps[] = [];
    let totalItems = 0;

    for (const [itemComplexityId, itemIds] of Object.entries(itemIdsByItemComplexityIds)) {
        if (itemIdsByItemComplexityIds[Number(itemComplexityId)] !== undefined) {
            totalItems += itemIds.length;
            attributeTree.push({
                label: `${itemComplexityById[Number(itemComplexityId)].name} (${itemIds.length})`,
                value: `${itemComplexityById[Number(itemComplexityId)].id}`,
            });
        }
    }
    return {
        label: t({ message: `Todos (${totalItems})` }),
        value: 'All',
        children: attributeTree
    };
};

export const createItemVolumeSearchTree = (
    itemIdsByItemVolumeIds: Dictionary<number[]>,
    itemVolumeById: Dictionary<any>,
): TreeNodeProps => {
    const attributeTree: TreeNodeProps[] = [];
    let totalItems = 0;

    for (const [itemComplexityId, itemIds] of Object.entries(itemIdsByItemVolumeIds)) {
        if (itemIdsByItemVolumeIds[Number(itemComplexityId)] !== undefined) {
            totalItems += itemIds.length;
            attributeTree.push({
                label: `${itemVolumeById[Number(itemComplexityId)].name} (${itemIds.length})`,
                value: `${itemVolumeById[Number(itemComplexityId)].id}`,
            });
        }
    }
    return {
        label: t({ message: `Todos (${totalItems})` }),
        value: 'All',
        children: attributeTree
    };
};
export const createItemUncertaintySearchTree = (
    itemIdsByItemUncertaintyIds: Dictionary<number[]>,
    itemUncertaintyById: Dictionary<any>,
): TreeNodeProps => {
    const attributeTree: TreeNodeProps[] = [];
    let totalItems = 0;

    for (const [itemComplexityId, itemIds] of Object.entries(itemIdsByItemUncertaintyIds)) {
        if (itemIdsByItemUncertaintyIds[Number(itemComplexityId)] !== undefined) {
            totalItems += itemIds.length;
            attributeTree.push({
                label: `${itemUncertaintyById[Number(itemComplexityId)].name} (${itemIds.length})`,
                value: `${itemUncertaintyById[Number(itemComplexityId)].id}`,
            });
        }
    }
    return {
        label: t({ message: `Todos (${totalItems})` }),
        value: 'All',
        children: attributeTree
    };
};

export const createItemStockGroupSearchTree = (
    itemIdsByStockGroupIds: Dictionary<number[]>,
    itemStockGroupById: Dictionary<IStockGroup>,
): TreeNodeProps => {
    const attributeTree: TreeNodeProps[] = [];
    let totalItems = 0;
    for (const [itemstockGroupId, itemIds] of Object.entries(itemIdsByStockGroupIds)) {
        if (itemIdsByStockGroupIds[Number(itemstockGroupId)] !== undefined) {
            totalItems += itemIds.length;
            attributeTree.push({
                label: `${itemStockGroupById[Number(itemstockGroupId)].name} (${itemIds.length})`,
                value: `${itemStockGroupById[Number(itemstockGroupId)].id}`,
            });
        }
    }
    return {
        label: t({ message: `Todos (${totalItems})` }),
        value: 'All',
        children: attributeTree
    };
};
export const createItemLevelSearchTree = (itemIdsByLevel: Dictionary<number[]>): TreeNodeProps => {

    const itemsTree: TreeNodeProps[] = [];

    for (const [level, itemIds] of Object.entries(itemIdsByLevel)) {
        itemsTree.push({
            label: `${level} (${itemIds.length})`,
            value: `${level}`,
        });
    }
    return {
        label: t({ message: 'Todos' }),
        value: 'All',
        children: itemsTree
    };
};

export const createSimpleAttributesTree = (attribute: Dictionary<IItemAttributes>, itemIdsByAttributeId: Dictionary<number[]>): TreeNodeProps => {
    const attributeTree: TreeNodeProps[] = [];
    let totalItems = 0;
    for (const [attributeId, itemIds] of Object.entries(itemIdsByAttributeId)) {
        totalItems += itemIds.length;
        attributeTree.push({
            label: `${attribute[Number(attributeId)].name} (${itemIds.length})`,
            value: `${attributeId}`,

        });
    }
    return {
        label: t({ message: `Todos (${totalItems})` }),
        value: 'All',
        children: attributeTree
    };
};


export const createStockStatusTypeSearchTree = (itemIdsByStockStatus: Record<string, number[]>): TreeNodeProps => {
    const itemsTree: TreeNodeProps[] = [];


    for (const [stockStatusName, itemIds] of Object.entries(itemIdsByStockStatus)) {
        itemsTree.push({
            label: `${stockStatusName} (${itemIds.length})`,
            value: `${stockStatusName}`,
        });
    }

    return {
        label: t({ message: 'Todos' }),
        value: 'All',
        children: itemsTree
    };
};
export const createStockStatusWithPeriodTypeSearchTree = (
    periodsIdToIndex: Dictionary<number>,
    itemIdsByStockStatusByPeriod: Record<string, Record<number, number[]>>,
    minifiedPeriods: string[],
    periods: IPeriod[]
): TreeNodeProps => {

    const itemsTree: TreeNodeProps[] = [];
    for (const [stockStatusName, itemIdsByPeriodId] of Object.entries(itemIdsByStockStatusByPeriod)) {
        const uniqueItems = new Set<number>();
        const localItemsTree: TreeNodeProps = {
            label: '',
            value: `${stockStatusName}`
        };

        localItemsTree.children = [];
        // let counter = 0
        for (let i = 0; i < periods.length; i++) {
            const periodId = periods[i].id;
            const periodIndex = periodsIdToIndex[periodId];
            const itemIds = itemIdsByPeriodId[periodId];

            if (itemIds) {
                localItemsTree.children.push({
                    label: `${minifiedPeriods[periodIndex]} (${itemIds.length})`,
                    value: `${stockStatusName}|${periodId}`
                });
                for (let a = 0; a < itemIds.length; a++) {
                    const itemId = itemIds[a];
                    if (!uniqueItems.has(itemId)) {
                        uniqueItems.add(itemId);
                    }
                }
            } else {
                localItemsTree.children.push({
                    label: `${minifiedPeriods[periodIndex]} (0)`,
                    value: '',
                    disabled: true
                });
            }
        }
        localItemsTree.label = `${stockStatusName} (${uniqueItems.size})`;
        itemsTree.push(localItemsTree);
    }

    return {
        label: t({ message: 'Todos' }),
        value: 'All',
        children: itemsTree
    };
};
export const createItemCodeSearchTree = (itemById: Dictionary<any>): TreeNodeProps => {
    const itemsTree: TreeNodeProps[] = Object.keys(itemById).map(itemId => ({
        label: itemById[itemId].code as string,
        value: `${itemById[itemId].id}`
    }));

    return {
        label: t({ message: 'Todos' }),
        value: 'All',
        children: itemsTree
    };
};
export const createSelectedFixedCriteriaFilters = (selectedFixedCriteriaOptions: FixedFilterData[], FixedCriteriaFilterOptions: FixedFilterData[]): FixedFilterData[] => {
    if (selectedFixedCriteriaOptions.length === 0) {
        const selectedFixedCriteriaFiltered: FixedFilterData[] = FixedCriteriaFilterOptions.map((value, index) => {
            return {
                filterId: value.filterId,
                filterCriteria: FilterCriteria[value.filterCriteria],
                label: value.label,
                placeholder: value.placeholder,
                checked: true
            };
        }).filter((c) => c !== undefined);
        return selectedFixedCriteriaFiltered;
    }
    if (selectedFixedCriteriaOptions[0].filterCriteria === 'None') {
        return [];
    } else {
        const selectedFixedCriteriaFiltered: FixedFilterData[] = selectedFixedCriteriaOptions.map((value, index) => {
            return {
                filterId: value.filterId,
                filterCriteria: FilterCriteria[value.filterCriteria],
                label: value.label,
                checked: value.checked,
                placeholder: value.placeholder
            };
        }).filter((c) => c !== undefined);
        return selectedFixedCriteriaFiltered;
    }

};
export const createSelectedAttributeFilters = (selectedAttributes: any, attributeList: any) => {
    if (selectedAttributes.length === 0) {
        const selectedAttributesFiltered = attributeList.map((value: any) => {
            return {
                code: value.code,
                name: value.name,
            };
        }).filter((c: any) => c !== undefined);
        return selectedAttributesFiltered;
    }
    if (selectedAttributes[0].code === 'none') {
        return [];
    } else {
        const selectedAttributesFiltered = selectedAttributes.map((value: any) => {
            return {
                code: value.code,
                name: value.name,
            };
        }).filter((c: any) => c !== undefined);
        return selectedAttributesFiltered;
    }
};

