import { Box, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { Icons } from 'utils/icons';
import { useDataContext } from 'providers';
import { t } from '@lingui/macro';

interface IContextMenuProps {
    onClose: () => void;
    position: MenuPosition | null;
    onAddTable: (event: React.MouseEvent<HTMLLIElement>) => void;
}

export const ContextMenu: React.FC<IContextMenuProps> = ({
    onClose,
    position,
    onAddTable,
}: IContextMenuProps): JSX.Element => {
    const { onRefreshTablesAsync } = useDataContext();

    const handleRefreshData = () => {
        onRefreshTablesAsync();
        onClose();
    };

    const handleAddTable = (event: React.MouseEvent<HTMLLIElement>) => {
        onAddTable(event);
        onClose();
    };

    return (
        <Menu
            anchorReference='anchorPosition'
            anchorPosition={
                position ? { top: position.y, left: position.x } : undefined
            }
            open={Boolean(position)}
            onClose={onClose}
        >
            <Box>
                <MenuItem onClick={handleRefreshData}>
                    <ListItemIcon>
                        <Icons.Refresh size={20} />
                    </ListItemIcon>
                    {t`Atualizar dados`}
                </MenuItem>
                <MenuItem onClick={handleAddTable} data-id='0'>
                    <ListItemIcon>
                        <Icons.AddTable size={20} />
                    </ListItemIcon>
                    {t`Adicionar Tabela`}
                </MenuItem>
            </Box>
        </Menu>
    );
};
