import { Box, Switch } from '@mui/material';
import { getJustifyContent } from 'pages/table-data/utils';
import { memo } from 'react';
/**
 * Interface para as props do componente BooleanRender
 * 
 * @param {any} row - A linha atual da tabela, contendo os dados dessa linha.
 * @param {Column} column - Informações sobre a coluna atual, como título, tipo e se usa chave estrangeira.
 */
interface BooleanRenderProps {
    row: any;
    column: Column;
}

/**
 * Componente BooleanRender para renderizar valores de células como um Switch booleano.
 *
 * @component
 *
 * @param {BooleanRenderProps} props - Props para configurar o componente.
 * @param {any} props.row - A linha atual da tabela, contendo os dados para essa linha.
 * @param {Column} props.column - Informações sobre a coluna atual, como título e tipo.
 *
 * @returns {JSX.Element} O elemento JSX para renderizar a célula.
 */
const BooleanRender: React.FC<BooleanRenderProps> = ({ row, column }) => {
    // Obtém o valor atual da célula
    const getValue = (): boolean => {
        const value = row[column.field];
        if (value === null || value === undefined) return false;
        return Boolean(value);
    };
    return (
        <Box
            sx={ {
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: getJustifyContent(column.alignId),
                alignItems: 'center'
            } }
        >
            {/* Renderiza um Switch para controlar o valor booleano */ }
            <Switch
                checked={ getValue() }
                disabled={ true }  // Desabilitado para somente leitura
            />
        </Box>
    );
};
const MemoizedBooleanRender = memo(BooleanRender);
export default MemoizedBooleanRender;