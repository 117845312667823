import { SelectChangeEvent } from '@mui/material';
import { MouseEvent, createContext } from 'react';


export interface IDataContextProps {

    //Scenario
    scenarios: Scenario[];
    scenario: Scenario;
    scenarioId: number;
    loadingScenarios: boolean;
    items: Item[];
    onChangeScenario: (event: SelectChangeEvent<number> | number) => void;
    onRefreshScenariosAsync: () => Promise<void>;
    
    //Notification
    notifications: AppNotification[];
    totalUnreaded: number;
    onRefreshNotificationsAsync: () => Promise<void>;
    onLoadMoreNotificationsAsync: () => Promise<void>;
    onMarkAsReadAsync: (event: MouseEvent<HTMLButtonElement>,) => Promise<void>;
    onMarkAllAsReadAsync: () => Promise<void>;

    //Scheduler
    executions: Execution[];
    onRefreshProceduresAsync: () => Promise<void>;
    onCancelProcedureAsync: (
        event: MouseEvent<HTMLButtonElement>,
    ) => Promise<void>;

    //Reports
    reports: NReport[];
    reportTypes: ReportType[];
    onRefreshReportsAsync: () => Promise<void>;

    //Table
    tables: Table[];
    groups: Group[];
    loadingTables: boolean;
    onRefreshTablesAsync: () => Promise<void>;

    stockStatus: StockStatus[];

    //MENUS
    hasMenuAccess: (menuName: string) => boolean;
}

export const DataContext = createContext<IDataContextProps | null>(null);
