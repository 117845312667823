import {
    Dialog,
    DialogContent,
    DialogTitle,
    Box,
    TextField,
    FormControlLabel,
    Switch,
    Button,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
} from '@mui/material';
import { Trans } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { useManageData } from './hooks/useManageData';
import { useDataGridContext } from '../../providers/hooks';
import { isNullOrEmpty, tryParseDateToISO, tryParseDateTimeToISO, useVariant } from '@nplan';
import { useLingui } from '@lingui/react';


interface IDialog {
    open: boolean;
    onClose: () => void;
    dataId: any;
}

export const ManageData: React.FC<IDialog> = ({
    open,
    onClose,
    dataId,
}): JSX.Element => {
    const {
        data,
        loading,
        editing,
        handleInputChange,
        handleSwitchChange,
        handleSelectChange,
        handleSaveAsync,
    } = useManageData(dataId, onClose, open);
    const variant = useVariant();
    const { i18n } = useLingui();
    const { 
        allColumns, 
        foreignFields
    } = useDataGridContext();
    if (!open || !allColumns) return <></>;
    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="xs"
            sx={{
                borderRadius: 10,
            }}
        >
            <DialogTitle textAlign={'center'}>
                {editing ? i18n.t('Editar') : i18n.t('Adicionar')}
            </DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        padding: 2,
                        overflow: 'auto',
                        minHeight: 500,
                    }}
                >
                    {allColumns
                        .filter((f) => !f.primaryKey && !f.userColumn)
                        .map((column: Column) => {
                            if (column.foreignKey) {
                                return (
                                    <FormControl
                                        variant="standard"
                                        key={column.id}
                                        title={column.tooltip}
                                    >
                                        <InputLabel id={`${column.field}-select`} title={i18n.t(column.tooltip)}>
                                            {i18n.t(column.header)}
                                        </InputLabel>
                                        <Select
                                            fullWidth
                                            variant="standard"
                                            value={data[column.field] ?? ''}
                                            onChange={handleSelectChange}
                                            name={column.field}
                                            labelId={`${column.field}-select`}
                                            disabled={!column.editable && editing}
                                            title={column.tooltip}
                                            
                                        >
                                            {column.enableNull && (
                                                <MenuItem value="null">
                                                    {isNullOrEmpty(
                                                        column.nullText,
                                                    )
                                                        ? 'NULL'
                                                        : column.nullText}
                                                </MenuItem>
                                            )}
                                            {foreignFields && foreignFields[column.id] && Object
                                                ?.entries(foreignFields[column.id])
                                                ?.sort((a, b) => a[1].localeCompare(b[1]))
                                                ?.map(([key, value]) => (
                                                    <MenuItem key={key} value={key}>
                                                        {value}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                );
                            } else {
                                switch (column.viewTypeId) {
                                    case 1:
                                        return (
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={data[column.field] ?? false}
                                                        onChange={handleSwitchChange}
                                                        name={column.field}
                                                        disabled={
                                                            !column.editable &&
                                                            editing
                                                        }
                                                        title={i18n.t(column.tooltip)}
                                                    />
                                                }
                                                title={i18n.t(column.tooltip)}
                                                label={i18n.t(column.header)}
                                                key={column.id}
                                            />
                                        );
                                    case 3:
                                        return (
                                            <FormControl
                                                variant="standard"
                                                key={column.id}
                                                title={i18n.t(column.tooltip)}
                                            >
                                                <TextField
                                                    label={i18n.t(column.header)}
                                                    variant="standard"
                                                    fullWidth
                                                    onChange={handleInputChange}
                                                    value={tryParseDateToISO(
                                                        data[column.field],
                                                    )}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    name={column.field}
                                                    type={'date'}
                                                    disabled={
                                                        !column.editable &&
                                                        editing
                                                    }
                                                />
                                            </FormControl>
                                        );
                                    case 4:
                                        return (
                                            <FormControl
                                                variant="standard"
                                                key={column.id}
                                                title={i18n.t(column.tooltip)}
                                            >
                                                <TextField
                                                    label={i18n.t(column.header)}
                                                    variant="standard"
                                                    fullWidth
                                                    onChange={handleInputChange}
                                                    value={tryParseDateTimeToISO(data[column.field]) ?? ''}
                                                    name={column.field}
                                                    type={'datetime-local'}
                                                    placeholder='yyyy-MM-ddTHH:mm'
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        step: '1',
                                                    }}
                                                    disabled={
                                                        !column.editable &&
                                                        editing
                                                    }
                                                />
                                            </FormControl>
                                        );
                                    case 5 || 6:
                                        return (
                                            <FormControl
                                                variant="standard"
                                                key={column.id}
                                                title={i18n.t(column.tooltip)}
                                            >
                                                <TextField
                                                    label={i18n.t(column.header)}
                                                    variant="standard"
                                                    fullWidth
                                                    onChange={handleInputChange}
                                                    value={
                                                        data[column.field] ?? ''
                                                    }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    name={column.field}
                                                    type={'number'}
                                                    disabled={
                                                        !column.editable &&
                                                        editing
                                                    }
                                                />
                                            </FormControl>
                                        );
                                    default:
                                        return (
                                            <FormControl
                                                variant="standard"
                                                key={column.id}
                                                title={i18n.t(column.tooltip)}
                                            >
                                                <TextField
                                                    label={i18n.t(column.header)}
                                                    variant="standard"
                                                    fullWidth
                                                    onChange={handleInputChange}
                                                    value={data[column.field] ?? ''}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled={
                                                        !column.editable &&
                                                        editing
                                                    }
                                                    name={column.field}
                                                    type={
                                                        column.viewTypeId === 2
                                                            ? 'color'
                                                            : column.viewTypeId === 7
                                                                ? 'number'
                                                                : 'text'
                                                    }
                                                />
                                            </FormControl>
                                        );
                                }
                            }
                        })}
                </Box>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={2}
                    padding={2}
                >
                    <LoadingButton
                        variant={variant}
                        onClick={handleSaveAsync}
                        loading={loading}
                    >
                        <Trans>Salvar</Trans>
                    </LoadingButton>
                    <Button variant={variant} color="error" onClick={onClose}>
                        <Trans>Cancelar</Trans>
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
