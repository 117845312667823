import { textFilter } from './textFilter';
import { numberFilter } from './numberFilter';
import { booleanFilter } from './booleanFilter';
import { selectFilter } from './selectFilter';
import { dateFilter } from './dateFilter';

export const applyFilter = (
    row: any,
    column: Column,
) => {
    switch (column.filterTypeId) {
        case 1:
            return booleanFilter(row, column);
        case 2:
            return dateFilter(row, column);
        case 3:
            return numberFilter(row, column);
        case 4:
            return selectFilter(row, column);
        case 5:
            return textFilter(row, column);
        default:
            return true;
    }
};
