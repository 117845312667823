import {
    Dialog,
    DialogContent,
    DialogTitle,
    Box,
    TextField,
    Button,
    Select,
    MenuItem,
    InputLabel,
} from '@mui/material';
import { Trans, t } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { useManageReportData } from './hooks/';
import { useDataContext } from 'providers';

export interface IManageReportProps {
    open: boolean;
    reportId: number;
    onClose: () => void;
}

export const ManageReport: React.FC<IManageReportProps> = ({ 
    open, 
    reportId,
    onClose,
}): JSX.Element => {
    const { 
        report,
        loading,
        handleInputChange, 
        handleSelectChange, 
        handleSaveAsync, 
    } = useManageReportData(
        reportId,
        onClose,
        open
    );
    const { reportTypes } = useDataContext();
    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth='xs'
        >
            <DialogTitle textAlign={'center'}>
                {report.id === 0 
                    ? <Trans>Adicionar Relatório</Trans> 
                    : <Trans>Editar Relatório</Trans>}
            </DialogTitle>
            <DialogContent>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={2}
                >
                    <TextField
                        value={report.name}
                        variant='standard'
                        label={t`Título`}
                        name='name'
                        onChange={handleInputChange}
                        disabled={loading}
                    />
                    <TextField
                        value={report.description}
                        variant='standard'
                        multiline={true}
                        label={t`Descrição`}
                        name='description'
                        onChange={handleInputChange}
                        disabled={loading}
                    />
                    <Box sx={{ width: '100%' }}>
                        <InputLabel
                            id='table-group-label'
                            sx={{ fontSize: 12, userSelect: 'none' }}
                        >
                            <Trans>Tipo</Trans>
                        </InputLabel>
                        <Select
                            value={report.typeId}
                            labelId='table-group-label'
                            variant='standard'
                            name='typeId'
                            fullWidth
                            onChange={handleSelectChange}
                            disabled={loading}
                        >
                            {reportTypes.map((type) => (
                                <MenuItem
                                    key={type.id}
                                    value={type.id}
                                >
                                    {type.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <TextField
                        value={report.url}
                        variant='standard'
                        label={t`URL`}
                        name='url'
                        onChange={handleInputChange}
                        disabled={loading}
                    />

                    <TextField
                        value={report.order}
                        type='number'
                        variant='standard'
                        label={t`Ordem`}
                        name='order'
                        onChange={handleInputChange}
                        disabled={loading}
                    />
                    <LoadingButton
                        variant='contained'
                        onClick={handleSaveAsync}
                        loading={loading}
                    >
                        <Trans>Salvar</Trans>
                    </LoadingButton>
                    <Button
                        variant='contained'
                        color='error'
                        onClick={() => onClose()}
                    >
                        <Trans>Cancelar</Trans>
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
