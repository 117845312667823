import { Box } from '@mui/material';
import React from 'react';

interface IMainProps {
    children: JSX.Element;
}

const Main: React.FC<IMainProps> = ({
    children,
}): JSX.Element => {
    return (
        <Box
            sx={{
                height: 'calc(100vh - 40px)',
                width: '100%',
                overflow: 'auto',
            }}
        >
            {children}
        </Box>
    );
};

const MemoizedMain = React.memo(Main);
export { MemoizedMain as Main };
