import { Box, TableContainer, Table, TableBody, TableRow, TableCell, Skeleton } from "@mui/material";

export const HealthSkeleton = () => {
  const skeletonArray = [];

  for (let i = 0; i <= 6; i += 1) {
    const randomPercentage = Math.floor(Math.random() * 151) + 400; // Generates a random value between 200 and 350
    skeletonArray.push(`${randomPercentage}px`);
  }
  return (
    <Box
      sx={{
        marginTop: '2rem',
        width: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'center'
      }}
    >
      <TableContainer sx={{ width: '50%', height: '350px', overflow: 'hidden' }}>
        <Table>
          <TableBody>
            {[...Array(6)].map((_, rowIndex) => (
              <TableRow key={rowIndex}>
                {[...Array(6)].map((_, colIndex) => (
                  <TableCell key={colIndex}>
                    <Skeleton variant="text" width="100%" />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          width: '90%',
          height: '400px',
          overflow: 'hidden',
          display: 'flex',
          gap: '1.5rem',
          alignItems: 'end',
        }}
      >
        {skeletonArray.map((height, index) => (
          <Skeleton
            animation="pulse"
            key={index}
            sx={{
              minWidth: '100px',
              height: { height },
              marginBottom: '-10rem',
            }}
          />
        ))}
      </Box>
    </Box>
  )
}