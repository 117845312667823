import React, { KeyboardEvent, useCallback, useEffect, useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
} from '@mui/material';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useVariant } from '@nplan';

export interface IConfirmDialog {
    open: boolean;
    title: string;
    description: React.ReactNode;
    onClose: (value: boolean) => void;
}

const ConfirmDialog: React.FC<IConfirmDialog> = ({
    open,
    title,
    description,
    onClose,
}) => {
    const [disabledButton, setDisabledButton] = React.useState(true);
    const [buttonText, setButtonText] = useState<string>('Confirmar');
    const { i18n } = useLingui();
    const variant = useVariant();
    const handleConfirm = useCallback(() => onClose(true), [onClose]);

    const handleCancel = useCallback(() => onClose(false), [onClose]);

    useEffect(() => {
        let timeout1: ReturnType<typeof setTimeout>;
        let timeout2: ReturnType<typeof setTimeout>;

        if (open) {
            setButtonText('2');
            timeout1 = setTimeout(() => {
                setButtonText('1');
            }, 1000);

            timeout2 = setTimeout(() => {
                setDisabledButton(false);
                setButtonText('Confirmar');
            }, 2000);
        } else {
            setDisabledButton(true);
        }

        return () => {
            clearTimeout(timeout1);
            clearTimeout(timeout2);
            if (!open) setDisabledButton(true);
        };
    }, [open]);
    const handleKeyDown = useCallback(
        (event: KeyboardEvent<HTMLDivElement>) => {
            if (event.key === 'Escape') {
                handleCancel();
            } else if (event.key === 'Enter') {
                handleConfirm();
            }
        },
        [handleCancel, handleConfirm],
    );
    return (
        <Dialog open={open} onClose={handleCancel} onKeyDown={handleKeyDown}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{description}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleCancel}
                    color="error"
                    variant={variant}
                >
                    <Trans>Cancelar</Trans>
                </Button>
                <Button
                    onClick={handleConfirm}
                    color="primary"
                    autoFocus
                    variant={variant}
                    disabled={disabledButton}
                    sx={{ width: 130 }}
                >
                    {i18n.t(buttonText)}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
