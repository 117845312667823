import React, { useCallback, useEffect, useMemo } from 'react';
import {
    Box,
    ClickAwayListener,
    Select,
    Switch,
    TextField,
    MenuItem,
    SelectChangeEvent,
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    DialogContent,
    DialogContentText,
} from '@mui/material';
import { useBlocker } from 'react-router-dom';
import { Trans } from '@lingui/macro';

export interface IEditorProps {
    value: any;
    type: 'color' | 'string' | 'checkbox' | 'select' | 'textarea';
    onChangeInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeSelect: (event: SelectChangeEvent<any>, child: React.ReactNode) => void;
    onSave: () => void;
    onCancel: () => void;
    selects: Align[] | ViewType[] | FilterType[];
}

export const Editor: React.FC<IEditorProps> = ({
    value,
    type,
    onChangeInput,
    onChangeCheckbox,
    onChangeSelect,
    onSave,
    onCancel,
    selects,
}) => {

    const handleEventKey = useCallback((
        event: KeyboardEvent
    ) => {
        if (event.key === 'Escape') {
            onCancel();
        } else if (event.key === 'Enter') {
            onSave();
        }
    }, [onCancel, onSave]);

    useEffect(() => {
        document.addEventListener('keydown', handleEventKey);
        return () => document.removeEventListener('keydown', handleEventKey);
    }, [handleEventKey]);
    const blocker = useBlocker(true);
    const handleClickAway = useCallback((
        event: MouseEvent | TouchEvent
    ) => {
        if(blocker.state === 'blocked') return;
        if (event.target === document.body) return;
        onSave();
    }, [blocker.state, onSave]);

    
    const renderEditor = useMemo(() => {
        switch (type) {
            case 'string':
                return (
                    <TextField
                        fullWidth
                        autoFocus
                        value={value}
                        variant='standard'
                        sx={{ height: 35 }}
                        onChange={onChangeInput}
                    />
                );
            case 'checkbox':
                return (
                    <Box
                        sx={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Switch checked={value as boolean} onChange={onChangeCheckbox} />
                    </Box>
                );
            case 'color':
                return (
                    <Box style={{ position: 'relative' }}>
                        <Box style={{ position: 'absolute', zIndex: 2 }}>
                            {/* <SketchPicker color={value ?? '#000000'} onChange={onChangeColor} /> */}
                        </Box>
                    </Box>
                );
            case 'select':
                return (
                    <Select
                        value={value}
                        fullWidth
                        variant='standard'
                        onChange={onChangeSelect}
                        defaultOpen={true}
                    >
                        {selects.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </Select>
                );
            case 'textarea':
                return (
                    <TextField
                        fullWidth
                        autoFocus
                        multiline
                        rows={4}
                        value={value}
                        variant='standard'
                        inputProps={{
                            sx: {
                                border: 2,
                                borderColor: 'primary.main',
                                bgcolor: 'background.paper',
                            },
                            disableUnderline: true,
                        }}
                        sx={{
                            width: '100%',
                            minWidth: 300,
                            maxHeight: 40,
                            border: 0,
                        }}
                        onChange={onChangeInput}
                    />
                );
            default:
                return null;
        }
    }, [type, value, onChangeInput, onChangeCheckbox, onChangeSelect, selects]);

    const renderBlocker = useMemo(() => {
        if(blocker.state === 'blocked')  {
            return (
                <Dialog open={true}>
                    <DialogTitle>
                        <Trans>Edição Pendente</Trans>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Trans>
                                Você tem alterações pendentes. Deseja
                                salvar antes de sair?
                            </Trans>
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button
                            onClick={() => {
                                onCancel();
                                blocker.proceed();
                            }}
                            color='error'
                            variant='contained'
                        >
                            <Trans>Não Salvar</Trans>
                        </Button>
                        <Button
                            onClick={() => {
                                onSave();
                                blocker.proceed();
                            }}
                            color='primary'
                            variant='contained'
                        >
                            <Trans>Salvar</Trans>
                        </Button>
                    </DialogActions>
                </Dialog>
            );
        }
        return null;
    }, [blocker, onCancel, onSave]);
    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    border: type === 'textarea' ? 0 : 2,
                    borderColor: 'primary.main',
                    maxHeight: 40,
                }}
            >
                {renderBlocker}
                {renderEditor}
            </Box>
        </ClickAwayListener>
    );
};
