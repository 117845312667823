import { DataContext, IDataContextProps } from './context';
import { useItemData } from './hooks/useItemData';
import { useMenuData } from './hooks/useMenuData';
import { useNotificationData } from './hooks/useNotificationData';
import { usePlanningGridData } from './hooks/usePlanningGridData';
import { useReportdata } from './hooks/useReportData';
import { useScenarioData } from './hooks/useScenarioData';
import { useSchedulerData } from './hooks/useSchedulerData';
import { useStockStatusData } from './hooks/useStockStatusData';
import { useTableData } from './hooks/useTableData';

export interface IDataProviderProps {
    children: React.ReactNode;
}

export const DataProvider: React.FC<IDataProviderProps> = ({ children }) => {
    
    const {
        attributeList
    } = usePlanningGridData();

    const {
        scenarioId,
        scenarios,
        scenario,
        handleChangeScenario,
        handleRefreshScenariosAsync,
        loadingScenarios,
    } = useScenarioData();

    const {
        notifications,
        totalUnreaded,
        handleRefreshNotificationsAsync,
        handleLoadMoreNotificationsAsync,
        handleMarkAllAsReadAsync,
        handleMarkAsReadAsync,
    } = useNotificationData();

    const { 
        items 
    } = useItemData();

    const { 
        handleRefreshProceduresAsync, 
        handleCancelProcedureAsync, 
        executions 
    } = useSchedulerData();

    const { 
        reports, 
        reportTypes,
        handleRefreshReportsAsync
    } = useReportdata();

    const {
        tables,
        groups,
        handleRefreshTablesAsync,
        loadingTables
    } = useTableData();

    const { 
        stockStatus
    } = useStockStatusData();

    const {
        hasMenuAccess
    } = useMenuData();
    const contextValue: IDataContextProps = {
        //Scenario
        scenarios,
        scenarioId,
        scenario,
        loadingScenarios,
        items,
        onRefreshScenariosAsync: handleRefreshScenariosAsync,
        onChangeScenario: handleChangeScenario,

        //Notifications
        notifications,
        totalUnreaded,
        onRefreshNotificationsAsync: handleRefreshNotificationsAsync,
        onLoadMoreNotificationsAsync: handleLoadMoreNotificationsAsync,
        onMarkAllAsReadAsync: handleMarkAllAsReadAsync,
        onMarkAsReadAsync: handleMarkAsReadAsync,

        //Scheduler
        executions,
        onRefreshProceduresAsync: handleRefreshProceduresAsync,
        onCancelProcedureAsync: handleCancelProcedureAsync,

        //Reports
        reports,
        reportTypes,
        onRefreshReportsAsync: handleRefreshReportsAsync,

        //Table
        tables,
        groups,
        loadingTables,
        onRefreshTablesAsync: handleRefreshTablesAsync,

        stockStatus,

        //Menus
        hasMenuAccess,
    };
    return (
        <DataContext.Provider value={contextValue}>
            {children}
        </DataContext.Provider>
    );
};
