import { Dictionary } from "types/Dictionary"
import { IInventorySegmentationData } from "./IInventorySegmentationData"
import { IInventorySummaryData } from "./IInventorySummaryData"
import { IInventoryGenericOptions } from "./IInventoryGenericOptions"

export enum stockDataFilterCriteria {
    All = 'all',
    uncertainty = 'Uncertainty',
    volume = 'Volume',
    complexity = 'Complexity',
    customer = 'Customer'
}

export interface IFilter {
    filteredByCompany: IInventorySummaryData[] | undefined,
    filteredByAxisFilter: IInventorySummaryData[] | undefined,
    filteredByStockGroup: IInventorySummaryData[] | undefined,
    filteredBySalesChannel: IInventorySummaryData[] | undefined,
    filteredByCustomerGroup: IInventorySummaryData[] | undefined,

    stockDataSearchCriteriasData: {
        yAxis: string,
        xAxis: string,
        filter: string
    }
    stockMatrix: {
        x: number,
        y: number,
        z: number
    } | undefined,
    axisPossibilities: {
        x: string[],
        y: string[],
        z: string[]
    } | undefined,
    axisData: {
        xAxis: { idKey: string, priorityKey: string, name: string, classificationName: string },
        yAxis: { idKey: string, priorityKey: string, name: string, classificationName: string },
        filter: { idKey: string, name: string, classificationName: string }
    } | undefined,
    companyOptions: number[],
    dataLines: Dictionary<IInventorySummaryData[]> | undefined,
    segmentationTypes: {
        Company: IInventoryGenericOptions[],
        Complexity: IInventoryGenericOptions[],
        Volume: IInventoryGenericOptions[],
        Uncertainty: IInventoryGenericOptions[],
        StockGroup: IInventoryGenericOptions[],
        Customer: IInventoryGenericOptions[],
        SalesChannel: IInventoryGenericOptions[]
    }
}

export interface IFilterSegmentation {
    dataValidation: {
        // customerGroupExists: boolean,
        companyExists: boolean,
        classificationVolumeExists: boolean,
        classificationComplexityExists: boolean,
        classificationUncertaintyExists: boolean,
        salesChannelExists: boolean,
        stockGroupExists: boolean,
    }
    filteredByCompany: IInventorySegmentationData[] | undefined,
    filteredByStockGroup: IInventorySegmentationData[] | undefined,
    filteredBySalesChannel: IInventorySegmentationData[] | undefined,
    filteredByCustomerGroup: IInventorySegmentationData[] | undefined,
    filteredByAxisFilter: IInventorySegmentationData[] | undefined,
    dataLines: Dictionary<IInventorySegmentationData[]> | undefined
    companyOptions: IInventoryGenericOptions[],
    stockGroupOptions: IInventoryGenericOptions[],
    salesChannelOptions: IInventoryGenericOptions[]
}

export type IFilterSegmentationOptionsSubset = Pick<IFilterSegmentation, 'dataValidation' | 'companyOptions' | 'stockGroupOptions' | 'salesChannelOptions'>;


export interface IInventorySummaryInfo {
    numberOfLines: number,
    columnOptions: any[],
    rowOptions: any[],
    dataLinesArray: IInventorySummaryData[][]
}
export interface ISegmentationSummaryInfo {
    numberOfLines: number,
    columnOptions: any[],
    rowOptions: any[],
    dataLinesArray: IInventorySegmentationData[][]
}