import { useAppSelector } from "redux/hooks"
import DropDownTreeSelectorContainer from "../components/DropDownTreeSelectorContainer"
import { FilterCriteriaToSelector } from "../FilterCriteria"
import { FilterCriteria } from "../FilterCriteria/types"
import React, { useMemo } from "react"
import { FixedCriteriaInfoBox } from "./FixedCriteriaInfoBoxContainer"
import { Box } from "@mui/material"
import { optionsToLanguage } from "locales/helper"


export function FixedCriteriasDropDownContainer() {
    const selectedFiltersToShow = useAppSelector(state => state.filter.planningGrid.selectedFiltersToShow)
    const selectedFiltersToShowMemo = useMemo(() => { return selectedFiltersToShow }, [selectedFiltersToShow])
    const key = useAppSelector(state => state.filter.planningGrid.redrawFilters)


    return (
        <Box key={key}>
            {selectedFiltersToShowMemo.map((filter, index) => (
                <Box key={index} sx={{ display: "flex", justifyContent: "space-between" }} >
                    <DropDownTreeSelectorContainer
                        key={`${index}-${filter.filterCriteria}}`}
                        filterCriteria={filter.filterCriteria}
                        selector={FilterCriteriaToSelector(FilterCriteria[filter.filterCriteria])}
                        placeholder={optionsToLanguage(filter.placeholder)}
                    />
                    <FixedCriteriaInfoBox filterCriteria={filter.filterCriteria} />
                </Box>
            ))}
        </Box>
    )


}

export const FixedCriteriasDropDownContainerMemoized = React.memo(FixedCriteriasDropDownContainer)
