import { TagsBundleData, TagsBundle } from "redux/Filters/types";
import { createDictionary } from "utils/dictionaryHelper";

export const initializeTags = (payload: TagsBundleData): TagsBundle => {
    const productTagsData: TagsBundleData = payload;
    const productTags: TagsBundle = {
        Tag01: createDictionary(productTagsData.Tag01, x => x.id),
        Tag02: createDictionary(productTagsData.Tag02, x => x.id),
        Tag03: createDictionary(productTagsData.Tag03, x => x.id),
    }
    return productTags;
}