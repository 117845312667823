import { Switch, TableBody, TableCell, TableRow } from '@mui/material';
import React from 'react';
import { useUserActions } from './hooks/useUserActions';
import { ActionButtons } from './components';
import { useLingui } from '@lingui/react';
import { dateOptions } from '@nplan';



export interface IBodyProps {
    users: User[];
    roles: Role[];
    onEditUserClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}
export const Body: React.FC<IBodyProps> = ({
    users,
    roles,
    onEditUserClick,
}): JSX.Element => {
    const {
        handleDeactivateUserAsync,
        handleResetPasswordAsync
    } = useUserActions();
    const { i18n } = useLingui();
    const getRoleName = (id: number): string | undefined => { 
        return roles.find((role) => role.id === id)?.name;
    };
    return (
        <TableBody>
            {users.map((user) => (
                <TableRow hover key={user.id}>
                    <TableCell>{user.userName}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.lastName}</TableCell>
                    <TableCell>{getRoleName(user.roleId)}</TableCell>
                    <TableCell>
                        <Switch checked={user.active} disabled />
                    </TableCell>
                    <TableCell>
                        <Switch checked={user.blocked} disabled />
                    </TableCell>
                    <TableCell>
                        {user.lastLogin
                            ? new Date(user.lastLogin)
                                .toLocaleString(i18n.locale, dateOptions)
                                .replace(',', '')
                            : ''}
                    </TableCell>
                    <ActionButtons
                        user={user}
                        onResetPasswordAsync={handleResetPasswordAsync}
                        onEditUserClick={onEditUserClick}
                        onDeactivateUserAsync={handleDeactivateUserAsync}
                    />
                </TableRow>
            ))}
        </TableBody>
    );
};
