import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAsync, postAsync } from '@nplan';

export const useButtons = () => {
    const { id } = useParams();
    const [buttons, setButtons] = useState<Button[]>([]);
    useEffect(() => {
        (async () => {
            const response = await getAsync<Button[]>(`buttons/table/${id}`);
            if(response.type === 'success' && response.data !== undefined) {
                setButtons(response.data);
            }
        })();
    },[id]);
    const handleCallProcedureAsync = useCallback(async (event: React.MouseEvent<HTMLButtonElement | HTMLLIElement>) => {
        const id = parseInt(event.currentTarget.dataset.id as string);
        const button = buttons.find(u => u.id === id);
        if(button) {
            const bodyToPost = {
                id: 0,
                ProcedureStatment: `[${button.procedureSchema.replace('[', '').replace(']', '')}].[${button.procedureName.replace('[', '').replace(']', '')}]`,
                title: button.name,
            };
            await postAsync<object, string>(`procedure-executions`, bodyToPost);
        }
    },[buttons]);
    return { buttons, handleCallProcedureAsync };
};