import { ReactNode, useEffect, useState } from 'react';
import { HubContext } from './context';
import {
    HttpTransportType,
    HubConnection,
    HubConnectionBuilder,
    LogLevel,
} from '@microsoft/signalr';
import { useUserContext } from 'providers';
export interface IHubProvider {
    children: ReactNode;
}
export const HubProvider: React.FC<IHubProvider> = ({ children }) => {
    const [hubConnection, setHubConnection] = useState<HubConnection | null>(null);
    const { getToken  } = useUserContext();
    useEffect(() => {
        (async () => {
            const token = getToken();
            if (!token) return;
            let newConnection: HubConnection | null = null;
            newConnection = new HubConnectionBuilder()
                .withUrl(`${import.meta.env.VITE_API}/hub`, {
                    accessTokenFactory: () => token,
                    skipNegotiation: true,
                    transport: HttpTransportType.WebSockets,
                })
                .withAutomaticReconnect()
                .configureLogging(LogLevel.Error)
                .build();
            
            await newConnection.start();
            setHubConnection(newConnection);
        })();
    }, [getToken]);

    return (
        <HubContext.Provider value={{ hubConnection }}>
            {children}
        </HubContext.Provider>
    );
};
