import { ResourceAggregator, StockStatusType } from "pages/planning-grid/types/PlanningGrid"




export interface SearchList<T> {
    label: string,
    id: string,
    data?: T
}

export type StockStatusDictionary<TValue> = {
    [Key in StockStatusType]: TValue
}
export type SearchCriteriasDictionary<TValue> = {
    [Key in FilterCriteria]: TValue | undefined
}

export enum FilterCriteria {
    None = 'None',
    ResourceGroup = 'ResourceGroup',
    ItemCode = 'ItemCode',
    Level = 'Level',
    ItemPlanner = 'ItemPlanner',
    ItemStockGroup = 'ItemStockGroup',
    StockStatusTypeWithPeriod = 'StockStatusTypeWithPeriod',
    OccupationPageResourceAndPeriod = 'OccupationPageResourceAndPeriod',
    OccupationPageResourceGroup = 'OccupationPageResourceGroup',
    ItemAttribute01 = 'ItemAttribute01',
    ItemAttribute02 = 'ItemAttribute02',
    ItemAttribute03 = 'ItemAttribute03',
    ItemAttribute04 = 'ItemAttribute04',
    ItemAttribute05 = 'ItemAttribute05',
    ItemAttribute06 = 'ItemAttribute06',
    ItemAttribute07 = 'ItemAttribute07',
    ItemAttribute08 = 'ItemAttribute08',
    ItemAttribute09 = 'ItemAttribute09',
    ItemAttribute10 = 'ItemAttribute10',
    Tag01 = 'Tag01',
    Tag02 = 'Tag02',
    Tag03 = 'Tag03',
    Company = 'Company',
    Complexity = 'Complexity',
    Volume = 'Volume',
    Uncertainty = 'Uncertainty',
}


export interface OccupationPageFilterData {
    chartId: number,
    selectedResourceData: ResourceAggregator
}
export interface OccupationPageFilterDataPeriod extends OccupationPageFilterData {
    chartPeriodIndex: number
}
