import { Trans } from '@lingui/macro';
import { Box, Button, Modal, Typography } from '@mui/material';
import React, { useState } from 'react';
import Cropper, { Area } from 'react-easy-crop';
import { putAsync } from 'services';

interface ImageCropperModalProps {
    open: boolean;
    onClose: () => void;
    itemCode?: string | null | undefined
}

const ImageCropperModal: React.FC<ImageCropperModalProps> = ({ onClose, open, itemCode }) => {
    const [image, setImage] = useState<string | null>(null);
    const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const [zoom, setZoom] = useState<number>(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

    const handleClose = () => {
        onClose();
        setImage('');
    };
    const onCropComplete = (_croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        if (file) {
            setImage(URL.createObjectURL(file));
        }
    };

    const showCroppedImage = async () => {
        try {
            if (image && croppedAreaPixels) {
                const croppedImage = await getCroppedImg(image);
                const formData = new FormData();
                formData.append('image', croppedImage as Blob);
                if (itemCode) {

                    await putAsync(`/images/${itemCode.trim()}`, formData);
                    onClose();
                }
            }
        } catch (e) {
            console.error(e);
        }
    };
    // const handleChange = (_event: Event, newValue: number | number[]) => {
    //     if (typeof newValue === 'number') {
    //         setZoom(newValue);
    //     }
    // };
    // const handleWheel = (event: any) => {
    //     event.preventDefault();

    //     // Calculate the new value based on the scroll delta and step size
    //     const delta = event.deltaY > 0 ? -0.001 : 0.001; // Invert delta for natural scroll direction
    //     const newValue = Math.min(Math.max(zoom + delta, 0.1), 4); // Clamp value between 0.1 and 4
    //     setZoom(newValue);
    // };

    return (
        <>
            {/* <Button onClick={handleOpen}>Open modal</Button> */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={
                    {
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-around',
                        height: '80vh',
                        alignItems: 'center'
                    }
                }>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        height: '100%'
                    }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '1rem' }}>
                            <input type="file" accept="image/*" onChange={handleFileChange} />
                        </Box>

                        <Box>
                            <Box sx={{ display: 'flex' }}>
                                {image && (
                                    <Box sx={{
                                        width: '400px',
                                        height: '450px',
                                    }}>
                                        <Cropper

                                            image={image}
                                            crop={crop}
                                            // rotation={rotation}
                                            // zoom={zoom}
                                            cropSize={{ width: 400, height: 450 }}
                                            onCropChange={setCrop}
                                            // onRotationChange={setRotation}
                                            onWheelRequest={() => { return false; }}
                                            onCropComplete={onCropComplete}
                                            onZoomChange={setZoom}
                                            // objectFit=''
                                            showGrid={false}
                                            // disableAutomaticStylesInjection
                                            style={{
                                                containerStyle: {
                                                    cursor: 'auto',
                                                    position: 'relative',
                                                    width: '100%',
                                                    height: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    margin: '0 auto',
                                                    alignSelf: 'center'
                                                },
                                            }}
                                        />
                                    </Box>
                                )}
                            </Box>
                            {image &&
                                <Box >
                                    {/* <Box sx={{ marginTop: '0.5rem', width: '100%', display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'center' }}>
                                        <Typography>
                                            <Trans>Zoom</Trans>
                                        </Typography>
                                        <Slider
                                            value={zoom}
                                            onChange={handleChange}
                                            max={2}
                                            onWheel={handleWheel}
                                            min={0.1}
                                            defaultValue={0.5}
                                            step={0.001}

                                        />
                                    </Box> */}
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0.5rem' }}>
                                        <Button onClick={showCroppedImage} variant="contained" color="primary" >
                                            <Typography><Trans>Enviar</Trans></Typography>
                                        </Button>
                                    </Box>
                                </Box>
                            }
                        </Box>
                    </Box>


                </Box>
            </Modal>
        </ >
    );
};

export default ImageCropperModal;

async function createImage(imageSrc: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.addEventListener('load', () => resolve(img));
        img.addEventListener('error', (error) => reject(error));
        img.src = imageSrc;
    });
}

// function getRadianAngle(degreeValue: number): number {
//     return (degreeValue * Math.PI) / 180;
// }


async function getCroppedImg(imageSrc: string, minSize = 256, fillColor = { r: 0, g: 0, b: 0, a: 0 }) {
    // Load the image
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    // Determine the dimensions of the new image
    const size = Math.max(minSize, image.width, image.height);

    canvas.width = image.width;
    canvas.height = image.height;

    // Fill the canvas with the specified color
    if (ctx) {
        ctx.fillStyle = `rgba(${fillColor.r}, ${fillColor.g}, ${fillColor.b}, ${fillColor.a})`;
        ctx.fillRect(0, 0, size, size);
        // Calculate the position to paste the image in the center
        // const x = (size - image.width) / 2;
        // const y = (size - image.height) / 2;

        // Paste the image onto the canvas
        ctx.drawImage(image, 0, 0);

        // As a blob
        return new Promise<Blob>((resolve, reject) => {
            canvas.toBlob((file) => {
                if (!file) {
                    reject(new Error('Failed to create blob.'));
                } else {
                    resolve(file);
                }
            }, 'image/png');
        });
    }


}

