import { Dialog, DialogContent, DialogTitle, MenuItem, Select } from '@mui/material';
import { useDataGridContext } from '../../../providers/hooks';

export const RequiredFilter = () => {
    const {
        requiredFilterValue,
        requiredFilterOptions,
        onRequiredFilterChange,
        table
    } = useDataGridContext();
    return (
        <Dialog
            open={
                table?.requiredFilter && requiredFilterValue === null
                    ? true
                    : false
            }
        >
            <DialogTitle>Filtro Obrigatório</DialogTitle>
            <DialogContent>
                <Select
                    variant="standard"
                    fullWidth
                    onChange={onRequiredFilterChange}
                    sx={{ minWidth: 200 }}
                >
                    {requiredFilterOptions.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
            </DialogContent>
        </Dialog>
    );
};