
import { Theme } from '@mui/material/styles';

export const selectedButtonStyle = {
    border: '1px solid',
    // borderColor: (theme: Theme) => theme.palette.text.primary,
    color: (theme: Theme) => theme.palette.primary.main,
};

export const nonSelectedButtonStyle = {
    borderColor: (theme: Theme) => theme.palette.primary.light,
    border: '1px solid',
    padding: '0.3rem',
    height: 'fit-content',
    color: (theme: Theme) => theme.palette.text.primary,
    textTransform: 'initial',
    ':hover': {
        border: '1px solid',
        borderColor: (theme: Theme) => theme.palette.text.primary,
        textDecoration: 'underline'
    },
};
export const basicButtonStyle = {
    borderColor: (theme: Theme) => theme.palette.primary.light,
    border: '1px solid',
    padding: '0.3rem',
    height: 'fit-content',
    color: (theme: Theme) => theme.palette.text.primary,
    textTransform: 'initial',
    ':hover': {
        textDecoration: 'underline',
        backgroundColor: (theme: Theme) => theme.palette.primary.main
    },
};