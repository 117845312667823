import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { IInventoryHealth } from 'pages/inventory-policy/types/IInventoryHealth';
import { setInventoryHealthSelectedPeriod } from 'redux/Filters/reducer';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { IScenario } from 'types/scenario';
import { v4 as uuidv4 } from 'uuid';
import { GraphComponent } from './Chart/Chart';
import { IterationComponent } from './Iteration';
import { TableComponent } from './Table/Table';

interface InventoryHealthComponentProps {
    inventoryHealth: IInventoryHealth;
    selectedScenario: IScenario | null;
    maxValue: any
}

export function InventoryHealthComponent({
    inventoryHealth,
    selectedScenario, maxValue
}: InventoryHealthComponentProps) {
    const theme = useTheme();
    const periodOptions = useAppSelector(state => state.filter.inventory.periodOptions);
    const scenarioTimeStamp = periodOptions.map(c => c.name);
    const dispatch = useAppDispatch();
    const fontSize = '0.8rem';
    if (!inventoryHealth) return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></Box>;
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'start',
                width: '100%',
                height: 'fit-content',
            }}
        >
            {/* ----- TABELA DE VALORES ----- */}
            <Box
                sx={{
                    display: 'flex',
                    maxHeight: 'fit-content',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    alignItems: 'center',
                }}
            >
                < TableComponent inventoryHealth={inventoryHealth} />
            </Box>
            {/* ----- GRÁFICO ----- */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    padding: '0.5rem',
                    flex: '1',
                }}
            >
                <GraphComponent inventoryHealth={inventoryHealth}
                    maxValue={maxValue} />
                <Box sx={{ display: 'flex' }}>
                    {inventoryHealth.scenarioStatusOptions.map((status) => (
                        <Box
                            key={uuidv4()}
                            sx={{
                                backgroundColor: `${status.stockStatusColor}`,
                                flex: 1,
                                height: 'fit-content',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '0.1rem',
                            }}
                        >
                            <Typography
                                variant="body2"
                                fontSize={fontSize}
                                fontWeight={'bold'}
                                color={theme.palette.common.black}
                            >
                                {status.stockStatusName}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
            <Box sx={{ display: 'flex', width: '100%' }}>
                <Box
                    sx={{
                        display: 'flex',
                        overflowX: 'auto',
                        width: '100%',
                        marginTop: '0.5rem',
                    }}
                >
                    <IterationComponent dispatch={dispatch} periodOptions={periodOptions} scenarioTimeStamp={scenarioTimeStamp} selectedScenario={selectedScenario} setInventoryHealthSelectedPeriod={setInventoryHealthSelectedPeriod} />
                </Box>
            </Box>
        </Box>
    );
}
