import { forwardRef, useCallback } from 'react'
import { ListOnScrollProps } from 'react-window';
import GridCollapsedHeaderColumnContainer from './Collapsed/GridCollapsedHeaderColumnContainer';
import GridExpandedHeaderColumnContainer from './Expanded/GridExpandedHeaderColumnContainer';
import { IPeriod } from 'types/scenarios/IPeriod';
import { PeriodData } from 'pages/planning-grid/types/PeriodData';
import { PlanningGridCommonWidth } from 'pages/planning-grid/types/PlanningGrid';
import { useAppSelector } from 'redux/hooks';

interface Props {
    onScroll?: ((props: ListOnScrollProps) => any)
    periods: IPeriod[] | undefined
    isWeekly: boolean
    complexPeriods: PeriodData[] | undefined
}

const GridHeaderColumnContainer = forwardRef((props: Props, ref: any) => {
    const { onScroll, periods, isWeekly } = props
    const isExpandedPeriods = useAppSelector(state => state.filter.planningGrid.isExpandedPeriods)
    const complexPeriods = useAppSelector(state => state.filter.planningGrid.complexPeriods)

    const gridHeaderWidth = useCallback((index: number) => {
        if ((complexPeriods)[index]) {
            return (complexPeriods)[index].periods.length * PlanningGridCommonWidth;
        }
        return PlanningGridCommonWidth * 4;
    }, [complexPeriods])


    if (!periods || !complexPeriods) return

    return (
        <>
            {
                isExpandedPeriods ?
                    <GridExpandedHeaderColumnContainer
                        height={80}
                        itemSize={gridHeaderWidth}
                        estimatedItemSize={PlanningGridCommonWidth * 4}
                        itemCount={complexPeriods.length}
                        onScroll={onScroll}
                        ref={ref}
                        itemData={{ complexPeriods, isWeekly }}
                    />
                    :
                    <GridCollapsedHeaderColumnContainer
                        height={80}
                        itemSize={PlanningGridCommonWidth}
                        itemCount={complexPeriods.length}
                        onScroll={onScroll}
                        ref={ref}
                        itemData={complexPeriods}
                    />
            }
        </>
    )
})

export default GridHeaderColumnContainer
