import { FixedFilterData } from "../types/PlanningGrid";
import { FilterCriteria } from "../components/PlanningGrid/FilterDrawer/FilterCriteria/types";

export const GetFixedFilterCriteriaByFilterCriteria = (filterCriteria: FilterCriteria): FixedFilterData => {
    const foundFilterCriteria = FilterCriteriaFilters.find(x => x.filterCriteria === filterCriteria);
    if (foundFilterCriteria) return foundFilterCriteria
    else return FilterCriteriaFilters[0];
}

export const FilterCriteriaFilters: FixedFilterData[] = [
    {
        filterId: 1,
        filterCriteria: FilterCriteria[FilterCriteria.ItemCode],
        label: (`Item Code`),
        placeholder: (`Item Code`),
        checked: true,
    },
    {
        filterId: 2,
        filterCriteria: FilterCriteria[FilterCriteria.StockStatusTypeWithPeriod],
        label: ('Status with Period'),
        placeholder: ('Status with Period'),
        checked: true
    },
    {
        filterId: 3,
        filterCriteria: FilterCriteria[FilterCriteria.Level],
        label: ('Level'),
        placeholder: ('Level'),
        checked: true
    },
    {
        filterId: 4,
        filterCriteria: FilterCriteria[FilterCriteria.Company],
        label: ('Company'),
        placeholder: ('Company'),
        checked: true
    },
    {
        filterId: 5,
        filterCriteria: FilterCriteria[FilterCriteria.ItemPlanner],
        label: ('Planner'),
        placeholder: ('Planner'),
        checked: true
    },
    {
        filterId: 6,
        filterCriteria: FilterCriteria[FilterCriteria.ItemStockGroup],
        label: ('Stock Group'),
        placeholder: ('Stock Group'),
        checked: true
    },
    {
        filterId: 7,
        filterCriteria: FilterCriteria[FilterCriteria.Tag01],
        label: ('Tag 01'),
        placeholder: ('Tag01'),
        checked: true
    },
    {
        filterId: 8,
        filterCriteria: FilterCriteria[FilterCriteria.Tag02],
        label: ('Tag 02'),
        placeholder: ('Tag02'),
        checked: true
    },
    {
        filterId: 9,
        filterCriteria: FilterCriteria[FilterCriteria.Tag03],
        label: ('Tag 03'),
        placeholder: ('Tag03'),
        checked: true
    },
    {
        filterId: 10,
        filterCriteria: FilterCriteria[FilterCriteria.Complexity],
        label: ('Complexity'),
        placeholder: ('Complexity'),
        checked: true
    },
    {
        filterId: 11,
        filterCriteria: FilterCriteria[FilterCriteria.Uncertainty],
        label: ('Uncertainty'),
        placeholder: ('Uncertainty'),
        checked: true
    },
    {
        filterId: 12,
        filterCriteria: FilterCriteria[FilterCriteria.Volume],
        label: ('Volume'),
        placeholder: ('Volume'),
        checked: true
    },

]

