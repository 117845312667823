
import { t } from '@lingui/macro';
import { getAsync, putAsync } from '@nplan';
import { useOccupationData } from 'pages/occupation/hooks';
import { FilterCriteria } from 'pages/planning-grid/components/PlanningGrid/FilterDrawer/FilterCriteria/types';
import { PlanningGridData } from 'pages/planning-grid/types/PlanningGrid';
import { FilterCriteriaFilters } from 'pages/planning-grid/utils/FixedCriteriaFilters';
import { initializeAttributes } from 'pages/planning-grid/utils/initializeAttributes';
import { initializeTags } from 'pages/planning-grid/utils/initializeTags';
import { useCallback, useEffect, useState } from 'react';
import { setAttributesData, setFixesPlanningGridFilters, setItemProperties, setItemsPlanning, setPeriods, setPlanningGrid, setPlanningGridItem, setPlanningGridRowOptions, setSelectedAttributes, setSelectedFilters, setTagsData } from 'redux/Filters/reducer';
import { ItemAttributesBundleData, TagsBundleData } from 'redux/Filters/types';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { IScenario } from 'types';
import { Dictionary } from 'types/Dictionary';
import { IItem } from 'types/data/IItem';
import { IPeriod } from 'types/scenarios/IPeriod';
import { IPlanningGrid } from 'types/scenarios/IPlanningGrid';
import { IAttributeList } from 'types/system/IAttributeList';
import { IPlanningGridFilterOptions } from 'types/system/IPlanningGridFilterOptions';

interface PlanningGridHook {
    loading: boolean;
    planningGridRowOptions: IPlanningGridFilterOptions[];
    planningGrid: IPlanningGrid[];
    periods: IPeriod[];
    isWeekly: boolean;
    handleReloadItemPlanningGrid: (itemId: number[]) => void;
    items: IItem[]
    handleRefreshPlanning: () => void
}

const usePlanningGrid = (scenario: IScenario | null): PlanningGridHook => {

    const { handleRefreshRefreshPGOR } = useOccupationData();
    const dispatch = useAppDispatch();
    const mpsManualObject = useAppSelector(state => state.filter.planningGrid.manualMpsObject);
    const [loading, setLoading] = useState(true);
    const [planningGridRowOptions, setHookPlanningGridRowOptions] = useState<IPlanningGridFilterOptions[]>([] as IPlanningGridFilterOptions[]);
    const [planningGrid, setContextPlanningGrid] = useState<IPlanningGrid[]>([] as IPlanningGrid[]);
    const [periods, setProviderPeriods] = useState<IPeriod[]>([] as IPeriod[]);
    const [isWeekly, setIsWeekly] = useState<boolean>(false);
    const [items, setItems] = useState<IItem[]>([]);

    const handleRefreshPlanning = useCallback(() => {
        if (scenario) { updatePlanningGrid(scenario?.id); }
    }, []);
    const handleLoadOnPageLoad = useCallback(async () => {
        const [planningGridFilterOptions] = await Promise.all(
            [
                getAsync('PlanningGridFilterOptions'),
            ]);
        if (planningGridFilterOptions.type === 'success' && planningGridFilterOptions.data) {
            setHookPlanningGridRowOptions(planningGridFilterOptions.data ?? []);
            dispatch(setPlanningGridRowOptions({ planningGridRowOptions: planningGridFilterOptions.data }));
        }
    }, []);

    const handleFixedFilters = useCallback(() => {
        const filterData = FilterCriteriaFilters.map((filter) => {
            return {
                label: filter.label,
                value: filter.filterCriteria,
                filterCriteria: FilterCriteria[filter.filterCriteria],
                placeholder: t`${filter.placeholder}`,
                filterId: filter.filterId,
                checked: true,
            };
        });
        dispatch(setFixesPlanningGridFilters({ fixedFilters: filterData }));
    }, []);

    useEffect(() => {
        handleLoadOnPageLoad();
        handleFixedFilters();
    }, [handleFixedFilters, handleLoadOnPageLoad]);



    const updatePlanningGrid = useCallback(async (scenarioId: number) => {
        let hasMoreData = true;
        let page = 1;
        let plannings: IPlanningGrid[] = [];
        while (hasMoreData) {
            const resPlanning = await getAsync<IPlanningGrid[]>(`PlanningGrid/${scenarioId}?page=${page}`, { maxBodyLength: Infinity, maxContentLength: Infinity });
            if (resPlanning.type === 'success' && resPlanning.data && resPlanning.data.length > 0) {
                plannings = [...plannings, ...resPlanning.data];
                page++;
            } else {
                hasMoreData = false;
            }
        }
        return plannings;
    }, []);
    const handlePlanningGrid = useCallback(async (scenarioId: number, periodTypeId: number) => {
        setLoading(true);
        const plannings = await updatePlanningGrid(scenarioId);
        setContextPlanningGrid(plannings);
        const [planningPeriods, attributeList, ItemAttributesBundle, tagBundle, item] = await Promise.all(
            [
                getAsync<IPeriod[]>(`Period/planning-period/${scenarioId}`),
                getAsync<IAttributeList[]>('AttributeList'),
                getAsync<ItemAttributesBundleData>('ItemAttributes'),
                getAsync<TagsBundleData>('Tags'),
                getAsync<IItem[]>(`items/${scenarioId}`)
            ]);
        if (planningPeriods.type === 'success' && planningPeriods.data) {
            setProviderPeriods(planningPeriods.data ?? []);
            dispatch(setPeriods({ periods: planningPeriods.data, periodTypeId: periodTypeId }));
            dispatch(setPlanningGrid({ planningGrid: plannings as PlanningGridData[] }));
        }

        if (item.type === 'success' && item.data) {
            const sortedItems = item.data.sort((a, b) => {
                if (a.level !== b.level) {
                    return a.level! - b.level!; // Sort by level
                } else {
                    return a.code!.localeCompare(b.code!); // If levels are equal, sort by code
                }
            });
            setItems(sortedItems);
            dispatch(setItemsPlanning({ items: sortedItems }));
        }
        if (ItemAttributesBundle.type === 'success' && ItemAttributesBundle.data &&
            attributeList.type === 'success' && attributeList.data) {

            const attributeListData = attributeList.data.map((attribute: any) => { return { label: attribute.name, value: attribute.code, checked: true, }; });
            const itemAttributes = initializeAttributes(ItemAttributesBundle.data, attributeList.data);
            for (const [key, value] of Object.entries(itemAttributes)) {
                const keyLowered = key.charAt(0).toLowerCase() + key.slice(1);
                dispatch(setItemProperties({ key: `${keyLowered}Id`, property: value }));
            }
            dispatch(setAttributesData({ attributeList: attributeListData, itemAttributes, attributeListRaw: attributeList.data }));
            dispatch(setSelectedAttributes({ selectedAttributes: attributeListData }));
            dispatch(setSelectedFilters());
        }
        if (tagBundle.type === 'success' && tagBundle.data) {
            const itemTags = initializeTags(tagBundle.data);
            for (const [key, value] of Object.entries(itemTags)) {
                const keyLowered = key.charAt(0).toLowerCase() + key.slice(1);
                dispatch(setItemProperties({ key: `${keyLowered}Id`, property: value }));
            }
            dispatch(setTagsData({ tags: itemTags }));
        }
        setLoading(false);
    }, [dispatch]);

    useEffect(() => {
        if (scenario) {
            if (scenario.id) { handlePlanningGrid(scenario?.id, scenario?.periodTypeId); }
        }
    }, [scenario, handlePlanningGrid, handleFixedFilters]);


    const handleReloadItemPlanningGrid = useCallback(async (itemIds: number[]) => {
        if (scenario?.id === undefined) return;

        const [planningGrid] = await Promise.all([
            getAsync<Dictionary<IPlanningGrid[]>>(`PlanningGrid/${scenario?.id}/${itemIds}`),
        ]);
        if (planningGrid.type === 'success' && planningGrid.data) {
            const newItems = planningGrid.data;
            dispatch(setPlanningGridItem({ planningGrid: newItems as any }));
            handleRefreshRefreshPGOR();
        }
    }, [dispatch, handleRefreshRefreshPGOR, scenario?.id]);

    useEffect(() => {
        (async () => {
            if (mpsManualObject.editedMpsObject.planningGridId === 0) return;
            await putAsync<any | string>(`/planninggrid/manual`, mpsManualObject.editedMpsObject!);
            await handleReloadItemPlanningGrid([mpsManualObject.itemId]);
        })();
    }, [handleReloadItemPlanningGrid, mpsManualObject]);

    return {
        loading,
        planningGridRowOptions,
        planningGrid,
        periods,
        isWeekly,
        handleReloadItemPlanningGrid,
        items,
        handleRefreshPlanning
    };
};

export default usePlanningGrid;
