import {
    Box,
    IconButton,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
    useTheme,
} from '@mui/material';
import { FC } from 'react';
import { Icons } from 'utils/icons';
import { 
    convertISODateStringToLocalDateTimeString, 
    getTimePassed 
} from '../util';

interface INotificationItemProps {
    notification: AppNotification;
    onReadAsync: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>;
}

export const NotificationItem: FC<INotificationItemProps> = ({
    notification,
    onReadAsync,
}: INotificationItemProps) => {
    const theme = useTheme();
    return (
        <Box
            key={notification.id}
            sx={{
                m: 1,
                maxHeight: 900,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'flex',
                alignItems: 'center',
                backgroundColor:
                    theme.palette.background.default,
            }}
        >
            <ListItemButton
                key={notification.id}
                sx={{
                    fontWeight: !notification.readed
                        ? 'bold'
                        : 'normal',
                    backgroundColor: !notification.readed
                        ? theme.palette.background.paper
                        : 'transparent',
                    height: '100%',
                    borderRadius: '10px',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Box sx={{ position: 'relative' }}>
                    <ListItemText
                        primary={notification.title}
                        secondary={notification.content}
                        title={notification.content}
                        sx={{
                            // Evita a quebra de linha
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            marginRight: '20px', // Adiciona um espaço automaticamente antes do ícone
                        }}
                    />
                    <Typography
                        variant='caption'
                        title={convertISODateStringToLocalDateTimeString(notification.created)}
                    >
                        {getTimePassed(notification.created.toLocaleString())}
                    </Typography>
                </Box>
                {!notification.readed && (
                    <ListItemSecondaryAction>
                        <IconButton
                            color={'info'}
                            edge='end'
                            aria-label='cancel'
                            onClick={onReadAsync}
                            data-id={notification.id}
                        >
                            <Icons.Unread />
                        </IconButton>
                    </ListItemSecondaryAction>
                )}
            </ListItemButton>
        </Box>
    );
};
