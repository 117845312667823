import React, { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';

interface ToolTipProps {
    tooltipText: string | null | undefined;
    children: React.ReactNode;
    onClickFunction?: () => void;
}

export function TooltipSimplesComponent({
    tooltipText,
    children,
    onClickFunction,
}: ToolTipProps) {
    const theme = useTheme();
    const [showTooltip, setShowTooltip] = useState(false);
    const [canOpenTooltip, setCanOpenTooltip] =
        useState(true);

    const handleTooltipClick = () => {
        setShowTooltip(false);
    };

    const handleTooltipOpen = () => {
        if (canOpenTooltip) {
            setShowTooltip(true);
            setCanOpenTooltip(false);
        }
    };

    useEffect(() => {
        if (!showTooltip) {
            const timer = setTimeout(() => {
                setCanOpenTooltip(true);
            }, 1000); // Adjust this delay as needed
            return () => clearTimeout(timer);
        }
    }, [showTooltip]);

    return (
        <Box
            onClick={onClickFunction}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ':hover': {
                    cursor: 'pointer',
                    filter: 'brightness(0.8)',
                },
            }}
        >
            <Tooltip
                arrow
                enterDelay={500}
                TransitionProps={{ timeout: 200 }}
                componentsProps={{
                    tooltip: {
                        sx: {
                            background:
                                theme.palette.background
                                    .default,
                            border: '1px solid',
                            borderColor:
                                theme.palette.primary.main,
                            borderRadius: '15px',
                            padding: 1,
                            whiteSpace: 'nowrap',
                            fontWeight: '600',
                            fontSize: '12px',
                            color: theme.palette.primary
                                .contrastText,
                        },
                    },
                }}
                title={
                    tooltipText && (
                        <Box
                            sx={{
                                ':hover': {
                                    cursor: 'pointer',
                                },
                            }}
                        >
                            {tooltipText}
                        </Box>
                    )
                }
                onClose={handleTooltipClick}
                open={showTooltip}
                onOpen={handleTooltipOpen}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    onClick={handleTooltipClick}
                >
                    {children}
                </Box>
            </Tooltip>
        </Box>
    );
}
