import React, { useState } from "react";
import {
    Select,
    MenuItem,
    FormControl,
    Box,
    Typography,
} from "@mui/material";
import { TiArrowUnsorted } from "react-icons/ti";
import { AiOutlineCheck } from "react-icons/ai";
import { IGenericOptions } from "types/Generics";
import { optionsToLanguage } from "locales/helper";

interface FilterDropDownSelectorProps {
    axisName: string
    filterOptions: IGenericOptions[];
    selectedFilter: IGenericOptions
    handleSelect: (selectedId: number, axes: string) => void
}

const DropDownSelector: React.FC<FilterDropDownSelectorProps> = ({
    filterOptions,
    selectedFilter,
    handleSelect,
    axisName
}) => {
    const [selectedFilterId, setSelectedFilterId] = useState(selectedFilter.id);

    const handleChange = (e: any, axisName: string) => {
        const selectedId = e as number;
        setSelectedFilterId(selectedId);
        handleSelect(selectedId, axisName);
    };

    return (
        <FormControl fullWidth>
            <Select
                data-testid="filter-selector"
                size="small"
                variant="outlined"
                inputProps={{
                    sx: {
                        paddingTop: '0.3rem',
                        paddingBottom: '0.1rem',
                    }
                }}
                labelId="company-select-label"
                id="company-select"
                value={selectedFilterId}
                IconComponent={TiArrowUnsorted}
                onChange={(e) => { handleChange(e.target.value, axisName) }}
            >
                {filterOptions.map((filter: any) => (
                    <MenuItem key={filter.id} value={filter.id}>
                        <Box display="flex" alignItems="center" gap={'0.5rem'}>
                            <Typography variant="caption">{optionsToLanguage(filter.name)}</Typography>
                            {selectedFilterId === filter.id && (
                                <AiOutlineCheck fontSize="small" />
                            )}
                        </Box>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default DropDownSelector
