import { t } from "@lingui/macro";
import { Box, Typography, useTheme } from "@mui/material";
import useIteration from "pages/inventory-policy/hooks/useIteration";
import { TooltipComponentNodeChildren } from "../../Tooltips/TooltipComponentNodeChildren";
import { Icons } from "utils";
import { useAppSelector } from "redux/hooks";

interface Props {
  periodOptions: any
  dispatch: any
  setInventoryHealthSelectedPeriod: any
  scenarioTimeStamp: any
  selectedScenario: any
}
export const IterationComponent = ({ periodOptions, dispatch, setInventoryHealthSelectedPeriod, scenarioTimeStamp, selectedScenario }: Props) => {
  const selectedTimeStamp = useAppSelector(state => state.filter.inventory.selectedPeriodFilter)
  const theme = useTheme()
  const { isRunning, pauseIteration, startIteration, stopIteration, setCurrentIndex } = useIteration({ periodOptions, dispatch, setInventoryHealthSelectedPeriod, scenarioTimeStamp, selectedScenario })

  const iconButtonStyles = {
    '&:hover': { filter: 'brightness(0.9)', cursor: 'pointer' },
    display: 'flex',
    padding: '0.5rem',
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {!isRunning ? (
        <Box
          sx={{ ...iconButtonStyles }}
          onClick={() => startIteration()}
        >
          <TooltipComponentNodeChildren
            tooltipText={t`Iniciar animação`}
            color={''}
          >
            <Icons.Play
              size={20}
              color={theme.palette.primary.main}
            />
          </TooltipComponentNodeChildren>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{ ...iconButtonStyles }}
            onClick={() => stopIteration()}
          >
            <TooltipComponentNodeChildren
              tooltipText={t`Parar animação`}
              color={''}
            >
              <Icons.Stop
                size={20}
                color={theme.palette.primary.main}
              />
            </TooltipComponentNodeChildren>
          </Box>
          <Box
            sx={{ ...iconButtonStyles }}
            onClick={() => pauseIteration()}
          >
            <TooltipComponentNodeChildren
              tooltipText={`Pausar animação`}
              color={''}
            >
              <Icons.Pause
                size={20}
                color={theme.palette.primary.main}
              />
            </TooltipComponentNodeChildren>
          </Box>
        </Box>
      )}
      <Box sx={{ display: 'flex', gap: '0.5rem' }}>
        {scenarioTimeStamp.map((timestamp: any, index: any) => {
          return (
            <Box
              key={index}
              onClick={() => setCurrentIndex(index)}
              sx={{
                display: 'flex',
                flex: '1',
                height: 'fit-content',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'right',
                border: '1px solid',
                borderColor:
                  theme.palette.primary.main,
                borderRadius: '8px',
                '&:hover': {
                  backgroundColor:
                    theme.palette.primary.main,
                  cursor: 'pointer',
                },
                padding: '0.2rem 0.4rem',
                backgroundColor:
                  timestamp ===
                    selectedTimeStamp.name
                    ? theme.palette.primary.main
                    : 'transparent',
              }}
            >
              <Typography
                variant="caption"
                fontWeight={'bold'}
              >
                {' '}
                {timestamp}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>)
}