import { Box } from '@mui/material';
import { DataProvider, HubProvider } from '@nplan';
import { NavbarMenu } from './navbar';
import { SidebarMenu } from './sidebar';
import { Main } from './main';
import { Outlet } from 'react-router-dom';

export const Layouts = () => {
    return (
        <HubProvider>
            <DataProvider>
                <Box sx={{ height: '100%' }}>
                    <NavbarMenu />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            height: '100%',
                            width: '100%',
                        }}
                    >
                        <SidebarMenu />
                        <Main>
                            <Outlet />
                        </Main>
                    </Box>
                </Box>
            </DataProvider>
        </HubProvider>
    );
};
