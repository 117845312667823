import { TablePagination } from '@mui/material';
import { t } from '@lingui/macro';

export interface IPagination {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
    onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const Pagination: React.FC<IPagination> = ({ 
    count, 
    page, 
    rowsPerPage, 
    onPageChange, 
    onRowsPerPageChange 
}): JSX.Element => {
    return (
        <TablePagination
            component='span'
            count={count ?? 0}
            page={page}
            onPageChange={onPageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={onRowsPerPageChange}
            labelRowsPerPage={t`Linhas por página`}
            sx={{
                mt: 'auto',
            }}
        />
    );
};