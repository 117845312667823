import React from 'react';
import { useDataGridContext } from '../../../providers/hooks';
import { Skeleton } from '@mui/material';



export const VirtualBodySkeleton: React.FC = (): JSX.Element => {
    const {
        columns
    } = useDataGridContext();
    return (
        <React.Fragment>
            {[...Array(30)].map((_, index) => (
                <div
                    style={{
                        display: 'contents',
                        gridRow: index + 1,
                    }}
                    key={index}
                >
                    {columns.map((col) => (
                        <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={30}
                            style={{
                                gridColumn: col.index,
                            }}
                            key={`${index  }-${  col.field}`}
                        />
                    ))}
                </div>
            ))}
        </React.Fragment>
    );
};

