import { t } from '@lingui/macro';
import { Box, Divider, Paper, Typography, useTheme, } from '@mui/material';
import { IInventorySummaryData } from 'pages/inventory-policy/types/IInventorySummaryData';
import React from 'react';
import useInventorySummaryStatistics from '../../hooks/useInventorySummaryStatistics';

interface InventorySummaryProps {
    summaryHeaderData: IInventorySummaryData[] | undefined

}

function InventorySummaryHeaderStatistics({ summaryHeaderData }: InventorySummaryProps) {
    const statistics = useInventorySummaryStatistics(summaryHeaderData);
    const theme = useTheme()
    return (
        <Paper data-testid="totalStockValue" elevation={3} sx={{ width: 'fit-content', margin: '0 auto', }}>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    justifyContent: 'center',
                    gap: '0.3rem',
                    p: '0.3rem',
                    backgroundColor: theme.palette.background.default,

                }}
            >
                <StatBox title={t`Itens`} value={statistics.totalItensQuantity} />
                <Divider orientation="vertical" flexItem variant='middle' />
                <StatBox title={t`Est. Seg.`} value={t`R$ ${statistics.totalSafetyStockValue}`} />
                <Divider orientation="vertical" flexItem variant='middle' />
                <StatBox title={t`Est. Ciclo`} value={t`R$ ${statistics.totalCycleStockValue}`} />
                <Divider orientation="vertical" flexItem variant='middle' />
                <StatBox title={t`Est. Total`} value={t`R$ ${statistics.totalStockValue}`} />
                <Divider orientation="vertical" flexItem variant='middle' />
                <StatBox title={t`Est. Seg.`} value={t`${statistics.averageSafetyStockDays} Dias`} />
                <Divider orientation="vertical" flexItem variant='middle' />
                <StatBox title={t`Est. Ciclo`} value={t`${statistics.averageCycleStockDays} Dias`} />
                <Divider orientation="vertical" flexItem variant='middle' />
                <StatBox title={t`Est. Total`} value={t`${statistics.totalStockDays} Dias`} />
                <Divider orientation="vertical" flexItem variant='middle' />
                <StatBox title={t`Atendimento`} value={`${statistics.averageServiceLevelPerc} %`} />
            </Box>
        </Paper>
    );
}
interface StatBoxProps {
    title: string;
    value: string | number;
}
const StatBox: React.FC<StatBoxProps> = ({ title, value }) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: '0.2rem 0.4rem',
            textAlign: 'center',
        }}
    >
        <Typography variant="body2" fontSize={'0.8rem'} fontWeight="bold">
            {value}
        </Typography>
        <Typography variant="caption" fontSize={'0.7rem'} >
            {title}
        </Typography>
    </Box>
);

export default InventorySummaryHeaderStatistics;
