import { Box, Button, LinearProgress, Typography, useTheme } from '@mui/material';
import { useDataContext } from '@nplan';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { InventoryPolicy } from './components/InventoryPolicy';
import useFilterOptions from './hooks/useFilterOptions';
import useInventoryPolicy from './hooks/useInventoryPolicy';
import { Trans } from '@lingui/macro';

export const InventoryPolicyContainer: React.FC = (): JSX.Element => {
    const { scenario } = useDataContext();
    const theme = useTheme();
    const navigate = useNavigate();

    const {
        loading,
        loadingHealth,
        segmentationData,
        inventorySummary,
        stockStrategy,
        isProcedureRunning,
        handleInventoryRecalculate,
        segmentationUpdate,
        isProcedureCompleted,
        fetchInitialData,
        fetchData
    } = useInventoryPolicy();



    useFilterOptions(segmentationData);
    useEffect(() => { fetchInitialData(); }, [fetchInitialData]);


    useEffect(() => {
        if (scenario?.id === undefined) return;
        fetchData();
    }, [scenario?.id, fetchData]);



    const handleCreateScenario = () => {
        navigate('/scenarios');
    };

    if (!scenario) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', margin: '0 auto' }}>
                <Box sx={{ gap: '0.5rem', display: 'flex', flexDirection: 'column', border: '1px solid', borderColor: theme.palette.primary.main, padding: '1rem', borderRadius: '0.5rem' }}>
                    <Typography variant="h6">Não existem cenários!</Typography>
                    <Button onClick={handleCreateScenario}>Criar Cenário</Button>
                </Box>
            </Box>
        );
    }

    if (loading || loadingHealth) {
        return (
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', color: theme.palette.text.secondary }}>
                    <Trans>Carregando cenário: {scenario?.name}</Trans>
                </Typography>
                <Box sx={{ width: '400px' }}>
                    <LinearProgress />
                </Box>
            </Box>
        );
    }

    return (
        <InventoryPolicy
            data-testid="inventory-policy-loaded"
            summaryData={inventorySummary}
            segmentationData={segmentationData}
            stockStrategy={stockStrategy}
            segmentationUpdate={segmentationUpdate}
            isProcedureRunning={isProcedureRunning}
            handleInventoryRecalculate={handleInventoryRecalculate}
            inventoryLoading={loading}
            isProcedureCompleted={isProcedureCompleted}
        />
    );
};
