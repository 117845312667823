import { useEffect, useState } from 'react';
import { setStockStatus } from 'redux/Filters/reducer';
import { useAppDispatch } from 'redux/hooks';
import { getAsync } from '@nplan';

interface IUseStockStatusData {
    stockStatus: StockStatus[];
}

export const useStockStatusData = (): IUseStockStatusData => {
    const [stockStatus, setHookStockStatus] = useState<StockStatus[]>([]);
    const dispatch = useAppDispatch();
    useEffect(() => {
        (async () => {
            const response = await getAsync<StockStatus[]>('stock-status');
            if (response.type === 'success' && response.data) {
                setHookStockStatus(response.data);
                dispatch(setStockStatus({ stockStatus: response.data }));
            }
        })();
    }, [dispatch]);

    return { stockStatus };
};