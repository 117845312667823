import { CSSProperties } from "react";

export const getJustifyContent = (alignId: number): CSSProperties['justifyContent'] => {
    switch (alignId) {
        case 1:
            return 'flex-start';
        case 2:
            return 'center';
        case 3:
            return 'flex-end';
        default:
            return 'flex-start';
    }
}