import { useCallback, useState } from 'react';

interface IUseDialog {
    open: boolean;
    id: number;
    handleCloseDialog: () => void;
    handleOpenDialog: (event: React.MouseEvent<HTMLButtonElement | HTMLLIElement> | number) => void;
}

export const useDialog = (): IUseDialog => {
    const [open, setOpen] = useState(false);
    const [id, setId] = useState<number>(0);

    const handleCloseDialog = useCallback((): void => {
        setOpen(false);
    }, []);

    const handleOpenDialog = useCallback((
        event: React.MouseEvent<HTMLButtonElement | HTMLLIElement> | number
    ): void => {
        if (typeof event === 'number' || typeof event === 'string') {
            setId(Number(event));
            setOpen(true);
            return;
        }
        event.preventDefault();
        const id = parseInt(event.currentTarget.dataset.id ?? '0');
        setId(id);
        setOpen(true);
    }, []);

    return {
        open,
        id,
        handleCloseDialog,
        handleOpenDialog
    };
};