import {
    Switch,
    TableBody,
    TableCell,
    TableRow,
} from '@mui/material';
import React from 'react';
import { useDataContext } from '@nplan';
import { useTableActions } from './hooks/useTableActions';
import { ActionButtons } from './components/ActionButtons';

interface IBodyProps {
    tables: Table[];
    onEditTable: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Body: React.FC<IBodyProps> = ({
    tables,
    onEditTable,
}): JSX.Element => {
    const { groups } = useDataContext();
    const { handleDeleteTable } = useTableActions();
    const getGroupName = (id: number) => {
        const group = groups.find((group) => group.id === id);
        return group?.name;
    };

    return (
        <TableBody>
            {tables.map((table) => (
                <TableRow hover key={table.id}>
                    <TableCell>{table.title}</TableCell>
                    <TableCell>{table.database}</TableCell>
                    <TableCell>{table.schema}</TableCell>
                    <TableCell>{table.name}</TableCell>
                    <TableCell>
                        {getGroupName(table.groupId)}
                    </TableCell>
                    <TableCell align="left">
                        <Switch checked={table.insertData} disabled />
                    </TableCell>
                    <TableCell align="left">
                        <Switch checked={table.deleteData} disabled />
                    </TableCell>
                    <TableCell align="left">
                        <Switch
                            checked={table.parameterTable}
                            disabled
                        />
                    </TableCell>
                    <TableCell align="left">
                        <Switch
                            checked={table.requiredFilter}
                            disabled
                        />
                    </TableCell>
                    <ActionButtons 
                        table={table}
                        onEditTable={onEditTable}
                        handleDeleteTable={handleDeleteTable}
                    />
                </TableRow>
            ))}
        </TableBody>
    );
};
