import { Box, TextField, useTheme } from '@mui/material'
import React, { HTMLInputTypeAttribute, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { NumericFormat } from 'react-number-format'
import { setEditableInputBorderColor } from 'redux/Filters/reducer'
import { useAppDispatch, useAppSelector } from 'redux/hooks'

interface Props {
    nInput?: boolean
    text?: string,
    textReactNode?: ReactNode
    inputValue: string | ReadonlyArray<string> | number | undefined | null,
    type: HTMLInputTypeAttribute,
    id: string,
    onExternalUserChange?: (value: any) => void,
    onLocalUserChange?: (value: React.SetStateAction<any>) => void,
    delay?: number,
    unit?: string,
    onChangeUnit?: () => void
    idDisabled?: boolean
}


function EditableInput(props: Props) {
    const { idDisabled, nInput, delay, text, id, inputValue, onExternalUserChange, type, unit, onChangeUnit, onLocalUserChange, textReactNode } = props
    const [localValue, setLocalValue] = useState(inputValue);
    const timeoutRef = useRef<NodeJS.Timeout>();
    const localDelay = delay ?? 500;
    const theme = useTheme()
    const dispatch = useAppDispatch()
    const borderColor = useAppSelector(state => state.filter.planningGrid.editableInputBorderColor)
    const inputRef = useRef<HTMLInputElement>(null); // Specify the type of ref
    const updateLocalValueDelayed = useCallback((userChangeableHandler: any) => {
        setLocalValue(userChangeableHandler.value);
        clearTimeout(timeoutRef.current);
        dispatch(setEditableInputBorderColor({ borderColor: 'red' }))
        // Check if the value has been changed by the user and is defined
        if (userChangeableHandler.changedByUser && userChangeableHandler.value !== undefined && userChangeableHandler.value !== null && (type !== 'number' || userChangeableHandler.value !== '')) {
            if (onExternalUserChange !== undefined) {
                timeoutRef.current = setTimeout(() => onExternalUserChange(userChangeableHandler), localDelay);
            } else if (onLocalUserChange !== undefined) {
                timeoutRef.current = setTimeout(() => onLocalUserChange(userChangeableHandler.value), localDelay);
            }
        }

    }, [dispatch, type, onExternalUserChange, onLocalUserChange, localDelay]);

    useEffect(() => {
        if (inputValue !== localValue)
            setLocalValue(inputValue)
    }, [inputValue]);

    useEffect(() => {
        if (borderColor === '#333') {
            if (inputRef.current) {
                inputRef.current.blur()
            }
        }
    }, [borderColor])

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', fontWeight: 500, gap: '0.5rem' }}>
            {textReactNode && <Box sx={{
                textAlign: 'left',
                fontSize: '12px',
                whiteSpace: 'nowrap',
                minWidth: 'min-content',
                flex: 2,
            }}>{text ?? textReactNode}</Box>}
            {
                nInput ?
                    (
                        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <NumericFormat
                                disabled={idDisabled}
                                value={localValue as string ?? 0}
                                thousandSeparator="."
                                decimalSeparator=','
                                inputProps={{
                                    style: {
                                        textAlign: 'center',
                                        borderColor: 'red'
                                    }
                                }}
                                inputRef={inputRef}
                                customInput={TextField}
                                onChange={event => {
                                    const formattedValue = event.currentTarget.value.replace(/[.,]/g, '');
                                    updateLocalValueDelayed({ value: formattedValue, changedByUser: true });
                                }}
                                InputProps={{
                                    style: {
                                        padding: '0.3rem',
                                        fontSize: '0.9rem',
                                        textAlign: 'center',
                                        background: theme.palette.background.default,
                                        height: '30px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    },

                                }}
                                sx={{
                                    width: '75%',
                                    "& .MuiOutlinedInput-root": {
                                        "&.Mui-focused fieldset": {
                                            borderColor: borderColor
                                        }
                                    }
                                }}
                            />
                        </Box>
                    )
                    :
                    (
                        <TextField
                            disabled={idDisabled}
                            value={localValue ?? 0}
                            id={id}
                            type={type}
                            size='small'
                            inputProps={{
                                style: {
                                    padding: '0.3rem',
                                    fontSize: '0.9rem',
                                    textAlign: 'center',
                                    background: theme.palette.background.default
                                }
                            }}
                            fullWidth
                            margin='none'
                            onChange={event => { updateLocalValueDelayed({ value: event.target.value, changedByUser: true }) }}
                            sx={{
                                flex: 1,
                                width: '100px'
                            }}
                        />
                    )
            }
            {textReactNode && onChangeUnit && (
                <Box
                    sx={{
                        display: 'flex',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        outline: '1px solid',
                        outlineColor: theme.palette.primary.contrastText,
                        width: '1.5rem',
                        height: '1.5rem',
                        textAlign: 'center',
                        ':hover': { cursor: 'pointer', filter: 'brightness(0.8)' },
                    }}
                    onClick={() => onChangeUnit()}
                >
                    {unit}
                </Box>
            )}

            {textReactNode && !onChangeUnit && (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '1.5rem',
                        height: '1.5rem',
                        textAlign: 'center',
                    }}
                >
                    {unit}
                </Box>
            )}

        </Box>
    )
}

export default EditableInput
