import { Box, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { Icons } from '@nplan';
import { t } from '@lingui/macro';

interface IContextMenuProps {
    position: MenuPosition | null;
    onClose: () => void;
    onRefreshDataAsync: () => Promise<void>;
    onAddRole: (event: number) => void;
}

export const ContextMenu: React.FC<IContextMenuProps> = ({
    onClose,
    position,
    onRefreshDataAsync,
    onAddRole,
}): JSX.Element => {
    const handleRefreshData = () => {
        onRefreshDataAsync();
        onClose();
    };
    const handleAddRole = () => {
        onAddRole(0);
        onClose();
    };
    return (
        <Menu
            anchorReference="anchorPosition"
            anchorPosition={
                position ? { top: position.y, left: position.x } : undefined
            }
            open={Boolean(position)}
            onClose={onClose}
        >
            <Box>
                <MenuItem onClick={handleRefreshData}>
                    <ListItemIcon>
                        <Icons.Refresh size={20} />
                    </ListItemIcon>
                    {t`Atualizar dados`}
                </MenuItem>
                <MenuItem onClick={handleAddRole}>
                    <ListItemIcon>
                        <Icons.AddGroup size={20} />
                    </ListItemIcon>
                    {t`Adicionar Grupo`}
                </MenuItem>
            </Box>
        </Menu>
    );
};
