import { NumberOperator } from '@nplan';

export const numberFilter = (
    row: any,
    column: Column,
) => {
    let value = row[column.field];
    if (value === null || value === undefined) value = 'null';
    const filterValue = column.filterValue;
    if (filterValue === null || filterValue === undefined) return true;
    if(filterValue === 'null' && value === 'null') return true; 
    switch (column.filterSignal) {
        case NumberOperator.Equal:
            return +value === +filterValue;
        case NumberOperator.NotEqual:
            return +value !== +filterValue;
        case NumberOperator.GreaterThan:
            return +value > +filterValue;
        case NumberOperator.GreaterThanOrEqual:
            return +value >= +filterValue;
        case NumberOperator.LessThan:
            return +value < +filterValue;
        case NumberOperator.LessThanOrEqual:
            return +value >= +filterValue;
        default:
            return false;
    }
};
