import { Box, Typography, useTheme } from '@mui/material';
import usePlanningGridStockValueAndColors from 'pages/planning-grid/hooks/Grid/usePlanningGridStockValueAndColors';
import { PlanningGridCommonWidth, PlanningGridData } from 'pages/planning-grid/types/PlanningGrid';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { GridChildComponentProps, areEqual } from 'react-window';
import { setEditableInputBorderColor, setItemRowSelectedProducts } from 'redux/Filters/reducer';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { putAsync } from 'services/api';
import { Dictionary } from 'types/Dictionary';
import { IPlanningGridFilterOptions } from 'types/system/IPlanningGridFilterOptions';
import { getContrastColor } from 'utils';
import { Icons } from 'utils/icons';
import EditableInput from '../components/EditableInput';
import { ResourceTippyComponent } from '../components/ResourceTippyComponent';

const PlanningGridRow = memo((props: GridChildComponentProps) => {
    const handleOnUnlockFromIndexToEnd = props.data.handleOnUnlockFromIndexToEnd;
    const handleOnLockFromStart = props.data.handleOnLockFromStart;
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const planningRowInput: Dictionary<PlanningGridData[]> = props.data.planningRowInput;
    const { getColorByStockStatusId, getStatusByStockStatusId } = usePlanningGridStockValueAndColors();
    const selectedPlanningGrid = useAppSelector((state) => state.filter.planningGrid.selectedPlanningGrid);
    const chartSelectedItemIds = useAppSelector((state) => state.filter.global.chartSelectedItemIds);
    const cellSize: number = props.data.calculateCellSize(props.rowIndex);
    const selectedItemIds = useAppSelector((state) => state.filter.global.selectedItemIds);
    const itemId = selectedItemIds[props.rowIndex];
    const isSelected = useMemo(() => { return chartSelectedItemIds.includes(itemId); }, [itemId, chartSelectedItemIds]);
    const planningGridByItemId = [...planningRowInput[itemId]];
    const sortedPlanningGridByItemId = planningGridByItemId.sort((a, b) => a.periodId - b.periodId);
    const planningGridByProductIdByPlanningGridIndex = sortedPlanningGridByItemId[props.columnIndex];
    const [localMps, setLocalMps] = useState(planningGridByProductIdByPlanningGridIndex.mps);
    useEffect(() => {
        setLocalMps(sortedPlanningGridByItemId[props.columnIndex].mps);
    }, [planningGridByProductIdByPlanningGridIndex, props.columnIndex, sortedPlanningGridByItemId]);
    const [isHovered, setIsHovered] = useState(false);
    const planningGridRowOptions = useAppSelector(state => state.filter.planningGrid.planningGridRowOptions);
    const handleOnSelectProduct = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        dispatch(setItemRowSelectedProducts({ ctrlKey: event.ctrlKey, shiftKey: event.shiftKey, itemId: itemId }));
    };

    const neoTipsLeft = useMemo(() => {
        return (
            planningGridByProductIdByPlanningGridIndex.locked ?
                <Icons.TiLockClosed size={20} onClick={() => handleOnUnlockFromIndexToEnd(itemId, props.columnIndex)} />
                :
                <Icons.TiLockOpen size={20} onClick={() => handleOnLockFromStart(itemId, props.columnIndex)} />
        );
    }, [handleOnLockFromStart, handleOnUnlockFromIndexToEnd, itemId, planningGridByProductIdByPlanningGridIndex.locked, props.columnIndex]);

    const handleInputs = useCallback(async (valueInputs: { value: number, changedByUser: boolean }, columnIndex: number) => {

        const { value } = valueInputs;
        const planningGrid = [...selectedPlanningGrid[itemId]];

        const planningGridSorted = planningGrid.sort((a, b) => a.periodId - b.periodId);
        const planningGridId = planningGridSorted[columnIndex].id;
        if (planningGridId === 0) return;
        const result = { planningGridId: planningGridId, manualMps: value };
        dispatch(setEditableInputBorderColor({ borderColor: theme.palette.primary.main }));
        await putAsync<any | string>(`/planninggrid/manual`, result!);
        await props.data.onReloadItemPlanningGrid([itemId]);
        dispatch(setEditableInputBorderColor({ borderColor: 'green' }));
        setTimeout(() => { dispatch(setEditableInputBorderColor({ borderColor: '#333' })); }, 1000);
    }, [dispatch, itemId, props.data, selectedPlanningGrid, theme.palette.primary.main]);

    function renderValuesBasedOnFilters(
        filters: IPlanningGridFilterOptions[],
        planningGridByProductIdByPlanningGridIndex: PlanningGridData
    ): JSX.Element[] {
        const resourceData = planningGridByProductIdByPlanningGridIndex.resourceData;
        let gradient;
        if (resourceData.length === 1) {
            gradient = resourceData[0].color; // If only one color exists, set it directly as the background color
        } else {
            gradient = `linear-gradient(to right, ${resourceData.map(data => data.color).join(', ')})`;
        }        // const gradient = 'linear-gradient(to right, red, orange,  green)';
        const trueValues: JSX.Element[] = [];
        for (const filter of filters) {
            if (filter.initialStatus) {
                const propertyName = filter.columnName;
                const propertyValue = (planningGridByProductIdByPlanningGridIndex as any)[propertyName];
                switch (propertyName) {
                    case 'mps':
                        trueValues.push(<Box key={filter.sequence} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                            <EditableInput
                                id='multipleBatch'
                                inputValue={localMps as number ?? 0}
                                onExternalUserChange={(value) => handleInputs(value, props.columnIndex)}
                                delay={750}
                                type='number'
                                nInput
                                idDisabled={planningGridByProductIdByPlanningGridIndex.locked ? true : false}
                            />
                        </Box>);
                        break;
                    case 'closingStockStatusId':
                        trueValues.push(
                            <Box key={filter.sequence}
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setTimeout(() => setIsHovered(false), 5000)}
                                sx={{
                                    position: 'relative', display: 'flex', flexDirection: 'row', alignItems: 'center',
                                }}>
                                <Box

                                    sx={{
                                        position: 'relative',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        ':hover': {
                                            cursor: 'pointer'
                                        }
                                    }}
                                >
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            width: '100%',
                                            height: '100%',
                                            background: planningGridByProductIdByPlanningGridIndex.locked
                                                ? 'repeating-linear-gradient(50deg, transparent, transparent 5px, #d1d5db 6px, #d1d5db 7px)'
                                                : 'transparent',
                                            borderRadius: '10px',
                                            padding: '3',
                                            opacity: '0.7',
                                        }}
                                    >
                                        {(isHovered || planningGridByProductIdByPlanningGridIndex.locked) && (
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    marginLeft: '-25px',
                                                }}
                                            >
                                                {neoTipsLeft}
                                            </Box>
                                        )}
                                    </Box>
                                    <Box >
                                        <Typography
                                            sx={{ borderRadius: '8px', padding: '0 5px', color: theme.palette.action.selected }}
                                            style={{ background: `${getColorByStockStatusId(planningGridByProductIdByPlanningGridIndex.closingStockStatusId)}` }} >
                                            {getStatusByStockStatusId(planningGridByProductIdByPlanningGridIndex.closingStockStatusId) ?? '-'}
                                        </Typography>
                                    </Box>
                                </Box>

                            </Box>
                        );
                        break;
                    case 'openingStockStatusId':
                        trueValues.push(
                            <Box key={filter.sequence}
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setTimeout(() => setIsHovered(false), 5000)}
                                sx={{
                                    position: 'relative', display: 'flex', flexDirection: 'row', alignItems: 'center',
                                }}>
                                <Box

                                    sx={{
                                        position: 'relative',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        ':hover': {
                                            cursor: 'pointer'
                                        }
                                    }}
                                >
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            width: '100%',
                                            height: '100%',
                                            background: planningGridByProductIdByPlanningGridIndex.locked
                                                ? 'repeating-linear-gradient(50deg, transparent, transparent 5px, #d1d5db 6px, #d1d5db 7px)'
                                                : 'transparent',
                                            borderRadius: '10px',
                                            padding: '3',
                                            opacity: '0.7',
                                        }}
                                    >
                                        {(isHovered || planningGridByProductIdByPlanningGridIndex.locked) && (
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    marginLeft: '-25px',
                                                }}
                                            >
                                                {neoTipsLeft}
                                            </Box>
                                        )}
                                    </Box>
                                    <Box >
                                        <Typography
                                            sx={{ borderRadius: '8px', padding: '0 5px', color: theme.palette.action.selected }}
                                            style={{ background: `${getColorByStockStatusId(planningGridByProductIdByPlanningGridIndex.openingStockStatusId)}` }} >
                                            {getStatusByStockStatusId(planningGridByProductIdByPlanningGridIndex.openingStockStatusId) ?? '-'}
                                        </Typography>
                                    </Box>
                                </Box>

                            </Box>
                        );
                        break;
                    case 'resourceData':
                        trueValues.push(
                            <Box key={filter.sequence}>
                                {
                                    resourceData.length === 0 ? (
                                        <Box sx={{
                                            backgroundColor: gradient,
                                            backgroundImage: gradient,
                                            display: 'flex',
                                            padding: '0 0.2rem',
                                            borderRadius: '4px'
                                        }}>
                                            <ResourceTippyComponent resourceData={resourceData} details={planningGridByProductIdByPlanningGridIndex.allocationDetails} notFound />
                                        </Box>
                                    ) : (
                                        <Box sx={{
                                            backgroundColor: gradient,
                                            backgroundImage: gradient,
                                            color: getContrastColor(resourceData[0].color),
                                            display: 'flex',
                                            padding: '0 0.2rem',
                                            borderRadius: '4px'
                                        }}>
                                            <ResourceTippyComponent resourceData={resourceData} details={planningGridByProductIdByPlanningGridIndex.allocationDetails} />
                                        </Box>
                                    )
                                }
                            </Box>
                        );
                        break;
                    case 'totalDaysOfCover':
                        trueValues.push(
                            <Typography key={filter.sequence}>{`${Math.floor(planningGridByProductIdByPlanningGridIndex.totalDaysOfCover as number)} d`}</Typography>
                        );
                        break;
                    default:
                        trueValues.push(
                            <Typography key={filter.sequence}>{propertyValue?.toLocaleString() ?? '-'}</Typography>
                        );
                        break;
                }
            }
        }
        return (trueValues.sort((a, b) => { return parseInt(a.key as string) - parseInt(b.key as string); }));
    }
    return (
        <Box  key={`${props.rowIndex}-${props.columnIndex}`} style={props.style}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    userSelect: 'none',
                    width: PlanningGridCommonWidth,
                    fontSize: '0.875rem',
                    backgroundColor: isSelected ? theme.palette.grey[300] : 'transparent',
                    borderTop: '1px solid',
                    borderColor: theme.palette.grey[400],
                    borderLeft: props.columnIndex === 0 ? '2px solid' : ''

                }}
                onClick={event => handleOnSelectProduct(event)}
                style={{ height: cellSize }}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                }}>

                    {renderValuesBasedOnFilters(planningGridRowOptions, planningGridByProductIdByPlanningGridIndex)}
                </Box>
            </Box>
        </Box>
    );
}, areEqual);

export default PlanningGridRow;