import { Box, Typography } from '@mui/material';
import { optionsToLanguage } from 'locales/helper';
import { IFilter, IInventorySummaryInfo, ISegmentationSummaryInfo } from 'pages/inventory-policy/types/IFilters';
import React from 'react';
import { ISupplyStrategy } from 'types/system/ISupplyStrategy';
import { EditableCards } from './EditableCards';
import { InventoryCards } from './InventoryCards';

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'start',
        overflow: 'auto',

    },
    rotatedText: {
        transform: 'rotate(-90deg)',
        fontWeight: 'bold',
    },
    cardContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'space-around',
        overflow: 'auto',
    },
};

interface ICardContainer {
    stockEditableTableValidation: {
        isEditable: boolean
    }
    filter: IFilter
    inventorySummaryInfo: IInventorySummaryInfo
    segmentationSummaryInfo: ISegmentationSummaryInfo
    stockStrategyOptions: ISupplyStrategy[]
}
export const CardContainer: React.FC<ICardContainer> = ({ stockEditableTableValidation, filter, inventorySummaryInfo, segmentationSummaryInfo, stockStrategyOptions }: ICardContainer): JSX.Element => {
    // const { stockEditableTableValidation, filter } = useAppSelector((state) => state.appStockState);
    // if (filter.axisData === undefined || filter.segmentationTypes === undefined) return <></>;
    return (
        <Box sx={styles.container} data-testid="inventory-cards" >
            <Box sx={{ display: 'flex', alignSelf: 'center' }}>
                <Typography variant="body2" sx={styles.rotatedText}>
                    {optionsToLanguage(filter.axisData?.yAxis.name ?? "")}
                </Typography>
            </Box>
            <Box sx={styles.cardContainer}>
                {stockEditableTableValidation.isEditable === false ? (
                    <InventoryCards filter={filter} inventorySummaryInfo={inventorySummaryInfo} />
                ) : (
                    <EditableCards filter={filter} segmentationSummaryInfo={segmentationSummaryInfo} stockStrategyOptions={stockStrategyOptions} />
                )}
            </Box>
        </Box>
    );
}
