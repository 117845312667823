import { Trans, t } from '@lingui/macro';
import { Box, Typography, useTheme } from '@mui/material';
import { TooltipSimplesComponent } from 'components/TippyComponent';
import useInventoryHealth from 'pages/inventory-policy/hooks/useInventoryHealth';
import { isNotEmptyObject } from 'pages/inventory-policy/utils/isNotEmptyObject';
import { useDataContext, Icons } from '@nplan';
import { useAppSelector } from 'redux/hooks';
import { InventoryHealthComponent } from '../../InventoryHealth(Chart)/InventoryHealth';
import { HealthSkeleton } from './Skeleton';


interface InventoryHealthContainerProps {
  isLeftSideFullScreen: boolean
  isRightSideFullScreen: boolean
  handleFullScreen: (name: string) => void
  handleExitFullScreen: () => void
}
export default function InventoryHealthContainer({ handleExitFullScreen, handleFullScreen, isLeftSideFullScreen, isRightSideFullScreen }: InventoryHealthContainerProps) {
    const selectedScenario = useDataContext().scenario;
    const selectedPeriodFilter = useAppSelector(state => state.filter.inventory.selectedPeriodFilter);
    const selectedCompanyFilter = useAppSelector(state => state.filter.inventory.selectedCompanyFilter);
    const selectedStockGroupFilter = useAppSelector(state => state.filter.inventory.selectedStockGroupFilter);
    const loadingHealth = useAppSelector(state => state.filter.inventory.loadingHealth);
    const inventoryHealthRedux = useAppSelector(state => state.filter.inventory.inventoryHealth);
    const { inventoryHealthSummaryData, maxValue } = useInventoryHealth(
        {
            inventoryHealthData: inventoryHealthRedux,
            selectedCompanyFilterRaw: selectedCompanyFilter,
            selectedStockGroupFilterRaw: selectedStockGroupFilter, selectedPeriod: selectedPeriodFilter
        }
    );
    
    const theme = useTheme();

    if (inventoryHealthRedux.length === 0 || !isNotEmptyObject(inventoryHealthSummaryData) || loadingHealth) return <HealthSkeleton />;
    return (
        <Box
            sx={{
                flexDirection: 'column',
                width: isLeftSideFullScreen ? '0%' : '50%',
                minWidth: isRightSideFullScreen ? '100%' : '50%',
                display: isLeftSideFullScreen ? 'none' : 'flex',
                borderColor: theme.palette.common.white,
                p: '0.1rem 0.5rem',
                overflow: 'hidden'
            }}>
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginBottom: '0.2rem' }}>
                <Typography variant="body1" fontWeight="bold" textAlign="left"><Trans>Situação do Estoque</Trans></Typography>
                {!isRightSideFullScreen ? (
                    <TooltipSimplesComponent tooltipText={t`Tela Cheia`} onClickFunction={() => handleFullScreen('right')} >
                        <Icons.FullScreen size={20} />
                    </TooltipSimplesComponent>
                ) : (
                    <TooltipSimplesComponent tooltipText={t`Tela Dividida`} onClickFunction={() => handleExitFullScreen()}>
                        <Icons.FullScreenExit size={20} />
                    </TooltipSimplesComponent>
                )}
            </Box>
            <InventoryHealthComponent
                inventoryHealth={inventoryHealthSummaryData}
                selectedScenario={selectedScenario}
                maxValue={maxValue}
            />
        </Box>
    );
}