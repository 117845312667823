import { 
    Button, 
    TableBody, 
    TableCell, 
    TableRow } from '@mui/material';
import { Trans } from '@lingui/macro';
import React from 'react';
import { useDataContext, Icons } from '@nplan';
import { useReportActions } from '../hooks/useReportActions';
import { useNavigate } from 'react-router-dom';

export interface IBodyProps {
    reports: NReport[];
    onChangeReportId: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Body: React.FC<IBodyProps> = ({
    reports,
    onChangeReportId,
}): JSX.Element => {
    const navigate = useNavigate();
    const { reportTypes } = useDataContext();
    const { handleDeleteReport } = useReportActions();

    const getTypeName = (id: number): string | undefined => {
        return reportTypes.find((type) => type.id === id)?.name;
    };
    
    return (
        <TableBody>
            {reports.map((report) => (
                <TableRow hover key={report.id}>
                    <TableCell>{report.name}</TableCell>
                    <TableCell>{report.description}</TableCell>
                    <TableCell
                        sx={{
                            maxWidth: 300,
                            overflow: 'hidden',
                        }}
                        title={report.url}
                    >
                        {report.url}
                    </TableCell>
                    <TableCell>{getTypeName(report.typeId)}</TableCell>
                    <TableCell align='right'>{report.order}</TableCell>
                    <TableCell>
                        <Button
                            variant='contained'
                            startIcon={<Icons.Edit />}
                            data-id={report.id}
                            onClick={onChangeReportId}
                        >
                            <Trans>Editar</Trans>
                        </Button>
                    </TableCell>
                    <TableCell>
                        <Button
                            variant='contained'
                            startIcon={<Icons.View />}
                            data-id={report.id}
                            onClick={() => navigate(`/reports/${report.id}`)}
                        >
                            <Trans>Visualizar</Trans>
                        </Button>
                    </TableCell>
                    <TableCell>
                        <Button
                            variant='contained'
                            startIcon={<Icons.Delete />}
                            data-id={report.id}
                            data-name={report.name}
                            onClick={handleDeleteReport}
                            color='error'
                        >
                            <Trans>Excluir</Trans>
                        </Button>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    );
};
