import { Dictionary } from 'types/Dictionary';
import { IPlanningGrid } from 'types/scenarios/IPlanningGrid';
import { FilterCriteria } from '../components/PlanningGrid/FilterDrawer/FilterCriteria/types';

export enum StockStatusType {
    // Shortage = 'Falta',
    Excess = 'excess',
    High = 'high',
    Normal = 'normal',
    Low = 'low',
    Stockout = 'stockout',
    Risk = 'risk',
    MTO = 'mto',
    // Locked = 'Trancado',
}


export enum GlobalPlanningGridTargetType {
    DailyGlobalTargetValue = 'DailyGlobalTargetValue',
    UnitaryGlobalTargetValue = 'UnitaryGlobalTargetValue'
}



export interface FixedFilterData {
    filterId: number
    label: string
    filterCriteria: FilterCriteria
    placeholder: string
    checked: boolean | undefined
}
export interface PlanningChartData {
    closingStock: number,
    openingStock: number,
    orders: number,
    mps: number,
    demand: number,
    minStock: number,
    targetStock: number,
    maxStock: number
    safetyStock: number
    locked: boolean,
    stockStatus: StockStatusType
    multipleReorder:number
}
export interface resourceData {
    description: string
    id: number
    itemId: number
    mpsQuantity: number
    operationType: string
    periodId: number
    planningGridId: number
    processTime: number
    resourceId: number
    productionType:string
    setupTime: number
    code: string
    color: string
    operationNo: number
}
export interface PlanningGridData extends IPlanningGrid {
    // stockStatusByFinalStock: StockStatusType;
    // stockStatusByInitalStock: StockStatusType;
    // globalMinStockType: GlobalPlanningGridTargetType
    // globalTargetStockType: GlobalPlanningGridTargetType;
    // globalSafetyStockType: GlobalPlanningGridTargetType;
    // resourceData: resourceData[]

    // isMpsHighlighted: boolean;
    // isOpeningStockHighlighted: boolean;
}

export interface AttributeListData {
    label: string;
    value: string;
    checked: boolean;
}

export interface ProductCellData {
    itemCode: string,
    itemDesc: string,
    id: number,
    openingStock: number,
    targetStock: number,
    currentStock: number
}
export interface GridCellHeaderData {
    groupCount: number,
    rankCount: number
}

export interface PlanningChartData {
    closingStock: number,
    openingStock: number,
    orders: number,
    mps: number,
    demand: number,
    minStock: number,
    targetStock: number,
    maxStock: number
    safetyStock: number
    locked: boolean,
    stockStatus: StockStatusType
    multipleReorder:number
}

export interface FilterData {
    text?: string
    searchCriteriasData: SearchCriteriasDictionary<any>
    itemIdsByResourceGroup: Dictionary<number[]>
    itemsIdsByAttributeId: ItemAttributesCustomBundle<number[]>
    itemsIdsTagId: TagsCustomBundle<number[]>
    itemIdsByStockStatus: Record<string, number[]>
    itemIdsByStockStatusByPeriod: Record<string, Record<number, number[]>>
    itemIdsByItemPlannerIds: Dictionary<number[]>
    itemIdsByStockGroupIds: Dictionary<number[]>
    itemIdsByLevel: Dictionary<number[]>
    itemIdsByCompany: Dictionary<number[]>
    itemIdsByComplexity: Dictionary<number[]>
    itemIdsByVolume: Dictionary<number[]>
    itemIdsByUncertainty: Dictionary<number[]>
    orderedItemIds: number[]
}

export interface SearchList<T> {
    label: string,
    id: string,
    data?: T
}

export type StockStatusDictionary<TValue> = {
    [Key in StockStatusType]: TValue
}
export type SearchCriteriasDictionary<TValue> = {
    [Key in FilterCriteria]: TValue | undefined
}




export interface OccupationPageFilterData {
    chartId: number,
    selectedResourceData: ResourceAggregator
}
export interface OccupationPageFilterDataPeriod extends OccupationPageFilterData {
    chartPeriodIndex: number
}
export interface ItemAttributesCustomBundle<TType> {
    [key: string]: Dictionary<TType>;
}

// export interface ItemAttributesCustomBundle<TType> {

//     ItemAttribute01: Dictionary<TType>,
//     ItemAttribute02: Dictionary<TType>,
//     ItemAttribute03: Dictionary<TType>,
//     ItemAttribute04: Dictionary<TType>,
//     ItemAttribute05: Dictionary<TType>,
//     ItemAttribute06: Dictionary<TType>,
//     ItemAttribute07: Dictionary<TType>,
//     ItemAttribute08: Dictionary<TType>,
//     ItemAttribute09: Dictionary<TType>,
//     ItemAttribute10: Dictionary<TType>
// }
export interface TagsCustomBundle<TType> {
    Tag01: Dictionary<TType>,
    Tag02: Dictionary<TType>,
    Tag03: Dictionary<TType>,
}
export const PlanningGridCommonWidth = 144;

export interface ResourceAggregator {
    resourceGroupName: string;
    resourceName: string;
    id: number;
    finiteCapacity: boolean
}
