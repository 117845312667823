import React from 'react';
import { Row, RowCellOptions } from '.';

interface IBodyProps {
    rows: any[];
    columns: Column[];
    maxIndex: number;
    deleteData?: boolean;
    onEditData: (data: any) => void;
}

const Body: React.FC<IBodyProps> = ({
    rows,
    columns,
    maxIndex,
    deleteData,
    onEditData,
}): JSX.Element => {
    return (
        <React.Fragment>
            {rows.map((row) => (
                <Row
                    style={{
                        display: 'contents'
                    }}
                    key={row.index}
                    row={row}
                    columns={columns}
                />
            ))}
            {rows.map((row) => (
                <RowCellOptions
                    maxIndex={maxIndex}
                    row={row}
                    deleteData={deleteData}
                    onEditData={onEditData}
                    key={row.index}
                    primaryKeyName={columns.find((column) => column.primaryKey)?.field}
                />
            ))}
        </React.Fragment>
    );
};
const MemoizedBody = React.memo(Body);
export { MemoizedBody as Body };