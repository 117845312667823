import { useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

const useChartConfig = (localOnDragStart: any, localOnDrag: any, localOnDragEnd: any, currentDataset: any, selectedProductText: string) => {
    const theme = useTheme();

    const chartConfig = useMemo(() => {
        return {
            tooltips: {
                backgroundColor: theme.palette.background.default,
                borderWidth: 0,
                borderColor: theme.palette.grey[300],
                bodyFontColor: theme.palette.text.primary,
                titleFontColor: theme.palette.text.primary
            },
            elements: {
                point: { radius: 0, hitRadius: 0, borderWidth: 0 },
                line: { tension: 0 }
            },
            maintainAspectRatio: false,
            scales: {
                y: {
                    ticks: {
                        color: theme.palette.text.primary
                    },
                    grid: {
                        color: theme.palette.background.paper,

                    }
                },
                x: {
                    ticks: {
                        color: theme.palette.text.primary
                    },
                    grid: {
                        color: theme.palette.background.paper,
                    }
                }
            },
            plugins: {
                dragData: {
                    round: 1,
                    showTooltip: true,
                    onDragStart: localOnDragStart,
                    onDrag: localOnDrag,
                    onDragEnd: (e: any, datasetIndex: any, index: any, value: any) => localOnDragEnd(e, datasetIndex, index, value)
                },
                title: {
                    display: true,
                    position: 'top',
                    text: `${currentDataset?.name
                    } | ${selectedProductText}`,
                    color: theme.palette.primary.contrastText,
                    font: {
                        size: 20,
                    }
                },
                legend: {
                    labels: {
                        color: theme.palette.primary.contrastText,
                        font: {
                            size: 16
                        }
                    }
                },
            }
        };
    }, [currentDataset?.name, localOnDrag, localOnDragEnd, localOnDragStart, selectedProductText, theme.palette.background.default, theme.palette.background.paper, theme.palette.grey, theme.palette.primary.contrastText, theme.palette.text.primary]);



    return { chartConfig };
};

export default useChartConfig;
