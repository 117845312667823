

import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';
import React from 'react';
import { deleteAsync, useConfirmContext } from '@nplan';

interface IUseReportActionsReturn {
    handleDeleteReport: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
}
export const useReportActions = (): IUseReportActionsReturn => {
    const confirm = useConfirmContext();
    const handleDeleteReport = async (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        const id = parseInt(event.currentTarget.dataset.id as string);
        const name = event.currentTarget.dataset.name as string;
        const confirmed = await confirm({
            description: (
                <React.Fragment>
                    <Typography>
                        <Trans>
                            Deseja realmente deletar ao relatório{' '}
                            <strong>{name}</strong>?
                        </Trans>
                    </Typography>
                </React.Fragment>
            ),
        });
        if (confirmed) {
            await deleteAsync(`reports/${id}`);
        }
    };

    return { handleDeleteReport };
};
