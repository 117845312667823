import React, { memo } from 'react';
import { useTheme } from '@mui/material';
import { HeaderCell } from '.';
import { NumberOperator, TextOperator } from '@nplan';

interface IHeaderProps {
    onFilterColumnSignalChange: (
        column: Column,
        signal: NumberOperator | TextOperator,
    ) => void;
    onFilterColumnValueChange: (column: Column, value: any) => void;
    columns: Column[];
    foreignFields: ForeignFields | null;
    getDistinctValuesForColumn: (field: string) => any[];
    onSortColumnChange: (id: number) => void;
    maxIndex: number;
}
const Header: React.FC<IHeaderProps> = ({
    columns,
    onFilterColumnSignalChange,
    onFilterColumnValueChange,
    getDistinctValuesForColumn,
    foreignFields,
    onSortColumnChange,
    maxIndex
}): JSX.Element => {
    const theme = useTheme();
    return (
        <div
            style={{
                display: 'contents',
                gridRow: 2
            }}
        >
            {columns.map((column) => (
                <HeaderCell
                    style={{
                        gridColumn: column.index,
                        position: 'sticky',
                        left: column.fixed ? column.init : 'unset',
                        top: 35,
                        backgroundColor: theme.tableHeader.background,
                        zIndex: column.fixed ? 5 : 4,
                        height: '70px',
                    }}
                    column={column}
                    key={column.index}
                    onFilterColumnSignalChange={onFilterColumnSignalChange}
                    onFilterColumnValueChange={onFilterColumnValueChange}
                    getDistinctValuesForColumn={getDistinctValuesForColumn}
                    foreignFields={foreignFields}
                    onSortColumnChange={onSortColumnChange}
                />
            ))}
            <div
                style={{
                    gridColumn: maxIndex,
                    position: 'sticky',
                    left: 'unset',
                    top: '35px',
                    right: 0,
                    backgroundColor: theme.tableHeader.background,
                    zIndex: 5,
                    height: '70px',
                }}
            />
            
        </div>
    );
};

const MemorizedHeader = memo(Header);
export { MemorizedHeader as Header };
