import { ClickAwayListener, TextField } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';

interface IColorEditorProps {
    defaultValue: string;
    onClose: () => void;
    onSave: (value: any) => void;
}
export const ColorEditor: React.FC<IColorEditorProps> = ({ defaultValue, onClose, onSave}): JSX.Element => {
    const [value, setValue] = useState<string>('');
    const handleClickAway = useCallback(() => {
        if (defaultValue !== value) {
            onSave(value);
        }
        onClose();
    }, [defaultValue, onClose, onSave, value]);
    const inputRef = useRef<HTMLInputElement>(null);
    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.key === 'Escape') {
                onClose();
                event.stopPropagation();
                event.preventDefault();
            } else if (event.key === 'Enter') {
                onSave(value === undefined ? 'null' : value);
                onClose();
                event.stopPropagation();
                event.preventDefault();
            }
        },
        [onClose, onSave, value],
    );
    useEffect(() => {
        setValue(defaultValue)
        if(inputRef.current) {
            inputRef.current.click();
        }
    }, [defaultValue]);
    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <TextField
                defaultValue={defaultValue}
                onChange={(e) =>setValue(e.target.value)}
                fullWidth
                autoFocus
                sx={{
                    height: 32,
                    padding: 0,
                }}
                inputProps={{
                    style: {
                        height: 32,
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingLeft: 5,
                        paddingRight: 5
                    },
                    ref: inputRef
                }}
                
                type='color'
                onKeyDown={handleKeyDown}
            />
        </ClickAwayListener>
    );
};
