import React from 'react';
import { Box, Paper, Typography, useTheme } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { getContrastColor } from 'utils/getContrastColor';
import { IFilter, IInventorySummaryInfo } from 'pages/inventory-policy/types/IFilters';
import { validateAndReturnDivisionNumber } from 'pages/inventory-policy/utils/validateAndReturnDivisionNumber';
import { validateAndReturnAbreviation } from 'pages/inventory-policy/utils/validateAndReturnAbreviation';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { optionsToLanguage } from 'locales/helper';
import { ToolTipComponent } from '../../../Tooltips/TooltipComponent';

interface InventoryCardsProps {
    inventorySummaryInfo: IInventorySummaryInfo;
    filter: IFilter;
}
export const InventoryCards: React.FC<InventoryCardsProps> = ({ inventorySummaryInfo, filter }: InventoryCardsProps): JSX.Element => {
    const { i18n } = useLingui()
    const { dataLinesArray, rowOptions } = inventorySummaryInfo;
    const theme = useTheme()
    const fontSize = '0.75rem'

    if (inventorySummaryInfo.dataLinesArray.length === 0) return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '500px' }}>
            <Typography variant='h6'>Não existem itens para os filtros selecionados!</Typography>
        </Box>
    )
    return (
        <>
            <Box sx={{ width: '100%', justifyContent: 'center', textAlign: 'center' }}>
                <Typography variant="body2" fontWeight={'bold'} sx={{ fontWeight: 'bold', padding: '2px', textDecoration: 'underline', margin: '0.1rem 0', color: theme.palette.text.primary }}>
                    {optionsToLanguage(filter.axisData?.xAxis.name as string)}
                </Typography>
            </Box>
            {dataLinesArray.map((lines, index) => {
                return (
                    <Box key={index} sx={{ display: 'flex', justifyContent: 'center', justifySelf: 'center', alignItems: 'center', width: 'fit-content' }}>
                        <Typography variant="body2" fontSize={fontSize} fontWeight='bold' sx={{ transform: 'rotate(-180deg)', writingMode: 'vertical-rl' }}>
                            {rowOptions[index]}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '1rem',
                                alignItems: 'center',
                                marginLeft: '0.3rem'
                            }}
                        >
                            {lines.map((card) => {
                                const fontColorCard = getContrastColor(card.color);
                                const fontColorTitle = getContrastColor('#F3F4F6');
                                return (
                                    <Paper
                                        elevation={3}
                                        sx={{
                                            display: 'flex',
                                            padding: '0.5rem',
                                            flexDirection: 'column',
                                            width: '180px',
                                            backgroundColor: card.color,
                                            color: fontColorCard,
                                            boxShadow: '0px 6px 24px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.08)',
                                            borderRadius: '10px',
                                            border: '1px solid',
                                            borderColor: theme.palette.primary.main,
                                            marginBottom: '0.5rem',
                                            gap: '0.4rem'
                                        }}
                                        key={uuidv4()}

                                    >
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography variant="body2" sx={{ fontSize: { fontSize }, fontWeight: 'bold', textAlign: 'center', backgroundColor: '#E5E7EB', color: fontColorTitle }}>
                                                {`${(card as any)[filter.axisData?.xAxis.classificationName as string]}`}
                                            </Typography>
                                            <Typography variant="body2" sx={{ fontSize: { fontSize }, fontWeight: 'bold', textAlign: 'center', backgroundColor: '#E5E7EB', color: fontColorTitle }}>
                                                {`${(card as any)[filter.axisData?.yAxis.classificationName as string]}`}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Typography variant='body2' fontSize={fontSize} whiteSpace={'nowrap'}>
                                                <Trans>Qtd. Itens</Trans>
                                            </Typography>
                                            <Typography variant='body2' fontSize={fontSize} fontWeight={'bold'}>
                                                {isNaN(card.itensQuantity) ? "0" : card.itensQuantity.toFixed(0)}
                                            </Typography>
                                        </Box>
                                        <ToolTipComponent
                                            color={card.color}
                                            type="Days"
                                            value={(card.safetyStockDays + card.cycleStockDays) as number}
                                            label={t`Est. Total (Dias)`}
                                            tooltipText={t`Est. Total = Est. Ciclo` + ' ' + card.cycleStockDays.toFixed(1) + ' + ' + t`Est.Seg` + ' ' + card.safetyStockDays.toFixed(1)}
                                        />

                                        <ToolTipComponent
                                            type="Value"
                                            color={card.color}
                                            value={(card.safetyStockValue + card.cycleStockValue) as number}
                                            label={t`Est. Total (Valor)`}
                                            tooltipText={t`Est. Total = Est. Ciclo` + ' ' + (card.cycleStockValue / validateAndReturnDivisionNumber(card.cycleStockValue)).toFixed(2) +
                                                validateAndReturnAbreviation(card.safetyStockValue) + ' + ' + t`Est.Seg` + ' ' + (card.safetyStockValue / validateAndReturnDivisionNumber(
                                                    card.safetyStockValue)).toFixed(2) + validateAndReturnAbreviation(card.safetyStockValue)}
                                        />
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Typography variant='body2' fontSize={fontSize} whiteSpace={'nowrap'}>
                                                <Trans>Nível Serviço</Trans>
                                            </Typography>
                                            <Typography variant='body2' fontSize={fontSize} fontWeight={'bold'}>
                                                {isNaN(card.serviceLevelPerc) ? "0" : card.serviceLevelPerc.toFixed(0) + " %"}
                                            </Typography>
                                        </Box>
                                    </Paper>
                                );
                            })}
                        </Box>
                    </Box>
                )
            })}
        </>
    );
};
