
import { TableContainer, Table } from '@mui/material';
import { Box } from '@mui/system';
import { 
    Pagination, 
    useContextMenu, 
    useDataContext, 
    usePagination, 
    TableBodySkeleton, 
    useDialog 
} from '@nplan';
import { useMemo } from 'react';
import { ManageScenario, ContextMenu, TableToolbar, Header, Body } from './components';
import { useSort } from './hooks/useSort';
import { getComparator } from './utils/getComparator';
import { stableSort } from './utils/stableSort';
import { useScenarioActions } from './hooks/useScenarioActions';

/**
 * Renders the ScenarioContainer component.
 * This component displays a table of scenarios and provides functionality for sorting, pagination, and managing scenarios.
 */
export const ScenarioContainer: React.FC = (): JSX.Element => {
    const {
        scenarios,
        loadingScenarios
    } = useDataContext();

    const {
        id,
        open,
        handleCloseDialog,
        handleOpenDialog
    } = useDialog();

    const {
        order,
        orderBy,
        handleChangeOrder,
        handleChangeOrderBy
    } = useSort();

    const {
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage
    } = usePagination();

    const {
        position,
        handleCloseMenu,
        handleOpenMenu
    } = useContextMenu();

    const visibleRows = useMemo(() => {
        return stableSort(
            scenarios,
            getComparator<Scenario>(order, orderBy as keyof Scenario),
        ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }, [order, orderBy, page, rowsPerPage, scenarios]);

    const {
        handleConfirmGenerateOrdersAsync,
        handleConfirmIntegrationAsync
    } = useScenarioActions();
    return (
        <Box padding={2}>
            <ManageScenario
                onClose={handleCloseDialog}
                open={open}
                scenarioId={id}
            />
            <ContextMenu 
                onClose={handleCloseMenu} 
                position={position} 
            />
            <TableToolbar 
                onCreateScenarioClick={handleOpenDialog} 
                onConfirmGenerateOrdersClick={handleConfirmGenerateOrdersAsync}
                onConfirmIntegrationClick={handleConfirmIntegrationAsync}
            />
            <TableContainer
                sx={{
                    minHeight: 130,
                    height: 'calc(100vh - 215px)',
                    display: 'flex',
                    flexDirection: 'column',
                }}
                onContextMenu={handleOpenMenu}
            >
                <Table sx={{ whiteSpace: 'nowrap' }}>
                    <Header
                        order={order}
                        orderBy={orderBy as keyof Scenario}
                        onOrderChange={handleChangeOrder}
                        onOrderByChange={handleChangeOrderBy}
                    />
                    {loadingScenarios ? (
                        <TableBodySkeleton
                            cols={12}
                            rows={12}
                        />
                    ) : (
                        <Body
                            visibleRows={visibleRows}
                            onEditScenarioClick={handleOpenDialog}
                        />
                    )}
                </Table>
            </TableContainer>
            <Pagination
                count={scenarios.length}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
            />
        </Box>
    );
};
