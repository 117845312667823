import { useCallback, useMemo, useState } from 'react';

interface IUseFilterReturn<T> {
    filteredDataLength: number;
    filteredData: T[];
    handleChangeFilterValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
    filterValue: string;
}

export const useFilter = <T extends { [s: string]: unknown; }>(
    data: T[],
    page: number,
    rowsPerPage: number,
): IUseFilterReturn<T> => {
    const [filterValue, setFilterValue] = useState<string>('');

    const handleChangeFilterValue = useCallback((
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        setFilterValue(event.target.value);
    },[]);

    const { filteredData, filteredDataLength } = useMemo(() => {
        const filtered = data.filter((item) => {
            const values = Object.values(item);
            return values.some(value => {
                if (typeof value === 'string') {
                    return value.toLowerCase().includes(filterValue.toLowerCase());
                }
                return false;
            });
        });

        const paginatedItems = filtered.slice(
            page * rowsPerPage,
            (page + 1) * rowsPerPage,
        );
        
        return {
            filteredData: paginatedItems,
            filteredDataLength: filtered.length,
        };

    }, [data, filterValue, page, rowsPerPage]);

    return {
        filteredData,
        filteredDataLength,
        filterValue,
        handleChangeFilterValue,
    };
};

