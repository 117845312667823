import { TextOperator } from '@nplan';

export const textFilter = (
    row: any,
    column: Column
) => {
    const value = row[column.field]?.toLowerCase();
    if (value === null || value === undefined) return false;
    const filterValue = column.filterValue?.toLowerCase();
    if (filterValue === null || filterValue === undefined) return true;
    switch (column.filterSignal) {
        case TextOperator.Equal:
            return value === filterValue;
        case TextOperator.NotEqual:
            return value !== filterValue;
        case TextOperator.Contains:
            return value.includes(filterValue);
        case TextOperator.NotContains:
            return !value.includes(filterValue);
        case TextOperator.StartsWith:
            return value.startsWith(filterValue);
        case TextOperator.EndsWith:
            return value.endsWith(filterValue);
        default:
            return false;
    }
};
