import { calculateWeeklyToMontlyPlanningGrids } from 'pages/planning-grid/utils/calculateWeeklyToMonthlyPlanningGrid';
import { forwardRef, useCallback } from 'react';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { GridOnScrollProps, VariableSizeGrid } from 'react-window';
import { lockPlanningGridFromStart, unlockPlanningGridFromIndexToEnd } from 'redux/Filters/reducer';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { IStockStatus } from 'types/system/IStockStatus';
import PlanningGridRow from './PlanningGridRow';

interface Props {
    columnCount: number | undefined;
    columnWidth: number;
    onScroll?: ((props: GridOnScrollProps) => any)
    calculateItemSize: (index: number) => number
    calculateCellSize: (index: number) => number
    stockStatus: IStockStatus[]
    onReloadItemPlanningGrid: (item: number[]) => void;
    gridRef: any
}
const PlanningGridRowContainer = forwardRef((props: Props, ref: any) => {
    const { stockStatus, columnCount, columnWidth, onScroll, calculateItemSize, calculateCellSize, onReloadItemPlanningGrid, gridRef } = props;
    const { selectedPlanningGrid, isExpandedPeriods, complexPeriods } = useAppSelector(state => state.filter.planningGrid);
    const { selectedItemIds } = useAppSelector(state => state.filter.global);
    const monthlyPlanningGrids = calculateWeeklyToMontlyPlanningGrids(selectedPlanningGrid, complexPeriods);
    const dispatch = useAppDispatch();

    const handleOnLockFromStart = useCallback((productId: number, productPeriodIndex: number) => {
        dispatch(lockPlanningGridFromStart({ itemId: productId, productPeriodIndex }));
    }, []);

    const handleOnUnlockFromIndexToEnd = useCallback((productId: number, productPeriodIndex: number) => {
        dispatch(unlockPlanningGridFromIndexToEnd({ itemId: productId, productPeriodIndex }));
    }, []);

    if (!columnCount) return;
    return (
        <AutoSizer>
            {(size: Size) => (
                <VariableSizeGrid
                    columnCount={columnCount}
                    columnWidth={() => columnWidth}
                    height={size.height}
                    width={size.width}
                    rowCount={selectedItemIds.length}
                    rowHeight={calculateItemSize}
                    onScroll={onScroll}
                    ref={gridRef}
                    itemData={{
                        planningRowInput: isExpandedPeriods ? selectedPlanningGrid : monthlyPlanningGrids,
                        calculateCellSize,
                        stockStatus,
                        handleOnLockFromStart,
                        handleOnUnlockFromIndexToEnd,
                        onReloadItemPlanningGrid
                    }}

                >
                    {PlanningGridRow}
                </VariableSizeGrid>
            )}
        </AutoSizer>
    );
});

export default PlanningGridRowContainer;


