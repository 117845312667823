import { stockDataFilterCriteria } from 'pages/inventory-policy/types/IFilters';
import { FiltersInterface } from './types';
import { IScenario } from 'types';

export const initialState: FiltersInterface = {
    planningGrid: {
        itemIdByResourceId: {},
        selectedResources: [],
        chartVisible: true,
        finiteButtonLoading: false,
        selectedOccupationProperty: 'id',
        editableInputBorderColor: '',
        completeStructureIds: [],
        isCompleteStructureUsed: false,
        completeStructureObject: {
            parentIds: [],
            selectedId: [],
            childIds: []
        },
        selectedPlanningGrid: {},
        manualMpsObject: {
            editedMpsObject: {
                manualMps: 0,
                planningGridId: 0
            },
            itemId: 0
        },
        planningGridRowOptions: [],
        planningGridById: {},
        planningGridOperationResource: {},
        planningGridOperationResourceByPeriodId: {},
        planningGridOperationResourceData: [],
        attributeListRaw: [],
        filteredPlanningGridItemIds: [],
        attributeListData: [],
        fixedFilterOptions: [],
        itemComplexityById: {},
        itemCompanyById: {},
        itemUncertaintyById: {},
        itemVolumeById: {},
        selectedAttributesToShow: [],
        selectedFiltersToShow: [],
        itemPlannerById: {},
        itemStockGroupById: {},
        complexPeriods: [],
        minifiedPeriods: [],
        periodsIdToIndex: {},
        periods: [],
        attributesInUse: [],
        itemById: {},
        redrawFilters: 0,
        itemIdsByLevel: {},
        isExpandedPeriods: true,
        itemAttributes: {
            ItemAttribute01: {},
            ItemAttribute02: {},
            ItemAttribute03: {},
            ItemAttribute04: {},
            ItemAttribute05: {},
            ItemAttribute06: {},
            ItemAttribute07: {},
            ItemAttribute08: {},
            ItemAttribute09: {},
            ItemAttribute10: {},
        },
        tags: {
            Tag01: {},
            Tag02: {},
            Tag03: {}
        },
        filterData: {
            itemIdsByItemPlannerIds: {},
            itemIdsByStockGroupIds: {},
            itemIdsByLevel: {},
            text: '',
            orderedItemIds: [],
            itemIdsByComplexity: {},
            itemIdsByUncertainty: {},
            itemIdsByVolume: {},
            itemIdsByCompany: {},
            searchCriteriasData: {
                None: undefined,
                Complexity: undefined,
                Uncertainty: undefined,
                Volume: undefined,
                Level: undefined,
                ItemPlanner: undefined,
                ItemStockGroup: undefined,
                // StockStatusType: undefined,
                StockStatusTypeWithPeriod: undefined,
                ItemCode: undefined,
                ResourceGroup: undefined,
                OccupationPageResourceAndPeriod: undefined,
                OccupationPageResourceGroup: undefined,
                ItemAttribute01: undefined,
                ItemAttribute02: undefined,
                ItemAttribute03: undefined,
                ItemAttribute04: undefined,
                ItemAttribute05: undefined,
                ItemAttribute06: undefined,
                ItemAttribute07: undefined,
                ItemAttribute08: undefined,
                ItemAttribute09: undefined,
                ItemAttribute10: undefined,
                Tag01: undefined,
                Tag02: undefined,
                Tag03: undefined,
                Company: undefined,
            },
            itemIdsByStockStatus: {
                Baixo: [],
                MTO: [],
                Alto: [],
                Excesso: [],
                Risco: [],
                Ruptura: [],
                Trancado: [],
                Normal: [],
                Falta: []
            },
            itemIdsByStockStatusByPeriod: {
                Baixo: {},
                MTO: {},
                Alto: {},
                Excesso: {},
                Risco: {},
                Ruptura: {},
                Trancado: {},
                Normal: {},
                Falta: {}
            },
            itemsIdsByAttributeId: {
                ItemAttribute01: {},
                ItemAttribute02: {},
                ItemAttribute03: {},
                ItemAttribute04: {},
                ItemAttribute05: {},
                ItemAttribute06: {},
                ItemAttribute07: {},
                ItemAttribute08: {},
                ItemAttribute09: {},
                ItemAttribute10: {},
            },
            itemsIdsTagId: {
                Tag01: {},
                Tag02: {},
                Tag03: {}
            },
            itemIdsByResourceGroup: {},
        },
    },
    global: {
        itemCodeSelectedItemIds: [],
        itemProperties: {},
        scenarioCriterias: [],
        scenarioParameters: [],
        scenariosRowsLocked: false,
        itemsComplete: [],
        items: [],
        periodTypes: [],
        planningGridFilterOptions: [],
        selectedItemIds: [],
        chartSelectedItemIds: [],
        stockStatus: [],
        scenario: {} as IScenario
    },
    inventory: {
        dataValidation: {
            classificationComplexityExists: false,
            classificationUncertaintyExists: false,
            classificationVolumeExists: false,
            companyExists: false,
            // customerGroupExists: false,
            stockGroupExists: false,
            salesChannelExists: false,
        },
        inventoryHealth: [],
        axisOptions: {},
        companyOptions: [],
        stockGroupOptions: [],
        salesChannelOptions: [],
        periodOptions: [],
        secondaryOptions: [],
        selectedAxis: { filter: stockDataFilterCriteria.complexity, xAxis: stockDataFilterCriteria.uncertainty, yAxis: stockDataFilterCriteria.volume },
        selectedCompanyFilter: { id: 0, name: 'Total', code: 'Total' },
        selectedSalesChannelFilter: { id: 0, name: 'Total', code: 'Total' },
        selectedSecondaryFilter: { id: 0, name: 'Total', code: 'Total' },
        selectedStockGroupFilter: { id: 0, name: 'Total', code: 'Total' },
        selectedPeriodFilter: { id: 1, name: 'S0', code: 'S0' },
        isEditable: false,
        inventoryHealthChartSelection: { id: 1, code: 'Value', name: ('Valor') },
        inventoryPolicyEditedObjects: [],
        stockTypeId: 1,
        loadingHealth: false
    }
};