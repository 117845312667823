import { useEffect, useState } from 'react';
import { getAsync } from '@nplan';
import { useParams } from 'react-router-dom';

interface IUseForeignKeysReturn {
    foreignFields: ForeignFields | null;
}

export const useForeignKeys = (): IUseForeignKeysReturn => {
    const { id } = useParams();
    const [foreignFields, setForeignFields] = useState<ForeignFields | null>(null);
    useEffect(() => {
        (async () => {
            if (id) {
                const response = await getAsync<ForeignFields>(`foreign-keys/table/${id}`);
                setForeignFields(response.data ?? null);
            } else {
                setForeignFields(null);
            }
        })();
    }, [id]);

    return {
        foreignFields,
    };
};
