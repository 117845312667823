import { Box, Typography } from "@mui/material";
import Tippy from "@tippyjs/react";
import { validateAndReturnAbreviation } from "pages/inventory-policy/utils/validateAndReturnAbreviation";
import { validateAndReturnDivisionNumber } from "pages/inventory-policy/utils/validateAndReturnDivisionNumber";


interface ToolTipProps {
    label: string;
    value: any;
    tooltipText: string;
    type: "Value" | `Days`;
    color: string;
}

export function ToolTipComponent({
    label,
    tooltipText,
    value,
    type,
    color,
}: ToolTipProps) {
    return (
        <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'space-between', alignItems: 'center' }}>
            <Tippy
                delay={[200, 50]}
                animation="fade"
                offset={[10, 10]}
                content={
                    <Box
                        sx={{
                            backgroundColor: color,
                            border: '1px solid',
                            borderColor: '#333',
                            borderRadius: '1rem',
                            fontWeight: 'semibold',
                            fontSize: '14px',
                            padding: '8px',
                            width: 'fit-content',
                            color: 'black'
                        }}
                    >
                        {tooltipText}
                    </Box>
                }
            >
                <Typography variant='body2' fontSize={'0.75rem'} whiteSpace={'nowrap'} sx={{ ':hover': { cursor: 'help' } }}>{label}</Typography>
            </Tippy>

            {type === "Value" ? (
                <Typography variant='body2' fontSize={'0.75rem'} fontWeight={'bold'} whiteSpace={'nowrap'}>
                    {isNaN(value)
                        ? "0"
                        : (value / validateAndReturnDivisionNumber(value)).toFixed(2) +
                        validateAndReturnAbreviation(value)}
                </Typography>
            ) : (
                <Typography variant='body2' fontSize={'0.75rem'} fontWeight={'bold'} whiteSpace={'nowrap'}>
                    {isNaN(value) ? "0" : Math.round(value) + " d"}
                    { }
                </Typography>
            )}
        </Box>
    );
}
