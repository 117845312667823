import { Trans, t } from '@lingui/macro';
import { Box, MenuItem, Paper, Select, TextField, Typography, useTheme } from '@mui/material';
import { optionsToLanguage } from 'locales/helper';
import { IFilter, ISegmentationSummaryInfo } from 'pages/inventory-policy/types/IFilters';
import { IInventorySegmentationData } from 'pages/inventory-policy/types/IInventorySegmentationData';
import { SupplyStrategyEditedObjectsProps } from 'pages/inventory-policy/types/types';
import { validateAndReplaceComma } from 'pages/inventory-policy/utils/ValidateAndReplaceComma';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { setInventoryPolicyEditedObjects } from 'redux/Filters/reducer';
import { useAppDispatch } from 'redux/hooks';
import { ISupplyStrategy } from 'types/system/ISupplyStrategy';
import { getContrastColor } from 'utils/getContrastColor';
import { ToolTipComponentOnlyText } from '../../../Tooltips/TooltipComponentOnlyText';

interface EditableCardsProps {
    stockStrategyOptions: ISupplyStrategy[]
    segmentationSummaryInfo: ISegmentationSummaryInfo
    filter: IFilter
}
export interface EditedObjectProps {
    id: number | null;
    supplyStrategyId?: number | null;
    safetyStockDays?: number | null;
    cycleStockDays?: number | null;
    serviceLevel?: number | null;
}
export function EditableCards({ filter, segmentationSummaryInfo, stockStrategyOptions }: EditableCardsProps) {
    const { rowOptions } = segmentationSummaryInfo;
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const [dataLinesArrayState, setDataLinesArrayState] = useState(segmentationSummaryInfo.dataLinesArray);
    useEffect(() => { setDataLinesArrayState(segmentationSummaryInfo.dataLinesArray); }, [segmentationSummaryInfo.dataLinesArray]);
    const [editedForm, setEditedForm] = useState<EditedObjectProps[]>([] as EditedObjectProps[]);


    const updateEditedItens = useCallback(async () => {
        dispatch(setInventoryPolicyEditedObjects({ array: editedForm as SupplyStrategyEditedObjectsProps[], }));
    }, [editedForm]);

    useEffect(() => { updateEditedItens(); }, [editedForm]);

    if (segmentationSummaryInfo.dataLinesArray.length === 0) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '500px' }}>
                <Typography variant='h6'>Não existem itens para os filtros selecionados!</Typography>
            </Box>
        );
    }
    function handleChange(id: number, type: string, value: string, card: IInventorySegmentationData) {
        const cardInfo = {
            classificationComplexityId: card.classificationComplexityId,
            classificationComplexity: card.classificationComplexity,
            classificationUncertaintyId: card.classificationUncertaintyId,
            classificationUncertainty: card.classificationUncertainty,
            classificationVolumeId: card.classificationVolumeId,
            classificationVolume: card.classificationVolume,
            salesChannelId: card.salesChannelId,
            salesChannel: card.salesChannel,
            stockGroupId: card.stockGroupId,
            stockGroup: card.stockGroup,
            customerGroupId: card.customerGroupId,
            customerGroup: card.customerGroup,
            supplyStrategyId: card.supplyStrategyId,
            supplyStrategy: card.supplyStrategy,
            companyId: card.companyId,
            company: card.company,
            color: card.color,
            safetyStockDays: card.safetyStockDays,
            cycleStockDays: card.cycleStockDays,
            serviceLevel: card.serviceLevel,
            notes: card.notes,
            active: card.active
        };

        if (
            type !== 'safetyStockDays' &&
            (validateAndReplaceComma(value) < 0 ||
                validateAndReplaceComma(value) > 100)
        ) {
            toast.warn('Os valores devem estar entre 0 e 100', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            return;
        }
        if (type === 'safetyStockDays') {
            const pattern = /^(C|[0-9]|[1-9][0-9]|100)$/i;
            if (!pattern.test(value)) {
                toast.warn(
                    "Os valores devem estar entre 0 e 100. Para campo calculado utilizar a letra 'c'.",
                    {
                        position: 'bottom-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'light',
                    }
                );
                return;
            }
        }

        switch (type) {
            case 'supplyStrategy':
                setEditedForm((state) => {
                    const objIndex = state.findIndex((obj) => obj.id === id);
                    const formattedValue = validateAndReplaceComma(value);
                    if (objIndex !== -1) {
                        return state.map((obj) => {
                            if (obj.id === id) {
                                return { ...obj, supplyStrategyId: formattedValue };
                            }
                            return obj;
                        });
                    } else {
                        const rest = { ...cardInfo, supplyStrategyId: formattedValue };
                        return [...state, { id, ...rest }];
                    }
                });
                break;
            case 'safetyStockDays':
                setEditedForm((state) => {
                    const objIndex = state.findIndex((obj) => obj.id === id);
                    const formattedValue = validateAndReplaceComma(value);
                    if (isNaN(formattedValue)) {
                        if (objIndex !== -1) {
                            return state.map((obj) => {
                                if (obj.id === id) {
                                    return { ...obj, safetyStockDays: null };
                                }
                                return obj;
                            });
                        } else {
                            const rest = { ...cardInfo, safetyStockDays: null };
                            return [...state, { id, ...rest }];
                        }
                    }
                    if (objIndex !== -1) {
                        return state.map((obj) => {
                            if (obj.id === id) {
                                return { ...obj, safetyStockDays: formattedValue };
                            }
                            return obj;
                        });
                    } else {
                        const rest = { ...cardInfo, safetyStockDays: formattedValue };
                        return [...state, { id, ...rest }];
                    }
                });
                break;
            case 'cycleStockDays':
                setEditedForm((state) => {
                    const objIndex = state.findIndex((obj) => obj.id === id);
                    const formattedValue = validateAndReplaceComma(value);
                    if (objIndex !== -1) {
                        return state.map((obj) => {
                            if (obj.id === id) {
                                return { ...obj, cycleStockDays: formattedValue };
                            }
                            return obj;
                        });
                    } else {
                        const rest = { ...cardInfo, cycleStockDays: formattedValue };
                        return [...state, { id, ...rest }];
                    }
                });
                break;
            case 'serviceLevel':
                setEditedForm((state) => {
                    const objIndex = state.findIndex((obj) => obj.id === id);
                    const formattedValue = validateAndReplaceComma(value);
                    if (objIndex !== -1) {
                        return state.map((obj) => {
                            if (obj.id === id) {
                                return { ...obj, serviceLevel: formattedValue };
                            }
                            return obj;
                        });
                    } else {
                        const rest = { ...cardInfo, serviceLevel: formattedValue };
                        return [...state, { id, ...rest }];
                    }
                });
                break;
        }
        return;
    }

    const inputProps = {
        sx: {
            color: 'black',
            borderRadius: '4px',
            padding: '0.1rem',
            width: '45px',
            textAlign: 'center',
            fontSize: '0.75rem',
            fontWeight: 'bold',
            height: '0.7rem'
        },
        min: 0,
        max: 100
    };
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', marginBottom: '3rem', justifyContent: 'start', alignItems: 'start' }}>
            <Box sx={{ width: '100%', justifyContent: 'center', textAlign: 'center' }}>
                <Typography variant="body2" fontWeight={'bold'} sx={{ fontWeight: 'bold', padding: '2px', textDecoration: 'underline', margin: '0.1rem 0', color: theme.palette.text.primary }}>
                    {optionsToLanguage(filter.axisData?.xAxis.name as string)}
                </Typography>
            </Box>
            {dataLinesArrayState.map((lines, index) => {
                return (
                    <Box key={index} sx={{ display: 'flex', justifyContent: 'center', justifySelf: 'center', alignItems: 'center', width: 'fit-content' }}>
                        <Typography variant="body2" fontSize={'0.75rem'} fontWeight='bold' sx={{ transform: 'rotate(-180deg)', writingMode: 'vertical-rl' }}>
                            {rowOptions[index] && rowOptions[index].name}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                widht: '100%',
                                gap: '1rem',
                                justifyContent: 'space-around',
                                alignItems: 'center',
                                flex: '1',
                                marginLeft: '0.3rem'
                            }}
                        >
                            {lines.sort((a, b) => a.id - b.id).map((card) => {
                                const fontColorCard = getContrastColor(card.color as string);
                                const fontColorTitle = getContrastColor('#F3F4F6');
                                return (
                                    <Paper
                                        data-testid="editable-card"
                                        elevation={3}
                                        sx={{
                                            display: 'flex',
                                            padding: '0.5rem',
                                            flexDirection: 'column',
                                            width: '185px',
                                            backgroundColor: card.color,
                                            color: fontColorCard,
                                            boxShadow: '0px 6px 24px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.08)',
                                            borderRadius: '10px',
                                            border: '1px solid',
                                            borderColor: theme.palette.primary.main,
                                            marginBottom: '0.5rem',
                                            gap: '0.3rem'
                                        }}
                                        key={card.id}
                                    >
                                        <Box
                                            sx={{
                                                whiteSpace: 'pre-line',
                                            }}>
                                            <Typography
                                                variant="body2"
                                                fontSize={'0.75rem'}
                                                sx={{
                                                    fontWeight: 'bold',
                                                    textAlign: 'center',
                                                    backgroundColor: '#E5E7EB',
                                                    color: fontColorTitle,
                                                }}
                                            >
                                                {`${(card as any)[filter.axisData?.xAxis.classificationName as string].name} ${card.company?.name ? `\n${card.company.name}` : ''} ${`\n${(card as any)[filter.axisData?.filter.classificationName as string].name}`} `}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Typography variant="body2" fontSize={'0.75rem'} whiteSpace={'nowrap'}>
                                                <Trans>Estratégia</Trans>
                                            </Typography>
                                            <Select
                                                id="supplyStrategy"
                                                variant="standard"
                                                size="small"
                                                inputProps={inputProps}
                                                defaultValue={
                                                    card.supplyStrategyId === null ? '' : card.supplyStrategyId
                                                }
                                                onChange={(e: any) => {
                                                    handleChange(card.id, 'supplyStrategy', e.target.value as string, card);
                                                }
                                                }
                                            >
                                                {stockStrategyOptions.map((option: any) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.code}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <ToolTipComponentOnlyText
                                                label={t`Estoque Seg.`}
                                                tooltipText={t`Para inserir como campo calculado, digitar a letra 'c'.`}
                                            />
                                            <TextField
                                                id="safetyStockDays"
                                                variant="standard"
                                                margin='none'
                                                type="text"
                                                size="small"
                                                inputProps={inputProps}
                                                defaultValue={
                                                    card.safetyStockDays === null
                                                        ? 'Calc.'
                                                        : card.safetyStockDays
                                                }
                                                onChange={(e) => {
                                                    handleChange(
                                                        card.id,
                                                        'safetyStockDays',
                                                        e.target.value.toLowerCase(),
                                                        card
                                                    );
                                                }
                                                }
                                            />
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Typography variant="body2" fontSize={'0.75rem'} whiteSpace={'nowrap'}>
                                                <Trans>Estoque Ciclo</Trans>
                                            </Typography>
                                            <TextField
                                                id="cycleStockDays"
                                                variant="standard"
                                                inputProps={inputProps}

                                                type="text"
                                                defaultValue={card.cycleStockDays ? card.cycleStockDays : ''}
                                                onChange={(e) =>
                                                    handleChange(
                                                        card.id,
                                                        'cycleStockDays',
                                                        e.target.value,
                                                        card
                                                    )
                                                }

                                            />
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Typography variant="body2" fontSize={'0.75rem'} whiteSpace={'nowrap'}>
                                                <Trans>Nível de Serviço (%)</Trans>
                                            </Typography>
                                            <TextField
                                                id="serviceLevel"
                                                variant="standard"
                                                inputProps={inputProps}
                                                type="text"
                                                defaultValue={card.serviceLevel ? card.serviceLevel : ''}
                                                onChange={(e) =>
                                                    handleChange(
                                                        card.id,
                                                        'serviceLevel',
                                                        e.target.value,
                                                        card
                                                    )
                                                }

                                            />
                                        </Box>
                                    </Paper>
                                );
                            })}
                        </Box>
                    </Box>
                );
            })}
        </Box>


    );
}
