import { Trans, t } from '@lingui/macro';
import { Box, Button, CircularProgress, Popover, TextField, Typography, useTheme } from '@mui/material';
import { TooltipSimplesComponent } from 'components/TippyComponent';
import { useScenarioData } from 'providers/data-provider/hooks/useScenarioData';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { setPlanningGridItem } from 'redux/Filters/reducer';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getAsync, putAsync } from 'services';
import { IPlanningGrid } from 'types';
import { Dictionary } from 'types/Dictionary';
import { Icons } from 'utils';



function PopoverWithForm() {
    const dispatch = useAppDispatch()
    const theme = useTheme();
    const itemIds = useAppSelector(state => state.filter.global.selectedItemIds)

    const scenarioId = useScenarioData().scenario?.id
    const [isWaiting, setIsWaiting] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [unitsOrDays, setUnitsOrDays] = useState(['days', 'days', 'days']);
    const [targetStockValue, setTargetStockValue] = useState(null);
    const [reorderPointValue, setReorderPoint] = useState(null);
    const [safetyStockValue, setSafetyStock] = useState(null);
    const [minimumLot, setMinimumLot] = useState(null);
    const [multipleLot, setMultipleLot] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const resetForm = () => {
        setUnitsOrDays(['days', 'days', 'days']);
        setTargetStockValue(null);
        setReorderPoint(null);
        setSafetyStock(null);
        setMinimumLot(null);
        setMultipleLot(null);
    };

    const handleChangeUnitsOrDays = (index) => {
        const updatedUnitsOrDays = [...unitsOrDays];
        updatedUnitsOrDays[index] = updatedUnitsOrDays[index] === 'days' ? 'units' : 'days';
        setUnitsOrDays(updatedUnitsOrDays);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        toast.info(t`Atualizando dados, por favor aguarde...`, {
            position: "top-right",
            autoClose: false, // Do not auto close, let it stay until the API call finishes
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        const result = {
            changedByUser: true,
            isDayMinStockValue: unitsOrDays[0] === 'days',
            isDaySafetyStockValue: unitsOrDays[1] === 'days',
            isDayTargetStockValue: unitsOrDays[2] === 'days',
            itemIds: itemIds,
            localMinStockValue: reorderPointValue,
            localSafetyStockValue: safetyStockValue,
            localTargetStockValue: targetStockValue,
            minimumReorder: minimumLot,
            multipleReorder: multipleLot,
            scenarioId,
        }
        setIsWaiting(true)
        handleClose();
        await putAsync<any | string>(`/planninggrid`, result!)
        const planningGrid = await getAsync<Dictionary<IPlanningGrid[]>>(`PlanningGrid/${scenarioId}/bulk?itemIds=${itemIds.join(',')}`);
        if (planningGrid.type === 'success' && planningGrid.data) {
            dispatch(setPlanningGridItem({ planningGrid: planningGrid.data as any }));
        }
        toast.dismiss();
        setIsWaiting(false)
        resetForm();
        handleClose();
        toast.success(t`Dados atualizados com sucesso!`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const boxStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        gap: '0.5rem',
        maxWidth: '75%'
    }
    const buttonStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '10px',
        height: '10px',
        borderRadius: '999px',
        padding: '0.8rem',
        backgroundColor: theme.palette.primary.main,
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        ":hover": { cursor: 'pointer', filter: 'brightness(0.9)' },
        color: theme.palette.text.primary
    }
    const inputProps = {
        sx: {
            paddingTop: '0.5rem',
            paddingBottom: '0.3rem',
            display: 'flex',
            alignItems: 'center',
            height: '20px'
        }
    }
    const labelProps = {
        sx: {
            fontSize: '0.8rem',
            textAlign: 'center'
        }
    }
    return (
        <div>
            <TooltipSimplesComponent
                onClickFunction={handleClick as any}
                tooltipText={t`Edição em Massa`}
            >
                {
                    isWaiting
                        ? <CircularProgress size={20} />
                        : <Icons.Edit color={theme.palette.primary.main} size={20} />


                }

            </TooltipSimplesComponent>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <form style={{ padding: 15, maxWidth: '250px' }} onSubmit={handleSubmit}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant='h6' sx={{ marginBottom: '0.5rem' }}>
                            <Trans>
                                Edição em Massa
                            </Trans>
                        </Typography>
                        <Box sx={{ ...boxStyles, maxWidth: '100%' }}>
                            <TextField
                                value={targetStockValue}
                                onChange={(e) => setTargetStockValue(e.target.value)}
                                inputProps={inputProps}
                                InputLabelProps={labelProps}
                                label={t`Estoque Alvo`}
                                type="number"
                                style={{ marginBottom: 10 }}
                            />
                            <Box
                                sx={buttonStyle}
                                onClick={() => handleChangeUnitsOrDays(0)}
                                style={{ marginBottom: 10 }}
                            >
                                {unitsOrDays[0] === 'days' ? 'D' : 'U'}
                            </Box>
                        </Box>
                        <Box sx={{ ...boxStyles, maxWidth: '100%' }}>
                            <TextField
                                value={reorderPointValue}
                                onChange={(e) => setReorderPoint(e.target.value)}
                                inputProps={inputProps}
                                InputLabelProps={labelProps}
                                label={t`Ponto de Reposição`}
                                type="number"
                                style={{ marginBottom: 10 }}
                            />
                            <Box
                                sx={buttonStyle}
                                onClick={() => handleChangeUnitsOrDays(1)}
                                style={{ marginBottom: 10 }}
                            >
                                {unitsOrDays[1] === 'days' ? 'D' : 'U'}
                            </Box>
                        </Box>
                        <Box sx={{ ...boxStyles, maxWidth: '100%' }}>
                            <TextField
                                value={safetyStockValue}
                                onChange={(e) => setSafetyStock(e.target.value)}
                                inputProps={inputProps}
                                InputLabelProps={labelProps}
                                label={t`Estoque de Segurança`}
                                type="number"
                                style={{ marginBottom: 10 }}
                            />
                            <Box
                                sx={buttonStyle}
                                onClick={() => handleChangeUnitsOrDays(2)}
                                style={{ marginBottom: 10 }}
                            >
                                {unitsOrDays[2] === 'days' ? 'D' : 'U'}
                            </Box>
                        </Box>
                        <Box sx={boxStyles}>
                            <TextField
                                value={minimumLot}
                                onChange={(e) => setMinimumLot(e.target.value)}
                                inputProps={inputProps}
                                InputLabelProps={labelProps}
                                label={t`Lote Mínimo`}
                                type="number"
                                style={{ marginBottom: 10 }}
                            />
                        </Box>
                        <Box sx={boxStyles}>
                            <TextField
                                value={multipleLot}
                                onChange={(e) => setMultipleLot(e.target.value)}
                                inputProps={inputProps}
                                InputLabelProps={labelProps}
                                label={t`Lote Múltiplo`}
                                type="number"
                                style={{ marginBottom: 10 }}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Button disabled={isWaiting} variant="contained" color="primary" type='submit' style={{ marginTop: 10 }} >
                                {isWaiting
                                    ? <CircularProgress size={25} sx={{ width: '100%' }} color='error' />
                                    : <Trans>Salvar</Trans>
                                }
                            </Button>
                            <Button variant="contained" color="primary" style={{ marginTop: 10 }} onClick={handleClose}>
                                <Trans>Cancelar</Trans>
                            </Button>
                        </Box>
                    </Box>
                </form>
            </Popover>
        </div>
    );
}

export default PopoverWithForm;
