import { forwardRef } from 'react';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { ListOnScrollProps, VariableSizeList } from 'react-window';
import { useAppSelector } from 'redux/hooks';
import { IScenario } from 'types';
import ItemRow from './ItemRow';

interface Props {
    itemListRef: any;
    itemSize: number;
    itemsLength: number;
    calculateItemSize: (index: number) => number;
    calculateCellSize: (index: number) => number;
    toggleSizeContainer: (index: number, itemId: number) => void;
    onScroll?: (props: ListOnScrollProps) => any;
    onReloadItemPlanningGrid: (item: number[]) => void;
    scenario: IScenario | null
    occupationOpen?: boolean;
}

const ItemRowListContainer = forwardRef((props: Props) => {
    const {
        onScroll,
        itemListRef,
        calculateItemSize,
        calculateCellSize,
        toggleSizeContainer,
        onReloadItemPlanningGrid,
        scenario,
        occupationOpen
    } = props;
    const { selectedItemIds } = useAppSelector(state => state.filter.global);
    return (
        <AutoSizer>
            {(size: Size) => (
                <VariableSizeList
                    height={size.height}
                    width={size.width}
                    style={{ overflow: 'scroll' }}
                    className="no-scrollbars"
                    itemCount={selectedItemIds.length}
                    itemSize={calculateItemSize}
                    onScroll={onScroll}
                    ref={itemListRef}
                    itemData={{
                        toggleSize: toggleSizeContainer,
                        calculateCellSize,
                        onReloadItemPlanningGrid,
                        scenario: scenario,
                        occupationOpen: occupationOpen
                    }}
                >
                    {ItemRow}
                </VariableSizeList>
            )}
        </AutoSizer>
    );
});

export default ItemRowListContainer;
