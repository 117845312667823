
import { Box, Typography } from "@mui/material";
import Tippy from "@tippyjs/react";
import { FcInfo } from "react-icons/fc";

interface ToolTipProps {
    tooltipText: string;
}

export function TooltipComponentInfo({
    tooltipText,
}: ToolTipProps) {
    return (
        <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'space-between', alignItems: 'center', height: '100%' }}>
            <Tippy
                delay={[200, 50]}
                animation="fade"
                offset={[10, 10]}
                content={
                    <Box
                        sx={{
                            border: '1px solid',
                            borderColor: '#333',
                            borderRadius: '1rem',
                            fontWeight: 'semibold',
                            fontSize: '0.75rem',
                            padding: '8px',
                            width: 'fit-content',
                            color: 'black',
                            backgroundColor: '#fff'
                        }}
                    >
                        <Typography variant="body2" fontSize='0.75rem'>
                            {tooltipText}
                        </Typography>
                    </Box>
                }
            >
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ":hover": { cursor: 'help' } }}>
                    <FcInfo size={'1rem'} />
                </Box>
            </Tippy>


        </Box>
    );
}
