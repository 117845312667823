import { t } from '@lingui/macro';
import { formatDate } from '../utils/formatDate';

export const tooltipFormatter = (params: any) => {


    if(params.seriesName === 'Capacidade'){
        return `
                ${t`Capacidade Disponível   `}: ${params.data.value}h<br/>
                ${t`Capacidade Indisponível`}: ${params.data.totalUnavailableHours}h<br/>
                ${t`Eficiência`}: ${ Number(params.data.efficiency)*100}%<br/>
                ${t`Calendário`}: ${params.data.name}<br/>`;
    }

    const productionTypes = params.data.productionType.split('&').map((type:any) => type.trim());
    const processTimeDescriptions = params.data.processTimeDescription.split(',');
    const mpsDetails = params.data.mpsDetails.split(',');
    const setupDetails = params.data.setupDetails.split(',');

    if (params.seriesType === 'bar' && params.seriesName !== 'Setup Total') {
        let tooltipContent = `<b>${t`Atributo de Visualização`}: ${params.data.property}</b><br/>
                ${t`Período`}: ${formatDate(params.data.periodStart)}<br/>
                ${t`Código`}: ${params.data.code}<br/>
                ${t`Descrição`}: ${params.data.description}<br/>
                ${t`Tipo de Operação`}: ${params.data.operationType}<br/>
                ${t`Número da Operação`}: ${params.data.operationNo}<br/>
                ${t`Tipo de Produção`}: ${params.data.productionType}<br/>`;

        tooltipContent += `<ul style="margin-top: 5px;">`;

        if (productionTypes.length > 1) {
            for (let i = 0; i < productionTypes.length; i++) {
                tooltipContent += `<br/> <li>${t`MPS ${productionTypes[i]}`}: ${mpsDetails[i]}</li>
                <li>${t`Tempo de Setup ${productionTypes[i]}`}: ${setupDetails[i]}</li>
                <li>${t`Tempo de Processo ${productionTypes[i]}`}: ${processTimeDescriptions[i]}</li>`;
            }
        }

        tooltipContent += `</ul>
                ${t`Tempo Total de Setup`}: ${params.data.setup}<br/>
                ${t`Tempo Total Processo`}: ${params.data.process.toFixed(2)}<br/>
                ${t`Tempo Total`}: ${params.data.total.toFixed(2)}<br/>
                ${t`MPS Total`}: ${params.data.mps}<br/>`;

        return tooltipContent;
    } else if (params.seriesType === 'bar' && params.seriesName === 'Setup Total') {
        return `${t`Período`}: ${formatDate(params.data.periodStart)}<br/>
                ${t`Descrição`}: ${params.data.description}<br/>
                ${t`Tempo Total`}: ${params.data.setup}<br/>`;
    } else {
        return `${t`Capacidade disponível`}: ${params.data.value}h<br/>
                ${t`Capacidade indisponível`}: ${params.data.totalUnavailableHours}h<br/>
                ${t`Eficiência`}: ${params.data.efficiency}%<br/>
                ${t`Calendário`}: ${params.data.name}<br/>`;
    }
};
