import { 
    Trans, 
    t 
} from '@lingui/macro';
import { 
    Box, 
    Button, 
    Dialog, 
    DialogContent, 
    DialogTitle, 
    TextField 
} from '@mui/material';
import { useReportConfigData } from './hooks/useReportConfigData';
import { LoadingButton } from '@mui/lab';


interface IManageConfigProps {
    open: boolean;
    onClose: () => void;
}
export const ManageConfig: React.FC<IManageConfigProps> = ({ 
    open, 
    onClose 
}): JSX.Element => {
    const { 
        config, 
        loading,
        handleInputChange,
        handleSaveConfigAsync,
    } = useReportConfigData(open, onClose);
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle align='center'>
                <Trans>Configurações do Superset</Trans>
            </DialogTitle>
            <DialogContent
                sx={{
                    minWidth: 400
                }}
            >
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={2}
                >
                    <TextField
                        value={config.url ?? ''}
                        variant='standard'
                        label={`URL`}
                        name='url'
                        onChange={handleInputChange}
                        disabled={loading}
                    />
                    <TextField
                        value={config.homeDashId ?? ''}
                        variant='standard'
                        label={`Dashboard Home Id`}
                        name='homeDashId'
                        onChange={handleInputChange}
                        disabled={loading}
                    />
                    <TextField
                        value={config.username ?? ''}
                        variant='standard'
                        label={t`Usuário`}
                        placeholder={t`Deixe em branco caso não queira alterar`}
                        name='username'
                        autoComplete='username'
                        onChange={handleInputChange}
                        disabled={loading}
                    />
                    <TextField
                        value={config.password ?? ''}
                        variant='standard'
                        label={t`Senha`}
                        placeholder={t`Deixe em branco caso não queira alterar`}
                        name='password'
                        type='password'
                        autoComplete='new-password'
                        onChange={handleInputChange}
                        disabled={loading}
                    />
                    <LoadingButton
                        loading={loading}
                        variant='contained'
                        onClick={handleSaveConfigAsync}
                    >
                        <Trans>
                            Salvar
                        </Trans>
                    </LoadingButton>
                    <Button
                        variant='contained'
                        onClick={onClose}
                        color='error'
                    >
                        <Trans>
                            Cancelar
                        </Trans>
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
