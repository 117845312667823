
export const selectFilter = (
    row: any,
    column: Column
) => {
    let value = row[column.field];
    if (value === null || value === undefined) value = 'null';	
    const filterValue = column.filterValue;
    if(!Array.isArray(filterValue) || filterValue.length === 0){
        return true;
    }
    return filterValue.includes(value);
};
