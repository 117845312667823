import { Box, useTheme } from '@mui/material';
import ReactEcharts from 'echarts-for-react';
import { IInventoryHealth } from 'pages/inventory-policy/types/IInventoryHealth';
import { IGenericOptions } from 'types/Generics';
import { useAppSelector } from 'redux/hooks';
import { t } from '@lingui/macro';
import ChartValueItemSelector from '../../Selectors/ChartValue&ItemSelector';

interface GraphComponentProps { inventoryHealth: IInventoryHealth; maxValue: any; }
export function GraphComponent({ inventoryHealth, maxValue }: GraphComponentProps) {
    const selectedGraphLabel = useAppSelector(state => state.filter.inventory.inventoryHealthChartSelection);
    const theme = useTheme();
    const selectOptions: IGenericOptions[] = [
        { id: 1, code: 'Value', name: t`Valor` },
        { id: 2, code: 'Item', name: t`Item` },
    ];

    const dataValues =
        inventoryHealth.inventoryHealthFilteredByCompany.map(c => {
            return {
                value: selectedGraphLabel.id === 1 ? Math.abs(c.value) : c.total,
                itemStyle: { color: c.stockStatusColor },
            };
        });
    if (dataValues.length === 0) return;

    const maximumAxisValue = selectedGraphLabel.id === 1 ? maxValue.maxValue : maxValue.maxTotal;

    const option = {
        axisPointer: {
            show: 'auto',
            z: '50',
            type: 'line',
            snap: false,
            triggerTooltip: true,
            triggerEmphasis: true,
            value: 'null',
            status: 'null',
            animation: 'null',
            animationDurationUpdate: 100
        },
        grid: {
            z: 0,
            left: '0',
            top: '100',
            right: '0',
            bottom: '-20',
            containLabel: true,
            borderWidth: 1,
            borderColor: theme.palette.grey[400],
            show: false
        },
        xAxis: {
            data: inventoryHealth.scenarioStatusOptions.map((status) => status.stockStatusName),
            show: false
        },
        yAxis: {
            show: false,
            type: 'value',
            max: maximumAxisValue,
            scale: false,
            axisLabel: {
                show: false,
                inside: true,
            },
            animation: true,
            animationEasingUpdate: 'cubitOut'
        },
        tooltip: {
            trigger: 'item',
            formatter: function (params: any) {
                if (selectedGraphLabel.id === 1) {
                    return params.data.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL', });
                } else {
                    return params.data.value;
                }
            },
        },
        series: [{
            type: 'bar',
            data: dataValues,
            barCategoryGap: '0.5%',
            itemStyle: {
                normal: {
                    label: {
                        show: true,
                        position: 'top',
                        color: theme.palette.text.primary,
                        fontSize: '0.8rem',
                        fontWeight: 'bold',
                        padding: [0, 0, 5, 0],
                        formatter: function (params: any) {
                            if (selectedGraphLabel.id === 1) {
                                return params.data.value.toLocaleString('pt-br', {
                                    style: 'currency',
                                    currency: 'BRL',
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0
                                });
                            } else {
                                return params.data.value;
                            }
                        },
                    }
                }
            }
        }],
    };
    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'right',
                position: 'relative',
            }}
        >
            <Box sx={{ position: 'absolute', top: '0', left: '0', zIndex: '10', display: 'flex', gap: '0.2rem', marginTop: '0.5rem' }}>
                <ChartValueItemSelector selectOptions={selectOptions} />
            </Box>
            <Box sx={{ height: '100%', width: '100%', }}>
                <ReactEcharts option={option} style={{ height: '200px' }} />
            </Box>
        </Box>
    );
}
