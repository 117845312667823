import { isValidDate } from 'pages/scenario/utils/isValidDate';

export const tryParseDateTimeToISO = (
    date: string | Date | null,
): string => {
    if (isValidDate(date)){
        return new Date(`${date}Z`)
            .toISOString()
            .substring(0, 19);
    }
    return '';
};

export default tryParseDateTimeToISO;
