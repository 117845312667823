import { Box, ClickAwayListener, Switch } from '@mui/material';
import { useEffect, useState } from 'react';

interface IBooleanEditorProps {
    defaultValue: boolean;
    onClose: () => void;
    onSave: (value: any) => void;
}
export const BooleanEditor: React.FC<IBooleanEditorProps> = ({ defaultValue, onClose, onSave}): JSX.Element => {
    const [value, setValue] = useState<boolean>(defaultValue);
    const handleClickAway = () => {
        if (defaultValue !== value) {
            onSave(value);
        }
        onClose();
    };
    const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === 'Escape') {
            onClose();
        } else if (event.key === 'Enter') {
            onSave(value);
            onClose()
        }
    };
    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Box
                sx={{
                    height: 32,
                    padding: '10px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: 2,
                    borderColor: 'primary.main',
                    borderRadius: 1,
                }}
            >
                <Switch
                    checked={value}
                    onChange={(e) => setValue(e.target.checked)}
                    onKeyDown={handleKeyDown}
                />
            </Box>
        </ClickAwayListener>
    );
};
