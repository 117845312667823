import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Box, FormControl, Slider, Typography, useTheme } from '@mui/material';

interface ICriteriaProps {
    scenarioCriteriaValue: ScenarioCriteriaValue[] | undefined;
    isNotEditable: boolean;
    getCriteria: (id: number) => ScenarioCriteria | undefined;
    onCriteriaSliderChange: (criteriaId: number, value: number) => void;
}
export const Criteria: React.FC<ICriteriaProps> = ({
    scenarioCriteriaValue,
    isNotEditable,
    getCriteria,
    onCriteriaSliderChange,
}: ICriteriaProps): JSX.Element => {
    const theme = useTheme();
    const { i18n } = useLingui();
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ marginBottom: '1rem', fontWeight: 'bold' }}>
                <Trans>Critérios de prioridade</Trans>
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '1',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid',
                    padding: '10px',
                    borderRadius: '8px',
                }}
            >
                {scenarioCriteriaValue?.map((criteria) => {
                    return (
                        <FormControl
                            key={criteria.scenarioCriteriaId}
                            sx={{
                                minWidth: 200,
                                display: 'flex',
                                flex: '1',
                                gap: '0.2rem',
                            }}
                        >
                            <Box
                                display={'flex'}
                                justifyContent={'space-between'}
                                width={'500px'}
                                height={'100%'}
                                alignItems={'center'}
                                gap={'1rem'}
                                padding={'0.5rem'}
                            >
                                <Typography >
                                    {i18n.t(getCriteria(criteria.scenarioCriteriaId)?.name ?? '')}
                                </Typography>
                                <Slider
                                    disabled={isNotEditable}
                                    value={criteria.value}
                                    max={10}
                                    min={0}
                                    valueLabelDisplay="on"
                                    size="medium"
                                    sx={{
                                        maxWidth: '225px',
                                        color: theme.palette.primary.dark,
                                        '&& .MuiSlider-valueLabel': {
                                            color: theme.palette.primary
                                                .contrastText,
                                        },
                                    }}
                                    onChange={(_event, newValue) =>
                                        onCriteriaSliderChange(
                                            criteria.scenarioCriteriaId,
                                            newValue as number,
                                        )
                                    }
                                    data-criteria-id={criteria.scenarioCriteriaId}
                                />
                            </Box>
                        </FormControl>
                    );
                })}
            </Box>
        </Box>
    );
};
