import React from 'react';
import {
    Box,
    Divider,
    List,
    ListItemButton,
    ListItemText,
    Popover,
    useTheme,
} from '@mui/material';
import { useDataContext } from '@nplan';
import { Icons } from 'utils/icons';
import { NotificationItem } from './components';
import { t } from '@lingui/macro';

interface INotificationMenuProps {
    open: boolean;
    anchorEl: HTMLElement | null;
    onClose: () => void;
}

export const NotificationMenu: React.FC<INotificationMenuProps> = ({ 
    open, 
    anchorEl, 
    onClose 
}): JSX.Element => {
    
    const {
        notifications,
        onLoadMoreNotificationsAsync,
        onMarkAllAsReadAsync,
        onMarkAsReadAsync,
    } = useDataContext();

    const theme = useTheme();
    return (
        <Popover
            anchorEl={anchorEl}
            open={open}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            onClose={onClose}
            sx={{
                borderRadius: 5,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxHeight: 400,
                    overflow: 'hidden',
                    maxWidth: 450,
                    minWidth: 450,
                    backgroundColor: theme.palette.background.default,
                }}
            >
                <List
                    sx={{
                        overflowY: 'auto',
                        maxHeight: 400,
                        backgroundColor: theme.palette.background.default,
                    }}
                >
                    {notifications.map((notification) => (
                        <React.Fragment
                            key={notification.id}
                        >
                            <NotificationItem
                                key={notification.id}
                                notification={notification}
                                onReadAsync={onMarkAsReadAsync}
                            />
                            <Divider variant='middle' />
                        </React.Fragment>
                    ))}
                </List>
                <Box
                    sx={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <ListItemButton
                        onClick={onLoadMoreNotificationsAsync}
                        sx={{
                            textAlign: 'center',
                            color: 'primary.main',
                            cursor: 'pointer',
                        }}
                    >
                        <ListItemText
                            primary={t`Carregar mais`}
                        />
                        <Icons.Refresh />
                    </ListItemButton>
                    <ListItemButton
                        onClick={onMarkAllAsReadAsync}
                        sx={{
                            textAlign: 'center',
                            color: 'primary.main',
                            cursor: 'pointer',
                        }}
                    >
                        <ListItemText
                            primary={t`Marcar todas como lidas`}
                        />
                        <Icons.EmailRead />
                    </ListItemButton>
                </Box>
            </Box>
        </Popover>
    );
};
