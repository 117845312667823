import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Box, useTheme } from '@mui/material';
import update from 'immutability-helper';
import { PeriodData } from 'pages/planning-grid/types/PeriodData';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { VariableSizeList } from 'react-window';
import { resetChartSelectedItems, resetCompleteStructure, resetFilters, setChartVisible, setExpandedPeriods } from 'redux/Filters/reducer';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Dictionary } from 'types/Dictionary';
import { Icons } from 'utils/icons';
import { TooltipSimplesComponent } from '../../../components/TippyComponent';
import { PlanningGridCommonWidth, PlanningGridData } from '../types/PlanningGrid';
import FilterDrawer from './PlanningGrid/FilterDrawer/FilterDrawer';
import GridHeaderColumnContainer from './PlanningGrid/GridHeader/GridHeaderColumnContainer';
import ItemRowListContainer from './PlanningGrid/PlanningGridItemRow(Left-Part)/ItemRowListContainer';
import PlanningGridRowContainer from './PlanningGrid/PlanningGridRow(Right-Part)/PlanningGridRowContainer';
import PopoverWithForm from './PlanningGrid/components/EditPopover';
import ImageCropperModal from './PlanningGrid/components/ModalImage';
import ScenarioDropDownSelector from './ScenarioDropDownSelector';

interface PlanningGridProps {
    isWeekly: boolean
    planningGridRowOptions: PlanningGridFilterOptions[]
    completeStructuresByItemMestre: any
    dynamicPeriodCount: number | undefined
    periods: Period[] | undefined
    complexPeriods: PeriodData[] | undefined
    stockStatus: StockStatus[]
    planningGridData: Dictionary<PlanningGridData[]>;
    onReloadItemPlanningGrid: (item: number[]) => void;
    onOccupationIconClick: () => void;
    scenario: Scenario | null
    occupationOpen?: boolean;
}

function PlanningGridContainer({ occupationOpen, scenario, onOccupationIconClick, isWeekly, stockStatus, completeStructuresByItemMestre, dynamicPeriodCount, periods, onReloadItemPlanningGrid }: PlanningGridProps) {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const { i18n } = useLingui();

    const { complexPeriods,planningGridRowOptions,isCompleteStructureUsed,chartVisible } = useAppSelector(state=>state.filter.planningGrid);
    const selectedItemIds = useAppSelector(state => state.filter.global.selectedItemIds);

    const headerColumnRef = useRef<VariableSizeList>(null);
    const itemListRef = useRef<any>(null);
    const gridRef = useRef<any>(null);

    const itemsLength = useMemo(() => { return selectedItemIds.length; }, [selectedItemIds.length]);
    const numberOfLinesOnPlanningGridRow = useMemo(() => { return planningGridRowOptions.filter(c => c.initialStatus === true).length; }, [planningGridRowOptions]);
    const calculatedItemSize = (1 + numberOfLinesOnPlanningGridRow) * 33 < 300 ? 320 : (1 + numberOfLinesOnPlanningGridRow) * 33;
    const handleGridScroll = useCallback((e: any) => {
        if (e.scrollUpdateWasRequested) return;
        itemListRef.current?.scrollTo(e.scrollTop);
        headerColumnRef?.current?.scrollTo(e.scrollLeft);
    }, []);
    const handleHeaderListScroll = useCallback((e: any) => {
        if (e.scrollUpdateWasRequested) { return; }
        gridRef.current?.scrollTo({ scrollLeft: e.scrollOffset });
    }, []);
    const handleProductScroll = useCallback((e: any) => {
        if (e.scrollUpdateWasRequested) return;
        gridRef.current?.scrollTo({ scrollTop: e.scrollOffset });
    }, []);
    const itemRowToggleSizeRef = useRef<boolean[]>(new Array(itemsLength).fill(false));
    const [rowSize, setRowSize] = useState<number[]>(new Array(itemsLength).fill(calculatedItemSize));
    const [cellSize, setCellSize] = useState<number[]>(new Array(itemsLength).fill(calculatedItemSize));
    useEffect(() => {
        setCellSize(new Array(itemsLength).fill(calculatedItemSize));
        setRowSize(new Array(itemsLength).fill(calculatedItemSize));
    }, [calculatedItemSize, itemsLength, numberOfLinesOnPlanningGridRow]);
    const calculateItemSize = (index: number) => { return rowSize[index]; };
    const calculateCellSize = (index: number) => { return cellSize[index]; };
    const handleToggleSizeContainer = (index: number, itemId: number) => {
        if (itemListRef.current) { itemListRef.current.resetAfterIndex(index); }
        if (gridRef.current) { gridRef.current.resetAfterRowIndex(index); }
        itemRowToggleSizeRef.current[itemId] = !itemRowToggleSizeRef.current[itemId];
        const completeStructuresByItemMestreById = completeStructuresByItemMestre[itemId];
        if (itemRowToggleSizeRef.current[itemId]) {
            setRowSize(update(rowSize, { [index]: { $set: completeStructuresByItemMestreById.length * calculatedItemSize } }));
            setCellSize(update(cellSize, { [index]: { $set: calculatedItemSize } }));
        } else {
            setRowSize(update(rowSize, { [index]: { $set: calculatedItemSize } }));
            setCellSize(update(cellSize, { [index]: { $set: calculatedItemSize } }));
        }
    };
    const handleCalendarClick = useCallback(() => { dispatch(setExpandedPeriods()); }, [dispatch]);
    const handleRemoveCompleteStructure = useCallback(() => { dispatch(resetCompleteStructure()); }, [dispatch]);

    const [open, setOpen] = useState(false);
    const onClose = () => {
        setOpen(!open);
    };
    return (
        <Box
            data-testid="planning-grid-container"
            sx={{
                display: 'flex',
                flexDirection: 'row',
                height: '100%',
                border: '1px solid',
                width: '100%',
                minHeight: 620,
                borderColor: '#e5e5e5',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexGrow: 1,
                    flexDirection: 'column',
                    maxWidth: 'fit-content',
                    height: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        height: '60px',
                        borderColor: '#e5e5e5',
                        top: 0,
                        zIndex: 10,
                        paddingLeft: 4,
                    }}
                >
                    <Box
                        sx={{
                            width: 'fit-content',
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                            gap: '0.5rem',
                            marginBottom: '4px',
                        }}
                    >
                        <Box data-testid="remove-filters" sx={{ display: 'flex', gap: '1rem' }}>
                            <ScenarioDropDownSelector />
                            <FilterDrawer />
                            <TooltipSimplesComponent
                                onClickFunction={() => dispatch(resetFilters())}
                                tooltipText={t`Remover Filtros`}
                            >
                                <Icons.RemoveFilter
                                    size={20}
                                    color={theme.palette.primary.main}
                                />
                            </TooltipSimplesComponent>
                            <TooltipSimplesComponent
                                onClickFunction={() => dispatch(resetChartSelectedItems())}
                                tooltipText={i18n.t('Limpar Seleção')}
                            >
                                <Icons.Clear color={theme.palette.primary.main} size={25} />
                            </TooltipSimplesComponent>
                            <TooltipSimplesComponent
                                onClickFunction={handleCalendarClick}
                                tooltipText={t`Mensal/Semanal`}
                            >
                                <Icons.Calendar
                                    size={20}
                                    color={theme.palette.primary.main}
                                />
                            </TooltipSimplesComponent>

                            <TooltipSimplesComponent
                                onClickFunction={onOccupationIconClick}
                                tooltipText={t`Visão de Ocupação`}
                            >
                                <Icons.Occupation
                                    color={theme.palette.primary.main}
                                    size={20}
                                />
                            </TooltipSimplesComponent>

                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <PopoverWithForm />
                            </Box>
                            <TooltipSimplesComponent
                                onClickFunction={() => dispatch(setChartVisible())}
                                tooltipText={t`Ocultar/Mostrar Gráfico`}
                            >
                                {
                                    chartVisible
                                        ? <Icons.ChartOff
                                            color={theme.palette.primary.main}
                                            size={20}
                                        />
                                        : <Icons.ChartOn
                                            color={theme.palette.primary.main}
                                            size={20}
                                        />
                                }

                            </TooltipSimplesComponent>
                            <Box sx={{ background: 'red' }}>
                                <ImageCropperModal onClose={onClose} open={open} />
                            </Box>
                            {isCompleteStructureUsed
                                ? <TooltipSimplesComponent
                                    onClickFunction={handleRemoveCompleteStructure}
                                    tooltipText={t`Remover Visão de Estrutura Completa`}
                                >
                                    <Icons.Cancel
                                        color={theme.palette.error.main}
                                        size={20}
                                    />
                                </TooltipSimplesComponent>
                                : null}
                        </Box>
                    </Box>
                </Box >
                <Box
                    data-testid="planning-grid-item-list-container"
                    sx={{
                        display: 'flex',
                        flexGrow: 1,
                        height: 'fit-content',
                        minWidth: '620px',
                    }}
                    key={cellSize[0]}
                >
                    <ItemRowListContainer
                        itemSize={calculatedItemSize}
                        onScroll={handleProductScroll}
                        ref={itemListRef}
                        calculateItemSize={calculateItemSize}
                        calculateCellSize={calculateCellSize}
                        itemListRef={itemListRef}
                        itemsLength={itemsLength}
                        toggleSizeContainer={handleToggleSizeContainer}
                        onReloadItemPlanningGrid={onReloadItemPlanningGrid}
                        scenario={scenario}
                        occupationOpen={occupationOpen}
                    />
                </Box>
            </Box >
            <Box
                sx={{
                    display: 'flex',
                    flexGrow: 1,
                    flexDirection: 'column',
                    height: '100%',
                }}
                key={cellSize[0]}
            >
                <Box
                    sx={{
                        display: 'flex',
                        height: '60px',
                        borderLeft: '2px solid',
                        borderColor: '#e5e5e5',
                        top: 0,
                        zIndex: 10,
                    }}
                >
                    <GridHeaderColumnContainer
                        complexPeriods={complexPeriods}
                        isWeekly={isWeekly}
                        ref={headerColumnRef}
                        onScroll={handleHeaderListScroll}
                        periods={periods}
                    />
                </Box>
                <Box
                    data-testid="planning-grid-row"
                    sx={{
                        display: 'flex',
                        flexGrow: 1,
                        height: 'fit-content',
                    }}
                >
                    <PlanningGridRowContainer
                        columnCount={dynamicPeriodCount}
                        columnWidth={PlanningGridCommonWidth}
                        calculateItemSize={calculateItemSize}
                        calculateCellSize={calculateCellSize}
                        onScroll={handleGridScroll}
                        ref={gridRef}
                        stockStatus={stockStatus}
                        onReloadItemPlanningGrid={onReloadItemPlanningGrid}
                        gridRef={gridRef}
                    />
                </Box>
            </Box>
        </Box>
    );
}

export const PlanningGridContainerMemo = React.memo(PlanningGridContainer);
