import { ItemAttributesCustomBundle, TagsCustomBundle } from "pages/planning-grid/types/PlanningGrid";
import { Dictionary } from "types/Dictionary";
import { IItem, IItemPartial } from "types/data/IItem";
import { ItemAttributesBundle } from "./types";

export const createAttributesFilter = (
    itemIds: number[],
    itemById: Dictionary<IItem>,
    itemAttributes: ItemAttributesBundle
) => {

    const localAttributesFilter: ItemAttributesCustomBundle<number[]> = {};
    Object.keys(itemAttributes).forEach(attributeKey => {
        localAttributesFilter[attributeKey] = {};
    });

    const pushToGenericAttribute = <TAttribute>(itemId: number, itemAttribute: TAttribute, attributeId?: number) => {
        if (!attributeId) return
        if (!(itemAttribute as any)[attributeId]) {
            (itemAttribute as any)[attributeId] = []
        }
        (itemAttribute as any)[attributeId].push(itemId);
    }
    for (let i = 0; i < itemIds.length; i++) {
        const item = itemById[itemIds[i]];
        if (!item) continue;
        Object.keys(itemAttributes).forEach(attributeKey => {
            const lowerCaseAttributeKey = attributeKey.charAt(0).toLowerCase() + attributeKey.slice(1);
            const attributeId = (item as any)[`${lowerCaseAttributeKey}Id`] as number;
            pushToGenericAttribute(item.id, localAttributesFilter[attributeKey], attributeId);
        });
    }
    return localAttributesFilter;
};

export const createTagsFilter = (
    itemIds: number[],
    itemById: Dictionary<IItem>,
) => {

    const localTagFilter: TagsCustomBundle<number[]> = {
        Tag01: {},
        Tag02: {},
        Tag03: {},
    };
    const pushToGenericAttribute = <TAttribute>(itemId: number, itemAttribute: TAttribute, attributeId?: number) => {
        if (!attributeId) return
        if (!(itemAttribute as any)[attributeId]) {
            (itemAttribute as any)[attributeId] = []
        }
        (itemAttribute as any)[attributeId].push(itemId);
    }

    for (let i = 0; i < itemIds.length; i++) {
        const item = itemById[itemIds[i]];
        if (!item) continue;

        pushToGenericAttribute(item.id, localTagFilter.Tag01, item.tag01Id as number);
        pushToGenericAttribute(item.id, localTagFilter.Tag02, item.tag02Id as number);
        pushToGenericAttribute(item.id, localTagFilter.Tag03, item.tag03Id as number);
    }

    return localTagFilter;
};



export const getItemDataById = (items, property) => {
    const itemDataById = {};

    items.forEach(item => {
        const id = item[property + 'Id'];

        if (id) {
            itemDataById[id] = {
                id: id,
                name: item[property]?.name,
                code: item[property]?.code
            };
        }
    });

    return itemDataById;
};
