import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAsync } from '@nplan';

export const useLogData = () => {
    const { id } = useParams();
    const [logs, setLogs] = useState<TableEditionLog[] | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const handleRefreshLogs = useCallback(async () => {
        setLoading(true);
        const response = await getAsync<TableEditionLog[]>(`/table-edition-logs/table/${id}`);
        if(response.type === 'success' && response.data) {
            setLogs(response.data);
        } else {
            setLogs([]);
        }
        setLoading(false);
    },[id]);
    return { 
        logs, 
        loading, 
        handleRefreshLogs 
    };
};