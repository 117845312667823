import { t } from '@lingui/macro';
import { MenuItem, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icons } from 'utils/icons';
interface IAdminConfigs {
    onClose: () => void;
}
export const AdminConfigs: React.FC<IAdminConfigs> = ({
    onClose,
}): JSX.Element => {
    const navigate = useNavigate();
    const navigateAndClose = useCallback((route: string) => {
        navigate(route);
        onClose();
    }, [navigate, onClose]);
    return (
        <React.Fragment>
            <MenuItem
                onClick={() => navigateAndClose('/users')}
            >
                <Icons.User
                    size={20}
                    style={{ marginLeft: 2 }}
                />
                <Typography
                    variant='body1'
                    sx={{ ml: 1 }}
                >
                    {t`Usuários`}
                </Typography>
            </MenuItem>
            <MenuItem
                onClick={() => navigateAndClose('/roles')}
            >
                <Icons.Group
                    size={20}
                    style={{ marginLeft: 2 }}
                />
                <Typography
                    variant='body1'
                    sx={{ ml: 1 }}
                >
                    {t`Grupos`}
                </Typography>
            </MenuItem>
            <MenuItem
                onClick={() => navigateAndClose('/tables')}
            >
                <Icons.Table
                    size={20}
                    style={{ marginLeft: 2 }}
                />
                <Typography
                    variant='body1'
                    sx={{ ml: 1 }}
                >
                    {t`Tabelas`}
                </Typography>
            </MenuItem>
            <MenuItem
                onClick={() => navigateAndClose('/reports')}
            >
                <Icons.Analytics
                    size={20}
                    style={{ marginLeft: 2 }}
                />
                <Typography
                    variant='body1'
                    sx={{ ml: 1 }}
                >
                    {t`Relatórios`}
                </Typography>
            </MenuItem>
        </React.Fragment>
    );
};
