import { useLingui } from '@lingui/react';
import { Box, Typography, TypographyOwnProps } from '@mui/material';
import { getTextAlign } from 'pages/table-data/utils';
import React, { memo } from 'react';

const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };

interface IDateRenderProps {
    row: any;
    column: Column;
}

export const DateRender: React.FC<IDateRenderProps> = ({
    row,
    column,
}: IDateRenderProps): JSX.Element => {
    const { i18n } = useLingui();
    
    const getValue = () => {
        const value = row[column.field];
        if(value === null || value === column.nullText) return column.nullText ?? i18n.t('Nulo');
        if(column.viewTypeId === 3) return new Date(value).toLocaleDateString(i18n.locale, options);
        if(column.viewTypeId === 4) return new Date(value).toLocaleTimeString(i18n.locale, options).replace(',','');
    };
    return (
        <Box
            sx={{
                height: '100%',
                width: column.width,
                maxWidth: column.width,
                display: 'flex',
                alignItems: 'center',
                padding: '0 5px',
                boxSizing: 'border-box',
            }}
            title={getValue()}
        >
            <Typography
                sx={{
                    width: '100%',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textAlign: getTextAlign(
                        column.alignId,
                    ) as TypographyOwnProps['align'],
                }}
            >
                {column.prefix && `${column.prefix} `}
                {getValue()}
                {column.suffix && ` ${column.suffix}`}
            </Typography>
        </Box>
    );
};

const MemoizedDateRender = memo(DateRender);
export default MemoizedDateRender;
