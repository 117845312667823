import { CSSProperties, memo } from 'react';
import {
    BooleanFilter,
    NumberFilter,
    SelectFilter,
    TextFilter,
} from './filter';

import { Box, Typography } from '@mui/material';
import { Icons } from 'utils/icons';
import { useLingui } from '@lingui/react';
import { NumberOperator, TextOperator } from 'enums';

interface IHeaderCellProps {
    style: CSSProperties;
    column: Column;
    onFilterColumnSignalChange: (
        column: Column,
        signal: NumberOperator | TextOperator,
    ) => void;
    onFilterColumnValueChange: (column: Column, value: any) => void;
    foreignFields: ForeignFields | null;
    getDistinctValuesForColumn: (field: string) => any[];
    onSortColumnChange: (id: number) => void;
}

const HeaderCell: React.FC<IHeaderCellProps> = ({
    style,
    column,
    onFilterColumnSignalChange,
    onFilterColumnValueChange,
    onSortColumnChange,
    getDistinctValuesForColumn
}) => {
    const { i18n } = useLingui();
    return (
        <div
            style={ {
                ...style,
                paddingLeft: 5,
                paddingRight: 5,
            } }
        >
            <Box
                onClick={ () => onSortColumnChange(column.id) }
                sx={ {
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    cursor: 'pointer',
                    width: column.width,
                    maxWidth: column.width,
                } }
            >
                <Typography
                    sx={ {
                        margin: 0,
                        display: 'flex',
                        alignItems: 'center',
                        height: '35px',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        width: '100%',
                    } }
                    title={
                        column.tooltip !== ''
                            ? `${i18n.t(column.header)}: ${column.tooltip}`
                            : `${i18n.t(column.header)}`
                    }
                >
                    { i18n.t(column.header) }
                </Typography>
                <Box
                    sx={ {
                        cursor: 'pointer',
                        minWidth: 20,
                        width: 20,
                        height: 35,
                        display: 'flex',
                        alignItems: 'center',
                    } }
                >
                    { column.sortDirection === 'asc' && <Icons.SortAsc /> }
                    { column.sortDirection === 'desc' && <Icons.SortDesc /> }
                </Box>
            </Box>

            { column.filterTypeId === 1 && (
                <BooleanFilter
                    column={ column }
                    onFilterColumnValueChange={ onFilterColumnValueChange }
                />
            ) }
            {/* { column.filterTypeId === 2 && (
                <DateFilter
                    column={ column }
                    onFilterColumnValueChange={ onFilterColumnValueChange }
                    getDistinctValuesForColumn={ getDistinctValuesForColumn }
                    getForeignKeys={ getForeignKeys }
                />
            ) } */}
            { column.filterTypeId === 3 && (
                <NumberFilter
                    column={ column }
                    onFilterColumnSignalChange={ onFilterColumnSignalChange }
                    onFilterColumnValueChange={ onFilterColumnValueChange }
                />
            ) }
            { column.filterTypeId === 4 && (
                <SelectFilter
                    column={ column }
                    onFilterColumnValueChange={ onFilterColumnValueChange }
                    getDistinctValuesForColumn={ getDistinctValuesForColumn }
                />
            ) }
            { column.filterTypeId === 5 && (
                <TextFilter
                    column={ column }
                    onFilterColumnSignalChange={ onFilterColumnSignalChange }
                    onFilterColumnValueChange={ onFilterColumnValueChange }
                />
            ) }
        </div>
    );
};
const MemoizedHeaderCell = memo(HeaderCell);

export { MemoizedHeaderCell as HeaderCell };
