import { Box } from '@mui/material';
import { useReportViewData } from './hooks';

export const ReportView: React.FC = (): JSX.Element => {
    const { ref, report } = useReportViewData();

    return report?.typeId === 1 ? (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            ref={ref}
        />
    ) : (
        <iframe
            title={report?.name}
            width='100%'
            height='98%'
            src={report?.url}
            allowFullScreen={true}
        ></iframe>
    );
};
