import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { getAsync, postAsync, putAsync } from '@nplan';

const defaultButton: Button = {
    id: 0,
    name: '',
    tableId: 0,
    procedureName: '',
    procedureSchema: '',
    waitForResult: false,
    refreshOnFinish: false,
};

interface IUseManageButtonReturn {
    button: Button;
    loading: boolean;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSwitchChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    handleSaveAsync: () => Promise<void>;
}

export const useManageButton = (
    buttonId: number,
    open: boolean,
    onClose: () => void,
): IUseManageButtonReturn => {

    const { id } = useParams();
    const [button, setButton] = useState<Button>(defaultButton);
    const [loading, setLoading] = useState(false);

    const handleInputChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            const { name, value } = event.target;
            setButton((prev) => ({
                ...prev,
                [name]: value,
            }));
        },
        [],
    );

    const handleSwitchChange = useCallback(
        (
            event: React.ChangeEvent<HTMLInputElement>,
            checked: boolean,
        ): void => {
            const { name } = event.target;
            setButton((prev) => ({
                ...prev,
                [name]: checked,
            }));
        },
        [],
    );

    const handleSaveAsync = useCallback(async () => {
        setLoading(true);
        const res = button.id === 0
            ? await postAsync('buttons', button)
            : await putAsync('buttons', button);
        setLoading(false);
        if (res.type === 'success') {
            onClose();
        }
    }, [button, onClose]);

    useEffect(() => {
        if (!open) return;
        if(!id) return;
        setButton({ ...defaultButton, tableId: Number(id) });
        (async () => {
            if (buttonId > 0) {
                setLoading(true);
                const response = await getAsync<Button>(`buttons/${buttonId}`);
                if (response.type === 'success' && response.data) {
                    setButton(response.data);
                }
                setLoading(false);
            }
        })();
    }, [buttonId, onClose, id, open]);

    return {
        button,
        loading,
        handleInputChange,
        handleSwitchChange,
        handleSaveAsync,
    };
};
