import { UIEvent, useCallback, useState } from 'react';
import { flushSync } from 'react-dom';

export interface IScroll {
    top: number;
    left: number;
}
interface IUseScroll {
    scroll: IScroll;
    handleScroll: (event: UIEvent<HTMLDivElement>) => void;
}
export const useScroll = (): IUseScroll => {
    const [scroll, setScroll] = useState<IScroll>({ top: 0, left: 0 });
    const handleScroll = useCallback((event: UIEvent<HTMLDivElement>): void => {
        const { scrollTop, scrollLeft } = event.target as HTMLDivElement;
        flushSync(() => {
            setScroll({ top: scrollTop, left: scrollLeft });
        });
    },[]);
    return { scroll, handleScroll };
};