import { Trans } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { 
    Box, 
    Button, 
    Dialog, 
    DialogActions,
    DialogContent, 
    DialogTitle, 
    FormControl, 
    TextField, 
    Typography, 
    useTheme
} from '@mui/material';
import { Icons } from 'utils/icons';
import tryParseDateTimeToISO from 'utils/tryParseDateTimeToISO';

interface IDateSchedulerProps {
    open: boolean;
    onClose: () => void;
    onSave: () => void;
    onChangeScenarioExecutionTime: (event: React.ChangeEvent<HTMLInputElement>) => void;
    scenario?: Scenario
    saving: boolean;
}
export const DateScheduler: React.FC<IDateSchedulerProps> = ({ 
    open, 
    scenario,
    saving,
    onClose, 
    onSave, 
    onChangeScenarioExecutionTime, 
}): JSX.Element => {
    const theme = useTheme();
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>
                <Trans>Agendar Execução</Trans>
            </DialogTitle>
            <DialogContent>
                <Box>
                    <FormControl>
                        <Typography>
                            <Trans>Data de Execução</Trans>
                        </Typography>
                        <TextField
                            type='datetime-local'
                            variant='standard'
                            name='executionDate'
                            inputProps={{
                                step: 1,
                                lang: 'pt-BR',
                            }}
                            onChange={onChangeScenarioExecutionTime}
                            value={tryParseDateTimeToISO(scenario?.executionDate ?? '')}
                        />
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    color='error'
                    startIcon={<Icons.Cancel />}
                    variant={theme.palette.mode === 'dark' ? 'outlined' : 'contained'}
                    disabled={saving}
                >
                    <Trans>Cancelar</Trans>
                </Button>
                <LoadingButton
                    variant={theme.palette.mode === 'dark' ? 'outlined' : 'contained'}
                    startIcon={<Icons.Save />}
                    onClick={onSave}
                    loading={saving}
                >
                    <Trans>Agendar</Trans>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};