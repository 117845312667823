import {
    Dialog,
    DialogContent,
    DialogTitle,
    Box,
    TextField,
    FormControlLabel,
    Switch,
    Button,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
} from '@mui/material';
import { Trans, t } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { useManageTable } from './hooks/useManageTable';
import { useDataContext } from 'providers';

interface IManageTableProps {
    open: boolean;
    onClose: () => void;
    tableId: number;
}

export const ManageTable: React.FC<IManageTableProps> = ({
    open,
    onClose,
    tableId,
}): JSX.Element => {
    const { groups } = useDataContext();
    const {
        table,
        handleInputChange,
        handleSwitchChange,
        handleSelectChange,
        handleSaveAsync,
        loading,
        databaseTables,
    } = useManageTable(
        tableId, 
        open,
        onClose
    );
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
            <DialogTitle textAlign={'center'}>
                {table?.id === 0 ? (
                    <Trans>Adicionar Tabela</Trans>
                ) : (
                    <Trans>Editar Tabela</Trans>
                )}
            </DialogTitle>
            <DialogContent>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={2}
                >
                    <TextField
                        value={table.title}
                        variant="standard"
                        label={t`Título`}
                        name="title"
                        onChange={handleInputChange}
                        disabled={loading}
                    />
                    <FormControl
                        variant="standard"
                        sx={{ minWidth: 120 }}
                    >
                        <InputLabel
                            id="table-group-label"
                            sx={{ userSelect: 'none' }}
                        >
                            <Trans>Grupo</Trans>
                        </InputLabel>
                        <Select
                            value={table.groupId}
                            labelId="table-group-label"
                            variant="standard"
                            name="groupId"
                            fullWidth
                            onChange={handleSelectChange}
                            disabled={loading}
                        >
                            {groups.map((group) => (
                                <MenuItem
                                    key={group.id}
                                    value={group.id}
                                >
                                    {group.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl
                        variant="standard"
                        sx={{ minWidth: 120 }}
                    >
                        <InputLabel
                            id="table-database-label"
                            sx={{ userSelect: 'none' }}
                        >
                            <Trans>Banco de Dados</Trans>
                        </InputLabel>
                        <Select
                            value={table.database}
                            labelId="table-database-label"
                            variant="standard"
                            name="database"
                            fullWidth
                            onChange={handleSelectChange}
                            disabled={loading}
                        >
                            {databaseTables.map((database) => (
                                <MenuItem
                                    key={database.databaseName}
                                    value={database.databaseName}
                                >
                                    {database.databaseName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl
                        variant="standard"
                        sx={{ minWidth: 120 }}
                    >
                        <InputLabel
                            id="table-schema-label"
                            sx={{ userSelect: 'none' }}
                        >
                            <Trans>Esquema da Tabela</Trans>
                        </InputLabel>
                        <Select
                            value={table.schema}
                            labelId="table-schema-label"
                            variant="standard"
                            name="schema"
                            fullWidth
                            onChange={handleSelectChange}
                            disabled={table.database === '' || loading}
                        >
                            {databaseTables
                                .find(
                                    (f) =>
                                        f.databaseName ===
                                        table.database,
                                )
                                ?.schemas.map((schema) => (
                                    <MenuItem
                                        key={schema.schemaName}
                                        value={schema.schemaName}
                                    >
                                        {schema.schemaName}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl
                        variant="standard"
                        sx={{ minWidth: 120 }}
                    >
                        <InputLabel
                            id="table-name-label"
                            sx={{ userSelect: 'none' }}
                        >
                            <Trans>Nome da Tabela</Trans>
                        </InputLabel>
                        <Select
                            value={table.name}
                            labelId="table-name-label"
                            variant="standard"
                            name="name"
                            fullWidth
                            onChange={handleSelectChange}
                            disabled={
                                table.database === '' ||
                                table.schema === '' ||
                                loading
                            }
                        >
                            {databaseTables
                                .find(
                                    (f) =>
                                        f.databaseName ===
                                        table.database,
                                )
                                ?.schemas.find(
                                    (f) =>
                                        f.schemaName === table.schema,
                                )
                                ?.tables.map((table) => (
                                    <MenuItem
                                        key={table}
                                        value={table}
                                    >
                                        {table}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControlLabel
                        sx={{ userSelect: 'none' }}
                        control={
                            <Switch
                                checked={table.customSql}
                                name="customSql"
                                onChange={handleSwitchChange}
                                disabled={loading}
                            />
                        }
                        label={t`Sql Customizado`}
                    />
                    {table.customSql && (
                        <TextField
                            value={table.sqlStatement ?? ''}
                            variant="standard"
                            label={t`Sql Statment`}
                            name="sqlStatement"
                            multiline
                            rows={5}
                            onChange={handleInputChange}
                            disabled={loading}
                        />
                    )}
                    <FormControlLabel
                        sx={{ userSelect: 'none' }}
                        control={
                            <Switch
                                checked={table.limitedPlanner}
                                name="limitedPlanner"
                                onChange={handleSwitchChange}
                                disabled={loading}
                            />
                        }
                        label={t`Limitado por Planejador`}
                    />
                    <FormControlLabel
                        sx={{ userSelect: 'none' }}
                        control={
                            <Switch
                                checked={table.insertData}
                                name="insertData"
                                onChange={handleSwitchChange}
                                disabled={loading}
                            />
                        }
                        label={t`Inserir`}
                    />
                    <FormControlLabel
                        sx={{ userSelect: 'none' }}
                        control={
                            <Switch
                                checked={table.deleteData}
                                name="deleteData"
                                onChange={handleSwitchChange}
                                disabled={loading}
                            />
                        }
                        label={t`Deletar`}
                    />
                    <FormControlLabel
                        sx={{ userSelect: 'none' }}
                        control={
                            <Switch
                                checked={table.parameterTable}
                                name="parameterTable"
                                onChange={handleSwitchChange}
                                disabled={loading}
                            />
                        }
                        label={t`Tabela de Parâmetros`}
                    />
                    <FormControlLabel
                        sx={{ userSelect: 'none' }}
                        control={
                            <Switch
                                checked={table.requiredFilter}
                                name="requiredFilter"
                                onChange={handleSwitchChange}
                                disabled={loading}
                            />
                        }
                        label={t`Filtro Obrigatório`}
                    />
                    {table.requiredFilter && (
                        <TextField
                            value={table.columnRequiredFilter}
                            variant="standard"
                            label={t`Coluna`}
                            name="columnRequiredFilter"
                            onChange={handleInputChange}
                            disabled={loading}
                        />
                    )}
                    <LoadingButton
                        variant="contained"
                        onClick={handleSaveAsync}
                        loading={loading}
                    >
                        <Trans>Salvar</Trans>
                    </LoadingButton>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={onClose}
                    >
                        <Trans>Cancelar</Trans>
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
