import { Box, Table, TableContainer } from '@mui/material';
import {
    Header,
    Body,
    ContextMenu,
    ManageButton,
} from './components';
import { useButtonData } from './hooks/useButtonData';
import { Pagination, TableBodySkeleton, useContextMenu, useDialog, useFilter, usePagination } from '@nplan'

export const TableEditionButton: React.FC = (): JSX.Element => {
    const {
        buttons,
        handleRefreshDataAsync,
        loading
    } = useButtonData();
    const {
        id,
        open, 
        handleCloseDialog,
        handleOpenDialog
    } = useDialog();
    const {
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
    } = usePagination();
    const { 
        position, 
        handleOpenMenu, 
        handleCloseMenu 
    } = useContextMenu();
    const {
        filteredData,
        filteredDataLength,
        filterValue,
        handleChangeFilterValue
    } = useFilter(
        buttons,
        page, 
        rowsPerPage
    )
    return (
        <Box padding={2}>
            <ManageButton
                open={open}
                onClose={handleCloseDialog}
                buttonId={id}
            />
            <ContextMenu
                position={position}
                onClose={handleCloseMenu}
                onRefreshDataAsync={handleRefreshDataAsync}
                onAddButton={handleOpenDialog}
            />
            <TableContainer
                sx={{
                    minHeight: 130,
                    height: 'calc(100vh - 125px)',
                    display: 'flex',
                    flexDirection: 'column',
                }}
                onContextMenu={handleOpenMenu}
            >
                <Table sx={{ whiteSpace: 'nowrap' }}>
                    <Header
                        filterValue={filterValue}
                        onChangeFilterValue={handleChangeFilterValue}
                    />
                    {loading ? (
                        <TableBodySkeleton rows={5} cols={7} />
                    ) : (
                        <Body
                            buttons={filteredData}
                            onEditButton={handleOpenDialog}
                        />
                    )}
                </Table>
            </TableContainer>
            <Pagination
                count={filteredDataLength}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
            />
        </Box>
    );
};
