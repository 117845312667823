
import { FiltersInterface } from "redux/Filters/types";
import { RootState } from "redux/store";
import { Dictionary } from "types/Dictionary";
import * as selectors from './selectors';
import { FilterCriteria, SearchList, StockStatusDictionary } from "./types";

export const FilterCriteriaToSelector = (filterCriteria: FilterCriteria) => {
    const selectorKey = `select${filterCriteria}SearchTree`;
    const dynamicSelector = (selectors as any)[selectorKey] || selectors.selectItemCodeSearchTree;
    return dynamicSelector as (state: RootState) => any;
};

export const intersectCustom = (a: number[], b: number[]): number[] => {
    const setA = new Set(a);
    const setB = new Set(b);

    if (!setA.size) return Array.from(setB);
    if (!setB.size) return Array.from(setA);

    return Array.from(new Set([...setA].filter(value => setB.has(value))));
}


export const applyCriteriaByFilterCriteria = (reduxState: FiltersInterface, filterCriteriaKey?: FilterCriteria): any => {
    let orderedItemIds: number[] = reduxState.global.items;
    let itemCodeIds: number[] = [];

    const { searchCriteriasData, itemIdsByStockStatus, itemIdsByStockStatusByPeriod, itemIdsByItemPlannerIds, itemIdsByComplexity, itemIdsByVolume, itemIdsByUncertainty, itemIdsByStockGroupIds, itemIdsByLevel, itemIdsByCompany, itemsIdsByAttributeId, itemsIdsTagId } = reduxState.planningGrid.filterData;

    if (!searchCriteriasData) return { orderedItemIds, itemCodeIds };

    // eslint-disable-next-line @typescript-eslint/ban-types
    const criteriaHandlers: { [key: string]: Function } = {
        [FilterCriteria.StockStatusTypeWithPeriod]: applyStockStatusWithPeriodTypeCriteria,
        [FilterCriteria.ItemPlanner]: applyItemPlannerCriteria,
        [FilterCriteria.Complexity]: applyItemPlannerCriteria,
        [FilterCriteria.Volume]: applyItemPlannerCriteria,
        [FilterCriteria.Uncertainty]: applyItemPlannerCriteria,
        [FilterCriteria.ItemStockGroup]: applyItemStockGroupCriteria,
        [FilterCriteria.Level]: applyItemLevelCriteria,
        [FilterCriteria.Company]: applyItemCompanyCriteria,
        [FilterCriteria.ItemAttribute01]: applyAttributesCriteria,
        [FilterCriteria.ItemAttribute02]: applyAttributesCriteria,
        [FilterCriteria.ItemAttribute03]: applyAttributesCriteria,
        [FilterCriteria.ItemAttribute04]: applyAttributesCriteria,
        [FilterCriteria.ItemAttribute05]: applyAttributesCriteria,
        [FilterCriteria.ItemAttribute06]: applyAttributesCriteria,
        [FilterCriteria.ItemAttribute07]: applyAttributesCriteria,
        [FilterCriteria.ItemAttribute08]: applyAttributesCriteria,
        [FilterCriteria.ItemAttribute09]: applyAttributesCriteria,
        [FilterCriteria.ItemAttribute10]: applyAttributesCriteria,
        [FilterCriteria.Tag01]: applyTagsCriteria,
        [FilterCriteria.Tag02]: applyTagsCriteria,
        [FilterCriteria.Tag03]: applyTagsCriteria,
    };

    const criteriaDataMapping: { [key: string]: any } = {
        [FilterCriteria.StockStatusTypeWithPeriod]: { idsByStatus: itemIdsByStockStatus, idsByPeriod: itemIdsByStockStatusByPeriod },
        [FilterCriteria.ItemPlanner]: itemIdsByItemPlannerIds,
        [FilterCriteria.Complexity]: itemIdsByComplexity,
        [FilterCriteria.Volume]: itemIdsByVolume,
        [FilterCriteria.Uncertainty]: itemIdsByUncertainty,
        [FilterCriteria.ItemStockGroup]: itemIdsByStockGroupIds,
        [FilterCriteria.Level]: itemIdsByLevel,
        [FilterCriteria.Company]: itemIdsByCompany,
        [FilterCriteria.ItemAttribute01]: itemsIdsByAttributeId[FilterCriteria.ItemAttribute01],
        [FilterCriteria.ItemAttribute02]: itemsIdsByAttributeId[FilterCriteria.ItemAttribute02],
        [FilterCriteria.ItemAttribute03]: itemsIdsByAttributeId[FilterCriteria.ItemAttribute03],
        [FilterCriteria.ItemAttribute04]: itemsIdsByAttributeId[FilterCriteria.ItemAttribute04],
        [FilterCriteria.ItemAttribute05]: itemsIdsByAttributeId[FilterCriteria.ItemAttribute05],
        [FilterCriteria.ItemAttribute06]: itemsIdsByAttributeId[FilterCriteria.ItemAttribute06],
        [FilterCriteria.ItemAttribute07]: itemsIdsByAttributeId[FilterCriteria.ItemAttribute07],
        [FilterCriteria.ItemAttribute08]: itemsIdsByAttributeId[FilterCriteria.ItemAttribute08],
        [FilterCriteria.ItemAttribute09]: itemsIdsByAttributeId[FilterCriteria.ItemAttribute09],
        [FilterCriteria.ItemAttribute10]: itemsIdsByAttributeId[FilterCriteria.ItemAttribute10],
        [FilterCriteria.Tag01]: itemsIdsTagId[FilterCriteria.Tag01],
        [FilterCriteria.Tag02]: itemsIdsTagId[FilterCriteria.Tag02],
        [FilterCriteria.Tag03]: itemsIdsTagId[FilterCriteria.Tag03],
    };

    for (const criteriaKey of Object.keys(searchCriteriasData)) {
        const criteriaValue = (searchCriteriasData as any)[criteriaKey];
        if (!criteriaValue) continue;
        let ids: number[] = [];
        if (criteriaKey === FilterCriteria.ItemCode) {
            if (criteriaValue.length === 0) continue;
            if (criteriaValue[0].id === 'All') {
                itemCodeIds = orderedItemIds;
                break;
            }
            itemCodeIds = criteriaValue.map((item: any) => Number(item.id));
        } else if (criteriaHandlers[criteriaKey]) {
            ids = criteriaHandlers[criteriaKey](orderedItemIds, criteriaValue, criteriaDataMapping[criteriaKey]);
        } else {
            throw new Error(`Unknown criteria: ${criteriaKey}`);
        }
        if (ids.length > 0) orderedItemIds = intersectCustom(orderedItemIds, ids);
        if (orderedItemIds.length === 0) break;
        if (itemCodeIds.length > 0) orderedItemIds = itemCodeIds;
    }
    return { orderedItemIds, itemCodeIds };
};

export const applyAttributesCriteria = (
    itemIdsToFilter: number[],
    filterParams: SearchList<null>[],
    itemIdsByAttributeNId: Dictionary<number[]>
): number[] => {
    if (filterParams.length === 0) return [];
    const selectedParamValues = filterParams.map(x => x.id);
    const filteredProductIds = new Set<number>();
    if (selectedParamValues[0] === 'All') {
        Object.values(itemIdsByAttributeNId).forEach(items => {
            items.forEach(id => filteredProductIds.add(id));
        });
    } else {
        selectedParamValues.forEach(selectedId => {
            const splitedIds = selectedId.split('|');
            if (splitedIds.length > 1) {
                filteredProductIds.add(Number(splitedIds[1]));
            } else {
                const ids = itemIdsByAttributeNId[selectedId] || [];
                ids.forEach(id => filteredProductIds.add(id));
            }
        });
    }
    return Array.from(filteredProductIds);
};


export const applyTagsCriteria = (
    itemIdsToFilter: number[],
    filterParams: SearchList<null>[],
    itemIdsByTagNId: Dictionary<number[]>
): number[] => {
    if (filterParams.length === 0) return [];
    const selectedParamValues = filterParams.map(x => x.id);
    const filteredProductIds = new Set<number>();
    if (selectedParamValues[0] === 'All') {
        Object.values(itemIdsByTagNId).forEach(items => {
            items.forEach(id => filteredProductIds.add(id));
        });
    } else {
        selectedParamValues.forEach(selectedId => {
            const splitedIds = selectedId.split('|');
            if (splitedIds.length > 1) {
                filteredProductIds.add(Number(splitedIds[1]));
            } else {
                const ids = itemIdsByTagNId[selectedId] || [];
                ids.forEach(id => filteredProductIds.add(id));
            }
        });
    }
    return Array.from(filteredProductIds);
};
export const applyStockStatusTypeCriteria = (
    itemIdsToFilter: number[],
    filterParams: SearchList<null>[],
    itemIdsByStockStatus: StockStatusDictionary<number[]>
): number[] => {
    if (filterParams.length === 0) return [];
    const stockStatusName = filterParams.map(x => x.id);
    const filteredProductIds = new Set<number>();
    if (filterParams[0].id === 'All') { return itemIdsToFilter; }
    stockStatusName.forEach(selectedId => {
        const ids = (itemIdsByStockStatus as any)[selectedId] || [];
        ids.forEach((id: any) => filteredProductIds.add(id));
    });
    return Array.from(filteredProductIds);
};

export const applyStockStatusWithPeriodTypeCriteria = (
    itemIdsToFilter: number[],
    filterParams: SearchList<null>[],
    itemIds: any,
): number[] => {
    if (filterParams.length === 0) return [];
    const filteredProductIds = new Set<number>();
    if (filterParams[0].id === 'All') { return itemIdsToFilter; }
    filterParams.forEach(filterParam => {
        const splitedSelectedId = filterParam.id.split('|');

        let ids: number[] = [];
        if (splitedSelectedId[1]) {
            ids = itemIds.idsByPeriod[splitedSelectedId[0]][Number(splitedSelectedId[1])];
        } else {
            ids = itemIds.idsByStatus[splitedSelectedId[0]] || [];
        }
        ids.forEach(id => filteredProductIds.add(id));
    });
    return Array.from(filteredProductIds);
};

export const applyItemPlannerCriteria = (
    itemIdsToFilter: number[],
    filterParams: SearchList<null>[],
    itemIdsByItemPlannerIds: Dictionary<number[]>
): number[] => {
    if (filterParams.length === 0) return [];
    const filteredProductIds = new Set<number>();
    filterParams.forEach(filterParam => {
        if (filterParam.id === 'All') {
            itemIdsToFilter.forEach(id => filteredProductIds.add(id));
        } else {
            const itemPlannerId = Number(filterParam.id);
            const ids = itemIdsByItemPlannerIds[itemPlannerId] || [];
            ids.forEach(id => filteredProductIds.add(id));
        }
    });
    return Array.from(filteredProductIds);
};
export const applyItemComplexityCriteria = (
    itemIdsToFilter: number[],
    filterParams: SearchList<null>[],
    itemIdsByComplexityIds: Dictionary<number[]>
): number[] => {
    if (filterParams.length === 0) return [];
    const filteredProductIds = new Set<number>();
    filterParams.forEach(filterParam => {
        if (filterParam.id === 'All') {
            itemIdsToFilter.forEach(id => filteredProductIds.add(id));
        } else {
            const itemComplexityId = Number(filterParam.id);
            const ids = itemIdsByComplexityIds[itemComplexityId] || [];
            ids.forEach(id => filteredProductIds.add(id));
        }
    });
    return Array.from(filteredProductIds);
};
export const applyItemUncertaintyCriteria = (
    itemIdsToFilter: number[],
    filterParams: SearchList<null>[],
    itemIdsByUncertaintyIds: Dictionary<number[]>
): number[] => {
    if (filterParams.length === 0) return [];
    const filteredProductIds = new Set<number>();
    filterParams.forEach(filterParam => {
        if (filterParam.id === 'All') {
            itemIdsToFilter.forEach(id => filteredProductIds.add(id));
        } else {
            const itemUncertaintyId = Number(filterParam.id);
            const ids = itemIdsByUncertaintyIds[itemUncertaintyId] || [];
            ids.forEach(id => filteredProductIds.add(id));
        }
    });
    return Array.from(filteredProductIds);
};
export const applyItemVolumeCriteria = (
    itemIdsToFilter: number[],
    filterParams: SearchList<null>[],
    itemIdsByVolumeIds: Dictionary<number[]>
): number[] => {
    if (filterParams.length === 0) return [];
    const filteredProductIds = new Set<number>();
    filterParams.forEach(filterParam => {
        if (filterParam.id === 'All') {
            itemIdsToFilter.forEach(id => filteredProductIds.add(id));
        } else {
            const itemVolumeId = Number(filterParam.id);
            const ids = itemIdsByVolumeIds[itemVolumeId] || [];
            ids.forEach(id => filteredProductIds.add(id));
        }
    });
    return Array.from(filteredProductIds);
};

export const applyItemStockGroupCriteria = (
    itemIdsToFilter: number[],
    filterParams: SearchList<null>[],
    itemIdsByStockGroupIds: Dictionary<number[]>
): number[] => {
    if (filterParams.length === 0) return [];
    if (filterParams.some(filterParam => filterParam.id === 'All')) { return itemIdsToFilter; }
    const filteredProductIds = new Set<number>();
    filterParams.forEach(filterParam => {
        const itemStockGroupId = Number(filterParam.id);
        const ids = itemIdsByStockGroupIds[itemStockGroupId] || [];
        ids.forEach(id => filteredProductIds.add(id));
    });
    return Array.from(filteredProductIds);
}
export const applyItemLevelCriteria = (
    itemIdsToFilter: number[],
    filterParams: SearchList<null>[],
    itemIdsByLevel: Dictionary<number[]>
): number[] => {
    if (filterParams.length === 0) return [];
    const filteredProductIds = new Set<number>();
    filterParams.forEach(filterParam => {
        if (filterParam.id === 'All') {
            itemIdsToFilter.forEach(id => filteredProductIds.add(id));
        } else {
            const itemLevel = Number(filterParam.id);
            const ids = itemIdsByLevel[itemLevel] || [];
            ids.forEach(id => filteredProductIds.add(id));
        }
    });
    return Array.from(filteredProductIds);
};
export const applyItemCompanyCriteria = (
    itemIdsToFilter: number[],
    filterParams: SearchList<null>[],
    itemIdsByCompany: Dictionary<number[]>
): number[] => {
    if (filterParams.length === 0) return [];
    const filteredProductIds = new Set<number>();
    filterParams.forEach(filterParam => {
        if (filterParam.id === 'All') {
            itemIdsToFilter.forEach(id => filteredProductIds.add(id));
        } else {
            const itemCompanyId = Number(filterParam.id);
            const ids = itemIdsByCompany[itemCompanyId] || [];
            ids.forEach(id => filteredProductIds.add(id));
        }
    });
    return Array.from(filteredProductIds);
};
