import { Trans } from '@lingui/macro';
import { TableCell, TableHead, TableRow } from '@mui/material';

export const Header: React.FC = (): JSX.Element => {
    return (
        <TableHead>
            <TableRow sx={ { position: 'sticky', top: 0, zIndex: 10, bgcolor: 'background.paper' } }>
                <TableCell>
                    <Trans>Nome</Trans>
                </TableCell>
                <TableCell>
                    <Trans>Descrição</Trans>
                </TableCell>
                <TableCell>
                    <Trans>Url</Trans>
                </TableCell>
                <TableCell>
                    <Trans>Tipo</Trans>
                </TableCell>
                <TableCell>
                    <Trans>Ordem</Trans>
                </TableCell>
                <TableCell colSpan={ 3 } />
            </TableRow>
        </TableHead>
    );
};