import React from "react";
import {
    Select,
    MenuItem,
    FormControl,
    Box,
    Typography,
} from "@mui/material";
import { TiArrowUnsorted } from "react-icons/ti";
import { AiOutlineCheck } from "react-icons/ai";
import { useAppDispatch } from "redux/hooks";
import { IGenericOptions } from "types/Generics";
import { setSelectedFilter } from "redux/Filters/reducer";
import { optionsToLanguage } from "locales/helper";

interface FilterDropDownSelectorProps {
    filterOptions: IGenericOptions[];
    selectedFilterName: string;
    selectedFilter: IGenericOptions
}

const FilterDropDownSelector: React.FC<FilterDropDownSelectorProps> = ({
    filterOptions,
    selectedFilterName,
    selectedFilter
}) => {
    const dispatch = useAppDispatch()
    return (
        <FormControl fullWidth>
            <Select
                size="small"
                variant="outlined"
                inputProps={{
                    sx: {
                        paddingTop: '0.3rem',
                        paddingBottom: '0.1rem',
                    }
                }}
                labelId="company-select-label"
                id="company-select"
                value={selectedFilter.id}
                onChange={(e) => {
                    const selectedOption = filterOptions.find((filter) => filter.id === e.target.value);
                    dispatch(
                        setSelectedFilter({
                            selectedOption: selectedOption as IGenericOptions,
                            selectorName: selectedFilterName,
                        })
                    );
                }}
                IconComponent={TiArrowUnsorted}
            >
                {filterOptions.map((filter: any) => (
                    <MenuItem key={filter.id} value={filter.id}>
                        <Box display="flex" alignItems="center" gap={'0.5rem'}>
                            <Typography variant="caption">{optionsToLanguage(filter.name)}</Typography>
                            {selectedFilter.id === filter.id && (
                                <AiOutlineCheck fontSize="small" />
                            )}
                        </Box>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default FilterDropDownSelector;
