import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React, { useMemo } from "react";
import DropdownTreeSelect, { TreeData, TreeNode } from "react-dropdown-tree-select";
import { updatePlanningGridRowOptions } from "redux/Filters/reducer";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { putAsync } from "services/api";


function PlanningGridRowSelectionDropdownMenu() {
    const dispatch = useAppDispatch();
    const { i18n } = useLingui();
    const planningGridRowOptions = useAppSelector((state) => state.filter.planningGrid.planningGridRowOptions);

    const localTreeData: TreeData = useMemo(() => {

        return planningGridRowOptions.filter(c => c.active).map((filter) => {
            return {
                label: i18n.t(filter.name ?? ""),
                filterCriteria: filter.columnName,
                value: filter.id,
                checked: filter.initialStatus,
                sequence: filter.sequence
            }
        }).sort((a, b) => a.sequence! - b.sequence!)
    }, [i18n, planningGridRowOptions])

    const onChangeSearch = async (currentNode: any, selectedNodes: TreeNode[]) => {
        const params = {
            id: currentNode.value,
            initialStatus: currentNode.checked
        }

        await putAsync(`/planningGridFilterOptions`, params)

        dispatch(updatePlanningGridRowOptions({ selectedPlanningGridRowOption: currentNode }))
    };
    return (
        <DropdownTreeSelect
            className="hide-selected-text"
            inlineSearchInput={true}
            texts={{ placeholder: t`Opções de Linha` }}
            showDropdown={"default"}
            data={localTreeData}
            keepTreeOnSearch={true}
            keepChildrenOnSearch={true}
            onChange={onChangeSearch}
        />

    );
}

export const PlanningGridRowSelectionDropdownMenuMemoized = React.memo(PlanningGridRowSelectionDropdownMenu);
