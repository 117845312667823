import React from 'react';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { fileToBase64, resizeImage } from '../utils';

interface IProfileAvatarProps {
    value?: string;
    onChangeProfilePic: (event: string) => void;
}
const ProfileAvatar:React.FC<IProfileAvatarProps> = ({ value, onChangeProfilePic }) => {
    const [image, setImage] = React.useState('');
    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if(!event.target.files) return;
        const selectedFile = event.target.files[0];
        const base64 = await fileToBase64(selectedFile);
        onChangeProfilePic(base64);
    };

    const handleAvatarClick = () => {
        document.getElementById('avatar-selector')?.click();
    };
    
    React.useEffect(() => {
        resizeImage(image, 150, 150).then((imageResize: string) =>{
            setImage(imageResize);
        });
    },[image]);
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ position: 'relative', display: 'inline-block' }}>
                <Avatar
                    alt="User Profile"
                    src={value}
                    sx={{ width: 150, height: 150, backgroundSize: 'cover' }}
                    onMouseOver={(e) => (e.currentTarget.style.cursor = 'pointer')}
                    onClick={handleAvatarClick}
                />
                <input
                    type="file"
                    accept="image/*"
                    id="avatar-selector"
                    name="foto"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                />
                <IconButton
                    sx={{ position: 'absolute', bottom: 0, right: 0 }}
                    onMouseOver={(e) => (e.currentTarget.style.cursor = 'pointer')}
                    onClick={handleAvatarClick}
                >
                    <PhotoCamera />
                </IconButton>
            </div>
        </div>
    );
};

export default ProfileAvatar;