import { useDataContext } from '@nplan';
import { useCallback, useEffect, useState } from 'react';
import { setPeriodOptions } from 'redux/Filters/reducer';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { IGenericOptions } from 'types/Generics';
import { IInventoryGenericOptions } from '../types/IInventoryGenericOptions';
import { IInventoryHealth, InventoryHealthData } from '../types/IInventoryHealth';


interface UseInventoryHealthProps {
    inventoryHealthData: InventoryHealthData[],
    selectedCompanyFilterRaw: IInventoryGenericOptions,
    selectedStockGroupFilterRaw: IInventoryGenericOptions
    selectedPeriod: IGenericOptions
}
const useInventoryHealth = ({ inventoryHealthData, selectedCompanyFilterRaw, selectedStockGroupFilterRaw }: UseInventoryHealthProps) => {
    const { stockStatus } = useDataContext();
    const dispatch = useAppDispatch();
    const selectedPeriod = useAppSelector(state => state.filter.inventory.selectedPeriodFilter);
    const [inventoryHealthSummaryData, setInventoryHealthSummaryData] = useState<IInventoryHealth>({} as IInventoryHealth);
    const [maxValue, setMaxValue] = useState({} as any);
    const getSequenceNumberByColumnName = (columnName: string): number | undefined => {
        const rule = stockStatus.find((rule) => rule.name === columnName);
        return rule ? rule.sequence : undefined;
    };
    const populateScenarioStatusOptions = (inventoryHealth: InventoryHealthData[]) => {
        const uniqueScenariosStatus: {
            stockStatusName: string;
            stockStatusDescription: string;
            stockStatusColor: string;
            sequence: number | undefined
        }[] = [];

        if (inventoryHealth !== undefined) {
            const scenarioMap = new Map();
            inventoryHealth.forEach((pos) => {
                const { stockStatusName, stockStatusDescription, stockStatusColor } = pos;
                if (!scenarioMap.has(stockStatusDescription)) {
                    scenarioMap.set(stockStatusDescription, stockStatusName);
                    uniqueScenariosStatus.push({ stockStatusName, stockStatusDescription, stockStatusColor, sequence: getSequenceNumberByColumnName(stockStatusName) });
                }
            });

            uniqueScenariosStatus.sort((a, b) => a.sequence! - b.sequence!);
        }
        const scenarioStatusOptions = uniqueScenariosStatus;
        return { scenarioStatusOptions };
    };

    const populateScenarioTimestamp = (inventoryHealth: InventoryHealthData[]) => {
        // Get unique timestamps and sort them by numeric value
        const uniqueTimeStamps = [...new Set(inventoryHealth.map(c => c.periodCode))].sort((a, b) => {
            const numA = parseInt((a.match(/\d+/)?.[0] || ''), 10);
            const numB = parseInt((b.match(/\d+/)?.[0] || ''), 10);
            return numA - numB;
        });

        // Create an array of objects with the desired type
        const timeStampDataArray: IGenericOptions[] = uniqueTimeStamps.map((timeStamp, index) => ({
            name: timeStamp, // Assuming the name is the same as the period code
            code: timeStamp,
            id: index
        }));

        // Get the initial timestamp
        const initialTimeStamp = uniqueTimeStamps[0];

        // Dispatch the period options (assuming dispatch is available)
        dispatch(setPeriodOptions({ periodOptions: timeStampDataArray }));

        return { uniqueTimeStamps: timeStampDataArray, initialTimeStamp };
    };

    const populateScenarioFilteredByTime = (inventoryHealth: InventoryHealthData[], selectedPeriod: IGenericOptions) => {
        let scenarioFilteredByTime: InventoryHealthData[] = [];
        scenarioFilteredByTime = inventoryHealth.filter((c) => c.periodCode === selectedPeriod.code);
        return { scenarioFilteredByTime };
    };


    const calculateAggregatedInventoryHealthData = (scenarioFilteredByTime: InventoryHealthData[]) => {
        const aggregatedData: any = [];
        const aggregationMap = new Map();
        let maxValue = 0; // Initialize the maximum value variable
        let maxItems: InventoryHealthData[] = []; // Initialize the array to store items with max value
        let maxItemCount = 0; // Initialize the maximum number of items variable

        scenarioFilteredByTime.forEach((item) => {
            const {
                company,
                period,
                stockStatusColor,
                stockStatusDescription,
                stockStatusName,
                stockGroup,
                total,
                value
            } = item;

            if (!aggregationMap.has(stockStatusName)) {
                aggregationMap.set(stockStatusName, {
                    total: 0,
                    value: 0,
                    company,
                    period,
                    stockStatusColor,
                    stockGroup,
                    stockStatusDescription,
                    stockStatusName,
                    items: [] // Store items with the same stockStatusName
                });
            }

            const currentItem = aggregationMap.get(stockStatusName);
            currentItem.total += total;
            currentItem.value += value;
            currentItem.items.push(item); // Add item to the corresponding aggregation entry

            // Update maxValue, maxItems, and maxItemCount if necessary
            if (value > maxValue) {
                maxValue = value;
                maxItems = [item]; // Update maxItems with a single item
                maxItemCount = 1; // Reset maxItemCount to 1
            } else if (value === maxValue) {
                maxItems.push(item); // Add item to maxItems if it has the same value as maxValue
                maxItemCount = maxItems.length; // Update maxItemCount
            }
        });

        aggregationMap.forEach((value, key) => {
            aggregatedData.push({
                company: 'Total',
                stockStatusCode: key,
                total: value.total,
                value: value.value,
                sequence: getSequenceNumberByColumnName(key),
                stockStatusColor: value.stockStatusColor,
                stockGroup: value.stockGroup,
                stockStatusName: value.stockStatusName,
                period: value.period,
                stockStatusDescription: value.stockStatusDescription
            });
        });

        const aggregatedInventoryHealthData = aggregatedData.sort((a: any, b: any) => a.sequence - b.sequence);
        return { aggregatedInventoryHealthData, maxValue, maxItems, maxItemCount };
    };



    const inventoryHealthSummary = (
        aggregatedInventoryHealthData: InventoryHealthData[],
        selectedCompanyFilter: IInventoryGenericOptions,
        selectedStockGroupFilter: IInventoryGenericOptions,
        scenarioFilteredByTime: InventoryHealthData[]
    ) => {
        let inventoryHealthFilteredByCompany: InventoryHealthData[] = aggregatedInventoryHealthData;
        let newArray: InventoryHealthData[] = [];
    
        // Check if all items have the 'stockGroup' property
        const hasStockGroup = scenarioFilteredByTime.every((item) => Object.prototype.hasOwnProperty.call(item, 'stockGroup'));
    
        // Apply stock group filter if necessary and if 'stockGroup' exists in all items
        if (selectedStockGroupFilter.id !== 0 && scenarioFilteredByTime !== undefined && hasStockGroup) {
            newArray = scenarioFilteredByTime.filter(c => c.stockGroup === selectedStockGroupFilter.code);
            const { aggregatedInventoryHealthData } = calculateAggregatedInventoryHealthData(newArray);
            inventoryHealthFilteredByCompany = aggregatedInventoryHealthData;
        } else {
            newArray = scenarioFilteredByTime; // If 'stockGroup' doesn't exist, use the original array
        }
    
        if (newArray.length === 0) return { inventoryHealthFilteredByCompany: [] };
    
        // Apply company filter if necessary
        if (selectedCompanyFilter.id !== 0 && scenarioFilteredByTime !== undefined) {
            inventoryHealthFilteredByCompany = newArray
                .filter((data) => data.company === selectedCompanyFilter.name)
                .sort((a: any, b: any) => a.sequence - b.sequence);
        }
    
        if (inventoryHealthFilteredByCompany.length === 0) return { inventoryHealthFilteredByCompany: [] };
    
        const { aggregatedInventoryHealthData: aggregatedInventoryHealthDataAfter } = calculateAggregatedInventoryHealthData(inventoryHealthFilteredByCompany);
        return { inventoryHealthFilteredByCompany: aggregatedInventoryHealthDataAfter };
    };


    const calculateTotalValue = (inventoryHealthFilteredByCompany: InventoryHealthData[]) => {
        let aggregatedValue: number = 0;
        if (inventoryHealthFilteredByCompany !== undefined) {
            aggregatedValue = inventoryHealthFilteredByCompany
                .filter(c => c.stockStatusName !== 'Ruptura')
                .reduce(
                    (accumulator, currentValue) => accumulator + currentValue.value,
                    0
                );
        }
        return { aggregatedValue };
    };

    const calculateTotalItemsNumber = (inventoryHealthFilteredByCompany: InventoryHealthData[]) => {
        let totalItemsNumber: number = 0;
        if (inventoryHealthFilteredByCompany !== undefined) {
            const aggregatedValue = inventoryHealthFilteredByCompany
                .filter(c => c.stockStatusName !== 'Ruptura')
                .reduce(
                    (accumulator, currentValue) =>
                        accumulator + currentValue.total,
                    0
                );
            totalItemsNumber = aggregatedValue;
        }
        return { totalItemsNumber };
    };

    const calculateMaxValuesForCompany = (inventoryHealthData, selectedCompanyFilterRaw) => {

        // Initialize an object to store max values and max totals for each company
        const maxValuesByCompany = {};
        let totalMaxValue = 0;
        let totalMaxTotal = 0;

        inventoryHealthData.forEach((item) => {
            const { company, total, value } = item;

            // Filter by company if selectedCompanyFilterRaw.id is not 0
            if (selectedCompanyFilterRaw.id !== 0 && company !== selectedCompanyFilterRaw.name) {
                return; // Skip if company doesn't match selected company
            }

            // Update maxValuesByCompany for the current company
            if (!maxValuesByCompany[company]) {
                maxValuesByCompany[company] = { maxValue: 0, maxTotal: 0 };
            }

            if (value > maxValuesByCompany[company].maxValue) {
                maxValuesByCompany[company].maxValue = value;
            }
            if (total > maxValuesByCompany[company].maxTotal) {
                maxValuesByCompany[company].maxTotal = total;
            }

            // Update totalMaxValue and totalMaxTotal
            totalMaxValue = Math.max(totalMaxValue, value);
            totalMaxTotal = Math.max(totalMaxTotal, total);
        });

        // Calculate total sum for maxValue and maxTotal
        let totalSumMaxValue = 0;
        let totalSumMaxTotal = 0;
        for (const key in maxValuesByCompany) {
            totalSumMaxValue += maxValuesByCompany[key].maxValue;
            totalSumMaxTotal += maxValuesByCompany[key].maxTotal;
        }

        // Add the total sum as a separate key in the object
        (maxValuesByCompany as any)['Total'] = { maxValue: totalSumMaxValue, maxTotal: totalSumMaxTotal };

        setMaxValue(maxValuesByCompany[selectedCompanyFilterRaw.name]);
        return maxValuesByCompany[selectedCompanyFilterRaw.name];
    };

    const getPopulateScenarioStatusOptions = useCallback((inventoryHealth: InventoryHealthData[]) => populateScenarioStatusOptions(inventoryHealth), []);
    const getPopulateScenarioTimestamp = useCallback((inventoryHealth: InventoryHealthData[]) => populateScenarioTimestamp(inventoryHealth), []);
    const getPopulateScenarioFilteredByTime = useCallback((inventoryHealth: InventoryHealthData[]) => populateScenarioFilteredByTime(inventoryHealth, selectedPeriod), [selectedPeriod]);
    const getCalculateAggregatedInventoryHealthData = useCallback((scenarioFilteredByTime: InventoryHealthData[]) => calculateAggregatedInventoryHealthData(scenarioFilteredByTime), []);
    const getInventoryHealthSummary = useCallback((aggregatedInventoryHealthData: InventoryHealthData[], selectedCompanyFilter: IInventoryGenericOptions, selectedStockGroupFilter: IInventoryGenericOptions, scenarioFilteredByTime: InventoryHealthData[]) => inventoryHealthSummary(aggregatedInventoryHealthData, selectedCompanyFilter, selectedStockGroupFilter, scenarioFilteredByTime), []);
    const getCalculateTotalValue = useCallback((inventoryHealthFilteredByCompany: InventoryHealthData[]) => calculateTotalValue(inventoryHealthFilteredByCompany), []);
    const getCalculateTotalItemsNumber = useCallback((inventoryHealthFilteredByCompany: InventoryHealthData[]) => calculateTotalItemsNumber(inventoryHealthFilteredByCompany), []);

    useEffect(() => {
        if (inventoryHealthData.length === 0) return;
        const inventoryHealthSummaryDataLocal = {} as IInventoryHealth;

        const { scenarioStatusOptions } = getPopulateScenarioStatusOptions(inventoryHealthData);
        inventoryHealthSummaryDataLocal.scenarioStatusOptions = scenarioStatusOptions;

        const { initialTimeStamp, uniqueTimeStamps } = getPopulateScenarioTimestamp(inventoryHealthData);
        inventoryHealthSummaryDataLocal.initialTimeStamp = initialTimeStamp;
        inventoryHealthSummaryDataLocal.scenarioTimestamp = uniqueTimeStamps;


        const { scenarioFilteredByTime } = getPopulateScenarioFilteredByTime(inventoryHealthData);
        inventoryHealthSummaryDataLocal.scenarioFilteredByTime = scenarioFilteredByTime;

        const { aggregatedInventoryHealthData } = getCalculateAggregatedInventoryHealthData(scenarioFilteredByTime);
        inventoryHealthSummaryDataLocal.aggregatedInventoryHealthData = aggregatedInventoryHealthData;

        const { inventoryHealthFilteredByCompany } = getInventoryHealthSummary(aggregatedInventoryHealthData, selectedCompanyFilterRaw, selectedStockGroupFilterRaw, scenarioFilteredByTime);
        inventoryHealthSummaryDataLocal.inventoryHealthFilteredByCompany = inventoryHealthFilteredByCompany;

        const { aggregatedValue } = getCalculateTotalValue(inventoryHealthFilteredByCompany);
        inventoryHealthSummaryDataLocal.totalValue = aggregatedValue;

        const { totalItemsNumber } = getCalculateTotalItemsNumber(inventoryHealthFilteredByCompany);
        inventoryHealthSummaryDataLocal.totalItemsNumber = totalItemsNumber;

        const maxValuesByCompany = calculateMaxValuesForCompany(inventoryHealthData, selectedCompanyFilterRaw);
        
        setInventoryHealthSummaryData(inventoryHealthSummaryDataLocal);
        setMaxValue(maxValuesByCompany);

    }, [inventoryHealthData,
        selectedCompanyFilterRaw,
        getCalculateAggregatedInventoryHealthData,
        getCalculateTotalItemsNumber,
        getCalculateTotalValue,
        getInventoryHealthSummary,
        getPopulateScenarioFilteredByTime,
        getPopulateScenarioStatusOptions,
        getPopulateScenarioTimestamp,
        selectedStockGroupFilterRaw]);



    return {
        inventoryHealthSummaryData,
        maxValue,
    };
};

export default useInventoryHealth;
