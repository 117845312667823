import { Trans } from '@lingui/macro';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    useTheme,
} from '@mui/material';
import { useTablePermissionData } from './hooks/useTablePermissionData';
import { } from '@nplan';
import { i18n } from '@lingui/core';
interface IManageTablePermissionProps {
    open: boolean;
    onClose: () => void;
    id: number;
}

export const ManageTablePermission: React.FC<
    IManageTablePermissionProps
> = ({ open, onClose, id }): JSX.Element => {
    const theme = useTheme();
    const variant = theme.palette.mode === 'dark' ? 'outlined' : 'contained';
    const { 
        tablePermissions,
        tables, 
        handleEditTablePermission,
        handleSaveTablePermission
    } = useTablePermissionData(id, open, onClose);

    
    const getTableName = (tableId: number): string => {
        const table = tables.find(
            (table) => table.id === tableId,
        );
        return table ? table.title : '';
    };
    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth='md'
        >
            <DialogTitle align='center'>
                <Trans>Permissões de Tabelas</Trans>
            </DialogTitle>
            <DialogContent>
                <TableContainer sx={{ overflowX: 'initial' }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Trans>Tabela</Trans>
                                </TableCell>
                                <TableCell>
                                    <Trans>Ver</Trans>
                                </TableCell>
                                <TableCell>
                                    <Trans>Inserir</Trans>
                                </TableCell>
                                <TableCell>
                                    <Trans>Atualizar</Trans>
                                </TableCell>
                                <TableCell>
                                    <Trans>Deletar</Trans>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tablePermissions
                                .sort((a, b) =>
                                    i18n.t(getTableName(a.tableId,)).localeCompare(i18n.t(getTableName(b.tableId)))
                                )
                                .map((tablePermission) => (
                                    <TableRow
                                        key={tablePermission.id}
                                    >
                                        <TableCell>
                                            {i18n.t(getTableName(tablePermission.tableId))}
                                        </TableCell>
                                        <TableCell>
                                            <Switch
                                                checked={tablePermission.view}
                                                name="view"
                                                onChange={handleEditTablePermission}
                                                inputProps={{ 'data-id': tablePermission.id.toString() } as React.HTMLAttributes<HTMLInputElement>}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Switch
                                                checked={tablePermission.insert}
                                                name="insert"
                                                onChange={handleEditTablePermission}
                                                inputProps={{ 'data-id': tablePermission.id.toString() } as React.HTMLAttributes<HTMLInputElement>}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Switch
                                                checked={tablePermission.update}
                                                name="update"
                                                onChange={handleEditTablePermission}
                                                inputProps={{ 'data-id': tablePermission.id.toString() } as React.HTMLAttributes<HTMLInputElement>}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Switch
                                                checked={tablePermission.delete }
                                                name="delete"
                                                onChange={handleEditTablePermission}
                                                inputProps={{ 'data-id': tablePermission.id.toString() } as React.HTMLAttributes<HTMLInputElement>}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: 2,
                        width: '100%',
                        flexDirection: 'column',

                    }}
                    gap={2}
                >
                    <Button
                        onClick={handleSaveTablePermission}
                        color='primary'
                        variant={variant}
                    >
                        Salvar
                    </Button>
                    <Button
                        onClick={onClose}
                        color='error'
                        variant={variant}
                    >
                        Cancelar
                    </Button>

                </Box>
            </DialogActions>
        </Dialog>
    );
};
