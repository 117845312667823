import React, { useCallback } from 'react';
import LoginIcon from 'assets/images/activeDirectory/ad.png';
import { Box, Button, Typography } from '@mui/material';
import { postAsync, useUserContext } from '@nplan';

interface IActiveDirectoryProps {
    onChangeLoading: (value: boolean) => void;
}
export const ActiveDirectory: React.FC<IActiveDirectoryProps> = ({ onChangeLoading }): JSX.Element => {
    const { onLoginAsync } = useUserContext();

    const handleLogin = useCallback(async () => {
        onChangeLoading(true);
        const res = await postAsync<null, string>('auth/ad', null, { withCredentials: true });
        if (res.type === 'success' && res.data) {
            onChangeLoading(false);
            onLoginAsync(res.data);
        }
        onChangeLoading(false);
    }, [onLoginAsync, onChangeLoading]);
    return (
        <Button onClick={handleLogin}>
            <Box
                padding={1}
                width={60}
                display={'flex'}
                justifyContent={'center'}
                flexDirection={'column'}
                height={55}
                alignItems={'center'}
                paddingX={6}
            >
                <img
                    src={LoginIcon}
                    alt='Google icon'
                    style={{
                        width: '40px',
                        padding: 3,
                    }}
                />
                <Typography
                    variant='caption'
                    whiteSpace={'nowrap'}
                    textTransform={'none'}
                    color={'text.primary'}
                >
                    Active Directory
                </Typography>
            </Box>
        </Button>
    );
};
