import { t } from '@lingui/macro';
import { PlanningChartData, PlanningGridData, StockStatusType } from 'pages/planning-grid/types/PlanningGrid';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/hooks';
import { Dictionary } from 'types/Dictionary';
import { IStockStatus } from 'types/system/IStockStatus';

interface useChartDataHandlerProps {
    calculateChartStockStatusType: (planningGridData: PlanningChartData) => StockStatusType;
    calculateSummedSelectedPlanningGrids: (
        selectedProductIds: number[],
        simplePeriodLenght: number,
        planningGridsByItemId: Dictionary<PlanningGridData[]>
    ) => PlanningChartData[];
}
interface useChartDataProps {
    stockStatus:IStockStatus[]
}
const useChartDataHandler: () => useChartDataHandlerProps = ({ stockStatus }: useChartDataProps) => {



    const calculateChartStockStatusType = (planningGridData: PlanningChartData): StockStatusType => {
        const { locked, closingStock, targetStock, minStock, maxStock, safetyStock } = planningGridData;


        if (targetStock === 0 && closingStock > 0) {
            return StockStatusType.MTO;
        } else if (closingStock >= 0 && closingStock < safetyStock) {
            return StockStatusType.Risk;
        } else if (closingStock < 0) {
            return StockStatusType.Stockout;
        } else if (closingStock >= safetyStock && closingStock < minStock) {
            return StockStatusType.Low;
        } else if (closingStock >= minStock && closingStock <= targetStock) {
            return StockStatusType.Normal;
        } else if (closingStock > targetStock && closingStock <= maxStock) {
            return StockStatusType.High;
        } else if (closingStock > maxStock) {
            return StockStatusType.Excess;
        }
        return StockStatusType.Stockout;
    };

    // const calculateChartStockStatusType = (planningGridData: PlanningChartData): StockStatusType => {
    //     const { 
    //         closingStock, 
    //         targetStock, 
    //         minStock, 
    //         maxStock, 
    //         safetyStock, 
    //         multipleReorder 
    //     } = planningGridData;
    
    //     if (closingStock < 0) {
    //         return StockStatusType.Stockout;
    //     } else if (closingStock >= 0 && closingStock < safetyStock) {
    //         return StockStatusType.Risk;
    //     } else if (closingStock >= safetyStock && closingStock < minStock) {
    //         return StockStatusType.Low;
    //     } else if (closingStock >= minStock && (closingStock< targetStock || closingStock<= targetStock+multipleReorder)) {
    //         return StockStatusType.Normal;
    //     } else if (closingStock > targetStock && (closingStock<=maxStock||closingStock<=maxStock+multipleReorder)) {
    //         return StockStatusType.High;
    //     } else if (closingStock > maxStock) {
    //         return StockStatusType.Excess;
    //     }
    //     return StockStatusType.MTO;
    // };


    const calculateDynamicStockStatusType = (planningGridData: PlanningChartData) => {
        const { closingStock, openingStock, targetStock, minStock, maxStock, safetyStock, multipleReorder } = planningGridData;
    
        for (const rule of stockStatus) {
            const { ruleFinalStock, displayCodeAg } = rule;
            
            // Replace placeholders with actual values
            let ruleWithValues = ruleFinalStock
                .replace(/\[ClosingStock\]/g, closingStock.toString())
                .replace(/\[OpeningStock\]/g, openingStock.toString())
                .replace(/\[TargetStock\]/g, targetStock.toString())
                .replace(/\[MinStock\]/g, minStock.toString())
                .replace(/\[MaxStock\]/g, maxStock.toString())
                .replace(/\[SafetyStock\]/g, safetyStock.toString())
                .replace(/\[MultipleReorder\]/g, multipleReorder.toString());
            
            // Replace logical operators
            ruleWithValues = ruleWithValues
                .replace(/AND/g, '&&')
                .replace(/OR/g, '||');
    
            // Custom evaluation function without using 'new Function()'
            const isSafeExpression = (expression: string) => {
                return /^[\d\s\.\(\)&|<>!=+-]+$/.test(expression);
            };
    
            if (isSafeExpression(ruleWithValues)) {
                try {
                    const evaluateRule = (values: string) => Function(`"use strict"; return (${values});`)();
                    if (evaluateRule(ruleWithValues)) {
                        return displayCodeAg as StockStatusType;
                    }
                } catch (error) {
                    console.error('Evaluation error: ', error);
                }
            } else {
                console.error('Unsafe expression detected: ', ruleWithValues);
            }
        }
        return StockStatusType.MTO;
    };
    
    const calculateSummedSelectedPlanningGrids = (
        selectedProductIds: number[],
        simplePeriodLength: number,
        planningGridsByItemId: Dictionary<PlanningGridData[]>
    ): PlanningChartData[] => {
        try {
            const selectedPlanningGrids: PlanningChartData[] = Array.from({ length: simplePeriodLength }, () => ({
                closingStock: 0,
                openingStock: 0,
                orders: 0,
                demand: 0,
                minStock: 0,
                mps: 0,
                targetStock: 0,
                maxStock: 0,
                safetyStock: 0,
                locked: true,
                stockStatus: StockStatusType.Stockout,
                multipleReorder: 0
            }));

            for (const itemId of selectedProductIds) {
                const planningGrids = planningGridsByItemId[itemId];
                if (!planningGrids) {
                    console.error(`productId: ${itemId} não foi encontrado no dicionário`);
                    continue;
                }

                const localPlanningGrids = [...planningGrids].sort((a, b) => a.periodId - b.periodId);

                for (let k = 0; k < localPlanningGrids.length; k++) {
                    const planningGrid = localPlanningGrids[k];
                    if (!planningGrid) continue;

                    selectedPlanningGrids[k].closingStock += planningGrid.closingStock ?? 0;
                    selectedPlanningGrids[k].openingStock += planningGrid.openingStock ?? 0;
                    selectedPlanningGrids[k].orders += planningGrid.orders ?? 0;
                    selectedPlanningGrids[k].demand += planningGrid.demand ?? 0;
                    selectedPlanningGrids[k].minStock += planningGrid.minStock ?? 0;
                    selectedPlanningGrids[k].mps += planningGrid.mps ?? 0;
                    selectedPlanningGrids[k].targetStock += planningGrid.targetStock ?? 0;
                    selectedPlanningGrids[k].maxStock += planningGrid.maxStock ?? 0;
                    selectedPlanningGrids[k].safetyStock += planningGrid.safetyStock ?? 0;
                    selectedPlanningGrids[k].multipleReorder = planningGrid.multipleReorder ?? 0;
                    // selectedPlanningGrids[k].stockStatus = calculateChartStockStatusType(selectedPlanningGrids[k]);
                    selectedPlanningGrids[k].stockStatus = calculateDynamicStockStatusType(selectedPlanningGrids[k]);
                }
            }
            return selectedPlanningGrids;
        } catch (error) {
            console.error(t`Ocorreu um erro durante o calculo:`, error);
            toast.error(t`Ocorreu um erro ao calcular os dados do gráfico.`, { autoClose: 3000 });
            return Array.from({ length: simplePeriodLength }, () => ({
                closingStock: 0,
                openingStock: 0,
                orders: 0,
                demand: 0,
                minStock: 0,
                mps: 0,
                targetStock: 0,
                maxStock: 0,
                safetyStock: 0,
                locked: true,
                stockStatus: StockStatusType.Stockout,
            }));
        }
    };


    return {
        calculateChartStockStatusType,
        calculateSummedSelectedPlanningGrids,
    };
};

export default useChartDataHandler;
