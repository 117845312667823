import { t } from '@lingui/macro';
import { deleteAsync, postAsync, putAsync, useConfirmContext } from '@nplan';
import { MouseEvent, useCallback, useState } from 'react';
import { toast } from 'react-toastify';

export const useScenarioActions = () => {
    const [deletingState, setDeletingState] = useState<{id:number, state:boolean}>({ id: 0, state: false });
    const confirm = useConfirmContext();

    const handleChangeScenarioStatusId = useCallback(
        async (event: MouseEvent<HTMLButtonElement>) => {
            const id = event.currentTarget.dataset.id;

            if (id) {
                await putAsync<undefined, string>(
                    `/scenarios/${id}/status/`,
                );
            }
        }, []);

    const handleDeleteScenario = useCallback(async (
        event: MouseEvent<HTMLButtonElement>,
    ) => {
        const id = event.currentTarget.dataset.id;
        const name = event.currentTarget.dataset.name;
        if (id) {
            if (
                await confirm({
                    title: t`Excluir cenário`,
                    description: t`Tem certeza que deseja excluir o cenário ${name}?`,
                })
            ) {
                try {
                    setDeletingState({
                        id: parseInt(id),
                        state: true
                    });
                    await deleteAsync<string>(`/scenarios/${id}`);
                    toast.success(`${t`Cenário ${name} foi excluído com sucesso!`}`);
                    setDeletingState({
                        id: 0,
                        state: false
                    });
                } catch (error) {
                    toast.error(`${t`Falha ao excluir o cenário ${name}. Tente novamente mais tarde.`}`);
                    setDeletingState({
                        id: 0,
                        state: false
                    });
                }                
            }
        }
    }, [confirm]);

    const handleCloneScenario = useCallback(async (
        event: MouseEvent<HTMLButtonElement>,
    ) => {
        const id = event.currentTarget.dataset.id;
        const name = event.currentTarget.dataset.name;
        if (id) {
            if (
                await confirm({
                    title: t`Clonar cenário`,
                    description: t`Tem certeza que deseja clonar o cenário ${name}?`,
                })
            ) {
                postAsync(`/procedure-executions/button/CloneScenario`, [{
                    name: 'scenarioId',
                    value: id
                }]);
            }
        }
    }, [confirm]);

    const handleRenameScenarioAsync = async (
        id: number | null | undefined, name: string | null | undefined
    ) => {
        if (!id || !name) return;
        await putAsync(`scenarios/${id}/name/${name}`);
    };

    const handleConfirmIntegrationAsync = useCallback(async () => {
        const confirmed = await confirm({
            title: t`Integração`,
            description: t`Tem certeza que deseja iniciar a integração?`,
        });
        if (confirmed) {
            postAsync(`/procedure-executions/button/IntegrateData`, []);
        }
    }, [confirm]);

    const handleConfirmGenerateOrdersAsync = useCallback(async () => {
        const confirmed = await confirm({
            title: t`Gerar Pedidos`,
            description: t`Tem certeza que deseja gerar os pedidos?`,
        });
        if (confirmed) {
            postAsync(`/procedure-executions/button/GenerateOrders`, []);
        }
    }, [confirm]);

    return {
        handleChangeScenarioStatusId,
        handleDeleteScenario,
        handleCloneScenario,
        handleRenameScenarioAsync,
        handleConfirmIntegrationAsync,
        handleConfirmGenerateOrdersAsync,
        deletingState
    };
};