import { embedDashboard } from '@superset-ui/embedded-sdk';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getAsync, postAsync } from '@nplan';

interface IUseHomeDataReturn {
    ref: React.RefObject<HTMLElement>;
}
export const useHomeData = (): IUseHomeDataReturn => {
    const [config, setConfig] = useState<ReportConfig>();
    const ref = useRef<HTMLElement>(null);

    useEffect(() => {
        (async () => {
            const response = await getAsync<ReportConfig>('reports-config');
            if (response.type === 'success' && response.data) {
                setConfig(response.data);
            }
        })();
    }, []);

    const fetchGuestTokenAsync = useCallback(async (): Promise<string> => {
        if(!config) return '';
        const response = await postAsync<string>(`report-token/${config.homeDashId}`);
        if (response.type === 'success' && response.data) {
            return response.data;
        }
        return '';
    }, [config]);

    useEffect(() => {
        (async () => {
            if (!config) return;
            if (ref.current === null) return;
            await embedDashboard({
                id: config.homeDashId,
                supersetDomain: config.url,
                mountPoint: ref.current,
                fetchGuestToken: fetchGuestTokenAsync,
            });
            const container = ref.current;
            const iframe = container?.childNodes[0] as HTMLIFrameElement;
            if (iframe) {
                iframe.style.height = '100%';
                iframe.style.width = '100%';
            }
        })();
    }, [config, fetchGuestTokenAsync, ref]);
    return { ref };
};
