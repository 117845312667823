import { Box, Chip, Switch, Typography } from "@mui/material"
import { CSSProperties } from "react";
import { getContrastColor } from "utils"

export interface IRenderProps {
    value: string | boolean | undefined | number | null,
    type: 'color' | 'string' | 'checkbox' | 'select' | 'textarea',
    onEditingTrue: () => void,
    selects: Align[] | ViewType[] | FilterType[];
    columnKey: keyof Column;

}


export const Render = ({ value, onEditingTrue, type, selects, columnKey }: IRenderProps) => {
    const getSelectValue = (): string | undefined => {
        return selects.find((item) => item.id === value)?.name;
    }
    const getJustifyContent = (): CSSProperties['justifyContent'] => {
        if (type === "color" || type === "checkbox")
            return "center";
        if(['width', 'numberFloats', 'order' as keyof Column].includes(columnKey))
            return "flex-end"
        return "flex-start"

    }
    return (
        <Box
            onClick={ onEditingTrue }
            sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: getJustifyContent(),
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: 300,
                overflow: 'hidden',
            }}
        >
            { type === "color" && <Chip label={ value?.toString().toUpperCase() } sx={ { bgcolor: `${value}`, color: `${getContrastColor(value as string)}` } } /> }
            { (type === "string" || type === "textarea") && <Typography> { value } </Typography> }
            { type === "checkbox" && <Switch checked={ Boolean(value) } disabled /> }
            { type === "select" && <Typography> { getSelectValue() } </Typography> }
        </Box>
    )
    
}