import React, { useCallback } from 'react';
import {
    Box,
    Divider,
    Menu,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import { Icons } from 'utils/icons';
import {
    useUserContext,
    useColorModeContext,
    activateLocaleAsync,
    useDialog,
} from '@nplan';
import { ThemeSwitch } from './components/Switch';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { AdminConfigs, UserProfile } from './components';
import { ProfileManage } from './components/ProfileManage';

interface ISettingsMenuProps {
    open: boolean;
    anchorEl: HTMLElement | null;
    onClose: () => void;
}

export const SettingsMenu: React.FC<ISettingsMenuProps> = ({
    open,
    anchorEl,
    onClose,
}: ISettingsMenuProps): JSX.Element => {

    const { user, onLogoutAsync, role, onGetUserAsync } = useUserContext();

    const { toggleColorMode, mode } = useColorModeContext();

    const { i18n } = useLingui();

    const {
        open: profileManageOpen,
        handleCloseDialog,
        handleOpenDialog
    } = useDialog();

    const onLanguageChange = useCallback(
        (event: SelectChangeEvent<string>) => {
            activateLocaleAsync(event.target.value);
            onClose();
        },
        [onClose],
    );

    return (
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
        >
            <ProfileManage 
                open={profileManageOpen}
                onClose={handleCloseDialog}
                onRefreshDataProfile={onGetUserAsync}
            />
            <MenuItem>
                <UserProfile user={user} role={role} onClick={handleOpenDialog}/>
            </MenuItem>
            <MenuItem>
                <Box
                    display={'flex'}
                    justifyContent={'space-evenly'}
                    width={'100%'}
                    height={'100%'}
                    alignItems={'center'}
                    onClick={toggleColorMode}
                >
                    Light
                    <ThemeSwitch
                        checked={
                            mode === 'dark' ? true : false
                        }
                    />
                    Dark
                </Box>
            </MenuItem>
            <MenuItem>
                <Icons.Language
                    size={25}
                    style={{ marginLeft: 2 }}
                />
                <Select
                    fullWidth
                    variant='standard'
                    sx={{ ml: 1 }}
                    value={i18n.locale}
                    onChange={onLanguageChange}
                >
                    <MenuItem value={'pt-BR'}>
                        Português
                    </MenuItem>
                    <MenuItem value={'en-US'}>
                        English
                    </MenuItem>
                    <MenuItem value={'es'}>
                        Español
                    </MenuItem>
                </Select>
            </MenuItem>
            {role?.admin === true && (
                <AdminConfigs onClose={onClose} />
            )}
            <MenuItem onClick={onLogoutAsync}>
                <Icons.LogOff
                    size={20}
                    style={{ marginLeft: 2 }}
                />
                <Typography
                    variant='body1'
                    sx={{ ml: 1 }}
                >{t`Sair`}</Typography>
            </MenuItem>
            <Divider />
        </Menu>
    );
};
