import { RootState } from 'redux/store';
import { createSelector } from 'reselect';
import { createItemCodeSearchTree, createItemLevelSearchTree, createItemPlannerSearchTree, createItemStockGroupSearchTree, createSimpleAttributesTree, createStockStatusTypeSearchTree, createStockStatusWithPeriodTypeSearchTree, createSelectedAttributeFilters, createSelectedFixedCriteriaFilters, createCompanySearchTree, createItemComplexitySearchTree, createItemVolumeSearchTree, createItemUncertaintySearchTree } from './helpers';
import { createCachedSelector } from 're-reselect';
import { getAsync } from 'services/api';

const imageURL = async (itemCode: string | null | undefined) => {
    if (typeof itemCode !== 'string') return null;
    try {
        const blob = await getAsync(`images/${itemCode}`, { responseType: 'blob' });
        return URL.createObjectURL(blob as any);
    } catch (error) {
        console.error('Error fetching image');
        return null;
    }

};
export const selectItemImageCached = createCachedSelector(
    (itemCode: string) => itemCode,
    (itemCode: string) => imageURL(itemCode)
)(
    (itemImage) => itemImage
);



export const selectItemAttribute01SearchTree = createSelector(
    (_state_: RootState) => _state_.filter.planningGrid.itemAttributes.ItemAttribute01,
    (_state_: RootState) => _state_.filter.planningGrid.filterData.itemsIdsByAttributeId.ItemAttribute01,
    (attributes1, itemsIdsByAttributeId) => createSimpleAttributesTree(attributes1, itemsIdsByAttributeId)
);

export const selectItemAttribute02SearchTree = createSelector(
    (_state_: RootState) => _state_.filter.planningGrid.itemAttributes.ItemAttribute02,
    (_state_: RootState) => _state_.filter.planningGrid.filterData.itemsIdsByAttributeId.ItemAttribute02,
    (attributes2, itemsIdsByAttributeId) => createSimpleAttributesTree(attributes2, itemsIdsByAttributeId)
);

export const selectItemAttribute03SearchTree = createSelector(
    (_state_: RootState) => _state_.filter.planningGrid.itemAttributes.ItemAttribute03,
    (_state_: RootState) => _state_.filter.planningGrid.filterData.itemsIdsByAttributeId.ItemAttribute03,
    (attributes3, itemsIdsByAttributeId) => createSimpleAttributesTree(attributes3, itemsIdsByAttributeId)
);

export const selectItemAttribute04SearchTree = createSelector(
    (_state_: RootState) => _state_.filter.planningGrid.itemAttributes.ItemAttribute04,
    (_state_: RootState) => _state_.filter.planningGrid.filterData.itemsIdsByAttributeId.ItemAttribute04,
    (attributes4, itemsIdsByAttributeId) => createSimpleAttributesTree(attributes4, itemsIdsByAttributeId)
);

export const selectItemAttribute05SearchTree = createSelector(
    (_state_: RootState) => _state_.filter.planningGrid.itemAttributes.ItemAttribute05,
    (_state_: RootState) => _state_.filter.planningGrid.filterData.itemsIdsByAttributeId.ItemAttribute05,
    (attributes5, itemsIdsByAttributeId) => createSimpleAttributesTree(attributes5, itemsIdsByAttributeId)
);

export const selectItemAttribute06SearchTree = createSelector(
    (_state_: RootState) => _state_.filter.planningGrid.itemAttributes.ItemAttribute06,
    (_state_: RootState) => _state_.filter.planningGrid.filterData.itemsIdsByAttributeId.ItemAttribute06,
    (attributes6, itemsIdsByAttributeId) => createSimpleAttributesTree(attributes6, itemsIdsByAttributeId)
);

export const selectItemAttribute07SearchTree = createSelector(
    (_state_: RootState) => _state_.filter.planningGrid.itemAttributes.ItemAttribute07,
    (_state_: RootState) => _state_.filter.planningGrid.filterData.itemsIdsByAttributeId.ItemAttribute07,
    (attributes7, itemsIdsByAttributeId) => createSimpleAttributesTree(attributes7, itemsIdsByAttributeId)
);

export const selectItemAttribute08SearchTree = createSelector(
    (_state_: RootState) => _state_.filter.planningGrid.itemAttributes.ItemAttribute08,
    (_state_: RootState) => _state_.filter.planningGrid.filterData.itemsIdsByAttributeId.ItemAttribute08,
    (attributes8, itemsIdsByAttributeId) => createSimpleAttributesTree(attributes8, itemsIdsByAttributeId)
);

export const selectItemAttribute09SearchTree = createSelector(
    (_state_: RootState) => _state_.filter.planningGrid.itemAttributes.ItemAttribute09,
    (_state_: RootState) => _state_.filter.planningGrid.filterData.itemsIdsByAttributeId.ItemAttribute09,
    (attributes9, itemsIdsByAttributeId) => createSimpleAttributesTree(attributes9, itemsIdsByAttributeId)
);

export const selectItemAttribute10SearchTree = createSelector(
    (_state_: RootState) => _state_.filter.planningGrid.itemAttributes.ItemAttribute10,
    (_state_: RootState) => _state_.filter.planningGrid.filterData.itemsIdsByAttributeId.ItemAttribute10,
    (attributes10, itemsIdsByAttributeId) => createSimpleAttributesTree(attributes10, itemsIdsByAttributeId)
);
export const selectTag01SearchTree = createSelector(
    (_state_: RootState) => _state_.filter.planningGrid.tags.Tag01,
    (_state_: RootState) => _state_.filter.planningGrid.filterData.itemsIdsTagId.Tag01,
    (tag01, itemsIdsByTagId) => createSimpleAttributesTree(tag01, itemsIdsByTagId)
);
export const selectTag02SearchTree = createSelector(
    (_state_: RootState) => _state_.filter.planningGrid.tags.Tag02,
    (_state_: RootState) => _state_.filter.planningGrid.filterData.itemsIdsTagId.Tag02,
    (tag02, itemsIdsByTagId) => createSimpleAttributesTree(tag02, itemsIdsByTagId)
);
export const selectTag03SearchTree = createSelector(
    (_state_: RootState) => _state_.filter.planningGrid.tags.Tag03,
    (_state_: RootState) => _state_.filter.planningGrid.filterData.itemsIdsTagId.Tag03,
    (tag03, itemsIdsByTagId) => createSimpleAttributesTree(tag03, itemsIdsByTagId)
);
//Funcionando a tree
export const selectStockStatusTypeWithPeriodSearchTree = createSelector(
    (_state_: RootState) => _state_.filter.planningGrid.filterData.itemIdsByStockStatusByPeriod,
    (_state_: RootState) => _state_.filter.planningGrid.periodsIdToIndex,
    (_state_: RootState) => _state_.filter.planningGrid.minifiedPeriods,
    (_state_: RootState) => _state_.filter.planningGrid.periods,
    (itemIdsByStockStatusByPeriod, periodsIdToIndex, minifiedPeriods, periods) => createStockStatusWithPeriodTypeSearchTree(periodsIdToIndex, itemIdsByStockStatusByPeriod, minifiedPeriods, periods)
);

export const selectItemCodeSearchTree = createSelector(
    (_state_: RootState) => _state_.filter.planningGrid.itemById,
    (itemById) => createItemCodeSearchTree(itemById)
);

export const selectItemPlannerSearchTree = createSelector(
    (_state_: RootState) => _state_.filter.planningGrid.filterData.itemIdsByItemPlannerIds,
    (_state_: RootState) => _state_.filter.planningGrid.itemPlannerById,
    (itemIdsByItemPlannerIds, itemPlannerById) => createItemPlannerSearchTree(itemIdsByItemPlannerIds, itemPlannerById)
);
export const selectComplexitySearchTree = createSelector(
    (_state_: RootState) => _state_.filter.planningGrid.filterData.itemIdsByComplexity,
    (_state_: RootState) => _state_.filter.planningGrid.itemComplexityById,
    (itemIdsByComplexity, itemComplexityById) => createItemComplexitySearchTree(itemIdsByComplexity, itemComplexityById)
);
export const selectUncertaintySearchTree = createSelector(
    (_state_: RootState) => _state_.filter.planningGrid.filterData.itemIdsByUncertainty,
    (_state_: RootState) => _state_.filter.planningGrid.itemUncertaintyById,
    (itemIdsByUncertainty, itemUncertaintyById) => createItemUncertaintySearchTree(itemIdsByUncertainty, itemUncertaintyById)
);
export const selectVolumeSearchTree = createSelector(
    (_state_: RootState) => _state_.filter.planningGrid.filterData.itemIdsByVolume,
    (_state_: RootState) => _state_.filter.planningGrid.itemVolumeById,
    (itemIdsByVolume, itemVolumeById) => createItemVolumeSearchTree(itemIdsByVolume, itemVolumeById)
);

export const selectItemStockGroupSearchTree = createSelector(
    (_state_: RootState) => _state_.filter.planningGrid.filterData.itemIdsByStockGroupIds,
    (_state_: RootState) => _state_.filter.planningGrid.itemStockGroupById,
    (itemIdsByStockGroupIds, itemStockGroupById) => createItemStockGroupSearchTree(itemIdsByStockGroupIds, itemStockGroupById)
);

export const selectLevelSearchTree = createSelector(
    (_state_: RootState) => _state_.filter.planningGrid.filterData.itemIdsByLevel,
    (itemIdsByLevel) => createItemLevelSearchTree(itemIdsByLevel)
);

export const selectCompanySearchTree = createSelector(
    (_state_: RootState) => _state_.filter.planningGrid.filterData.itemIdsByCompany,
    (_state_: RootState) => _state_.filter.planningGrid.itemCompanyById,
    (itemIdsByCompany, itemCompanyById) => createCompanySearchTree(itemIdsByCompany, itemCompanyById)
);
//StockStatusType
export const selectStockStatusTypeSearchTree = createSelector(
    (_state_: RootState) => _state_.filter.planningGrid.filterData.itemIdsByStockStatus,
    (itemIdsByStockStatus) => createStockStatusTypeSearchTree(itemIdsByStockStatus)
);

export const selectFilterAttributesOptions = createSelector(
    (_state_: RootState) => _state_.filter.planningGrid.selectedAttributesToShow,
    (_state_: RootState) => _state_.filter.planningGrid.attributeListData,
    (selectedAttributesToShow, attributeListData) => createSelectedAttributeFilters(selectedAttributesToShow, attributeListData)
);
export const selectFilterFixedCriteriaOptions = createSelector(
    (_state_: RootState) => _state_.filter.planningGrid.selectedFiltersToShow,
    (_state_: RootState) => _state_.filter.planningGrid.fixedFilterOptions,
    (selectedFiltersToShow, FixedCriteriaFilterOptions) => createSelectedFixedCriteriaFilters(selectedFiltersToShow, FixedCriteriaFilterOptions)
);