// hooks/useManageUser.js
import { SelectChangeEvent } from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import { getAsync, postAsync, putAsync } from '@nplan';

const defaultUser: User = {
    id: 0,
    name: '',
    email: '',
    roleId: 1,
    active: true,
    blocked: false,
    lastName: '',
    userName: '',
    authWithPassword: true,
    userType: 0
};

interface IUseManageUser {
    user: User;
    planners: Planner[];
    userPlannerPermissions: UserPlannerPermission[];
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelectChange: (event: SelectChangeEvent<number>) => void;
    handleSwitchChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    handleSaveAsync: () => Promise<void>;
    handleChangeUserPlannerPermission: (event: SelectChangeEvent<number[]>) => void;
    loading: boolean;
}
export const useManageUser = (
    userId: number,
    onClose: (refresh: boolean) => void,
    open: boolean
): IUseManageUser => {
    const [user, setUser] = useState<User>(defaultUser);
    const [userPlannerPermissions, setUserPlannerPermissions] = useState<UserPlannerPermission[]>([]);
    const [planners, setPlanners] = useState<Planner[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const handleInputChange = useCallback((
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        const { name, value } = event.target;
        setUser((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, []);

    const handleSelectChange = useCallback((
        event: SelectChangeEvent<number>
    ): void => {
        const { name, value } = event.target;
        setUser((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, []);

    const handleSwitchChange = useCallback((
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean,
    ): void => {
        const { name } = event.target;
        setUser((prev) => ({
            ...prev,
            [name]: checked,
        }));
    }, []);

    const handleSaveAsync = useCallback(async (): Promise<void> => {
        setLoading(true);
        const res =
            user.id === 0
                ? await postAsync<User>('users', user)
                : await putAsync(`users`, user);
        
        if (res.type === 'success') {
            await putAsync(`user-planner-permissions/user/${user.id}`, userPlannerPermissions);
            onClose(true);
        }
        setLoading(false);
    }, [onClose, user, userPlannerPermissions]);

    const handleChangeUserPlannerPermission = useCallback((
        event: SelectChangeEvent<number[]>
    ): void => {
        const value  = event.target.value as number[];
        const newPermissions = value.map((plannerId) => ({
            id: 0,
            plannerId,
            userId: user.id,
        }));
        setUserPlannerPermissions(newPermissions);
    }, [user.id]);

    useEffect(() => {
        if(!open) return;
        setUser(defaultUser);
        (async () => {
            if (userId > 0) {
                setLoading(true);
                const [resUser, resPermissions, resPlanners] = await Promise.all([
                    getAsync(`users/${userId}`),
                    getAsync<UserPlannerPermission[]>(`user-planner-permissions/user/${userId}`),
                    getAsync<Planner[]>('planners'),
                ]);
                if (resUser.type === 'success') {
                    setUser(resUser.data);
                } else {
                    setUser(defaultUser);
                }
                if (resPermissions.type === 'success' && resPermissions.data) {
                    setUserPlannerPermissions(resPermissions.data);
                }
                if (resPlanners.type === 'success' && resPlanners.data) {
                    setPlanners(resPlanners.data);
                }
                setLoading(false);
            }
        })();
    }, [userId, open]);

    return {
        user,
        loading,
        planners,
        userPlannerPermissions,
        handleInputChange,
        handleSelectChange,
        handleSwitchChange,
        handleSaveAsync,
        handleChangeUserPlannerPermission
    };
};
