import { useCallback,  useEffect, useState } from 'react';
import { getAsync, putAsync } from '@nplan';

interface IUseReportConfigDataReturn {
    config: ReportConfig;
    loading: boolean;
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleSaveConfigAsync: () => Promise<void>;
}
export const useReportConfigData = (
    open: boolean, 
    onClose: () => void,
): IUseReportConfigDataReturn => {

    const [config, setConfig] = useState<ReportConfig>({} as ReportConfig);
    const [loading, setLoading] = useState<boolean>(false);

    const handleLoadConfigAsync = useCallback(async (): Promise<void> => {
        setLoading(true);
        const response = await getAsync<ReportConfig>('reports-config');
        if (response.type === 'success' && response.data){
            setConfig(response.data);
        }
        setLoading(false);
    }, []);

    

    const handleInputChange = useCallback((
        e: React.ChangeEvent<HTMLInputElement>
    ): void => {
        setConfig({
            ...config,
            [e.target.name]: e.target.value,
        });
    }, [config]);

    const handleSaveConfigAsync = useCallback(async (): Promise<void> => {
        setLoading(true);
        const response = await putAsync<ReportConfig, string>('reports-config',config);
        if (response.type === 'success') {
            onClose();
        }
        setLoading(false);
    }, [config, onClose]);
    
    useEffect(() => {
        if (open) handleLoadConfigAsync();
    }, [open, handleLoadConfigAsync]);

    return {
        config,
        loading,
        handleInputChange,
        handleSaveConfigAsync
    };
};
