import { useCallback, useState } from 'react';

const useMenu = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [open, setOpen] = useState(false);

    const handleOpen = useCallback((
        event: React.MouseEvent<HTMLElement>,
    ) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    return {
        anchorEl,
        open,
        handleOpen,
        handleClose,
    };
};
export default useMenu;
