import { Box, Typography, TypographyOwnProps } from '@mui/material';
import { getTextAlign } from 'pages/table-data/utils';
import React, { memo } from 'react';


interface IDefaultRenderProps {
    row: any;
    column: Column;
}

export const DefaultRender: React.FC<IDefaultRenderProps> = ({
    row,
    column,
}: IDefaultRenderProps): JSX.Element => {
    
    return (
        <Box
            sx={{
                height: '100%',
                width: column.width,
                maxWidth: column.width,
                display: 'flex',
                alignItems: 'center',
                padding: '0 5px',
                boxSizing: 'border-box',
            }}
            title={row[column.field]}
        >
            <Typography
                sx={{
                    width: '100%',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textAlign: getTextAlign(
                        column.alignId,
                    ) as TypographyOwnProps['align'],
                }}
            >
                {column.prefix && `${column.prefix} `}
                {row[column.field]}
                {column.suffix && ` ${column.suffix}`}
            </Typography>
        </Box>
    );
};

const MemoizedDefaultRender = memo(DefaultRender);
export default MemoizedDefaultRender;
