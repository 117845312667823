export const convertISODateStringToLocalDateTimeString = (
    isoDateString: Date
): string => {

    const date = new Date(isoDateString);
    date.setTime(date.getTime());
    const localDate = date.toLocaleDateString();
    const localTime = date.toLocaleTimeString([], {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
    });
    const localDateTimeString = `${localDate  } ${  localTime}`;
    return localDateTimeString;
};