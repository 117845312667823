import { Box, Typography } from '@mui/material';
import { PeriodData } from 'pages/planning-grid/types/PeriodData';
import { memo } from 'react';
import { areEqual, ListChildComponentProps } from 'react-window';

const GridCollapsedHeaderCol = memo((props: ListChildComponentProps) => {
    const periods: PeriodData[] = props.data;
    const periodData = periods[props.index];

    return (
        <Box key={props.index} style={props.style} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box sx={{ display: 'flex', marginTop: '0.2rem', justifyContent: 'center', alignItems: 'center', borderBottom: '2px solid', borderColor: '#e5e5e5' }}>
                <Typography fontWeight="bold">
                    {periodData.yearNumber}
                </Typography>
            </Box>

            <Box sx={{ display: 'flex', marginTop: '0.3rem', flexDirection: 'row', alignItems: 'center', textAlign: 'center', justifyContent: 'space-around' }}>
                <Typography key={`${props.index}`} >
                    {periodData.monthName}
                </Typography>
            </Box>
        </Box>


    );
}, areEqual);

export default GridCollapsedHeaderCol;
