import { t } from '@lingui/macro';
import { Box } from '@mui/material';
import { registerables } from 'chart.js';
import Chart from 'chart.js/auto';
import 'chartjs-plugin-dragdata';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { applyManualMpsChange } from 'redux/Filters/reducer';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import useChartConfig from '../hooks/Chart/useChartConfig';
import useChartData from '../hooks/Chart/useChartDataConfig';
import useChartDataHandler from '../hooks/Chart/useChartDataHandler';
import { createMinifiedPeriods } from '../utils/PeriodUtils';

Chart.register(...registerables);
interface Props {
    planningGridData: PlanningGrid;
    periods: Period[];
    scenario: Scenario | null;
    isWeekly: boolean;
    handleReloadItemPlanningGrid: (itemId: number[]) => void;
}

export const PlanningGridChart = ({
    periods,
    scenario,
    isWeekly,
}: Props) => {
    const dispatch = useAppDispatch();
    const selectedIds = useAppSelector((state) => state.filter.global.chartSelectedItemIds,);
    const { selectedPlanningGrid: planningGridDataByItemId, itemById } = useAppSelector((state) => state.filter.planningGrid);
    const stockStatus = useAppSelector(state=>state.filter.global.stockStatus);
    
    const { calculateSummedSelectedPlanningGrids } = useChartDataHandler({ stockStatus });

    const selectedIdsRef = useRef<number[]>([]);
    const currentDataset = useMemo(() => {
        return scenario;
    }, [scenario]);
    const chartReference = useRef(null);

    useEffect(() => {
        selectedIdsRef.current = selectedIds;
    }, [selectedIds]);

    const summedSelectedPlanningGrids = useMemo(() => {
        return calculateSummedSelectedPlanningGrids(
            selectedIds,
            periods.length,
            planningGridDataByItemId,
        );
    }, [
        calculateSummedSelectedPlanningGrids,
        periods.length,
        planningGridDataByItemId,
        selectedIds,
    ]);

    const minifiedPeriods = createMinifiedPeriods(periods, isWeekly);

    const localOnDragStart = useCallback(() => {
        if (selectedIdsRef.current.length !== 1) return false;
    }, []);
    const localOnDrag = useCallback((e: any, _: any, value: any) => {
        if (value < 0 || selectedIdsRef.current.length !== 1) { return false; }
        e.target.style.cursor = 'grabbing';
    }, []);
    const localOnDragEnd = useCallback((e: any, _: any, index: any, value: any) => {
        if (value < 0 || selectedIdsRef.current.length !== 1) { return false; }
        e.target.style.cursor = 'default';
        dispatch(applyManualMpsChange({ mpsValue: parseInt(value), index }));
    }, [dispatch]);


    const handleChartTitle = useMemo(() => {
        if (selectedIds.length === 1) {
            const itemSelected = Object.values(itemById).find((c) => c.id === selectedIds[0]);
            return `${t({ message: 'Produto' })} ${itemSelected?.code}`;
        } else {
            return `${t({ message: 'Produtos Selecionados' })}: ${selectedIds.length}`;
        }
    }, [itemById, selectedIds]);


    const { chartConfig } = useChartConfig(
        localOnDragStart,
        localOnDrag,
        localOnDragEnd,
        currentDataset,
        handleChartTitle,
    );
    const { chartData } = useChartData(
        summedSelectedPlanningGrids,
        minifiedPeriods,
    );
    const planningChartContainerMemoized = useMemo(() => {
        return (
            <Box
                sx={{
                    position: 'relative',
                    height: '100%',
                    backgroundColor: 'transparent',
                }}
            >
                <Bar
                    options={chartConfig as any}
                    data={chartData as any}
                    ref={chartReference}
                />
            </Box>
        );
    }, [chartConfig, chartData]);
    return <>{planningChartContainerMemoized}</>;
};
