import { HubContext, IHubContext } from '../context';
import { useContext } from 'react';

export const useHubContext = (): IHubContext => {
    const context = useContext<IHubContext | null>(HubContext);
    if (context === null) {
        throw new Error('Component must be wrapped with <HubProvider>');
    }
    return context;
};
