import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    useTheme,
} from '@mui/material';
import { useScenarioData } from './hooks/useScenarioData';
import { Scenario } from './components/Scenario';
import { Criteria } from './components/Criteria';
import { Parameter } from './components/Parameter';
import { Icons } from 'utils/icons';
import { Trans } from '@lingui/macro';
import { DateScheduler } from './components/DateScheduler';
import { LoadingButton } from '@mui/lab';

export interface IManageScenarioProps {
    open: boolean;
    onClose: () => void;
    scenarioId: number;
}
export const ManageScenario: React.FC<IManageScenarioProps> = ({
    open,
    scenarioId,
    onClose,
}: IManageScenarioProps): JSX.Element => {
    const {
        scenario,
        scenarioCriteriaValues,
        scenarioParameterValues,
        executionDateDialogOpen,
        saving,
        loading,
        getCriteria,
        getParameter,
        handleChangeInputScenario,
        handleChangeSelectScenario,
        handleCriteriaSliderChange,
        handleParameterValueChange,
        handleCancelChangeExecutionDate,
        handleOpenExecutionDateDialog,
        handleSave,
    } = useScenarioData(
        scenarioId, 
        open,
        onClose, 
    );

    const theme = useTheme();

    const isNotEditable =
        scenario?.scenarioStatusId === undefined ||
        scenario.scenarioStatusId > 1 ||
        loading;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={false}
            data-testid="manage-scenario-dialog"
        >
            <DateScheduler
                saving={saving}
                open={executionDateDialogOpen}
                onClose={handleCancelChangeExecutionDate}
                onChangeScenarioExecutionTime={handleChangeInputScenario}
                onSave={handleSave}
                scenario={scenario}
            />
            <DialogContent
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    background: theme.palette.background.default,
                }}
            >
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    gap={2}
                >
                    <Scenario
                        scenario={scenario}
                        isNotEditable={isNotEditable}
                        onChangeInputScenario={handleChangeInputScenario}
                        onChangeSelectScenario={handleChangeSelectScenario}
                    />
                    <Criteria
                        isNotEditable={isNotEditable}
                        getCriteria={getCriteria}
                        scenarioCriteriaValue={scenarioCriteriaValues}
                        onCriteriaSliderChange={handleCriteriaSliderChange}
                    />
                    <Parameter
                        isNotEditable={isNotEditable}
                        getParameter={getParameter}
                        scenarioParameterValue={scenarioParameterValues}
                        onParameterValueChange={handleParameterValueChange}
                    />
                </Box>
            </DialogContent>
            <DialogActions
                sx={{
                    background: theme.palette.background.default,
                }}
            >
                <Button
                    onClick={onClose}
                    color='error'
                    startIcon={<Icons.Cancel />}
                    variant={theme.palette.mode === 'dark' ? 'outlined': 'contained'}
                    disabled={saving}
                >
                    <Trans>Cancelar</Trans>
                </Button>
                <Button
                    variant={theme.palette.mode === 'dark' ? 'outlined': 'contained'}
                    color='info'
                    startIcon={<Icons.Calendar />}
                    onClick={handleOpenExecutionDateDialog}
                    disabled={isNotEditable || saving}
                >
                    <Trans>Agendar</Trans>
                </Button>
                {scenario?.scenarioStatusId === 0 && (
                    <LoadingButton
                        variant={theme.palette.mode === 'dark' ? 'outlined': 'contained'}
                        startIcon={<Icons.Save />}
                        onClick={handleSave}
                        disabled={isNotEditable}
                        loading={saving}
                        data-testid="save-scenario-button"
                    >
                        <Trans>Gerar</Trans>
                    </LoadingButton>
                )}
            </DialogActions>
        </Dialog>
    );
};
