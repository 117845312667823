// ReleaseNotes.tsx
import { Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

export const ReleaseNotes: React.FC = () => {
    const [markdown, setMarkdown] = useState('');

    useEffect(() => {
        import('../../../RELEASE.md')
            .then(res => {
                fetch(res.default)
                    .then((mdRes) => mdRes.text())
                    .then(setMarkdown);
            });
    }, []);

    return (
        <Box
            sx={{
                padding: 2,
            }}
        >
            <ReactMarkdown>{markdown}</ReactMarkdown>
        </Box>
    );
};

export default ReleaseNotes;
