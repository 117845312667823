import { StockStatusType } from 'pages/planning-grid/types/PlanningGrid';
import { useDataContext } from '@nplan';

export const usePlanningGridStockValueAndColors = () => {

    const { stockStatus: stockStatuses } = useDataContext();

    const toUpperFirstLetter = (string: string): string => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const toLowerFirstLetter = (string: string): string => {
        return string.charAt(0).toLowerCase() + string.slice(1);
    };

    const getColorByStockStatusType = (stockStatusType: StockStatusType) => {
        const matchingStockStatus = stockStatuses.find((status) => status.code === stockStatusType);
        if (matchingStockStatus) {
            return matchingStockStatus.color as string;
        }
        return '#000000';
    };
    const getColorByStockStatusId = (stockStatusId: number | undefined | null) => {
        const matchingStockStatus = stockStatuses.find((status) => status.id === stockStatusId);
        if (matchingStockStatus) {
            return matchingStockStatus.color as string;
        }
        return '#000000';
    };
    const getStatusByStockStatusId = (stockStatusId: number | undefined | null) => {
        const matchingStockStatus = stockStatuses.find((status) => status.id === stockStatusId);
        if (matchingStockStatus) {
            return matchingStockStatus.name as string;
        }
        return '#000000';
    };

    return {
        toUpperFirstLetter,
        toLowerFirstLetter,
        getColorByStockStatusType,
        getColorByStockStatusId,
        getStatusByStockStatusId
    };
};

export default usePlanningGridStockValueAndColors;
