/* eslint-disable @typescript-eslint/no-explicit-any */
import CssBaseline from '@mui/material/CssBaseline';
import { i18n } from '@lingui/core';
import { ToastContainer } from 'react-toastify';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './App.css';
import { AppRoutes } from 'routes';
import React, { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.min.css';
import {
    activateLocaleAsync,
    ConfirmProvider,
} from '@nplan';
import { I18nProvider } from '@lingui/react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useTheme } from '@mui/material';
const locales = ['pt-BR', 'en-US', 'es'];
const App: React.FC = (): JSX.Element => {
    useEffect(() => {
        (async () => {        
            // Get the locale from the local storage or the browser.
            let locale = localStorage.getItem('locale') || navigator.language;
        
            // 
            if (!locales.includes(locale)) {
                locale = 'pt-BR';
            }
        
            // Define the locale in the local storage.
            localStorage.setItem('locale', locale);
        
            // Activate the locale.
            await activateLocaleAsync(locale);
        })();
    }, []);
    const router = createBrowserRouter([
        {
            path: '*',
            element: <AppRoutes />,
        },
    ]);
    const theme = useTheme();
    return (
        <React.Fragment>
            <CssBaseline />
            <I18nProvider i18n={i18n}>
                <ToastContainer
                    position="top-right"
                    style={{
                        top: 40,
                    }}
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme={theme.palette.mode}
                />
                <GoogleOAuthProvider clientId="407992395558-d29volf8mpr7852lsim34u3cc3bnnste.apps.googleusercontent.com">
                    <ConfirmProvider>
                        <RouterProvider router={router} />
                    </ConfirmProvider>
                </GoogleOAuthProvider>
            </I18nProvider>
        </React.Fragment>
    );
};
export default App;
