import { Trans, t } from '@lingui/macro';
import { TableCell, TableHead, TableRow, TextField } from '@mui/material';
interface IHeaderProps {
    filterValue: string;
    onChangeFilterValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export const Header: React.FC<IHeaderProps> = ({
    filterValue,
    onChangeFilterValue,
}: IHeaderProps): JSX.Element => {
    return (
        <TableHead>
            <TableRow
                sx={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 10,
                    bgcolor: 'background.paper',
                }}
            >
                <TableCell>
                    <Trans>Nome</Trans>
                </TableCell>
                <TableCell>
                    <Trans>Esquema</Trans>
                </TableCell>
                <TableCell>
                    <Trans>Procedure</Trans>
                </TableCell>
                <TableCell>
                    <Trans>Atualizar ao terminar</Trans>
                </TableCell>
                <TableCell>
                    <Trans>Esperar pelo resultado</Trans>
                </TableCell>
                <TableCell colSpan={2}>
                    <TextField
                        fullWidth
                        value={filterValue}
                        onChange={onChangeFilterValue}
                        placeholder={t`Filtrar...`}
                    />
                </TableCell>
            </TableRow>
        </TableHead>
    );
};