import { Trans, t } from '@lingui/macro';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import ProfileAvatar from './components/ProfileAvatar';
import { useProfileData } from './hooks/useProfileData';
import { useVariant } from 'utils';

interface IProfileManageProps {
    open: boolean;
    onClose: () => void;
    onRefreshDataProfile: () => Promise<boolean>;
}
export const ProfileManage: React.FC<IProfileManageProps> = ({
    open,
    onClose,
    onRefreshDataProfile
}) => {
    const variant = useVariant();	
    const { 
        profileData,
        handleProfilePicChange,
        handleInputChange,
        handleSaveProfileAsync
    } = useProfileData(
        open,
        onClose,
        onRefreshDataProfile
    );
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle 
                align='center'
            >
                <Trans>Editar Perfil</Trans>
            </DialogTitle>
            <DialogContent>
                <Box
                    gap={2}
                    display={'flex'}
                    flexDirection={'column'}
                    width={320}
                >
                    <ProfileAvatar 
                        value={profileData?.avatar} 
                        onChangeProfilePic={handleProfilePicChange} 
                    />
                    <TextField 
                        label={t`Nome`}
                        value={profileData?.name ?? ''}
                        onChange={handleInputChange}
                        name='name'
                        fullWidth
                        variant='standard'
                        autoFocus
                    />
                    <TextField 
                        label={t`Sobrenome`}
                        value={profileData?.lastName ?? ''}
                        onChange={handleInputChange}
                        name='lastName'
                        fullWidth
                        variant='standard'
                    />
                    <TextField 
                        label={t`Email`}
                        value={profileData?.email ?? ''}
                        onChange={handleInputChange}
                        name='email'
                        fullWidth
                        variant='standard'
                    />
                    <TextField 
                        label={t`Senha`}
                        value={profileData?.password ?? ''}
                        onChange={handleInputChange}
                        name='password'
                        fullWidth
                        type='password'
                        variant='standard'
                        placeholder={t`Deixe em branco para manter a senha atual`}
                    />
                    <TextField 
                        label={t`Confirme a senha`}
                        value={profileData?.confirmPassword ?? ''}
                        onChange={handleInputChange}
                        name='confirmPassword'
                        fullWidth
                        variant='standard'
                        type='password'
                        placeholder={t`Deixe em branco para manter a senha atual`}
                    />
                </Box>
                <DialogActions>
                    <Box
                        display={'flex'}
                        justifyContent={'center'}
                        flexDirection={'column'}
                        width={'100%'}
                        gap={2}
                        mt={2}
                    >
                        <Button
                            variant={variant}
                            onClick={handleSaveProfileAsync}
                        >
                            <Trans>
                                Salvar
                            </Trans>
                        </Button>
                        <Button
                            variant={variant}
                            onClick={onClose}
                            color='error'
                        >
                            <Trans>
                                Cancelar
                            </Trans>
                        </Button>
                    </Box>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};