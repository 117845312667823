import { Trans } from '@lingui/macro';
import { useConfirmContext, deleteAsync } from '@nplan';
import React from 'react';

export const useTableActions = () => {
    const confirm = useConfirmContext();
    const handleDeleteTable = async (event: React.MouseEvent<HTMLButtonElement>) => {
        const id = parseInt(event.currentTarget.dataset.id as string);
        const table = event.currentTarget.dataset.name as string;
        const confirmed = await confirm({
            description: (
                <Trans>
                    Deseja realmente deletar a tabela <strong>{table}</strong>?
                </Trans>
            ),
        });
        if (confirmed) {
            await deleteAsync(`tables/${id}`);
        }
    };

    return { handleDeleteTable };
};
