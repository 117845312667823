import { Trans } from '@lingui/macro';
import { Box, Typography, useTheme } from '@mui/material';
import Tippy from '@tippyjs/react';
import { IPlanningGridOperationResource } from 'types';
import { getContrastColor } from 'utils';

interface ToolTipProps {
    resourceData: IPlanningGridOperationResource[]
    details: string | null | undefined
    notFound?: boolean
}

export function ResourceTippyComponent({ resourceData, details, notFound }: ToolTipProps) {
    const theme = useTheme();
    function convertFloatHoursToHoursAndMinutes(floatHours: number) {
        if (typeof floatHours !== 'number') {
            throw new Error('Input must be a number.');
        }

        const hours = Math.floor(floatHours);
        const remainingMinutes = Math.round((floatHours % 1) * 60);

        return `${hours}h ${remainingMinutes}min`;
    }
    const sortedResourceData = [...resourceData].sort((a, b) => {

        // Primeiro, ordena por productionType.Id
        if (a.productionType?.id !== b.productionType?.id) {
            return Number(a.productionType?.id) - Number(b.productionType?.id);
        }
    
        // Se productionType.Id for igual, ordena por operationNo
        if (a.operationNo !== b.operationNo) {
            return a.operationNo - b.operationNo;
        }
    
        // Se operationNo também for igual, ordena por code (nome)
        return a.code!.localeCompare(b.code!);
    });
    
    return (
        <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'space-between', alignItems: 'center', height: '100%' }} >
            <Tippy
                delay={[200, 50]}
                animation="fade"
                offset={[10, 10]}
                content={!notFound &&
                    <Box sx={{ backgroundColor: theme.palette.background.paper, padding: '8px', borderRadius: '10px', border: '1px solid', borderColor: theme.palette.primary.main }}>
                        {sortedResourceData.map((resource, index) => {
                            return (
                                <Box key={index} sx={{ display: 'flex', flexDirection: 'column', marginBottom: '0.5rem' }}>
                                    <Typography variant='body2' sx={{ fontSize: '0.9rem', fontWeight: 'bold', backgroundColor: resource.color, width: 'fit-content', padding: '0.2rem', borderRadius: '6px', color: getContrastColor(resource.color) }}>
                                        {`${resource.code}: `}
                                    </Typography>
                                    <Typography variant='body2' sx={{ fontSize: '0.9rem' }}>
                                        <Trans>Tempo de Processo:</Trans>{` ${convertFloatHoursToHoursAndMinutes(resource.processTime)}`}
                                    </Typography>
                                    <Typography variant='body2' sx={{ fontSize: '0.9rem' }}>
                                        <Trans>Tempo de Setup: </Trans>{` ${resource.setupTime}`}
                                    </Typography>
                                    <Typography variant='body2' sx={{ fontSize: '0.9rem' }}>
                                        <Trans>Plano: </Trans>{` ${resource.mpsQuantity}`}
                                    </Typography>
                                    <Typography variant='body2' sx={{ fontSize: '0.9rem' }}>
                                        <Trans>Tipo de Operação: </Trans>{` ${resource.operationType}`}
                                    </Typography>
                                    <Typography variant='body2' sx={{ fontSize: '0.9rem' }}>
                                        <Trans>Tipo de Produção: </Trans>{` ${resource.productionType?.name}`}
                                    </Typography>
                                    <Typography variant='body2' sx={{ fontSize: '0.9rem' }}>
                                        <Trans>Número da Operação: </Trans>{` ${resource.operationNo}`}
                                    </Typography>
                                    <Typography variant='body2' sx={{ fontSize: '0.9rem' }}>
                                        <Trans>Detalhes:</Trans>{` ${details ?? '-'}`}
                                    </Typography>

                                </Box>
                            );
                        })}
                    </Box>
                }
            >
                <Typography sx={{ fontSize: '0.8rem', cursor: notFound ? 'auto' : 'help' }}>
                    {notFound ? '-' : `${resourceData[0].code!.substring(0, 10)}...`}
                </Typography>
            </Tippy>
        </Box>
    );
}
