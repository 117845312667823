import styled from '@emotion/styled';
import { Theme } from '@mui/material/styles';

type RowHoverProps = {
    theme?: Theme;
};

export const RowHover = styled.div<RowHoverProps>`
    &:hover > div {
        background-color: ${props => props.theme?.tableRow.hover} !important;
    }
`;
