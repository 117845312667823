import { Box } from '@mui/material';
import React from 'react';
import { IPeriod } from 'types';
import { BarLineChart, Header } from './components';
import { useOccupationData } from './hooks/';


declare interface IOccupationProps {
    open?: boolean;
    onOccupationIconClick: () => void
    // items: IItem[]
    periods: IPeriod[]
}
const Occupation: React.FC<IOccupationProps> = ({
    open,
    periods,
    onOccupationIconClick,
}) => {
    const {
        resources,
        selectedResources,
        capacities,
        handleSelectResource,
        setupGrouped,
        handleSetupGrouped,
        handleFiniteScenarioAsync,
        allocatedResources,
        handleOnlyAllocated,
        onlyAllocated,
        groupedResources,
        handleSelectWorkCenter,
        handleTextSearch,
        items,
    } = useOccupationData(open);


    return open || open === undefined ? (
        <Box
            sx={{
                height: '100%',
                width: '100%',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    width: '100%',
                    border: 2,
                    overflowY: 'scroll'
                }}
            >
                <Header
                    handleFiniteScenario={handleFiniteScenarioAsync}
                    handleSelectResource={handleSelectResource}
                    handleSetupGrouped={handleSetupGrouped}
                    resources={onlyAllocated ? allocatedResources : resources}
                    selectedResources={selectedResources}
                    setupGrouped={setupGrouped}
                    onOccupationIconClick={onOccupationIconClick}
                    handleOnlyAllocated={handleOnlyAllocated}
                    onlyAllocated={onlyAllocated}
                    groupedResources={groupedResources}
                    handleSelectWorkCenter={handleSelectWorkCenter}
                    handleTextSearch={handleTextSearch}

                />
                {selectedResources.map((resourceId) => (
                    <BarLineChart
                        resources={resources}
                        resourceId={resourceId}
                        key={resourceId}
                        periods={periods}
                        capacities={capacities}
                        setupGrouped={setupGrouped}
                        items={items}
                    />
                ))}
            </Box>
        </Box>
    ) : null;
};


export const OccupationMemo = React.memo(Occupation);
