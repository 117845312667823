import { i18n } from '@lingui/core';
import { getAsync } from '@nplan';

interface IAccumulator {
    [key: string]: string;
}
export const activateLocaleAsync = async (locale: string): Promise<boolean> => {
    try {
        if(i18n.locales && !i18n.locales.includes(locale)) {
            return false;
        }
            
        const { messages } = await import(`./${locale}.po`);
        i18n.load(locale, messages);
        if(locale !== 'pt-BR') {
            try {
                const res = await getAsync<Translate[]>(`/translates/country/${locale}`);
                if (res.type === 'success' && res.data) {
                    const translates = res.data.reduce((acc: IAccumulator, obj) => {
                        acc[obj.key] = obj.value;
                        return acc;
                    }, {});
                    i18n.load(locale, translates);
                }
            } catch {
                return false;
            }
        }
        i18n.activate(locale);
        localStorage.setItem('locale', locale);
        return true;
    } catch {
        return false;
    }
};
