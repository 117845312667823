import { Box, Table, TableContainer } from '@mui/material';
import { Header, ManageRole, Body, ContextMenu, ManageTablePermission } from './components';
import { useRoleData } from './hooks';

import {
    useContextMenu,
    useDialog,
    usePagination,
    Pagination,
    TableBodySkeleton,
    useFilter,
} from '@nplan';
import { ManageMenuPermission } from './components/ManageMenuPermission';

export const Role: React.FC = (): JSX.Element => {
    const {
        roles,
        handleRefreshDataAsync,
        loading,
    } = useRoleData();
    const {
        id,
        open,
        handleOpenDialog,
        handleCloseDialog
    } = useDialog();

    const {
        id: idManageRole,
        open: openManageRole,
        handleOpenDialog: handleOpenManageRole,
        handleCloseDialog: handleCloseManageRole
    } = useDialog();

    const {
        id: idManageMenu,
        open: openManageMenu,
        handleOpenDialog: handleOpenManageMenu,
        handleCloseDialog: handleCloseManageMenu
    } = useDialog();
    const { 
        page, 
        rowsPerPage,
        handleChangePage, 
        handleChangeRowsPerPage 
    } = usePagination();
    const { 
        position,
        handleOpenMenu, 
        handleCloseMenu 
    } = useContextMenu();
    const { 
        filteredData, 
        filteredDataLength, 
        filterValue, 
        handleChangeFilterValue
    } = useFilter(
        roles,
        page,
        rowsPerPage
    );
    return (
        <Box padding={2}>
            <ManageRole 
                open={open} 
                onClose={handleCloseDialog} 
                roleId={id} 
            />
            <ManageTablePermission
                open={openManageRole}
                onClose={handleCloseManageRole}
                id={idManageRole}
            />
            <ManageMenuPermission
                open={openManageMenu}
                onClose={handleCloseManageMenu}
                id={idManageMenu}
            />
            <ContextMenu
                position={position}
                onClose={handleCloseMenu}
                onRefreshDataAsync={handleRefreshDataAsync}
                onAddRole={handleOpenDialog}
            />
            <TableContainer
                sx={{
                    minHeight: 130,
                    height: 'calc(100vh - 130px)',
                    display: 'flex',
                    flexDirection: 'column',
                }}
                onContextMenu={handleOpenMenu}
            >
                <Table sx={{ whiteSpace: 'nowrap' }}>
                    <Header 
                        filterValue={filterValue}
                        onChangeFilterValue={handleChangeFilterValue}
                    />
                    {loading ? (
                        <TableBodySkeleton rows={20} cols={5} />
                    ) : (
                        <Body
                            roles={filteredData}
                            onEditRole={handleOpenDialog}
                            onEditTablePermission={handleOpenManageRole}
                            onEditMenuPermission={handleOpenManageMenu}
                        />
                    )}
                </Table>
            </TableContainer>
            <Pagination
                count={filteredDataLength}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
            />
        </Box>
    );
};
