import { stockDataFilterCriteria } from "pages/inventory-policy/types/IFilters";

export enum ReduxSliceTypes {
    GlobalFilter = 'globalFilter',
    InventorySummary = 'inventorySummary',
    GlobalVariables = 'globalVariables'
}

export interface AttributeList {
    label: string;
    value: string;
    checked: boolean;
}

export interface SelectedAxisOptions {
    filter: stockDataFilterCriteria,
    xAxis: stockDataFilterCriteria,
    yAxis: stockDataFilterCriteria
}