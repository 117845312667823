import { useHubContext, getAsync } from '@nplan';
import { useCallback, useEffect, useState } from 'react';

interface IUseMenuDataReturn {
    hasMenuAccess: (menu: string) => boolean;
}

export const useMenuData = (): IUseMenuDataReturn => {
    const { hubConnection } = useHubContext();
    const [menus, setMenus] = useState<Menu[]>([]);
    const hasMenuAccess = useCallback((menuName: string): boolean => {
        if (!menus) return false;
        return menus.some((m) => m.name?.toLocaleLowerCase() === menuName?.toLocaleLowerCase() || m.code?.toLocaleLowerCase() === menuName?.toLocaleLowerCase());
    }, [menus]);
    const handleRefreshMenusAsync = useCallback(async () => {
        const response = await getAsync<Menu[]>('menus');
        if (response.type === 'success' && response.data) {
            setMenus(response.data);
        }
    }, []);
    useEffect(() => {
        handleRefreshMenusAsync();
    }, [handleRefreshMenusAsync]);

    useEffect(() => {
        if (hubConnection) {
            hubConnection.on('RefreshMenus', handleRefreshMenusAsync);
            return () => hubConnection.off('RefreshMenus', handleRefreshMenusAsync);
        }
    }, [handleRefreshMenusAsync, hubConnection]);
    return { hasMenuAccess };
};
