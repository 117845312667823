import React, { memo } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';

interface IGroupsProps {
    groups: GroupInfo[];
    maxIndex: number;
}
const Groups: React.FC<IGroupsProps> = ({
    groups,
    maxIndex
}): JSX.Element => {
    const theme = useTheme();
    return (
        <div
            style={{
                display: 'contents',
                gridRow: 1
            }}
        >
            {groups.map((group) => (
                <div
                    style={{
                        gridColumn: group.index,
                        position: 'sticky',
                        left: group.fixed ? `${group.init}px` : 'unset',
                        top: 0,
                        width: group.end - group.init,
                        backgroundColor: theme.palette.background.default,
                        zIndex: group.fixed ? 5 : 4,
                        height: '35px',
                    }}
                    key={group.init}
                >
                    { group.name !== '' && (
                        <Box>
                            <Typography textAlign={ 'center' } variant='body2' height={ 20 }>
                                <Trans>
                                    { i18n.t(group.name) ?? i18n.t('Padrão') }
                                </Trans>
                            </Typography>
                            <Box
                                sx={ {
                                    height: 13,
                                    borderTop: `2px solid ${theme.palette.primary.main}`,
                                    ml: 1,
                                    mr: 1,
                                    borderLeft: `2px solid ${theme.palette.primary.main}`,
                                    borderRight: `2px solid ${theme.palette.primary.main}`
                                } }
                            />
                        </Box>
                    
                    )}
                </div>
            ))}
            <div
                style={{
                    gridColumn: maxIndex,
                    position: 'sticky',
                    left: 'unset',
                    top: '0',
                    right: 0,
                    backgroundColor: theme.palette.background.default,
                    zIndex: 5,
                    height: '35px',
                }}
            />
        </div>
    );
};

const MemorizedGroups = memo(Groups);
export { MemorizedGroups as Groups };
