import { useState, useCallback, useEffect } from 'react';
import { getAsync, useHubContext } from '@nplan';
import { SelectChangeEvent } from '@mui/material';

export interface IUseScenarioData {
    scenarios: Scenario[];
    scenario: Scenario;
    scenarioId: number;
    loadingScenarios: boolean;
    handleRefreshScenariosAsync: () => Promise<void>;
    handleChangeScenario: (event: SelectChangeEvent<number> | number) => void;
}

export const useScenarioData = (): IUseScenarioData => {
    const [scenarios, setScenarios] = useState<Scenario[]>([]);
    const [scenarioId, setScenarioId] = useState<number>(0);
    const [scenario, setScenario] = useState<Scenario>({} as Scenario);
    const [loadingScenarios, setLoadingScenarios] = useState<boolean>(true);

    const { hubConnection } = useHubContext();

    const handleRefreshScenariosAsync = useCallback(async () => {
        const response = await getAsync<Scenario[]>('/scenarios');
        if (
            response.type === 'success' &&
            response.data
        ) {
            if(response.data.length === 0) {
                localStorage.removeItem('scenarioId');
                setScenarioId(0);
                setScenario({} as Scenario);
                setScenarios([]);
                return;
            }
            setScenarios(response.data.sort((a: Scenario, b: Scenario) => {
                const dateA = a?.createdOn ? new Date(a.createdOn) : null;
                const dateB = b?.createdOn ? new Date(b.createdOn) : null;
                if (dateA === null) return 1;
                if (dateB === null) return -1;
                return dateB.getTime() - dateA.getTime();
            }));
            const scenarioId = localStorage.getItem('scenarioId');
            let scenarioOk = false;
            if (scenarioId) {
                const defaultScenario = response.data.find((c: any) => c.id === parseInt(scenarioId));
                if(defaultScenario?.id === scenario.id) return;
                if (defaultScenario) {
                    setScenarioId(defaultScenario.id);
                    setScenario(defaultScenario);
                    scenarioOk = true;
                } else {
                    localStorage.removeItem('scenarioId');
                }
            }
            if (!scenarioOk) {
                const defaultScenario = response.data.find((c: any) => c.scenarioStatusId === 4 || c.scenarioStatusId === 5);
                if(defaultScenario?.id === scenario.id) return;
                if (defaultScenario) {
                    setScenarioId(defaultScenario.id);
                    setScenario(defaultScenario);
                    localStorage.setItem('scenarioId', defaultScenario.id.toString());
                } else {
                    setScenarioId(0);
                    setScenario({} as Scenario);
                }
            }
        }
    }, [scenario.id]);

    const handleChangeScenario = useCallback((
        event: SelectChangeEvent<number> | number
    ): void => {
        if(typeof event === 'number') {
            const scenario = scenarios.find((scenario) => scenario.id === event);
            if (scenario) {
                setScenarioId(event);
                setScenario(scenario);
                localStorage.setItem('scenarioId', event.toString());
            }
        } else {
            const scenario = scenarios.find((scenario) => scenario.id === event.target.value);
            if (scenario) {
                setScenarioId(Number(event.target.value));
                setScenario(scenario);
                localStorage.setItem('scenarioId', event.target.value.toString());
            }
        }
    }, [scenarios]);

    useEffect(() => {
        handleRefreshScenariosAsync().then(() => { setLoadingScenarios(false); });
    }, [handleRefreshScenariosAsync]);

    useEffect(() => {
        if (hubConnection) {
            hubConnection.on('RefreshScenarios', handleRefreshScenariosAsync);
            return () => hubConnection.off('RefreshScenarios', handleRefreshScenariosAsync);
        }
    }, [hubConnection, handleRefreshScenariosAsync]);

    return {
        scenarios,
        scenario,
        scenarioId,
        loadingScenarios,
        handleRefreshScenariosAsync,
        handleChangeScenario,
    };
};
