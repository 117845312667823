import { createTheme } from '@mui/material/styles';
import darkScrollbar from '@mui/material/darkScrollbar';
import { fontWeight } from '@mui/system';
export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#FFC807',
            light: '#ffb300',
            dark: '#ffb300',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#f50057',
            light: '#ff4081',
            dark: '#c51162',
            contrastText: '#ffffff',
        },
        common: {
            black: '#000',
            white: '#fff',
        },
        // Você pode adicionar outras cores conforme necessário
        background: {
            default: '#292828',
            paper: '#3d3d3d',
        },
        text: {
            primary: '#f5f5f5',
            secondary: '#f5f5f5',
        },
        action: {
            active: '#FFC807',
            selected: '#292828',
        },
        grey: {
            '100': '#fff',
            '300': '#3d3d3f',
            '200': '#d1d5db',
            '400': 'rgba(245,245,245,0.5)',
            '500': 'rgba(61,61,63,0.6)',
            'A100': 'rgba(61,61,63,0.4)',
            '50': '#000',
            '600': '#fff',
            '700': '#f5f5f5',
            'A400': '#000',

        },
    },
    tableHeader: {
        background: '#333333',
    },
    tableRow: {
        background: {
            even: '#383838',
            odd: '#3d3d3d',
        },
        hover: '#333333',
    },

    components: {
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    ...darkScrollbar(),
                    scrollbarWidth: 'thin',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '20px !important',
                },
            },
        },
        MuiSpeedDialAction: {
            styleOverrides: {
                staticTooltipLabel: {
                    color: '#FFC807'
                }
            }
        }
        
        
    },
});