import { IInventorySummaryData } from "../types/IInventorySummaryData";

export function aggregateDataByCompany(data: IInventorySummaryData[] | undefined) {
    if (!data) return
    const distinctConcatenatedIds = [
        ...new Set(
            data.map(
                (obj) =>
                    `${obj.classificationVolumeId}-${obj.classificationUncertaintyId}-${obj.classificationComplexityId}-${obj.customerGroupId}-${obj.salesChannelId}`
            )
        ),
    ];

    const result = distinctConcatenatedIds.map((concatenatedId) => {
        const filteredObjects = data
            .filter(
                (obj) =>
                    `${obj.classificationVolumeId}-${obj.classificationUncertaintyId}-${obj.classificationComplexityId}-${obj.customerGroupId}-${obj.salesChannelId}` ===
                    concatenatedId
            );

        const totalCycleStockValue = filteredObjects.reduce((sum, obj) => sum + obj.cycleStockValue, 0);
        const totalSafetyStockValue = filteredObjects.reduce((sum, obj) => sum + obj.safetyStockValue, 0);

        const totalStockValue = totalCycleStockValue + totalSafetyStockValue;  // Sum of cycleStockValue and safetyStockValue

        const weightedSumSafetyStockDays = filteredObjects.reduce((sum, obj) => sum + obj.safetyStockValue * (isNaN(obj.safetyStockDays) ? 0 : obj.safetyStockDays), 0);
        const weightedAvgSafetyStockDays = (weightedSumSafetyStockDays / totalSafetyStockValue) || 0;

        const weightedSumCycleStockDays = filteredObjects.reduce((sum, obj) => sum + obj.cycleStockValue * (isNaN(obj.cycleStockDays) ? 0 : obj.cycleStockDays), 0);
        const weightedAvgCycleStockDays = (weightedSumCycleStockDays / totalCycleStockValue) || 0;

        const weightedSumServiceLevelPerc = filteredObjects.reduce((sum, obj) => sum + (obj.cycleStockValue + obj.safetyStockValue) * obj.serviceLevelPerc, 0);
        const weightedAvgServiceLevelPerc = (weightedSumServiceLevelPerc / totalStockValue);

        const totalItensQuantity = filteredObjects.reduce((sum, obj) => sum + obj.itensQuantity, 0);


        // Extracting the first object to include all other keys from the original object
        const {
            classificationVolumeId,
            classificationUncertaintyId,
            classificationComplexityId,
            classificationComplexity,
            classificationComplexityPriority,
            classificationUncertainty,
            classificationUncertaintyPriority,
            classificationVolume,
            classificationVolumePriority,
            customerGroupPriority, customerGroup, customerGroupId, stockGroupId, stockGroupPriority, salesChannel, salesChannelId, salesChannelPriority,
            stockGroup,
            color,
            company,
            companyId,
        } = filteredObjects[0];
        return {
            classificationComplexity,
            classificationComplexityId,
            classificationComplexityPriority,
            classificationUncertainty,
            classificationUncertaintyId,
            classificationUncertaintyPriority,
            classificationVolume,
            classificationVolumeId,
            classificationVolumePriority,
            customerGroupPriority,
            customerGroup,
            customerGroupId,
            stockGroupId,
            stockGroupPriority,
            stockGroup,
            salesChannel,
            salesChannelId,
            salesChannelPriority,
            serviceLevelPerc: weightedAvgServiceLevelPerc,
            company: 'Todos',
            companyId: 0,
            cycleStockDays: weightedAvgCycleStockDays,
            cycleStockValue: totalCycleStockValue,
            itensQuantity: totalItensQuantity,
            safetyStockDays: weightedAvgSafetyStockDays,
            safetyStockValue: totalSafetyStockValue,
            color,
        };
    });

    return result;
}
