
import { useAppDispatch, useAppSelector } from "redux/hooks";
import React, { useMemo } from "react";
import DropdownTreeSelect, { TreeNode } from "react-dropdown-tree-select";
import { setSelectedAttributes, updateAttributeOptions } from "redux/Filters/reducer";
import { t } from "@lingui/macro";

export function AttributesSelectorDropDownContainer() {
    const attributeList = useAppSelector(state => state.filter.planningGrid.attributeListData)
    const attributeListMemo = useMemo(() => { return attributeList }, [attributeList])
    const dispatch = useAppDispatch()

    const onAttributeChangeSearch = (currentNode: any, selectedNodes: TreeNode[]) => {
        const newDataValue = selectedNodes.map((node) => ({
            label: node.label,
            value: node.value,
            checked: node.checked,
        }));
        newDataValue.push({ label: currentNode.label, value: currentNode.value, checked: false });

        dispatch(setSelectedAttributes({ selectedAttributes: newDataValue }));
        dispatch(updateAttributeOptions({ selectedAttribute: currentNode }));
    };

    return (
        <>
            <DropdownTreeSelect
                data={attributeListMemo}
                className="hide-selected-text dropdown-select dropdown-tree"
                inlineSearchInput={true}
                texts={{ placeholder: t`Atributos` }}
                showDropdown={'default'}
                keepTreeOnSearch={true}
                keepChildrenOnSearch={true}
                onChange={onAttributeChangeSearch}
            />
        </>
    )
}

export const AttributesSelectorDropDownContainerMemoized = React.memo(AttributesSelectorDropDownContainer)
