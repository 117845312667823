import { ReactNode, useCallback, useEffect, useState } from 'react';
import { IUserContextProps, UserContext } from './context';
import { useNavigate } from 'react-router-dom';
import { Loader, deleteAsync, getAsync } from '@nplan';

export interface IUserProviderProps {
    children: ReactNode;
}
export const UserProvider: React.FC<IUserProviderProps> = ({
    children,
}: IUserProviderProps) => {
    const [user, setUser] = useState<User | null>(null);
    const [role, setRole] = useState<Role | null>(null);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>();

    const navigate = useNavigate();

    const handleLogoutAsync = useCallback(async () => {
        await deleteAsync('auth');
        localStorage.removeItem('nplan_token');
        setUser(null);
        navigate('/login');
    }, [navigate]);

    const getToken = useCallback(() => {
        try {
            return localStorage.getItem('nplan_token');
        } catch (error) {
            return null;
        }
    }, []);

    const handleGetUserAsync = useCallback(async (): Promise<boolean> => {
        const token = getToken();
        if (token) {
            const [resUser, resRole] = await Promise.all([
                getAsync<User>('profile'),
                getAsync<Role>('profile/role'),
                
            ]);
            if (resUser.type === 'success' && resUser.data) {
                setUser(resUser.data);
                if (resRole.type === 'success' && resRole.data) {
                    setRole(resRole.data);
                }
                return true;
            }
            return false;
        }
        return false;
    }, [getToken]);

    const handleLoginAsync = useCallback(
        async (token: string) => {
            localStorage.setItem('nplan_token', token);
            if (await handleGetUserAsync()) navigate('/home');
        },
        [handleGetUserAsync, navigate],
    );
    
    
    useEffect(() => {
        if (isAuthenticated !== undefined) return;
        (async () => {
            if (await handleGetUserAsync()) {
                setIsAuthenticated(true);
            } else {
                setIsAuthenticated(false);
                handleLogoutAsync();
            }
        })();
    }, [handleGetUserAsync, handleLogoutAsync, isAuthenticated]);

    const contextValue: IUserContextProps = {
        user,
        role,
        getToken,
        onLoginAsync: handleLoginAsync,
        onLogoutAsync: handleLogoutAsync,
        onGetUserAsync: handleGetUserAsync,
    };
    return (
        <UserContext.Provider value={contextValue}>
            { isAuthenticated === undefined ? <Loader /> : children }
        </UserContext.Provider>
    );
};
