
import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { deleteAsync, useConfirmContext } from '@nplan';


interface IUserButtonActions {
    handleDeleteButtonAsync: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
}

export const useButtonActions = (): IUserButtonActions => {
    const confirmAsync = useConfirmContext();

    const handleDeleteButtonAsync = useCallback(async (
        event: React.MouseEvent<HTMLButtonElement>
    ): Promise<void> => {
        const id = parseInt(
            event.currentTarget.dataset.id as string,
        );
        const button = event.currentTarget.dataset.name as string;
        const confirmed = await confirmAsync({
            description: (
                <React.Fragment>
                    <Typography>
                        <Trans>
                            Deseja realmente deletar o botão{' '}
                            <strong>{button}</strong>?
                        </Trans>
                    </Typography>
                </React.Fragment>
            ),
        });
        if (confirmed) {
            await deleteAsync(`buttons/${id}`);
        }
    }, [confirmAsync]);

    return { handleDeleteButtonAsync };
};
