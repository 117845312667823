import { Box, IconButton, useTheme } from '@mui/material';
import { memo } from 'react';
import { Icons } from 'utils/icons';
import { useTableDataActions } from '../hooks/useTableDataActions';

interface IRowCellOptionsProps {
    maxIndex: number;
    row: any;
    deleteData?: boolean;
    onEditData: (data: any) => void;
    primaryKeyName?: string;
}
const RowCellOptions: React.FC<IRowCellOptionsProps> = ({
    maxIndex,
    row,
    deleteData,
    onEditData,
    primaryKeyName,
}) => {
    const theme = useTheme();
    const { handleDeleteRowAsync } = useTableDataActions();
    return (
        <Box
            style={{
                gridColumn: maxIndex,
                gridRow: row.index + 1,
                position: 'sticky',
                left: 'unset',
                right: 0,
                zIndex: 3,
                backgroundColor:
                    row.index % 2 === 0
                        ? theme.tableRow.background.even
                        : theme.tableRow.background.odd,
                height: 35,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 0,
            }}
        >
            <IconButton
                size="small"
                onClick={() => onEditData(row[primaryKeyName ?? 'id'])}
            >
                <Icons.Edit style={{ cursor: 'pointer' }} />
            </IconButton>
            <IconButton
                size="small"
                color="error"
                disabled={!deleteData}
                onClick={() => handleDeleteRowAsync(row)}
            >
                <Icons.Delete style={{ cursor: 'pointer' }} />
            </IconButton>
        </Box>
    );
};
const MemoRowCellOptions = memo(RowCellOptions);
export { MemoRowCellOptions as RowCellOptions };
