import { SelectChangeEvent } from '@mui/material';
import { useDataGridContext } from '../../../providers/hooks/';
import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAsync, postAsync, putAsync } from '@nplan';

interface IUseManageData {
    data: any;
    loading: boolean;
    editing: boolean;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSwitchChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    handleSelectChange: (event: SelectChangeEvent<number>) => void;
    handleSaveAsync: () => Promise<void>;
}

interface IRowUpdate {
    value: any;
    columnId: number;
    rowId: string;
}

export const useManageData = (
    dataToEdit: any,
    onClose: () => void,
    open: boolean
): IUseManageData => {

    const { id } = useParams();
    const [data, setData] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const { allColumns } = useDataGridContext();
    const [editing, setEditing] = useState<boolean>(false);
    const [originalData, setOriginalData] = useState<any>({});
    
    const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value, type } = event.target;
        if (type === 'date' || type === 'datetime-local') {
            if(!value)  {
                setData((prev: any) => ({ ...prev, [name]: null }));
                return;
            }
            if(type === 'datetime-local'){
                if(value.length === 16) {
                    setData((prev: any) => ({ ...prev, [name]: `${value}:00` }));
                    return;
                }
                setData((prev: any) => ({ ...prev, [name]: value }));
                return;
            }
            setData((prev: any) => ({
                ...prev,
                [name]: value,
            }));
            return;
        }
        setData((prev: any) => ({
            ...prev,
            [name]: value,
        }));
    }, []);

    const handleSwitchChange = useCallback((
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean,
    ): void => {
        const { name } = event.target;
        setData((prev: any) => ({
            ...prev,
            [name]: checked,
        }));
    }, []);

    const handleSelectChange = useCallback((event: SelectChangeEvent<number>): void => {
        const { name, value } = event.target;
        setData((prev: any) => ({
            ...prev,
            [name]: value,
        }));
    }, []);

    const handleSaveAsync = useCallback(async (): Promise<void> => {
        setLoading(true);
        if (!editing) {
            const dataToPost = data;
            Object.keys(dataToPost).forEach((key) => {
                if (dataToPost[key] === 'null') {
                    dataToPost[key] = null;
                }
            });
            let isValid = true;

            for (const col of allColumns) {
                const value = dataToPost[col.field];
                if (
                    (
                        value === null ||
                        value === undefined ||
                        (col.foreignKey && value === '')) && 
                        !col.enableNull
                ) {
                    toast.error(`Campo ${col.header} não pode ser nulo`);
                    setLoading(false);
                    isValid = false;
                    break; 
                }
                if (col.foreignKey && value === '') {
                    dataToPost[col.field] = null;
                }
            }

            if (!isValid) {
                return;
            }
            const response = await postAsync<any[], string>(`rows/table/${id}`, dataToPost);
            if (response.type === 'success') {
                onClose();
            }
        } else {
            const dataToPut: IRowUpdate[] = [];
            const colPK = allColumns.find((col) => col.primaryKey);
            if (!colPK) return;
            for (const column of allColumns.filter((col) => !col.primaryKey && col.editable)) {
                if (data[column.field] !== originalData[column.field]){
                    dataToPut.push({
                        value: data[column.field],
                        columnId: column.id,
                        rowId: dataToEdit
                    });
                }
                    
            }
            if (dataToPut.length === 0) {
                setLoading(false);
                return;
            }
            await Promise.all(dataToPut.map(async (row) => {
                await putAsync(`/rows/`, row);
            }));
            onClose();
        }
        setLoading(false);
    }, [allColumns, data, dataToEdit, editing, id, onClose, originalData]);

    useEffect(() => {
        (async () => {
            if (!open) return;
            if (dataToEdit === null || dataToEdit === undefined) return;
            if (Number(dataToEdit) === 0) {
                const object: any = {};
                allColumns.forEach((col) => {
                    if (col.foreignKey) {
                        object[col.field] = '';
                        return;
                    }
                    switch (col.viewTypeId) {
                        case 1:
                            object[col.field] = false;
                            break;
                        case 2:
                            object[col.field] = '#E7C904';
                            break;
                        case 3:
                            object[col.field] = new Date();
                            break;
                        case 4:
                            object[col.field] = new Date();
                            break;
                        case 5:
                            object[col.field] = 0.0;
                            break;
                        case 6:
                            object[col.field] = '00:00:00';
                            break;
                        case 7:
                            object[col.field] = 0;
                            break;
                        case 8:
                            object[col.field] = '';
                            break;
                        default:
                            object[col.field] = '';
                    }
                });
                setData(object);
                setEditing(false);
            } else {
                const response = await getAsync<any>(`rows/${dataToEdit}/table/${id}`);
                if (response.type === 'success' && response.data) {
                    setData(response.data);
                    setOriginalData(response.data);
                } else {
                    onClose();
                    return;
                }
                setEditing(true);
            }
            
        })();
        
    }, [allColumns, dataToEdit, id, onClose, open]);

    return {
        data,
        loading,
        editing,
        handleInputChange,
        handleSwitchChange,
        handleSelectChange,
        handleSaveAsync,
        
    };
};
