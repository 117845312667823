import { Box, Table, TableContainer } from '@mui/material';
import { Header,ManageTable, Body, ContextMenu } from './components';
import {
    useDataContext,
    useContextMenu,
    usePagination,
    useDialog,
    useFilter,
    TableBodySkeleton,
    Pagination,
} from '@nplan';

export const TableEdition: React.FC = (): JSX.Element => {
    const { 
        tables, 
        loadingTables 
    } = useDataContext();

    const { 
        page, 
        rowsPerPage, 
        handleChangePage, 
        handleChangeRowsPerPage
    } = usePagination();

    const { 
        position, 
        handleOpenMenu, 
        handleCloseMenu 
    } = useContextMenu();

    const { 
        open, 
        id, 
        handleCloseDialog, 
        handleOpenDialog 
    } = useDialog();

    const {
        filteredData,
        filteredDataLength,
        filterValue,
        handleChangeFilterValue
    } = useFilter(
        tables, 
        page,
        rowsPerPage
    );
    
    return (
        <Box padding={2}>
            <ManageTable
                open={open}
                onClose={handleCloseDialog}
                tableId={id}
            />
            <ContextMenu
                position={position}
                onClose={handleCloseMenu}
                onAddTable={handleOpenDialog}
            />
            <TableContainer
                sx={{
                    minHeight: 130,
                    height: 'calc(100vh - 125px)',
                    display: 'flex',
                    flexDirection: 'column',
                }}
                onContextMenu={handleOpenMenu}
            >
                <Table
                    sx={{
                        whiteSpace: 'nowrap',
                    }}
                >
                    <Header
                        onFilterChange={handleChangeFilterValue}
                        filter={filterValue}
                    />
                    {loadingTables ? (
                        <TableBodySkeleton rows={20} cols={10} />
                    ) : (
                        <Body
                            tables={filteredData}
                            onEditTable={handleOpenDialog}
                        />
                    )}
                </Table>
            </TableContainer>
            <Pagination
                count={filteredDataLength}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
            />
        </Box>
    );
};
