import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import { TableContainer, Table, TableHead, TableRow, TableCell, Typography, TableBody, Box, useTheme } from '@mui/material';
import { replaceDotWithComma } from 'pages/inventory-policy/utils/replaceDotWithComma';
import { TooltipComponentInfo } from '../../Tooltips/TooltipComponentInfo';
import { IInventoryHealth } from 'pages/inventory-policy/types/IInventoryHealth';
import { v4 as uuidv4 } from 'uuid';
import { useCallback } from 'react';

interface TableProps {
  inventoryHealth: IInventoryHealth

}
export const TableComponent = ({ inventoryHealth }: TableProps) => {
    const theme = useTheme();

    const formatGraphMoneyValue = useCallback((number: number) => {
        const numeroFormatado = number.toLocaleString('pt-BR', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });
        const numeroFormatadoComSimbolo = numeroFormatado.padStart(10);
        return numeroFormatadoComSimbolo;
    }, []);
    const tableCellStyle = {
        textAlign: 'center',
        color: theme.palette.primary.contrastText,
    };
    const fontSize = '0.8rem';
    const typographyStyles = {
        fontWeight: 'bold',
        fontSize: fontSize,
    };



    return (
        <TableContainer
            sx={{
                border: '1px solid',
                borderColor: theme.palette.text.primary,
                borderRadius: '16px',
            }}
        >
            <Table size="small" padding="normal">
                <TableHead>
                    <TableRow>
                        <TableCell
                            sx={{ width: '40%', ...tableCellStyle }}
                        >
                            <Typography
                                variant="body2"
                                sx={{ ...typographyStyles }}
                            >
                                <Trans>Situação</Trans>
                            </Typography>
                        </TableCell>
                        <TableCell
                            sx={{ width: '40%', ...tableCellStyle }}
                        >
                            <Typography
                                variant="body2"
                                sx={{ ...typographyStyles }}
                            >
                                <Trans>Valor</Trans>
                            </Typography>
                        </TableCell>
                        <TableCell
                            sx={{ width: '30%', ...tableCellStyle }}
                        >
                            <Typography
                                variant="body2"
                                sx={{ ...typographyStyles }}
                            >
                                <Trans>Valor</Trans> %
                            </Typography>
                        </TableCell>
                        <TableCell
                            sx={{ width: '20%', ...tableCellStyle }}
                        >
                            <Typography
                                variant="body2"
                                sx={{ ...typographyStyles }}
                            >
                                <Trans>Itens</Trans>
                            </Typography>
                        </TableCell>
                        <TableCell
                            sx={{ width: '20%', ...tableCellStyle }}
                        >
                            <Typography
                                variant="body2"
                                sx={{ ...typographyStyles }}
                            >
                                <Trans>Itens</Trans> %
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {inventoryHealth.inventoryHealthFilteredByCompany
                        .filter((c) => c.stockStatusName === 'Ruptura')
                        .map((statusData) => {
                            const valuePositive = Math.abs(
                                statusData.value,
                            );
                            return (
                                <TableRow
                                    key={uuidv4()}
                                    sx={{
                                        backgroundColor:
                      'rgba(245, 59, 90,0.3)',
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            color: 'rgba(245, 59, 90,1)',
                                            fontWeight: 'bold',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent:
                          'center',
                                                alignItems: 'center',
                                                gap: '1em',
                                            }}
                                        >
                                            <TooltipComponentInfo
                                                tooltipText={
                                                    statusData.stockStatusDescription
                                                }
                                            />
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent:
                            'start',
                                                    alignItems:
                            'center',
                                                    gap: '0.5rem',
                                                    width: '100%',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        borderRadius:
                              '50%',
                                                        background: `${statusData.stockStatusColor}`,
                                                        width: '0.875rem',
                                                        height: '0.875rem',
                                                    }}
                                                ></Box>
                                                <Typography
                                                    variant="body2"
                                                    fontSize={fontSize}
                                                    sx={{
                                                        fontWeight:
                              'bold',
                                                    }}
                                                >{i18n.t(statusData.stockStatusName)}</Typography>
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            textAlign: 'right',
                                            width: 'fit-content',
                                            color: 'rgba(245, 59, 90,1)',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent:
                          'space-between',
                                            }}
                                        >
                                            <Typography
                                                variant="body2"
                                                fontSize={fontSize}
                                                sx={{
                                                    fontWeight: 'bold',
                                                    display: 'flex',
                                                }}
                                            >
                                                <Trans>R$</Trans>
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                fontSize={fontSize}
                                                sx={{
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {formatGraphMoneyValue(
                                                    valuePositive,
                                                )}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            textAlign: 'right',
                                            color: 'rgba(245, 59, 90,1)',
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            fontSize={fontSize}
                                            sx={{
                                                fontWeight: 'bold',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            {`${replaceDotWithComma(
                                                (
                                                    (valuePositive /
                            inventoryHealth.totalValue) *
                          100
                                                ).toFixed(1),
                                            )  } %`}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            textAlign: 'right',
                                            color: 'rgba(245, 59, 90,1)',
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            fontSize={fontSize}
                                            sx={{
                                                fontWeight: 'bold',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            {statusData.total}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            textAlign: 'right',
                                            color: 'rgba(245, 59, 90,1)',
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            fontSize={fontSize}
                                            sx={{
                                                fontWeight: 'bold',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            {`${replaceDotWithComma(
                                                (
                                                    (statusData.total /
                            inventoryHealth.totalItemsNumber) *
                          100
                                                ).toFixed(1),
                                            )  } %`}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    {inventoryHealth.inventoryHealthFilteredByCompany
                        .filter((c) => c.stockStatusName !== 'Ruptura')
                        .map((statusData) => {
                            const valuePositive = Math.abs(
                                statusData.value,
                            );
                            return (
                                <TableRow key={uuidv4()}>
                                    <TableCell>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent:
                          'space-between',
                                                alignItems: 'center',
                                                gap: '1em',
                                            }}
                                        >
                                            <TooltipComponentInfo
                                                tooltipText={
                                                    statusData.stockStatusDescription
                                                }
                                            />
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent:
                            'start',
                                                    alignItems:
                            'center',
                                                    gap: '0.5rem',
                                                    width: '100%',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        borderRadius:
                              '50%',
                                                        background: `${statusData.stockStatusColor}`,
                                                        width: '0.875rem',
                                                        height: '0.875rem',
                                                    }}
                                                ></Box>
                                                <Typography
                                                    variant="body2"
                                                    fontSize={fontSize}
                                                    sx={{
                                                        fontWeight:
                              'bold',
                                                    }}
                                                >{i18n.t(statusData.stockStatusName)}</Typography>
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            textAlign: 'right',
                                            width: 'fit-content',
                                            fontWeight: 'bold',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent:
                          'space-between',
                                                gap: '0.5rem',
                                            }}
                                        >
                                            <Typography
                                                variant="body2"
                                                fontSize={fontSize}
                                                sx={{
                                                    fontWeight: 'bold',
                                                    display: 'flex',
                                                }}
                                            >
                                                <Trans>R$</Trans>
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                fontSize={fontSize}
                                                sx={{
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {formatGraphMoneyValue(
                                                    valuePositive,
                                                )}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell
                                        sx={{ textAlign: 'right' }}
                                    >
                                        <Typography
                                            variant="body2"
                                            fontSize={fontSize}
                                            sx={{
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            {`${replaceDotWithComma(
                                                (
                                                    (valuePositive /
                            inventoryHealth.totalValue) *
                          100
                                                ).toFixed(1),
                                            )  } %`}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        sx={{ textAlign: 'right' }}
                                    >
                                        <Typography
                                            variant="body2"
                                            fontSize={fontSize}
                                            sx={{
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            {statusData.total}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        sx={{ textAlign: 'right' }}
                                    >
                                        <Typography
                                            variant="body2"
                                            fontSize={fontSize}
                                            sx={{
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            {`${replaceDotWithComma(
                                                (
                                                    (statusData.total /
                            inventoryHealth.totalItemsNumber) * 100
                                                ).toFixed(1),
                                            )  } %`}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    <TableRow
                        key={uuidv4()}
                        sx={{ background: theme.palette.grey[300] }}
                    >
                        <TableCell>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    fontSize={fontSize}
                                    sx={{ fontWeight: 'bold' }}
                                >
                  Total
                                </Typography>
                            </Box>
                        </TableCell>
                        <TableCell>
                            <Box
                                data-testid="healthTotalValue"
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    fontSize={fontSize}
                                    sx={{ fontWeight: 'bold' }}
                                >
                                    <Trans>R$</Trans>
                                </Typography>
                                <Typography
                                    variant="body2"
                                    fontSize={fontSize}
                                    sx={{ fontWeight: 'bold' }}
                                >
                                    {formatGraphMoneyValue(
                                        inventoryHealth.totalValue,
                                    )}
                                </Typography>
                            </Box>
                        </TableCell>
                        <TableCell sx={{ textAlign: 'right' }}>
                            <Typography
                                variant="body2"
                                fontSize={fontSize}
                                sx={{
                                    fontWeight: 'bold',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {' '}
                                100,0 %
                            </Typography>
                        </TableCell>
                        <TableCell sx={{ textAlign: 'right' }}>
                            <Typography
                                variant="body2"
                                fontSize={fontSize}
                                sx={{
                                    fontWeight: 'bold',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {' '}
                                {inventoryHealth.totalItemsNumber}
                            </Typography>
                        </TableCell>
                        <TableCell sx={{ textAlign: 'right' }}>
                            <Typography
                                variant="body2"
                                fontSize={fontSize}
                                sx={{
                                    fontWeight: 'bold',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                100,0 %
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};