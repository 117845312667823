import { ClickAwayListener, TextField } from '@mui/material';
import { isValidDate } from 'pages/scenario/utils/isValidDate';
import { useCallback, useEffect, useState } from 'react';
import tryParseDateTimeToISO from 'utils/tryParseDateTimeToISO';

interface IDateTimeEditorProps {
    defaultValue: string | Date;
    onClose: () => void;
    onSave: (value: any) => void;
}
export const DateTimeEditor: React.FC<IDateTimeEditorProps> = ({ 
    defaultValue, 
    onClose, 
    onSave 
}): JSX.Element => {

    const [value, setValue] = useState<string | null>(null);

    const handleClickAway = useCallback(() => {
        if(value !== null && !isValidDate(value)) {
            return;
        }
        if (defaultValue !== value) {
            onSave(value);
        }
        onClose();
    }, [defaultValue, onClose, onSave, value]);

    const handleKeyDown = useCallback((
        event: React.KeyboardEvent<HTMLDivElement>
    ): void => {
        if (event.key === 'Escape') {
            onClose();
            event.stopPropagation();
            event.preventDefault();
        } else if (event.key === 'Enter') {
            if (defaultValue !== value) {
                onSave(value);
            }
            onClose();
            event.stopPropagation();
            event.preventDefault();
        }
    }, [defaultValue, onClose, onSave, value],);

    const handleValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.value === '') {
            setValue(null);
            return;
        }
        if(event.target.value.length === 16) {
            setValue(`${event.target.value}:00`);
            return;
        }
        setValue(event.target.value);
    };

    useEffect(() => {
        if (defaultValue instanceof Date) {
            setValue(defaultValue.toISOString());
        } else {
            setValue(defaultValue);
        }
    }, [defaultValue]);

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <TextField
                value={tryParseDateTimeToISO(value) || ''}
                onChange={handleValue}
                fullWidth
                autoFocus
                sx={{
                    height: 32,
                    padding: 0,
                }}
                inputProps={{
                    style: {
                        height: 32,
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingLeft: 5
                    },
                    step: 1,
                }}
                onKeyDown={handleKeyDown}
                type='datetime-local'
            />
        </ClickAwayListener>
    );
};
