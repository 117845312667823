import { Box, useTheme } from '@mui/material';
import { LoginForm, ActiveDirectory, Footer, Google, Microsoft } from './components';
import { useState } from 'react';
export const Login: React.FC = (): JSX.Element => {
    const theme = useTheme();
    const [loading, setLoading] = useState<boolean>(false);
    const handleLoading = (value: boolean) => {
        setLoading(value);
    };
    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                '& > :not(style)': {
                    m: 1,
                    width: 420,
                    height: 520,
                },
                height: '100%',
                width: '100%',
                background: '#121212',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    borderRadius: '25px',
                    paddingLeft: 6,
                    paddingRight: 6,
                    bgcolor: theme.palette.background.default,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                }}
            >
                <LoginForm
                    loading={loading}
                    onChangeLoading={handleLoading}
                />
                <Box
                    display={'flex'}
                    justifyContent={'center'}
                    gap={3}
                >
                    <Google onChangeLoading={handleLoading} />
                    <Microsoft onChangeLoading={handleLoading} />
                    <ActiveDirectory onChangeLoading={handleLoading} />
                </Box>
                <Footer />
            </Box>
        </Box>
    );
};
