import { Dictionary } from "lodash";
import { PeriodData } from "../types/PeriodData";
import { PlanningGridData } from "../types/PlanningGrid";
interface PeriodSlicer {
    fromIndex: number
    toIndex: number
}

export const calculateWeeklyToMontlyPlanningGrids = (planningGrids: Dictionary<PlanningGridData[]>, complexPeriods: PeriodData[]) => {
    const monthlyPlanningGridDataByProductId: Dictionary<PlanningGridData[]> = {}
    let periodIndex = 0;
    const periodSlicers: PeriodSlicer[] = []
    for (let i = 0; i < complexPeriods.length; i++) {
        const period = complexPeriods[i];
        periodSlicers.push({
            fromIndex: periodIndex,
            toIndex: periodIndex + period.periods.length
        })
        periodIndex += period.periods.length;
    }

    for (const key in planningGrids) {
        const planningGridsByProductData = planningGrids[key];
        const monthlyPlanningGridsByProductId: PlanningGridData[] = []
        for (let a = 0; a < periodSlicers.length; a++) {
            const periodSlicer = periodSlicers[a];
            const slicedPlanningGrids = planningGridsByProductData.slice(periodSlicer.fromIndex, periodSlicer.toIndex);
            if (slicedPlanningGrids.length < 1) continue;
            const lastPlanningGrid = slicedPlanningGrids[slicedPlanningGrids.length - 1];

            //TODO great-fix
            const planningGrid: PlanningGridData = { ...lastPlanningGrid };
            planningGrid.id = 0
            planningGrid.demand = slicedPlanningGrids.map(x => x.demand ?? 0).reduce((sum, a) => sum + a, 0)
            planningGrid.locked = true
            planningGrid.mps = slicedPlanningGrids.map(x => x.mps ?? 0).reduce((sum, a) => sum + a, 0)
            planningGrid.openingStock = slicedPlanningGrids[0].openingStock
            planningGrid.orders = slicedPlanningGrids.map(x => x.orders ?? 0).reduce((sum, a) => sum + a, 0)

            monthlyPlanningGridsByProductId.push(planningGrid)
        }
        monthlyPlanningGridDataByProductId[key] = monthlyPlanningGridsByProductId
    }
    return monthlyPlanningGridDataByProductId;
}