import { SelectChangeEvent } from "@mui/material";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { ITableEditionLog } from "types/log/ITableEditionLog";

export interface IFilter {
    columns: string[];
    users: string[];
    date: Date | null;
}

interface IUseFilter {
    filter: IFilter;
    handleFilter: (event: SelectChangeEvent<string[]> | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    filtered: ITableEditionLog[];
}

export const useFilter = (logs: ITableEditionLog[]): IUseFilter => {
    const [filter, setFilter ] = useState<IFilter>({ columns: [], users: [], date: null });
    const handleFilter = useCallback((event: SelectChangeEvent<string[]> | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if(event.target instanceof HTMLInputElement) {
            setFilter({ ...filter, date: new Date(event.target.value + 'T00:00') });
            return;
        }
        const { name, value } = event.target;
        setFilter({ ...filter, [name]: value });
    },[filter]);
    const filtered = useMemo(() => {
        return logs.filter(log => {
            const column = !filter.columns.length || filter.columns.includes(log.column);
            const user = !filter.users.length || filter.users.includes(log.user);
            const date =
                !filter.date ||
                filter.date.toLocaleDateString() ===
                    new Date(log.date).toLocaleDateString();
            return column && user && date;
        })
    },[filter, logs])
    return { filter, handleFilter, filtered }
}