import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Box, Typography, useTheme } from '@mui/material';
import { Months, translateMonth } from 'pages/planning-grid/utils/PeriodUtils';
import { memo } from 'react';
import { ListChildComponentProps, areEqual } from 'react-window';
import { useAppSelector } from 'redux/hooks';

const GridExpandedHeaderCol = memo((props: ListChildComponentProps) => {
    const isWeekly = props.data.isWeekly;
    const complexPeriods = useAppSelector(state => state.filter.planningGrid.complexPeriods)
    const theme = useTheme()
    useLingui();
    const periodData = complexPeriods[props.index];
    return (
        <Box key={props.index} style={props.style} sx={{ display: 'flex', flexDirection: 'column', height: '100%', textAlign: !isWeekly ? 'text-center' : '' }}>
            <Box sx={{ display: 'flex', marginTop: '0.2rem', justifyContent: 'center', alignItems: 'center', borderBottom: '2px solid', borderRight: '3px solid', borderColor: theme.palette.divider }}>
                <Typography fontWeight="bold">
                    {translateMonth(Months[periodData.monthNumber])} - {periodData.yearNumber}
                </Typography>
            </Box>

            <Box sx={{ display: 'flex', marginTop: '0.3rem', flexDirection: 'row', alignItems: 'center', textAlign: 'center', justifyContent: 'space-around', borderRight: '3px solid', borderColor: theme.palette.divider }}>
                {periodData.periods.map((period, index) => (
                    <Typography key={`${props.index}-${index}`} >
                        <Trans>de {period.startDay} a {(period.endDay)}</Trans>
                    </Typography>
                ))}
            </Box>
        </Box>
    );
}, areEqual);

export default GridExpandedHeaderCol;
