import React, { useState, useMemo, useCallback } from 'react';
import { darkTheme, lightTheme } from 'themes';
import { ThemeProvider, useMediaQuery } from '@mui/material';
import { ColorModeContext } from './context';




export interface IColorModeProviderProps {
    children: React.ReactNode;
}
export const ColorModeProvider: React.FC<IColorModeProviderProps> = ({ children }): JSX.Element => {
    const storedMode = localStorage.getItem('mode');
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const defaultMode = storedMode ? storedMode === 'dark' ? 'dark' : 'light' : prefersDarkMode ? 'dark' : 'light';
    const [mode, setMode] = useState<'light' | 'dark'>(defaultMode);
    const handleToggleColorMode = useCallback(() => {
        setMode((prevMode) => {
            const newMode = prevMode === 'light' ? 'dark' : 'light';
            localStorage.setItem('mode', newMode);
            document.documentElement.style.setProperty(
                '--text-color', newMode === 'light' 
                    ? lightTheme.palette.text.primary
                    : darkTheme.palette.text.primary
            );
            return newMode;
        });
    }, []);
    const theme = useMemo(() => (mode === 'light' ? lightTheme : darkTheme), [mode]);
    const buttonVariant = mode === 'light' ? 'contained' : 'outlined';
    return (
        <ColorModeContext.Provider value={{
            toggleColorMode: handleToggleColorMode,
            mode,
            buttonVariant,
        }}>
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
};

