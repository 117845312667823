import { ColorModeContext, IColorModeContext } from '../context';
import { useContext } from 'react';

export const useColorModeContext = (): IColorModeContext => {
    const context = useContext<IColorModeContext | null>(ColorModeContext);
    if (context === null) {
        throw new Error('Component must be wrapped with <ColorModeProvider>');
    }
    return context;
};

