
import { IFilter, stockDataFilterCriteria } from "../types/IFilters";
import { IInventorySummaryData } from "../types/IInventorySummaryData";


export function aggregateDataWithAxisFilter(data: IInventorySummaryData[] | undefined, filter: IFilter | undefined, selectedCompanyFilterId: number) {
  if (!data) return
  if (!filter) return
  const distinctConcatenatedIds = [...new Set(data.map((obj) => `${(obj as any)[filter.axisData?.yAxis.idKey as string]}-${(obj as any)[filter.axisData?.xAxis.idKey as string]}`))]

  const result = distinctConcatenatedIds.map((concatenatedId) => {
    const filteredObjects = data.filter((obj) => `${(obj as any)[filter.axisData?.yAxis.idKey as string]}-${(obj as any)[filter.axisData?.xAxis.idKey as string]}` === concatenatedId);

    const totalCycleStockValue = filteredObjects.reduce((sum, obj) => sum + obj.cycleStockValue, 0);
    const totalCycleStockDays = filteredObjects.reduce((sum, obj) => sum + obj.cycleStockDays, 0);
    const totalSafetyStockValue = filteredObjects.reduce((sum, obj) => sum + obj.safetyStockValue, 0);
    const totalSafetyStockDays = filteredObjects.reduce((sum, obj) => sum + obj.safetyStockDays, 0);

    const totalStockValue = totalCycleStockValue + totalSafetyStockValue;  // Sum of cycleStockValue and safetyStockValue

    const weightedSumSafetyStockDays = filteredObjects.reduce((sum, obj) => sum + obj.safetyStockValue * (isNaN(obj.safetyStockDays) ? 0 : obj.safetyStockDays), 0);
    const weightedAvgSafetyStockDays = (weightedSumSafetyStockDays / totalSafetyStockValue) || 0;

    const weightedSumCycleStockDays = filteredObjects.reduce((sum, obj) => sum + obj.cycleStockValue * (isNaN(obj.cycleStockDays) ? 0 : obj.cycleStockDays), 0);
    const weightedAvgCycleStockDays = (weightedSumCycleStockDays / totalCycleStockValue) || 0;

    const weightedSumServiceLevelPerc = filteredObjects.reduce((sum, obj) => sum + (obj.cycleStockValue + obj.safetyStockValue) * obj.serviceLevelPerc, 0);
    const weightedAvgServiceLevelPerc = (weightedSumServiceLevelPerc / totalStockValue);

    const totalItensQuantity = filteredObjects.reduce((sum, obj) => sum + obj.itensQuantity, 0);

    const {
      classificationVolumeId,
      classificationComplexityId,
      classificationComplexity,
      classificationComplexityPriority,
      classificationVolume,
      classificationVolumePriority,
      classificationUncertainty,
      classificationUncertaintyId,
      classificationUncertaintyPriority,
      customerGroup,
      customerGroupId,
      customerGroupPriority,
      stockGroup,
      stockGroupId,
      stockGroupPriority,
      salesChannel,
      salesChannelId,
      salesChannelPriority,
      color,
      company,
      companyId,
    } = filteredObjects[0];
    return {
      classificationComplexity: filter.stockDataSearchCriteriasData.filter === stockDataFilterCriteria.complexity ? 'Todos' : classificationComplexity,
      classificationComplexityId: filter.stockDataSearchCriteriasData.filter === stockDataFilterCriteria.complexity ? 0 : classificationComplexityId,
      classificationComplexityPriority: filter.stockDataSearchCriteriasData.filter === stockDataFilterCriteria.complexity ? 0 : classificationComplexityPriority,
      classificationVolume: filter.stockDataSearchCriteriasData.filter === stockDataFilterCriteria.volume ? 'Todos' : classificationVolume,
      classificationVolumeId: filter.stockDataSearchCriteriasData.filter === stockDataFilterCriteria.volume ? 0 : classificationVolumeId,
      classificationVolumePriority: filter.stockDataSearchCriteriasData.filter === stockDataFilterCriteria.volume ? 0 : classificationVolumePriority,
      classificationUncertainty: filter.stockDataSearchCriteriasData.filter === stockDataFilterCriteria.uncertainty ? 'Todos' : classificationUncertainty,
      classificationUncertaintyId: filter.stockDataSearchCriteriasData.filter === stockDataFilterCriteria.uncertainty ? 0 : classificationUncertaintyId,
      classificationUncertaintyPriority: filter.stockDataSearchCriteriasData.filter === stockDataFilterCriteria.uncertainty ? 0 : classificationUncertaintyPriority,
      customerGroup: filter.stockDataSearchCriteriasData.filter === stockDataFilterCriteria.customer ? 'Todos' : customerGroup,
      customerGroupId: filter.stockDataSearchCriteriasData.filter === stockDataFilterCriteria.customer ? 0 : customerGroupId,
      customerGroupPriority: filter.stockDataSearchCriteriasData.filter === stockDataFilterCriteria.customer ? 0 : customerGroupPriority,
      stockGroup,
      stockGroupId,
      stockGroupPriority,
      salesChannel,
      salesChannelId,
      salesChannelPriority,
      serviceLevelPerc: weightedAvgServiceLevelPerc,
      company: selectedCompanyFilterId === 0 ? 'Todas' : company,
      companyId: selectedCompanyFilterId === 0 ? 0 : companyId,
      cycleStockDays: weightedAvgCycleStockDays,
      cycleStockValue: totalCycleStockValue,
      itensQuantity: totalItensQuantity,
      safetyStockDays: weightedAvgSafetyStockDays,
      safetyStockValue: totalSafetyStockValue,
      color,
    };
  });
  return result;
}
