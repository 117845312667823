import {
    Box,
    MenuItem,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import React, { memo } from 'react';

interface ISelectFilterProps {
    column: Column;
    onFilterColumnValueChange: (column: Column, value: any) => void;
    getDistinctValuesForColumn: (field: string) => any[];
}

const SelectFilter: React.FC<ISelectFilterProps> = ({
    column,
    onFilterColumnValueChange,
    getDistinctValuesForColumn,
}) => {
    const handleFilterValueChange = (
        event: SelectChangeEvent<HTMLInputElement>
    ) => {
        const target = event.target as HTMLInputElement;
        onFilterColumnValueChange(column, target.value);
    };
    return (
        <Box display={'flex'} flexDirection={'row'} minWidth={90} gap={1} paddingRight={1}>
            <Select
                sx={{
                    height: 32,
                    paddingRight: 0,
                    paddingLeft: 0
                }}
                name={`${column.field}-value`}
                fullWidth
                variant="standard"
                onChange={handleFilterValueChange}
                multiple
                value={column.filterValue || []}
            >
                {
                    column.enableNull && (
                        <MenuItem value={'null'}>
                            {column.nullText ?? 'NULL'}
                        </MenuItem>
                    )
                }
                {
                    getDistinctValuesForColumn(column.field)?.map((value, index) => (
                        <MenuItem key={index} value={value}>
                            {value}
                        </MenuItem>
                    ))
                }
            </Select>

        </Box>
    );
};

const MemoizedSelectFilter = memo(SelectFilter);
export default MemoizedSelectFilter;
