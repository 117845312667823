import { useCallback, useEffect, useMemo, useState } from 'react';
import { getAsync, useHubContext } from '@nplan';
import { applyFilter } from '../utils/applyFilters';
import { useParams } from 'react-router-dom';

const BUFFER = 5;
const ROW_HEIGHT = 35;

interface IRowProps {
    index: number;
    getter: number;
}

interface IUseRowsReturn {
    getVisibleRows: IRowProps[];
    getTemplateRows: string;
    allRows: any[];
    loading: boolean;
    currentIdsFiltered: any[];
    getDistinctValuesForColumn: (field: string) => any[];
    handleRefreshDataAsync: () => Promise<void>;
}

export const useRows = (
    scrollTop: number,
    containerHeight: number,
    columns: Column[],
    foreignFields: ForeignFields | null,
    requiredFilterValue: string | null,
    table: Table | undefined,
): IUseRowsReturn => {
    const [loading, setLoading] = useState<boolean>(false);
    const [rows, setRows] = useState<any[]>([]);
    const { hubConnection } = useHubContext();
    const { id } = useParams();
    
    const rowsWithFks = useMemo(() => {
        if(!columns) return [];
        return rows.map((oldOrw) => {
            const row = { ...oldOrw };
            columns.forEach((col) => {
                if(col.primaryKey) return;
                const value = row[col.field];
                if (value == null) {
                    row[col.field] = col.nullText ?? null;
                    return;
                }
                if (col.foreignKey && foreignFields && foreignFields[col.id]) {
                    row[col.field] = foreignFields[col.id][value];
                }
                if(col.viewTypeId === 5) row[col.field] = parseFloat(value).toFixed(col.numberFloats ?? 2);
                if(col.viewTypeId === 6) row[col.field] = new Date(value).toLocaleTimeString();
                if(col.viewTypeId === 7) row[col.field] = parseInt(value);
            });
            return row;
        });
    }, [rows, columns, foreignFields]);

    const filteredRows = useMemo(() => {
        const filtered = columns.reduce((acc: any, column) => {
            if (
                column.filterValue === '' ||
                column.filterValue === undefined ||
                column.filterValue === null
            ){ 
                return acc;
            }
            return acc.filter((row: any) => applyFilter(row, column));
        }, rowsWithFks);
        let sortColumn = columns.find((col) => col.sortDirection);
        if (!sortColumn) {
            sortColumn = columns.find((col) => col.sortDefault);
        }
        if (!sortColumn){
            sortColumn = columns.find((col) => col.primaryKey);
        }
        if (!sortColumn) sortColumn = columns[0];
        if (!sortColumn) return filtered;
        const { field, id, foreignKey } = sortColumn;
        if (!field || !id) return filtered;
        const isAsc = sortColumn.sortDirection === 'asc';
        return  filtered.sort((a: any, b: any) => {
            if (foreignKey && foreignFields && foreignFields[id]) {
                const valueA = foreignFields[id][a[field]];
                const valueB = foreignFields[id][b[field]];
                if (valueA && valueB) {
                    if (valueA > valueB) return isAsc ? -1 : 1;
                    if (valueA < valueB) return isAsc ? 1 : -1;
                    return 0;
                }
                if (valueA && !valueB) return isAsc ? -1 : 1;
                if (!valueA && valueB) return isAsc ? 1 : -1;
            }
            if (a[field] > b[field]) return isAsc ? -1 : 1;
            if (a[field] < b[field]) return isAsc ? 1 : -1;
            return 0;
        });
    }, [columns, rowsWithFks, foreignFields]);

    const getRows = useMemo(() => {
        let index = 2;
        return filteredRows.map((el: any) => {
            const newEl: IRowProps = { ...el, index, getter: index };
            ++index;
            return newEl;
        });
    }, [filteredRows]);

    const currentIdsFiltered = useMemo(() => {
        const colPK = columns.find((col) => col.primaryKey);
        if (!colPK) return [];
        return filteredRows.map((row: any) => row[colPK.field]);
    }, [filteredRows, columns]);

    const getVisibleRows = useMemo(() => {
        const init = Math.max(
            0,
            Math.floor(scrollTop / ROW_HEIGHT) - BUFFER,
        );
        const end = Math.min(
            getRows.length,
            init +
                BUFFER * 2 +
                Math.ceil(containerHeight / ROW_HEIGHT),
        );
        return getRows.slice(init, end);
    }, [scrollTop, containerHeight, getRows]);

    const getTemplateRows = useMemo(() => {
        return `repeat(1, 35px) repeat(1, 70px) repeat(${getRows.length === 0 ? 30 : getRows.length}, ${ROW_HEIGHT}px)`;
    }, [getRows]);

    const getDistinctValuesForColumn = useCallback((field: string) => {
        return [...new Set(rowsWithFks.map((row) => row[field]))];
    }, [rowsWithFks]);

    const handleRefreshDataAsync = useCallback(async () => {
        if(!table) return;
        if(table.requiredFilter && requiredFilterValue === null) setRows([]);
        const response = await getAsync<any[]>(`rows/table/${table.id}?filter=${requiredFilterValue}`);
        if (response.data) setRows(response.data);
        else setRows([]);
        setLoading(false);
    }, [table, requiredFilterValue]);


    const handleCreateRow = useCallback((
        row: any
    ) => {
        setRows((prev) => [...prev, row]);
    }, []);
    
    const handleUpdateRow = useCallback((
        row: any
    ) => {
        setRows((prev) => {
            const colPK = columns.find((col) => col.primaryKey);
            if (!colPK) return prev;
            const index = prev.findIndex((el) => el[colPK.field] === row[colPK.field]);
            if (index !== -1) {
                const newRows = [...prev];
                newRows[index] = row;
                return newRows;
            }
            return [...prev, row];
        });
    }, [columns]);

    const handleDeleteRow = useCallback((
        rowId: any
    ) => {
        const colPK = columns.find((col) => col.primaryKey);
        if (!colPK) return;
        setRows((prev) =>
            prev.filter((el) => String(el[colPK.field]) !== String(rowId)),
        );
    }, [columns]);
    
    useEffect(() => {
        setLoading(true);
        handleRefreshDataAsync();
    }, [handleRefreshDataAsync]);

    useEffect(() => {
        if (!hubConnection) return;
        hubConnection.on(`UpdateRow${id}`, handleUpdateRow);
        hubConnection.on(`CreateRow${id}`, handleCreateRow);
        hubConnection.on(`DeleteRow${id}`, handleDeleteRow);
        return () => {
            hubConnection.off(`UpdateRow${id}`, handleUpdateRow);
            hubConnection.off(`CreateRow${id}`, handleCreateRow);
            hubConnection.off(`DeleteRow${id}`, handleDeleteRow);
        };
    }, [hubConnection, id, handleUpdateRow, handleCreateRow, handleDeleteRow]);


    return {
        getVisibleRows,
        getTemplateRows,
        currentIdsFiltered,
        allRows: rows,
        loading,
        getDistinctValuesForColumn,
        handleRefreshDataAsync,
    };
};
