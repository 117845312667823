import { Trans, t } from '@lingui/macro';
import { Button, TableCell } from '@mui/material';
import { Icons, useColorModeContext } from '@nplan';
import React from 'react';
interface IActionButtonProps {
    user: User;
    onResetPasswordAsync: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onEditUserClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onDeactivateUserAsync: (event: React.MouseEvent<HTMLButtonElement>) => void;
}
export const ActionButtons: React.FC<IActionButtonProps> = ({
    user,
    onResetPasswordAsync,
    onEditUserClick,
    onDeactivateUserAsync,
}) => {
    const { buttonVariant } = useColorModeContext();
    return (
        <React.Fragment>
            <TableCell>
                <Button
                    variant={buttonVariant}
                    startIcon={<Icons.Key />}
                    data-id={user.id}
                    data-name={user.userName}
                    onClick={onResetPasswordAsync}
                    color="info"
                    title={t`Ao clicar uma nova senha será gerada e enviada para o email do usuário`}
                >
                    <Trans>Redefinir Senha</Trans>
                </Button>
            </TableCell>
            <TableCell>
                <Button
                    variant={buttonVariant}
                    startIcon={<Icons.Edit />}
                    data-id={user.id}
                    onClick={onEditUserClick}
                >
                    <Trans>Editar</Trans>
                </Button>
            </TableCell>
            <TableCell>
                <Button
                    variant={buttonVariant}
                    startIcon={<Icons.Delete />}
                    data-id={user.id}
                    data-name={user.userName}
                    onClick={onDeactivateUserAsync}
                    color="error"
                >
                    <Trans>Desativar</Trans>
                </Button>
            </TableCell>
        </React.Fragment>
    );
    
};