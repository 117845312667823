import { Trans, t } from '@lingui/macro';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { TooltipSimplesComponent } from 'components/TippyComponent';
import { optionsToLanguage } from 'locales/helper';
import { IFilter, IInventorySummaryInfo, ISegmentationSummaryInfo } from 'pages/inventory-policy/types/IFilters';
import { IInventorySummaryData } from 'pages/inventory-policy/types/IInventorySummaryData';
import { useCallback, useEffect, useRef, useState } from 'react';
import { MdSettings } from 'react-icons/md';
import { setIsEditableInventoryCards } from 'redux/Filters/reducer';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { putAsync } from 'services';
import { ISupplyStrategy } from 'types/system/ISupplyStrategy';
import { Icons } from 'utils';
import InventoryButtonGroup from '../../Selectors/FilterButtonGroupSelector';
import AxisSelector from '../../Selectors/AxisSelector';
import InventorySummaryHeaderStatistics from '../Header';
import { CardContainer } from './CardsContainer/CardsContainer';

interface InventoryCardsProps {
  filter: IFilter
  inventorySummaryInfo: IInventorySummaryInfo
  segmentationSummaryInfo: ISegmentationSummaryInfo
  stockStrategyOptions: ISupplyStrategy[]
  summaryHeaderData: IInventorySummaryData[] | undefined
  isProcedureRunning: boolean
  isProcedureCompleted: boolean
  segmentationUpdate: () => Promise<void>
  handleInventoryRecalculate: (name: string) => Promise<void>
  isLeftSideFullScreen: boolean
  isRightSideFullScreen: boolean
  handleFullScreen: (name: string) => void
  handleExitFullScreen: () => void
}
export default function InventoryCardsContainer({ isProcedureCompleted, handleExitFullScreen, handleFullScreen, isLeftSideFullScreen, isRightSideFullScreen, isProcedureRunning, handleInventoryRecalculate, summaryHeaderData, filter, inventorySummaryInfo, segmentationSummaryInfo, stockStrategyOptions }: InventoryCardsProps) {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const isEditable = useAppSelector(state => state.filter.inventory.isEditable);
    const stockEditableTableValidation = { isEditable };
    const editedCards = useAppSelector(state => state.filter.inventory.inventoryPolicyEditedObjects);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    useEffect(() => {
        if (isProcedureCompleted === true) {
            setShowSuccessMessage(true);
            const timer = setTimeout(() => {
                setShowSuccessMessage(false);
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [isProcedureCompleted]);


    const handleClickOnEdit = () => {
        dispatch(setIsEditableInventoryCards());
    };
    const handleSaveEditedObjects = useCallback(async () => {
        await putAsync('/segmentation', editedCards);
        handleInventoryRecalculate('recalculateInventory');
    }, [editedCards, handleInventoryRecalculate]);
    return (
        <Box
            sx={{
                flexDirection: 'column',
                width: isRightSideFullScreen ? '0%' : '50%',
                minWidth: isLeftSideFullScreen ? '100%' : '50%',
                display: isRightSideFullScreen ? 'none' : 'flex',
                borderRadius: '4px',
                p: '0.1rem 0.5rem',
                height: '100%',
                overflow: 'hidden',
                paddingBottom: '5rem'
            }}
        >
            <Box data-testid="inventory-cards-header" sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', height: 'fit-content', marginBottom: '0.1rem' }}>
                <Typography variant="body1" fontWeight="bold" textAlign="left"><Trans>Política de Estoque</Trans></Typography>

                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                    <Box sx={{ display: 'flex ', alignItems: 'start' }}>
                        {showSuccessMessage && (
                            <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center', height: '100%', justifyContent: 'center', marginRight: '0.5rem', padding: '0.1rem' }}>
                                <Typography variant='body2' fontSize={'0.75rem'} color={theme.palette.text.primary} >
                                    <Trans>Atualizado com sucesso!</Trans>
                                </Typography>
                            </Box>
                        )}
                        {isProcedureRunning ? (
                            <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'flex-start', height: '100%' }}>

                                <Typography variant='body2' fontSize={'0.75rem'}><Trans>Recalculando Política</Trans></Typography>
                                <CircularProgress size={18} />
                            </Box>
                        ) : !stockEditableTableValidation.isEditable ? (
                            <TooltipSimplesComponent onClickFunction={() => handleClickOnEdit()} tooltipText={t`Editar Segmentação`}>
                                <MdSettings size={20} />
                            </TooltipSimplesComponent>
                        ) : null}
                    </Box>
                    {stockEditableTableValidation.isEditable ? (
                        <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                            <TooltipSimplesComponent onClickFunction={() => handleSaveEditedObjects()} tooltipText={t`Salvar Alterações`}>
                                <Icons.Save size={20} />
                            </TooltipSimplesComponent>
                            <TooltipSimplesComponent onClickFunction={() => { handleClickOnEdit(); }} tooltipText={t`Cancelar Alterações`}>
                                <Icons.Cancel size={20} />
                            </TooltipSimplesComponent>
                        </Box>
                    ) : null}

                    <AxisSelector
                        filter={filter} />

                    {!isLeftSideFullScreen ? (
                        <TooltipSimplesComponent onClickFunction={() => handleFullScreen('left')} tooltipText={t`Tela Cheia`}>
                            <Icons.FullScreen size={20} />
                        </TooltipSimplesComponent>
                    ) : (
                        <TooltipSimplesComponent onClickFunction={() => handleExitFullScreen()} tooltipText={t`Tela Dividida`}>
                            <Icons.FullScreenExit size={20} />
                        </TooltipSimplesComponent>
                    )}
                </Box>
            </Box>
            <InventorySummaryHeaderStatistics summaryHeaderData={summaryHeaderData} />
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    p: '0.2rem',
                    borderRadius: '1px',
                    borderBottom: '1px solid',
                    borderColor: theme.palette.grey[400],
                }}
            >
                <Box data-testid="filter-selected" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', }}>
                        <Typography variant="body2"><Trans>Filtro selecionado:</Trans></Typography>
                        <Typography
                            variant="body2"
                            fontWeight="bold"
                            p={2}
                            color={theme.palette.text.primary}
                            sx={{ textDecoration: 'underline' }}
                        >
                            {optionsToLanguage(filter.axisData?.filter.name as string)}
                        </Typography>
                    </Box>
                    <InventoryButtonGroup filter={filter} />
                </Box>
            </Box>
            {/* ----- GRID CARDS ----- */}
            <CardContainer
                stockEditableTableValidation={stockEditableTableValidation}
                filter={filter}
                inventorySummaryInfo={inventorySummaryInfo}
                segmentationSummaryInfo={segmentationSummaryInfo}
                stockStrategyOptions={stockStrategyOptions}
            />
        </Box>

    );
}