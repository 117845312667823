import { SelectChangeEvent } from '@mui/material';
import { getAsync, postAsync, putAsync } from '@nplan';
import { useState, useEffect, useCallback } from 'react';


const defaultTable: Table = {
    id: 0,
    columnRequiredFilter: null,
    customSql: false,
    deleteData: false,
    groupId: 1,
    insertData: false,
    name: '',
    parameterTable: false,
    requiredFilter: false,
    schema: '',
    title: '',
    database: '',
    limitedPlanner: false,
};

type SchemaTables = {
    schemaName: string;
    tables: string[];
}
type DatabaseTables = {
    databaseName: string;
    schemas: SchemaTables[];
    defaultDatabase: boolean;
}

interface IUseManageTableReturn {
    table: Table;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSwitchChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    handleSelectChange: (event: SelectChangeEvent<number | string>) => void;
    handleSaveAsync: () => Promise<void>;
    loading: boolean;
    databaseTables: DatabaseTables[];
}

export const useManageTable = (
    tableId: number,
    open: boolean,
    onClose: (refresh: boolean) => void,
): IUseManageTableReturn => {
    const [databaseTables, setDatabaseTables] = useState<DatabaseTables[]>([]);
    const [table, setTable] = useState<Table>(defaultTable);
    const [loading, setLoading] = useState(false);

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ): void => {
        const { name, value } = event.target;
        setTable((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSwitchChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean,
    ): void => {
        const { name } = event.target;
        setTable((prev) => ({
            ...prev,
            [name]: checked,
        }));
    };
    
    const handleSelectChange = (
        event: SelectChangeEvent<number | string>,
    ): void => {
        const { name, value } = event.target;
        if (name === 'databaseName') {
            setTable((prev) => ({
                ...prev,
                database: value as string,
                schema: '',
                name: '',
            }));
            return;
        }
        if (name === 'schema') {
            setTable((prev) => ({
                ...prev,
                schema: value as string,
                name: '',
            }));
            return;
        }
        setTable((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSaveAsync = useCallback(async (): Promise<void> => {
        setLoading(true);
        const res =
            table.id === 0
                ? await postAsync('tables', table)
                : await putAsync('tables', table);
        if (res.type === 'success') {
            onClose(true);
        }
        setLoading(false);
    },[table, onClose]);

    useEffect(() => {
        (async () => {
            const res = await getAsync<ValidTable[]>('database-tables');
            if (res.type === 'success' && res.data) {
                const validTables = res.data;
                const databaseTables: DatabaseTables[] = [];

                validTables.forEach((row: any) => {
                    const { 
                        databaseName, 
                        schemaName, 
                        tableName 
                    } = row;

                    if (
                        !databaseTables.find(
                            (db) => db.databaseName === databaseName,
                        )
                    ) {
                        databaseTables.push({
                            databaseName,
                            schemas: [],
                            defaultDatabase: row.defaultDatabase,
                        });
                    }

                    if (
                        !databaseTables
                            .find(
                                (db) =>
                                    db.databaseName === databaseName,
                            )
                            ?.schemas.find(
                                (s) => s.schemaName === schemaName,
                            )
                    ) {
                        databaseTables
                            .find(
                                (db) =>
                                    db.databaseName === databaseName,
                            )
                            ?.schemas.push({
                                schemaName,
                                tables: [],
                            });
                    }

                    databaseTables
                        .find(
                            (db) => db.databaseName === databaseName,
                        )
                        ?.schemas.find(
                            (s) => s.schemaName === schemaName,
                        )
                        ?.tables.push(tableName);
                });
                databaseTables.sort((a, b) =>
                    a.databaseName.localeCompare(b.databaseName),
                );
                databaseTables.forEach((db) => {
                    db.schemas.sort((a, b) =>
                        a.schemaName.localeCompare(b.schemaName),
                    );
                    db.schemas.forEach((s) => {
                        s.tables.sort((a, b) => a.localeCompare(b));
                    });
                });
                setDatabaseTables(databaseTables);
            }
        })();
    }, []);

    useEffect(() => {
        if(!open) return;
        setTable({
            ...defaultTable,
            database: databaseTables.find(f => f.defaultDatabase === true)?.databaseName ?? '',
            schema: '',
        });
        (async () => {
            if (tableId > 0) {
                const res = await getAsync<Table>(`tables/${tableId}`);
                if (res.type === 'success' && res.data) {
                    setTable(res.data);
                }
            }
        })();
    }, [tableId, databaseTables, open]);

    return {
        table,
        loading,
        databaseTables,
        handleInputChange,
        handleSwitchChange,
        handleSaveAsync,
        handleSelectChange,
    };
};
