import { getAsync, putAsync } from '@nplan';
import { useDataContext, useHubContext } from 'providers';
import { useCallback, useMemo, useState } from 'react';
import { setInventoryHealthLoadingState, setIsEditableInventoryCards, setReduxInventoryHealth } from 'redux/Filters/reducer';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { ISupplyStrategy } from 'types/system/ISupplyStrategy';
import { InventoryHealthData } from '../types/IInventoryHealth';
import { IInventorySegmentationData } from '../types/IInventorySegmentationData';
import { IInventorySummaryData } from '../types/IInventorySummaryData';


const useInventoryPolicy = () => {
    const dispatch = useAppDispatch();
    const { scenario } = useDataContext();
    const { hubConnection } = useHubContext();
    const stockTypeId = useAppSelector(state => state.filter.inventory.stockTypeId);
    const [loading, setLoading] = useState(true);
    const [loadingHealth, setLoadingHealth] = useState(false);
    const [segmentationData, setSegmentationData] = useState<IInventorySegmentationData[]>([]);
    const [inventoryHealth, setInventoryHealth] = useState<InventoryHealthData[]>([]);
    const [inventorySummary, setInventorySummary] = useState<IInventorySummaryData[]>([]);
    const [stockStrategy, setSupplyStrategy] = useState<ISupplyStrategy[]>([]);
    const [isProcedureRunning, setIsProcedureRunning] = useState(false);
    const [isProcedureCompleted, setIsProcedureCompleted] = useState(false);
    const selectedAxis = useAppSelector(state => state.filter.inventory.selectedAxis);

    const filter = useMemo(() => {
        const localStorageAxis = localStorage.getItem('axisObject');
        if (localStorageAxis) return JSON.parse(localStorageAxis).filter;
        return selectedAxis.filter;
    }, [selectedAxis]);

    const fetchInitialData = useCallback(async () => {
        setLoading(true);
        const [segData, supplyStrategy] = await Promise.all([
            getAsync<IInventorySegmentationData[]>('/segmentation'),
            getAsync<ISupplyStrategy[]>('/supplyStrategy'),
        ]);
        if (segData.type === 'success' && segData.data !== undefined) {
            setSegmentationData(segData.data);
        }
        if (supplyStrategy.type === 'success' && supplyStrategy.data !== undefined) {
            setSupplyStrategy(supplyStrategy.data);
        }
        setLoading(false);
    }, []);



    const handleInventoryHealthManualUpdate = useCallback(async (selectedFilterId: number | null | undefined) => {
        dispatch(setInventoryHealthLoadingState({ loadingHealth: true }));
        if (selectedFilterId === undefined || selectedFilterId === null) return;
        const inventoryHealthData = await getAsync<InventoryHealthData[]>(`/inventoryHealth/${scenario.id}/${stockTypeId}/${filter}/${selectedFilterId}`);
        if (inventoryHealthData.type === 'success' && inventoryHealthData.data !== undefined) {
            dispatch(setReduxInventoryHealth({ inventoryHealth: inventoryHealthData.data }));
        }
        dispatch(setInventoryHealthLoadingState({ loadingHealth: false }));
    }, [dispatch, scenario.id, stockTypeId, filter]);

    const fetchData = useCallback(async () => {
        setLoadingHealth(true);
        const [inventoryHealthData, inventorySummaryData] = await Promise.all([
            getAsync<InventoryHealthData[]>(`/inventoryHealth/${scenario.id}/${stockTypeId}`),
            getAsync<IInventorySummaryData[]>(`/inventorySummary/${scenario.id}`),
        ]);
        if (inventoryHealthData.type === 'success' && inventoryHealthData.data !== undefined) {
            // setInventoryHealth(inventoryHealthData.data);
            dispatch(setReduxInventoryHealth({ inventoryHealth: inventoryHealthData.data }));
        }
        if (inventorySummaryData.type === 'success' && inventorySummaryData.data !== undefined) {
            setInventorySummary(inventorySummaryData.data);
        }

        setLoading(false);
        setLoadingHealth(false);
    }, [dispatch, scenario.id, stockTypeId]);


    const handleSegmentationUpdate = useCallback(async () => {
        const [segData, supplyStrategy, inventoryHealthData, inventorySummaryData] = await Promise.all([
            getAsync<IInventorySegmentationData[]>('/segmentation'),
            getAsync<ISupplyStrategy[]>('/supplyStrategy'),
            getAsync<InventoryHealthData[]>(`/inventoryHealth/${scenario.id}/${stockTypeId}`),
            getAsync<IInventorySummaryData[]>(`/inventorySummary/${scenario.id}`),
        ]);
        if (segData.type === 'success' && segData.data !== undefined) {
            setSegmentationData(segData.data);
        }
        if (supplyStrategy.type === 'success' && supplyStrategy.data !== undefined) {
            setSupplyStrategy(supplyStrategy.data);
        }
        if (inventoryHealthData.type === 'success' && inventoryHealthData.data !== undefined) {
            setInventoryHealth(inventoryHealthData.data);
        }
        if (inventorySummaryData.type === 'success' && inventorySummaryData.data !== undefined) {
            setInventorySummary(inventorySummaryData.data);
        }
    }, [scenario.id, stockTypeId]);


    const handleInventoryRecalculate = useCallback(async (name: string) => {
        if (!hubConnection || !scenario?.id) return;
        const params: ExecutionParameter[] = [
            {
                id: 0,
                name: 'scenarioid',
                value: scenario.id.toString(),
            },
        ];
        const response = await putAsync(`ButtonProcedure/${name}`, params);
        setIsProcedureRunning(true);
        setIsProcedureCompleted(false);
        dispatch(setIsEditableInventoryCards());
        if (response.type === 'success') {
            hubConnection.on('RefreshPolicy', async () => {
                await handleSegmentationUpdate();
                setIsProcedureRunning(false);
                setIsProcedureCompleted(true);
                hubConnection?.off('RefreshPolicy');
            });
        }
    }, [hubConnection, scenario.id, dispatch, handleSegmentationUpdate]);

    return {
        loading,
        loadingHealth,
        segmentationData,
        inventoryHealth,
        inventorySummary,
        stockStrategy,
        isProcedureRunning,
        handleInventoryRecalculate,
        segmentationUpdate: fetchInitialData,
        handleInventoryHealthManualUpdate,
        isProcedureCompleted,
        fetchInitialData,
        fetchData
    };
};

export default useInventoryPolicy;
