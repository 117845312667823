import { t } from '@lingui/macro';
import { Box, Typography, useTheme } from '@mui/material';
import * as echarts from 'echarts';
import {
    FC,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useRef
} from 'react';
import { toast } from 'react-toastify';
import { applyManualItemFilter } from 'redux/Filters/reducer';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { IItem } from 'types/data/IItem';
import {
    ICapacity
} from 'types/scenario';
import { useChartOptions } from './hooks';

interface IBarLineChartProps {
    resources: Resource[];
    periods: Period[];
    capacities: ICapacity[];
    setupGrouped: boolean;
    resourceId: number;
    items: IItem[]
}
const BarLineChart: FC<IBarLineChartProps> = ({
    periods,
    capacities,
    resources,
    setupGrouped,
    resourceId,
    items
}) => {

    const chartRef = useRef<HTMLDivElement | null>(null);
    const planningGridOperationResourceByPeriodId = useAppSelector(state => state.filter.planningGrid.planningGridOperationResourceByPeriodId);
    const planningGridOperationResources = useAppSelector(state => state.filter.planningGrid.planningGridOperationResourceData);
    const selectedItems = useAppSelector(state => state.filter.global.selectedItemIds);
    const chartInstanceRef = useRef<echarts.ECharts | null>(null);
    const dispatch = useAppDispatch();
    const options = useChartOptions(
        periods,
        capacities,
        planningGridOperationResources,
        setupGrouped,
        resourceId,
        items
    );
    const resource = useMemo(() => {
        return resources.find((f) => f.id === resourceId);
    }, [resourceId, resources]);

    useEffect(()=>{ 
        if(selectedItems.length === 0){
            toast.warn(t`Nenhum item pôde ser selecionado!`,{ autoClose: 1000 });
        }
    },[selectedItems]);
    

    const onChartClick = useCallback((params: any) => {
        if (params.seriesName === 'Capacidade') {
            const itemIds = planningGridOperationResourceByPeriodId[Number(params.data.periodId)].filter(c => c.resourceId === Number(params.data.resourceId)).map(c => c.itemId);
            dispatch(applyManualItemFilter({ itemIds: itemIds }));
            return;
        }
        const itemId = params.data.id;
        dispatch(applyManualItemFilter({ itemIds: [itemId] }));

    }, [dispatch, planningGridOperationResourceByPeriodId]);


    useEffect(() => {
        const onResize = () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.resize();
            }
        };
        if (chartRef.current && (chartInstanceRef.current?.isDisposed || !chartInstanceRef.current)) {
            const instance = echarts.init(chartRef.current, undefined, { renderer: 'canvas' });
            instance.on('click', onChartClick);
            chartInstanceRef.current = instance;
            window.addEventListener('resize', onResize);
        }
        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.off('click', onChartClick);
                chartInstanceRef.current.dispose();
                window.removeEventListener('resize', onResize);
            }
        };
    }, [onChartClick]);

    const handleResourceFilterItemsClick = () => {
        const resourceId = resource?.id;
        const items = [...new Set(planningGridOperationResources.filter(c => c.resourceId === resourceId).map(c => c.itemId))];
        dispatch(applyManualItemFilter({ itemIds: items }));
    };
    useEffect(() => {
        if (chartInstanceRef.current && !chartInstanceRef.current.isDisposed() && options) {
            chartInstanceRef.current.setOption(
                { ...options, animation: true } as any,
                true,
            );
        }
    }, [options, chartInstanceRef]);
    const theme = useTheme();
    return (
        <Box padding={2} sx={{
            paddingBottom: '9rem'
        }}>
            <Box
                sx={{
                    border: 1,
                    borderColor: theme.palette.grey[300],
                    backgroundColor: 'background.default',
                    height: '100%',
                }}
            >
                <Typography
                    textAlign={'center'}

                    sx={{
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        fontSize: '1.2rem',
                        marginTop: '1rem',
                        marginBottom: '1.5rem'

                    }}
                    onClick={handleResourceFilterItemsClick}
                >
                    {resource?.name} - {resource?.description}
                </Typography>
                <div
                    ref={chartRef}
                    style={{
                        height: 350,
                        width: '100%',
                    }}
                ></div>
            </Box>
        </Box>
    );
};
const MemoizedBarLineChart = memo(BarLineChart);
export { MemoizedBarLineChart as BarLineChart };

