import {
    Dialog,
    DialogContent,
    DialogTitle,
    Box,
    TextField,
    FormControlLabel,
    Switch,
    Select,
    MenuItem,
    InputLabel,
    Button,
} from '@mui/material';
import { Trans, t } from '@lingui/macro';
import { useManageUser } from './hooks/useManageUser';
import { LoadingButton } from '@mui/lab';
import { i18n } from '@lingui/core';
export interface IManageUserProps {
    open: boolean;
    onClose: () => void;
    userId: number;
    roles: Role[];
}
export const ManageUser: React.FC<IManageUserProps> = ({
    open,
    onClose,
    userId,
    roles,
}): JSX.Element => {
    const {
        user,
        loading,
        planners,
        userPlannerPermissions,
        handleInputChange,
        handleSelectChange,
        handleSwitchChange,
        handleSaveAsync,
        handleChangeUserPlannerPermission
    } = useManageUser(userId, onClose, open);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
            <DialogTitle textAlign={'center'}>
                {user?.id === 0 
                    ? <Trans>Adicionar Usuário</Trans>
                    : <Trans>Editar Usuário</Trans> }
            </DialogTitle>
            <DialogContent>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={2}
                >
                    <TextField
                        value={user.name}
                        variant="standard"
                        label={i18n.t(`Nome`)}
                        name="name"
                        onChange={handleInputChange}
                        disabled={loading}
                    />
                    <TextField
                        value={user.lastName}
                        variant="standard"
                        label={i18n.t(`Sobrenome`)}
                        name="lastName"
                        onChange={handleInputChange}
                        disabled={loading}
                    />
                    <TextField
                        value={user.userName}
                        variant="standard"
                        label={i18n.t(`Usuário`)}
                        name="userName"
                        onChange={handleInputChange}
                        disabled={loading}
                    />
                    <Box sx={{ width: '100%' }}>
                        <InputLabel
                            id="role-label"
                            sx={{ fontSize: 12, userSelect: 'none' }}
                        >
                            <Trans>Grupo</Trans>
                        </InputLabel>
                        <Select
                            value={user.roleId}
                            labelId="role-label"
                            variant="standard"
                            name="roleId"
                            fullWidth
                            onChange={handleSelectChange}
                            disabled={loading}
                        >
                            {roles.map((role) => (
                                <MenuItem
                                    key={role.id}
                                    value={role.id}
                                >
                                    {role.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        <InputLabel
                            id="licence-label"
                            sx={{ fontSize: 12, userSelect: 'none' }}
                        >
                            <Trans>Licença</Trans>
                        </InputLabel>
                        <Select
                            value={user.userType}
                            labelId="licence-label"
                            variant="standard"
                            name="userType"
                            fullWidth
                            onChange={handleSelectChange}
                            disabled={loading}
                        >
                            <MenuItem value={-1}>
                                Sys Admin
                            </MenuItem>
                            <MenuItem value={0}>
                                Sem Licença
                            </MenuItem>
                            <MenuItem value={1}>
                                Full
                            </MenuItem>
                            <MenuItem value={2}>
                                Viewer
                            </MenuItem>
                        </Select>
                    </Box>

                    <TextField
                        value={user.email}
                        variant="standard"
                        label={i18n.t(`Email`)}
                        name="email"
                        onChange={handleInputChange}
                        disabled={loading}
                    />
                    <FormControlLabel
                        sx={{ userSelect: 'none' }}
                        control={
                            <Switch
                                checked={user.active}
                                name="active"
                                onChange={handleSwitchChange}
                                disabled={loading}
                            />
                        }
                        label={i18n.t(`Ativo`)}
                    />
                    <FormControlLabel
                        sx={{ userSelect: 'none' }}
                        control={
                            <Switch
                                checked={user.blocked}
                                name="blocked"
                                onChange={handleSwitchChange}
                                disabled={loading}
                            />
                        }
                        label={i18n.t(`Bloqueado`)}
                    />
                    <FormControlLabel
                        sx={{ userSelect: 'none' }}
                        control={
                            <Switch
                                checked={user.authWithPassword}
                                name="authWithPassword"
                                onChange={handleSwitchChange}
                                disabled={loading}
                            />
                        }
                        label={i18n.t(`Autenticar com senha`)}
                    />
                    <Box sx={{ width: '100%' }}>
                        <InputLabel
                            id="user-planner-access-label"
                            sx={{ fontSize: 12, userSelect: 'none' }}
                        >
                            <Trans>Planejadores</Trans>
                        </InputLabel>
                        <Select
                            multiple
                            labelId="user-planner-access-label"
                            variant="standard"
                            name="userType"
                            fullWidth
                            disabled={loading}
                            value={userPlannerPermissions.map((permission) => permission.plannerId)}
                            onChange={handleChangeUserPlannerPermission}
                        >
                            {planners.map((planner) => (
                                <MenuItem key={planner.id} value={planner.id}>
                                    {planner.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <LoadingButton
                        variant="outlined"
                        onClick={handleSaveAsync}
                        loading={loading}
                    >
                        <Trans>Salvar</Trans>
                    </LoadingButton>
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={onClose}
                    >
                        <Trans>Cancelar</Trans>
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
