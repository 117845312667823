import { AttributeListData } from 'pages/planning-grid/types/PlanningGrid';
import { useEffect, useState } from 'react';
import { setCompanyById, setItemProperties, setPlannerById, setStockGroupById } from 'redux/Filters/reducer';
import { useAppDispatch, } from 'redux/hooks';
import { getAsync, createDictionary } from '@nplan';
interface IUsePlanningGridData {
    attributeList: AttributeListData[];
}

export const usePlanningGridData = (): IUsePlanningGridData => {
    const [attributeList] = useState<AttributeListData[]>([]);
    const dispatch = useAppDispatch();

    useEffect(() => {
        (async () => {
            const [planner, stockGroup, company] = await Promise.all([
                getAsync<Planner[]>('planners'),
                getAsync<StockGroup[]>('stock-groups'),
                getAsync<Company[]>('companies'),
            ]);

            if (planner.type === 'success' && planner.data) {
                const plannerDictionary = createDictionary(planner.data, c => c.id);
                dispatch(setPlannerById({ plannerById: plannerDictionary }));
                dispatch(setItemProperties({ key: 'plannerId', property: plannerDictionary }));

            }
            if (stockGroup.type === 'success' && stockGroup.data) {
                const stockGroupDictionary = createDictionary(stockGroup.data, c => c.id);
                dispatch(setStockGroupById({ stockGroupById: stockGroupDictionary }));
                dispatch(setItemProperties({ key: 'stockGroupId', property: stockGroupDictionary }));
            }
            if (company.type === 'success' && company.data) {
                const companyDicionary = createDictionary(company.data, c => c.id);
                dispatch(setCompanyById({ companyById: companyDicionary }));
                dispatch(setItemProperties({ key: 'companyId', property: companyDicionary }));
            }
        })();
    }, [dispatch]);
    return { attributeList };
};

