import { Trans, t } from '@lingui/macro';
import {
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';

interface IHeaderProps {
    filterValue: string;
    onChangeFilterValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export const Header: React.FC<IHeaderProps> = ({
    filterValue,
    onChangeFilterValue,
}: IHeaderProps): JSX.Element => {
    return (
        <TableHead>
            <TableRow
                sx={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 10,
                    bgcolor: 'background.paper',
                }}
            >
                <TableCell
                    sx={{
                        width: '25%',
                    }}
                >
                    <Trans>Nome</Trans>
                </TableCell>
                <TableCell
                    sx={{
                        width: '25%',
                    }}
                >
                    <Trans>Admin</Trans>
                </TableCell>
                <TableCell 
                    colSpan={5}
                    sx={{
                        width: '50%',
                    }}
                >
                    <TextField
                        value={filterValue}
                        onChange={onChangeFilterValue}
                        placeholder={t`Filtrar...`}
                        fullWidth
                    />
                </TableCell>
            </TableRow>
        </TableHead>
    );
};
