import { Avatar, Badge } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    },
    '& .MuiBadge-badge-online': {
        backgroundColor: '#44b700',
        color: '#44b700',
    },
    '& .MuiBadge-badge-offline': {
        backgroundColor: '#B0B0B0',
        color: '#B0B0B0',
    },
}));

interface IUserBadgeProps {
    user: string;
    online: boolean;
    avatar?: string;
}

export const UserBadge: React.FC<IUserBadgeProps> = ({ user, online, avatar }) => {
    return (
        <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="dot"
            classes={{
                badge: online
                    ? 'MuiBadge-badge-online' 
                    : 'MuiBadge-badge-offline',
            }}
            title={user}

        >
            <Avatar
                sx={{
                    width: 25,
                    height: 25,
                    fontSize: '14px',
                }}
                src={avatar}
            >
                {user.charAt(0).toUpperCase()}
            </Avatar>
        </StyledBadge>
    );
};

