import { useCallback, useState } from 'react';
import { ConfirmContext } from './context';
import ConfirmDialog from './components/ConfirmDialog';

export interface IConfirmOptions {
    title?: string;
    description: React.ReactNode;
}
interface ConfirmProviderProps {
    children: React.ReactNode;
}
export const ConfirmProvider: React.FC<ConfirmProviderProps> = ({
    children,
}) => {
    const [options, setOptions] = useState<IConfirmOptions | null>(null);
    const [resolveConfirm, setResolveConfirm] = useState<(value: boolean) => void>(() => {});

    const handleConfirmAsync = useCallback((
        options: IConfirmOptions
    ): Promise<boolean> => {
        setOptions(options);
        return new Promise<boolean>((resolve) => {
            setResolveConfirm(() => resolve);
        });
    }, []);

    const handleClose = useCallback((value: boolean) => {
        setOptions(null);
        resolveConfirm(value);
    }, [resolveConfirm]);

    return (
        <ConfirmContext.Provider value={{ confirmAsync: handleConfirmAsync }}>
            {children}
            {options && (
                <ConfirmDialog
                    open={true}
                    title={options.title || 'Você tem certeza?'}
                    description={options.description}
                    onClose={handleClose}
                />
            )}
        </ConfirmContext.Provider>
    );
};
