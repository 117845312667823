export function sortArrayByKey(array, key) {
  const newArray: any[] | undefined = [...array];
  newArray.sort(function (a, b) {
    if (a === null || b === null) return 0
    const aValue = a[key];
    const bValue = b[key];

    if (aValue < bValue) {
      return -1;
    } else if (aValue > bValue) {
      return 1;
    } else {
      return 0;
    }
  });

  return newArray;
}
