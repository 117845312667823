import { i18n } from '@lingui/core';
import Axios, { AxiosInstance } from 'axios';
import { toast } from 'react-toastify';

const api: AxiosInstance = Axios.create({
    baseURL: import.meta.env.VITE_API,
});

let lastToastMessage: string | null = null;
let toastTimer: NodeJS.Timeout | null = null;

const showToast = (message: string, type: any) => {
    const normalizedMessage = message.toLowerCase(); // Normaliza a mensagem para comparação insensível ao caso.
    if (lastToastMessage?.toLowerCase() !== normalizedMessage) {
        toast(i18n.t(message), { type });
        // Limpa o timer anterior se houver um.
        if (toastTimer) {
            clearTimeout(toastTimer);
        }

        lastToastMessage = message;

        toastTimer = setTimeout(() => {
            lastToastMessage = null;
        }, 2500);
    }
};

api.interceptors.request.use((config) => {
    const token = localStorage.getItem('nplan_token');
    if (typeof token === 'string' && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

api.interceptors.response.use(
    (response) => {
        const { type, message, showToast: shouldShowToast } = response.data;
        if (shouldShowToast === true) {
            showToast(message, type);
        }
        return response.data;
    },
    (error) => {
        try {
            if (!error.response) {
                const message =
                    'Verifique sua conexão com a internet e tente novamente!';
                showToast(message, toast.TYPE.ERROR);
                return {
                    type: 'error',
                    message: 'Sem internet/Porta bloqueada',
                };
            }
            if (error.response?.data != null) {
                const {
                    type,
                    message,
                    showToast: shouldShowToast,
                } = error.response.data;
                if (shouldShowToast === true) {
                    showToast(message, type);
                }
                return error.response.data;
            }
            return { type: 'error', message: 'Problema desconhecido' };
        } catch (error) {
            return { type: 'error', message: 'Problema desconhecido' };
        }
    }
);

export default api;
