import {
    Avatar,
    Box,
    Card,
    CardContent,
    Divider,
    Typography,
    useTheme,
} from '@mui/material';
import React from 'react';
import {
    StyledBadge,
} from './components/Badges';
import { useLicenceInfo } from './hooks/useLicenceInfo';
import { Trans } from '@lingui/macro';

interface ISummaryBody {
    users: User[];
}

export const SummaryBody: React.FC<ISummaryBody> = ({
    users
}): JSX.Element => {
    const onlineUsers = users.filter(user => user.lastLogin && new Date(user.lastLogin).getTime() > new Date().getTime() - 1000 * 60 * 5);
    const offlineUsers = users.filter(user => !user.lastLogin || new Date(user.lastLogin).getTime() <= new Date().getTime() - 1000 * 60 * 5);
    const theme = useTheme();    
    onlineUsers.sort((a, b) => a.name.localeCompare(b.name));
    offlineUsers.sort((a, b) => a.name.localeCompare(b.name));

    const sortedUsers = [...onlineUsers, ...offlineUsers];
    const { licenceInfo } = useLicenceInfo();
    return (
        <Box sx={{
            display: 'flex', 
            justifyContent: 'space-evenly',
        }}>
            <Card
                sx={{
                    flex: 1,
                    minWidth: '250px',
                    maxWidth: '500px',

                    borderRadius: '2rem',
                }}
            >
                <CardContent>
                    <Typography
                        sx={{
                            borderRadius: 10,
                            padding: 0.2,
                            fontWeight: '500',
                            color: theme.palette.text.primary,
                            backgroundColor: theme.palette.info.main,
                            textAlign: 'center',
                            width: '50%',
                            margin: '0 auto 1rem '
                        }}
                        gutterBottom
                    >
                        FULL
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent:
                                'space-between',
                            width: '100%',
                            padding: '0 1rem',
                        }}
                    >
                        <Typography>
                            <Trans>Licenças Totais:</Trans>
                        </Typography>
                        <Typography>
                            {licenceInfo?.full}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent:
                                'space-between',
                            width: '100%',
                            padding: '0 1rem',
                        }}
                    >
                        <Typography component='div'>
                            <Trans>Licenças Atribuidas:</Trans>
                        </Typography>
                        <Typography component='div'>
                            {licenceInfo?.fullUsed}
                        </Typography>
                    </Box>
                    <Divider
                        sx={{ margin: '0.5rem 0 ' }}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '0.7rem',
                            flexWrap: 'wrap',
                        }}
                    >
                        {sortedUsers
                            .filter(user => user.userType === 1)
                            .map((user) => (
                                <StyledBadge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    variant="dot"
                                    classes={{
                                        badge: user.lastLogin != null && new Date(user.lastLogin).getTime() > new Date().getTime() - 1000 * 60 * 5
                                            ? 'MuiBadge-badge-online'
                                            : 'MuiBadge-badge-offline',
                                    }}
                                    title={`${user.name} ${user.lastName}`}
                                    key={user.id}

                                >
                                    <Avatar
                                        sx={{
                                            width: 25,
                                            height: 25,
                                            fontSize:
                                                '14px',
                                        }}
                                        alt={
                                            ''
                                        }
                                        src={''}
                                    />
                                </StyledBadge>
                            ))
                        }
                    </Box>
                    <Divider
                        sx={{ margin: '0.5rem 0 ' }}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent:
                                'space-between',
                            width: '100%',
                            padding: '0 1rem',
                        }}
                    >
                        <Typography component='div'>
                            <Trans>Licenças Disponíveis:</Trans>
                        </Typography>
                        <Typography component='div'>
                            {licenceInfo && licenceInfo?.full - licenceInfo?.fullUsed}
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
            <Card
                sx={{
                    flex: 1,
                    minWidth: '250px',
                    maxWidth: '500px',
                    borderRadius: '2rem',
                }}
            >
                <CardContent>
                    <Typography
                        sx={{
                            borderRadius: 10,
                            padding: 0.2,
                            fontWeight: '500',
                            color: theme.palette.text.primary,
                            backgroundColor: theme.palette.primary.main,
                            textAlign: 'center',
                            width: '50%',
                            margin: '0 auto 1rem '
                        }}
                        gutterBottom
                    >
                        VIEWER
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent:
                                'space-between',
                            width: '100%',
                            padding: '0 1rem',
                        }}
                    >
                        <Typography>
                            <Trans>Licenças Totais:</Trans>
                        </Typography>
                        <Typography>
                            {licenceInfo?.viewer}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent:
                                'space-between',
                            width: '100%',
                            padding: '0 1rem',
                        }}
                    >
                        <Typography component='div'>
                            <Trans>
                                Licenças Atribuidas:
                            </Trans>
                        </Typography>
                        <Typography component='div'>
                            {licenceInfo?.viewerUsed}
                        </Typography>
                    </Box>
                    <Divider
                        sx={{ margin: '0.5rem 0 ' }}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '0.7rem',
                            flexWrap: 'wrap',
                        }}
                    >
                        {sortedUsers
                            .filter(user => user.userType === 2)
                            .map((user) => (
                                <StyledBadge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    variant="dot"
                                    classes={{
                                        badge: user.lastLogin != null && new Date(user.lastLogin).getTime() > new Date().getTime() - 1000 * 60 * 5
                                            ? 'MuiBadge-badge-online'
                                            : 'MuiBadge-badge-offline',
                                    }}
                                    title={`${user.name} ${user.lastName}`}
                                    key={user.id}

                                >
                                    <Avatar
                                        sx={{
                                            width: 25,
                                            height: 25,
                                            fontSize:
                                                '14px',
                                        }}
                                        alt={
                                            ''
                                        }
                                        src={''}
                                    />
                                </StyledBadge>
                            ))
                        }
                    </Box>
                    <Divider
                        sx={{ margin: '0.5rem 0 ' }}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent:
                                'space-between',
                            width: '100%',
                            padding: '0 1rem',
                        }}
                    >
                        <Typography component='div'>
                            <Trans>Licenças Disponíveis:</Trans>
                        </Typography>
                        <Typography component='div'>
                            {licenceInfo && licenceInfo?.viewer - licenceInfo?.viewerUsed}
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};
