import { createContext } from 'react';

export interface IUserContextProps {
    user: User | null;
    role: Role | null;
    getToken: () => string | null;
    onLoginAsync: (token: string) => Promise<void>;
    onLogoutAsync: () => Promise<void>;
    onGetUserAsync: () => Promise<boolean>;
}

export const UserContext = createContext<IUserContextProps | null>(null);