import {
    Dialog,
    DialogContent,
    DialogTitle,
    Box,
    TextField,
    FormControlLabel,
    Switch,
    Button,
} from '@mui/material';
import { Trans, t } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { useManageButton } from './hooks/useManageButton';

interface IManageButtonProps {
    open: boolean;
    onClose: () => void;
    buttonId: number;
}
export const ManageButton: React.FC<IManageButtonProps> = ({
    open,
    onClose,
    buttonId,
}): JSX.Element => {
    const {
        button,
        loading,
        handleInputChange,
        handleSwitchChange,
        handleSaveAsync,
    } = useManageButton(
        buttonId, 
        open, 
        onClose
    );
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
            <DialogTitle textAlign={'center'}>
                {button?.id === 0 
                    ? <Trans>Adicionar Tabela</Trans>
                    : <Trans>Editar Tabela</Trans>  }
            </DialogTitle>
            <DialogContent>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={2}
                >
                    <TextField
                        value={button.name}
                        variant="standard"
                        label={t`Nome`}
                        name="name"
                        onChange={handleInputChange}
                        disabled={loading}
                    />
                    <TextField
                        value={button.procedureSchema}
                        variant="standard"
                        label={t`Esquema da procedure`}
                        name="procedureSchema"
                        onChange={handleInputChange}
                        disabled={loading}
                    />
                    <TextField
                        value={button.procedureName}
                        variant="standard"
                        label={t`Nome da procedure`}
                        name="procedureName"
                        onChange={handleInputChange}
                        disabled={loading}
                    />
                    <FormControlLabel
                        sx={{ userSelect: 'none' }}
                        control={
                            <Switch
                                checked={button.refreshOnFinish}
                                name="refreshOnFinish"
                                onChange={handleSwitchChange}
                                disabled={loading}
                            />
                        }
                        label={t`Atualizar dados ao terminar`}
                    />
                    <FormControlLabel
                        sx={{ userSelect: 'none' }}
                        control={
                            <Switch
                                checked={button.waitForResult}
                                name="waitForResult"
                                onChange={handleSwitchChange}
                                disabled={loading}
                            />
                        }
                        label={t`Aguardar resultado`}
                    />
                    <LoadingButton
                        variant="outlined"
                        onClick={handleSaveAsync}
                        loading={loading}
                    >
                        <Trans>Salvar</Trans>
                    </LoadingButton>
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={onClose}
                    >
                        <Trans>Cancelar</Trans>
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
