import { Trans } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { useColorModeContext, postAsync } from '@nplan';
import React, { useCallback, useRef } from 'react';
import * as XLSX from 'xlsx';

export const SendData:React.FC = (): JSX.Element => {
    const [loading, setLoading] = React.useState(false);
    const ref = useRef<HTMLInputElement>(null);

    const isExcelFile = useCallback((fileName: string) => {
        return /\.(xlsx|xls|xlsm)$/i.test(fileName);
    }, []);

    const isCsvFile = useCallback((fileName: string) => {
        return /\.(csv)$/i.test(fileName);
    },[]);

    const handleSendFileClick = () => {
        ref.current?.click();
    };
    const { buttonVariant } = useColorModeContext(); 
    const processFileAsync = useCallback((
        file: File
    ): Promise<File[]> => {
        return new Promise((resolve, reject) => {
            if (isExcelFile(file.name)) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = e.target?.result;
                    const workbook = XLSX.read(data, {
                        type: 'binary',
                    });
                    const csvFiles: File[] = [];
                    workbook.SheetNames.forEach(
                        (sheetName) => {
                            const worksheet =
                                workbook.Sheets[
                                    sheetName
                                ];
                            const csv =
                                XLSX.utils.sheet_to_csv(
                                    worksheet,
                                );
                            const blob = new Blob(
                                [csv],
                                {
                                    type: 'text/csv;charset=utf-8;',
                                },
                            );
                            csvFiles.push(
                                new File(
                                    [blob],
                                    `${sheetName}.csv`,
                                ),
                            );
                        },
                    );
                    resolve(csvFiles);
                };
                reader.onerror = reject;
                reader.readAsBinaryString(file);
            } else if (isCsvFile(file.name)) {
                resolve([file]);
            } else {
                reject(
                    new Error('Unsupported file type'),
                );
            }
        });
    }, [isCsvFile, isExcelFile]);

    const handleInputChange = async (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const formData = new FormData();
        const files = event.target.files;
        if (!files) return;
        try {
            setLoading(true);
            const filePromises: Promise<File[]>[] =
                Array.from(files).map((file) =>
                    processFileAsync(file),
                );
            const fileGroups: File[][] = await Promise.all(
                filePromises,
            );

            fileGroups.flat().forEach((file) => {
                formData.append(file.name, file);
            });

            await postAsync('/import', formData);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };
    return (
        <React.Fragment>
            <input
                type='file'
                id='file'
                name='file'
                style={{ display: 'none' }}
                ref={ref}
                onChange={handleInputChange}
                accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                multiple={true}
                onClick={(event) => { 
                    event.currentTarget.value = '';
                }}
            />
            <LoadingButton
                color='primary'
                variant={buttonVariant}
                onClick={handleSendFileClick}
                loading={loading}
                sx={{
                    borderRadius: '15px',
                    padding: '10px',
                    whiteSpace: 'nowrap',
                
                }}
            >
                <Trans>Enviar Dados</Trans>
            </LoadingButton>
        </React.Fragment>
    );
};
