import {
    useMemo,
    CSSProperties,
    useState,
    useEffect,
    useCallback,
} from 'react';
import { getAsync, NumberOperator, TextOperator } from '@nplan';
import { useParams } from 'react-router-dom';

const BUFFER_WIDTH = 150;

interface IUseColumns {
    cols: Column[];
    getVisibleColumns: Column[];
    getTemplateColumns: CSSProperties['gridTemplateColumns'];
    getWidth: string;
    getGroups: GroupInfo[];
    handleFilterColumnValue: (column: Column, value: any) => void;
    handleFilterColumnSignal: (column: Column, value: TextOperator | NumberOperator) => void;
    handleSortColumn: (id: number) => void;
    maxIndex: number;
}

export const useColumns = (
    scrollLeft: number,
    containerWidth: number,
): IUseColumns => {
    const [cols, setCols] = useState<Column[]>([]);
    const { id } = useParams();
    const getColumns = useMemo(() => {
        let init = 0;
        let index = 1;
        const newCols = cols
            .sort((a, b) => a.order - b.order)
            .filter((el) => !el.hidden)
            .map((el) => {
                el.init = init;
                el.index = index;
                el.fixedRigth = false;
                init += el.width;
                if (el.filterValue === undefined) el.filterValue = '';
                if (el.filterSignal === undefined){
                    switch (el.filterTypeId) {
                        case 3:
                            el.filterSignal = NumberOperator.Equal;
                            break;
                        case 5:
                            el.filterSignal = TextOperator.Equal;
                            break;
                        default:
                            '';
                    }
                }
                index++;
                return el;
            });
        return [...newCols];
    }, [cols]);

    const maxIndex = useMemo(
        () => getColumns.length + 1,
        [getColumns],
    );

    const getVisibleColumns = useMemo(() => {
        const rightLimit = scrollLeft + containerWidth + BUFFER_WIDTH;
        const leftLimit = scrollLeft - BUFFER_WIDTH;
        return getColumns.filter((column) => {
            if (column.fixed) return true;
            const isStartInRange =
                column.init >= leftLimit && column.init <= rightLimit;
            const isEndInRange =
                column.init + column.width >= leftLimit &&
                column.init + column.width <= rightLimit;
            const isSpanning =
                column.init <= scrollLeft &&
                column.init + column.width >= rightLimit;
            return isStartInRange || isEndInRange || isSpanning;
        });
    }, [getColumns, scrollLeft, containerWidth]);

    const getTemplateColumns: CSSProperties['gridTemplateColumns'] = useMemo(() => {
        return (
            `${getColumns.map((col) => `${col.width}px`).join(' ')  } 60px`
        );
    }, [getColumns]);

    const getWidth = useMemo(() => {
        return (
            `${getColumns.reduce((acc, col) => acc + col.width, 60)}px`
        );
    }, [getColumns]);


    const getGroups = useMemo(() => {
        const groups: GroupInfo[] = [];
        let currentGroup: GroupInfo | null = null;
        let oldGroup: string = '';
        let oldIsFixed: boolean | undefined = false;
    
        getColumns.forEach((col) => {
            let group = col.group;
            if(group == null) group = '';
            
            if (currentGroup == null || group !== oldGroup || col.fixed !== oldIsFixed) {
                if (currentGroup) {
                    groups.push(currentGroup);
                }
                currentGroup = {
                    name: group,
                    init: col.init,
                    end: col.init + col.width,
                    index: col.index,
                    fixed: col.fixed,
                };
                oldGroup = group;
                oldIsFixed = col.fixed;
            } else {
                if (currentGroup) {
                    currentGroup.end = col.init + col.width;
                }
            }
        });
    
        if (currentGroup) {
            groups.push(currentGroup);
        }
        
        return groups;
    }, [getColumns]);
    
    

    const handleFilterColumnValue = useCallback(
        (column: Column, value: any) => {
            const colToChange = cols.find(
                (el) => el.id === column.id,
            );
            if (colToChange) {
                colToChange.filterValue = value;
                setCols([...cols]);
            }
        },
        [cols],
    );

    const handleFilterColumnSignal = useCallback(
        (column: Column, value: TextOperator | NumberOperator) => {
            const colToChange = cols.find(
                (el) => el.id === column.id,
            );
            if (colToChange) {
                colToChange.filterSignal = value;
                setCols([...cols]);
            }
        },
        [cols],
    );

    const handleSortColumn = useCallback(
        (id: number) => {
            const colToChange = cols.find((el) => el.id === id);
            const colSortered = cols.find((el) => el.sortDirection);
            if (colSortered && colSortered.id !== id) {
                colSortered.sortDirection = undefined;
            }
            if (colToChange) {
                colToChange.sortDirection =
                    colToChange.sortDirection === 'asc'
                        ? 'desc'
                        : 'asc';
                setCols([...cols]);
            }
        },
        [cols],
    );

    useEffect(() => {
        (async () => {
            if (id) {
                const response = await getAsync<Column[]>(`columns/table/${id}`);
                if (response.data) setCols(response.data);
            }
        })();
    }, [id]);

    return {
        cols,
        getVisibleColumns,
        getTemplateColumns,
        getWidth,
        maxIndex,
        getGroups,
        handleFilterColumnValue,
        handleFilterColumnSignal,
        handleSortColumn,
    };
};
