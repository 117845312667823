import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { deleteAsync, getAsync, useConfirmContext, useHubContext } from '@nplan';
import { t } from '@lingui/macro';

interface IUseSchedulerDataReturn {
    executions: Execution[];
    handleRefreshProceduresAsync: () => Promise<void>;
    handleCancelProcedureAsync: (event: MouseEvent<HTMLButtonElement>,) => Promise<void>;
}

export const useSchedulerData = (): IUseSchedulerDataReturn => {
    const [executions, setExecutions] = useState<Execution[]>([]);
    const confirm = useConfirmContext();

    const { hubConnection } = useHubContext();

    const handleRefreshProceduresAsync = useCallback(async () => {
        const response = await getAsync<Execution[]>('procedure-executions');
        setExecutions(response.data ?? []);
    }, []);




    const handleCancelProcedureAsync = useCallback(async (
        event: MouseEvent<HTMLButtonElement>,
    ): Promise<void> => {
        const id = event.currentTarget.dataset.id;
        if (!id) return;
        const confirmed = await confirm({
            title: t`Cancelar Procedure`,
            description: t`Tem certeza que deseja cancelar a Procedure?`,
        });
        if (confirmed) {
            await deleteAsync<string>(`procedure-executions/${id}`);
        }

    }, []);

    useEffect(() => {
        handleRefreshProceduresAsync();
    }, [handleRefreshProceduresAsync]);

    useEffect(() => {
        if (hubConnection) {
            hubConnection.on('RefreshProcedures', handleRefreshProceduresAsync);
            return () => hubConnection.off('RefreshProcedures', handleRefreshProceduresAsync);
        }
    }, [hubConnection, handleRefreshProceduresAsync]);

    return {
        executions,
        handleRefreshProceduresAsync,
        handleCancelProcedureAsync,
    };
};
