import {
    Box,
    Divider,
    IconButton,
    Menu,
    MenuItem,
} from '@mui/material';
import { Icons, useUserContext } from '@nplan';
import { useDataGridContext } from '../providers/hooks/';
import { t } from '@lingui/macro';
import { useNavigate } from 'react-router-dom';
import { memo, useCallback } from 'react';
import * as XLSX from 'xlsx';
export interface IContextMenu {
    position: MenuPosition | null;
    onClose: () => void;
    onAddData: (data: any) => void;
    onOpenLog: (event: React.MouseEvent<HTMLLIElement>) => void;
}
const ContextMenu: React.FC<IContextMenu> = ({
    onClose,
    position,
    onAddData,
    onOpenLog,
}): JSX.Element => {
    const {
        table,
        editiorAll,
        onEditiorAllChange,
        onRefreshDataAsync,
        onCallProcedureAsync,
        buttons,
        allColumns,
        allRows,
        onResetRequiredFilter,
        foreignFields
    } = useDataGridContext();
    const {
        role
    } = useUserContext();
    const navigate = useNavigate();
    const handleRefreshData = () => {
        onRefreshDataAsync();
        onClose();
    };
    const handleAddData = () => {
        onAddData(0);
        onClose();
    };
    const handleConfig = useCallback(() => {
        navigate(`/tables/${table?.id}/columns`);
    },[navigate, table]);
    const handleOpenLog = (
        event: React.MouseEvent<HTMLLIElement>,
    ) => {
        onOpenLog(event);
        onClose();
    };
    const handleExportToExcel = useCallback(() => {
        const data = [allColumns.map((column) => column.header)];
        allRows.forEach((row) => {
            const rowData: any = [];
            allColumns.forEach((column) => {
                let value = row[column.field];
                if (column.foreignKey && value != null && value !== undefined && foreignFields) {
                    value = foreignFields[column.id][row[column.field]] ?? column.nullText ?? 'NULL'; 
                } else if (value == null) {
                    value = column.nullText ?? 'NULL';
                }
                rowData.push(value);
            });
            data.push(rowData);
        });
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(
            workbook,
            worksheet,
            table?.title ?? 'Sheet',
        );
        XLSX.writeFile(workbook, `${table?.title ?? 'default'}.xlsx`);
    }, [allColumns, allRows, table?.title, foreignFields]);

    const handleResetRequiredFilter = useCallback(() => {
        onResetRequiredFilter();
        onClose();
    }, [onClose, onResetRequiredFilter]);
    return (
        <Menu
            anchorReference="anchorPosition"
            anchorPosition={
                position
                    ? { top: position.y, left: position.x }
                    : undefined
            }
            open={Boolean(position)}
            onClose={onClose}
        >
            <Box>
                <MenuItem onClick={handleRefreshData}>
                    <IconButton>
                        <Icons.Refresh size={20} />
                    </IconButton>
                    {t`Atualizar dados`}
                </MenuItem>
                <MenuItem
                    onClick={handleAddData}
                    disabled={!table?.insertData}
                >
                    <IconButton>
                        <Icons.Add size={20} />
                    </IconButton>
                    {t`Adicionar dados`}
                </MenuItem>
                {table?.requiredFilter && (
                    <MenuItem
                        onClick={handleResetRequiredFilter}
                    >
                        <IconButton>
                            <Icons.Refresh size={20} />
                        </IconButton>
                        {t`Alterar filtro obrigatório`}
                    </MenuItem>
                )}
                <MenuItem onClick={handleExportToExcel}>
                    <IconButton>
                        <Icons.Excel size={20} color="green" />
                    </IconButton>
                    {t`Exportar para XLSX`}
                </MenuItem>
                <MenuItem onClick={onEditiorAllChange}>
                    <IconButton
                        color={editiorAll ? 'success' : 'error'}
                    >
                        <Icons.Edit size={20} />
                    </IconButton>
                    {t`Edição dos dados em massa `}
                </MenuItem>
                {role?.admin && (
                    <MenuItem onClick={handleConfig}>
                        <IconButton>
                            <Icons.Settings size={20} />
                        </IconButton>
                        {t`Configurações`}
                    </MenuItem>)}
                <MenuItem onClick={handleOpenLog}>
                    <IconButton>
                        <Icons.History size={20} />
                    </IconButton>
                    {t`Histório de edição de dados`}
                </MenuItem>
                <Divider variant="middle" />
                {buttons.map((button) => (
                    <MenuItem
                        key={button.id}
                        onClick={onCallProcedureAsync}
                        data-id={button.id}
                    >
                        <IconButton>
                            <Icons.Play size={20} />
                        </IconButton>
                        {button.name}
                    </MenuItem>
                ))}
            </Box>
        </Menu>
    );
};

const MemoizedContextMenu = memo(ContextMenu);
export { MemoizedContextMenu as ContextMenu };
