import { Trans } from '@lingui/macro';
import {
    Box,
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { tryParseDateToISO, getAsync } from '@nplan';

interface IScenarioProps {
    scenario?: Scenario;
    onChangeInputScenario: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeSelectScenario: (event: SelectChangeEvent<number>) => void;
    isNotEditable: boolean;
}

export const Scenario: React.FC<IScenarioProps> = ({
    scenario,
    onChangeInputScenario,
    onChangeSelectScenario,
    isNotEditable,
}: IScenarioProps): JSX.Element => {
    const [periodTypes, setPeriodTypes] = useState<PeriodType[]>([]);
    const [scenarioCategories, setScenarioCategories] = useState<ScenarioCategory[]>([]);

    useEffect(() => {
        (async () => {
            const [
                resPeriodTypes,
                resScenarioCategories
            ] = await Promise.all([
                getAsync<PeriodType[]>('/period-types'),
                getAsync<ScenarioCategory[]>('/scenario-categories'),
            ]);
            setPeriodTypes(resPeriodTypes.data ?? []);
            setScenarioCategories(resScenarioCategories.data ?? []);
        })();
    }, []);
    
    return (
        <Box
            sx={{
                padding: '20px',
                paddingTop: '42px',
                borderRadius: '25px',
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
                width: 320,
            }}
        >
            <FormControl>
                <Typography>
                    <Trans>Nome</Trans>
                </Typography>
                <TextField
                    disabled={isNotEditable}
                    variant="standard"
                    name="name"
                    onChange={onChangeInputScenario}
                    value={scenario?.name ?? ''}
                    data-testid='scenario-name-input'
                />
            </FormControl>
            <FormControl>
                <Typography>
                    <Trans>Tipo de Período</Trans>
                </Typography>
                <Select
                    value={scenario?.periodTypeId ?? ''}
                    disabled={isNotEditable}
                    variant="standard"
                    name="periodTypeId"
                    onChange={onChangeSelectScenario}
                    data-testid='scenario-period-type-select'
                >
                    {periodTypes
                        .map((row, index) => (
                            <MenuItem key={index} value={row.id}  >
                                {row.name}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            <FormControl>
                <Typography>
                    <Trans>Início do Plano</Trans>
                </Typography>
                <TextField
                    type="date"
                    value={(scenario?.startDate && new Date(scenario.startDate).toISOString().slice(0, 10)) || ''}
                    disabled={isNotEditable}
                    variant="standard"
                    name="startDate"
                    inputProps={{
                        lang: 'pt-BR',
                    }}
                    onChange={onChangeInputScenario}
                    data-testid='scenario-start-date-input'
                />
            </FormControl>
            <FormControl>
                <Typography>
                    <Trans>Fim do Período Congelado</Trans>
                </Typography>
                <TextField
                    type="date"
                    data-testid='scenario-frozen-end-date-input'
                    value={tryParseDateToISO(scenario?.frozenEndDate)}
                    disabled={isNotEditable}
                    variant="standard"
                    name="frozenEndDate"
                    onChange={onChangeInputScenario}
                />
            </FormControl>
            <FormControl>
                <Typography>
                    <Trans>Fim do Plano</Trans>
                </Typography>
                <TextField
                    type="date"
                    data-testid='scenario-end-date-input'
                    value={tryParseDateToISO(scenario?.endDate)}
                    disabled={isNotEditable}
                    variant="standard"
                    name="endDate"
                    onChange={onChangeInputScenario}
                />
            </FormControl>
            <FormControl>
                <Typography>
                    <Trans>Tipo de Cenário</Trans>
                </Typography>
                <Select
                    value={scenario?.scenarioCategoryId ?? ''}
                    disabled={isNotEditable}
                    variant="standard"
                    name="scenarioCategoryId"
                    onChange={onChangeSelectScenario}
                >
                    {scenarioCategories
                        .map((row, index) => (
                            <MenuItem key={index} value={row.id}>
                                {row.name}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        </Box>
    );
};
