import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { TableCell, TableHead, TableRow, TextField } from '@mui/material';
import React from 'react';

interface IHeaderProps {
    filter: string;
    onChangeFilterValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
interface IColumn {
    index: number;
    title: string;
    minWidth: number;
}
const columns: IColumn[] = [
    { index: 0, title: 'Usuário', minWidth: 150 },
    { index: 1, title: 'Email', minWidth: 200 },
    { index: 2, title: 'Nome', minWidth: 120 },
    { index: 3, title: 'Sobrenome', minWidth: 120 },
    { index: 4, title: 'Grupo', minWidth: 120 },
    { index: 5, title: 'Ativo', minWidth: 120 },
    { index: 6, title: 'Bloqueado', minWidth: 120 },
    { index: 7, title: 'Último Login', minWidth: 120 },
];
export const Header: React.FC<IHeaderProps> = ({
    filter,
    onChangeFilterValue,
}: IHeaderProps): JSX.Element => {
    return (
        <TableHead>
            <TableRow
                sx={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 10,
                    bgcolor: 'background.paper',
                }}
            >
                {columns.map((column) => (
                    <TableCell
                        key={column.index}
                        align='left'
                        sx={{ minWidth: column.minWidth }}
                    >
                        {i18n.t(column.title)}
                    </TableCell>
                ))}
                <TableCell colSpan={3} sx={{
                    minWidth: 520
                }}>
                    <TextField
                        fullWidth
                        placeholder={t`Filtrar...`}
                        value={filter}
                        onChange={onChangeFilterValue}
                    />
                </TableCell>
            </TableRow>
        </TableHead>
    );
};