import { Trans } from '@lingui/macro';
import {
    Box,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material';
import { NumberOperator } from 'enums';
import React from 'react';

interface INumberFilterProps {
    column: Column;
    onFilterColumnSignalChange: (
        column: Column,
        signal: NumberOperator
    ) => void;
    onFilterColumnValueChange: (column: Column, value: any) => void;
}

const NumberFilter: React.FC<INumberFilterProps> = ({
    column,
    onFilterColumnSignalChange,
    onFilterColumnValueChange,
}) => {
    const handleFilterValueChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const target = event.target as HTMLInputElement;
        onFilterColumnValueChange(column, target.value);
    };
    const handleFilterSignalChange = (
        event: SelectChangeEvent<string>
    ) => {
        const target = event.target as HTMLSelectElement;
        onFilterColumnSignalChange(column, target.value as NumberOperator);
    };
    return (
        <Box display={'flex'} flexDirection={'row'} minWidth={90} gap={1}>
            <Select
                sx={{
                    height: 32,
                    minWidth: 40,
                    maxWidth: 40,
                    paddingLeft: 0,
                    paddingRight: 0,
                }}
                SelectDisplayProps={{
                    style: {
                        paddingRight: 0,
                    },
                }}
                disableUnderline
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                }}
                name={`${column.field}-signal`}
                defaultValue={NumberOperator.Equal}
                renderValue={(value) => <>{value}</>}
                variant="standard"
                onChange={handleFilterSignalChange}
            >
                <MenuItem value={NumberOperator.Equal}>
                    <Trans>Igual</Trans>
                </MenuItem>
                <MenuItem value={NumberOperator.NotEqual}>
                    <Trans>Diferente</Trans>
                </MenuItem>
                <MenuItem value={NumberOperator.GreaterThan}>
                    <Trans>Maior</Trans>
                </MenuItem>
                <MenuItem value={NumberOperator.GreaterThanOrEqual}>
                    <Trans>Maior ou Igual</Trans>
                </MenuItem>
                <MenuItem value={NumberOperator.LessThan}>
                    <Trans>Menor</Trans>
                </MenuItem>
                <MenuItem value={NumberOperator.LessThanOrEqual}>
                    <Trans>Menor ou Igual</Trans>
                </MenuItem>
            </Select>
            <TextField
                sx={{
                    width: 'calc(100% - 40px)',
                    height: 40,
                    minWidth: 40,
                }}
                name={`${column.field}-name`}
                onChange={handleFilterValueChange}
                type={'text'}
                variant="standard"
            />
        </Box>
    );
};

const MemoizedNumberFilter = React.memo(NumberFilter);
export default MemoizedNumberFilter;
