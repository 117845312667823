import { Box, Divider } from '@mui/material';
import useFullScreen from 'pages/inventory-policy/hooks/useFullScreen';
import { IFilter, IInventorySummaryInfo, ISegmentationSummaryInfo } from 'pages/inventory-policy/types/IFilters';
import { IInventorySummaryData } from 'pages/inventory-policy/types/IInventorySummaryData';
import React from 'react';
import { ISupplyStrategy } from 'types/system/ISupplyStrategy';
import InventoryCardsContainer from './Cards';
import InventoryHealthContainer from './Health';
import useInventoryPolicy from 'pages/inventory-policy/hooks/useInventoryPolicy';

interface InventorySummaryProps {
    filter: IFilter
    inventorySummaryInfo: IInventorySummaryInfo
    segmentationSummaryInfo: ISegmentationSummaryInfo
    stockStrategyOptions: ISupplyStrategy[]
    summaryHeaderData: IInventorySummaryData[] | undefined
    isProcedureRunning: boolean
    isProcedureCompleted: boolean
    segmentationUpdate: () => Promise<void>
    handleInventoryRecalculate: (name: string) => Promise<void>
}

export const InventorySummaryBody: React.FC<InventorySummaryProps> = ({ isProcedureCompleted, segmentationUpdate, isProcedureRunning, handleInventoryRecalculate, summaryHeaderData, filter, inventorySummaryInfo, segmentationSummaryInfo, stockStrategyOptions }: InventorySummaryProps): JSX.Element => {
    const { isLeftSideFullScreen, isRightSideFullScreen, handleFullScreen, handleExitFullScreen } = useFullScreen();
    return (
        <Box sx={{ display: 'flex', width: '100%', height: '100%', padding: '0.1rem', margin: '0 auto' }}>
            <InventoryCardsContainer
                segmentationUpdate={segmentationUpdate}
                handleInventoryRecalculate={handleInventoryRecalculate}
                inventorySummaryInfo={inventorySummaryInfo}
                summaryHeaderData={summaryHeaderData}
                filter={filter}
                segmentationSummaryInfo={segmentationSummaryInfo}
                stockStrategyOptions={stockStrategyOptions}
                isProcedureRunning={isProcedureRunning}
                isProcedureCompleted={isProcedureCompleted}
                isLeftSideFullScreen={isLeftSideFullScreen}
                isRightSideFullScreen={isRightSideFullScreen}
                handleFullScreen={handleFullScreen}
                handleExitFullScreen={handleExitFullScreen}
            />
            <Divider orientation="vertical" flexItem variant='middle' />
            <InventoryHealthContainer
                isLeftSideFullScreen={isLeftSideFullScreen}
                isRightSideFullScreen={isRightSideFullScreen}
                handleFullScreen={handleFullScreen}
                handleExitFullScreen={handleExitFullScreen}
            />
        </Box>
    );
};

export default InventorySummaryBody;
