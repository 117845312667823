import { 
    Box, 
    Table, 
    TableContainer 
} from '@mui/material';
import {
    Header,
    Body,
    ContextMenu,
    ManageReport,
    ManageConfig,
} from './components';
import {  } from '@nplan';
import { 
    useContextMenu, 
    useDialog, 
    usePagination,
    useDataContext,
    Pagination
} from '@nplan';

export const Report: React.FC = (

): JSX.Element => {
    const { 
        handleOpenDialog, 
        handleCloseDialog, 
        open, 
        id 
    } = useDialog();

    const {
        handleOpenDialog: handleOpenConfig,
        handleCloseDialog: handleCloseConfig,
        open: openConfig,
    } = useDialog();

    const { 
        page, 
        rowsPerPage, 
        handleChangePage, 
        handleChangeRowsPerPage 
    } = usePagination();
    
    const { 
        position, 
        handleOpenMenu, 
        handleCloseMenu 
    } = useContextMenu();

    const { 
        reports 
    } = useDataContext();
    
    return (
        <Box padding={2}>
            <ManageReport
                open={open}
                onClose={handleCloseDialog}
                reportId={id}
            />
            <ManageConfig
                open={openConfig}
                onClose={handleCloseConfig}
            />
            <ContextMenu
                position={position}
                onClose={handleCloseMenu}
                onAddReport={handleOpenDialog}
                onManageConfig={handleOpenConfig}
            />
            <TableContainer
                sx={{
                    minHeight: 130,
                    height: 'calc(100vh - 130px)',
                    display: 'flex',
                    flexDirection: 'column',
                }}
                onContextMenu={handleOpenMenu}
            >
                <Table sx={{ whiteSpace: 'nowrap' }}>
                    <Header />
                    <Body
                        reports={reports.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage,
                        )}
                        onChangeReportId={handleOpenDialog}
                    />
                </Table>
            </TableContainer>
            <Pagination
                count={reports.length}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
            />
        </Box>
    );
};
