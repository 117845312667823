import { SelectChangeEvent } from '@mui/material';
import { useState, useEffect } from 'react';
import { getAsync, postAsync, putAsync } from '@nplan';

const defaultReport: NReport = {
    id: 0,
    name: '',
    description: '',
    order: 1,
    url: '',
    typeId: 1,
};

interface IUseManageReportDataReturn {
    report: NReport;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelectChange: (event: SelectChangeEvent<number>) => void;
    handleSaveAsync: () => Promise<void>;
    loading: boolean;
}

export const useManageReportData = (
    reportId: number,
    onClose: () => void,
    open: boolean,
): IUseManageReportDataReturn => {

    const [report, setReport] = useState<NReport>(defaultReport);
    const [loading, setLoading] = useState<boolean>(false);

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const { name, value } = event.target;
        setReport((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSelectChange = (
        event: SelectChangeEvent<number>,
    ) => {
        const { name, value } = event.target;
        setReport((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSaveAsync = async () => {
        setLoading(true);
        const res = report.id === 0
            ? await postAsync('reports', report)
            : await putAsync('reports', report);
        setLoading(false);
        if (res.type === 'success') {
            onClose();
        }
    };

    useEffect(() => {
        if(!open) return;
        setReport(defaultReport);
        if(reportId <= 0) return;
        (async () => {
            setLoading(true);
            const res = await getAsync<NReport>(`reports/${reportId}`,);
            if (res.type === 'success' && res.data) {
                setReport(res.data);
            }
            setLoading(false);
        })();
    }, [reportId, onClose, open]);
    
    return {
        report,
        loading,
        handleInputChange,
        handleSelectChange,
        handleSaveAsync,
    };
};
