import { Box, IconButton, Typography } from '@mui/material';
import { FC } from 'react';
import { Filter } from './components/Filters';
import { useFilter } from './hooks/useFilter';
import { Icons } from 'utils/icons';
import { Trans } from '@lingui/macro';
import { putAsync } from 'services/api';

interface IBody {
    logs: TableEditionLog[];
}
export const Body: FC<IBody> = ({ logs }) => {
    const { filter, filtered, handleFilter } = useFilter(logs);

    const handleUndoEditAsync = async (event: React.MouseEvent<HTMLButtonElement>) => {
        const id = event.currentTarget.name;
        const log = logs.find((log) => log.id.toString() === id);
        if (!log) return;
        const dataToPost = {
            value: log.oldValue,
            columnId: log.columnId,
            rowId: log.key,
        };
        await putAsync(`/rows/`, dataToPost);
    };
    
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    overflow: 'auto',
                    paddingBottom: 2,
                }}
            >
                <Filter
                    logs={logs}
                    filter={filter}
                    onFilterChange={handleFilter}
                />
            </Box>
            <Box
                sx={{
                    overflow: 'auto',
                    maxHeight: 600,
                }}
            >
                {filtered
                    .sort((a, b) =>
                        new Date(a.date) > new Date(b.date) ? -1 : 1,
                    )
                    .map((log) => (
                        <Box
                            margin={1}
                            padding={2}
                            display={'flex'}
                            flexDirection={'row'}
                            border={2}
                            borderColor={'primary.main'}
                            borderRadius={5}
                        >
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                whiteSpace={'nowrap'}
                                width={'70%'}
                                overflow={'hidden'}
                                textOverflow={'ellipsis'}
                            >
                                <Typography title={log.key}>
                                    <strong>
                                        <Trans>Linha:</Trans>
                                    </strong>
                                    {` ${  log.key}`}
                                </Typography>
                                <Typography title={log.column}>
                                    <strong>
                                        <Trans>Coluna:</Trans>
                                    </strong>
                                    {` ${  log.column}`}
                                </Typography>
                                <Typography title={log.user}>
                                    <strong>
                                        <Trans>Usuário:</Trans>
                                    </strong>
                                    {` ${  log.user}`}
                                </Typography>
                                <Typography title={log.column}>
                                    <strong>
                                        <Trans>Data:</Trans>
                                    </strong>
                                    {` ${ 
                                        new Date(log.date)
                                            .toLocaleString()
                                            .replace(',', '')}`}
                                </Typography>
                                <Typography title={log.oldValue}>
                                    <strong>
                                        <Trans>Valor Antigo:</Trans>
                                    </strong>
                                    {` ${  log.oldValue}`}
                                </Typography>
                                <Typography title={log.newValue}>
                                    <strong>
                                        <Trans>Valor Novo:</Trans>
                                    </strong>
                                    {` ${  log.newValue}`}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '30%',
                                }}
                            >
                                <IconButton
                                    name={log.id.toString()}
                                    onClick={handleUndoEditAsync}
                                >
                                    <Icons.Refresh />
                                </IconButton>
                            </Box>
                        </Box>
                    ))}
            </Box>
        </Box>
    );
};
