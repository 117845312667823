import { Box } from '@mui/material';
import Yellow from 'assets/images/nplan/icon/2t.png';

export const Loader:React.FC = (): JSX.Element => {
    return (
        <Box
            sx={{
                position: 'relative',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                height: '100%',
                width: '100%',
                zIndex: '999',
                opacity: '0.7',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <img
                src={Yellow}
                alt=''
                className='rotate'
                style={{ width: 150, height: 150 }}
            />
        </Box>
    );
};

