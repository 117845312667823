import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IFilterSegmentationOptionsSubset } from 'pages/inventory-policy/types/IFilters';
import { InventoryHealthData } from 'pages/inventory-policy/types/IInventoryHealth';
import { SupplyStrategyEditedObjectsProps } from 'pages/inventory-policy/types/types';
import { applyCriteriaByFilterCriteria } from 'pages/planning-grid/components/PlanningGrid/FilterDrawer/FilterCriteria';
import { updateAllItemIdsByStockStatus, updateAllItemIdsByStockStatusByPeriod } from 'pages/planning-grid/components/PlanningGrid/FilterDrawer/FilterCriteria/helpers';
import { FilterCriteria } from 'pages/planning-grid/components/PlanningGrid/FilterDrawer/FilterCriteria/types';
import { AttributeListData, FixedFilterData, GlobalPlanningGridTargetType, PlanningGridData, SearchList } from 'pages/planning-grid/types/PlanningGrid';
import { createComplexPeriodDataDaily, createComplexPeriodDataMonthly, createComplexPeriodDataWeekly, createMinifiedPeriodsFilters } from 'pages/planning-grid/utils/PeriodUtils';
import { ReduxSliceTypes } from 'redux/types';
import { IPlanningGridOperationResource, IScenario } from 'types';
import { Dictionary } from 'types/Dictionary';
import { IGenericOptions } from 'types/Generics';
import { ICompany } from 'types/data/ICompany';
import { IItem } from 'types/data/IItem';
import { IPlanner } from 'types/data/IPlanner';
import { IPeriod } from 'types/scenarios/IPeriod';
import { IAttributeList } from 'types/system/IAttributeList';
import { IPeriodTypes } from 'types/system/IPeriodTypes';
import { IPlanningGridFilterOptions } from 'types/system/IPlanningGridFilterOptions';
import { IScenarioCriteria } from 'types/system/IScenarioCriteria';
import { IScenarioParameter } from 'types/system/IScenarioParameter';
import { IStockStatus } from 'types/system/IStockStatus';
import { createDictionaryRange, createPropertyRangeDictionary } from 'utils/dictionaryHelper';
import { createAttributesFilter, createTagsFilter, getItemDataById } from '../Filters/helpers';
import { initialState } from './initialState';
import { ItemAttributesBundle, PlanningGridUnitaryOrGlobalType, TagsBundle } from './types';



const filterSlice = createSlice({
    name: ReduxSliceTypes.GlobalFilter,
    initialState: initialState,
    reducers: {

        setItemsPlanning: (state, action: PayloadAction<{ items: IItem[] }>) => {
            const { items } = action.payload;
            const itemById = {};
            const itemComplexityById = {};
            const itemVolumeById = {};
            const itemUncertaintyById = {};
            const itemIdsByProps = {
                level: {},
                plannerId: {},
                stockGroupId: {},
                companyId: {},
                classificationComplexityId: {},
                classificationUncertaintyId: {},
                classificationVolumeId: {}
            };
            const complexityById = getItemDataById(items, 'classificationComplexity');
            const volumeById = getItemDataById(items, 'classificationVolume');
            const uncertaintyById = getItemDataById(items, 'classificationUncertainty');
            state.global.itemProperties['classificationComplexityId'] = complexityById;
            state.global.itemProperties['classificationVolumeId'] = volumeById;
            state.global.itemProperties['classificationUncertaintyId'] = uncertaintyById;

            items.forEach(item => {
                const { id, classificationComplexityId, classificationVolumeId, classificationUncertaintyId } = item;

                (itemById as any)[id] = item;
                if (classificationComplexityId) {
                    (itemComplexityById as any)[classificationComplexityId] = {
                        id: classificationComplexityId,
                        name: item.classificationComplexity?.name,
                        code: item.classificationComplexity?.code
                    };
                }
                if (classificationVolumeId) {
                    (itemVolumeById as any)[classificationVolumeId] = {
                        id: classificationVolumeId,
                        name: item.classificationVolume?.name,
                        code: item.classificationVolume?.code
                    };
                }
                if (classificationUncertaintyId) {
                    (itemUncertaintyById as any)[classificationUncertaintyId] = {
                        id: classificationUncertaintyId,
                        name: item.classificationUncertainty?.name,
                        code: item.classificationUncertainty?.code
                    };
                }

                Object.keys(itemIdsByProps).forEach(prop => {
                    if ((item as any)[prop] !== undefined && (item as any)[prop] !== null) {
                        if (!(itemIdsByProps as any)[prop][(item as any)[prop]]) {
                            (itemIdsByProps as any)[prop][(item as any)[prop]] = [];
                        }
                        (itemIdsByProps as any)[prop][(item as any)[prop]].push(id);
                    }
                });
            });

            state.global.itemsComplete = items;
            state.global.items = items.map(item => item.id);
            state.global.selectedItemIds = [...state.global.items];
            state.global.chartSelectedItemIds = [...state.global.items];
            state.planningGrid.itemById = itemById;
            state.planningGrid.itemComplexityById = itemComplexityById;
            state.planningGrid.itemVolumeById = itemVolumeById;
            state.planningGrid.itemUncertaintyById = itemUncertaintyById;
            state.planningGrid.filterData.itemIdsByLevel = itemIdsByProps.level;
            state.planningGrid.filterData.itemIdsByItemPlannerIds = itemIdsByProps.plannerId;
            state.planningGrid.filterData.itemIdsByStockGroupIds = itemIdsByProps.stockGroupId;
            state.planningGrid.filterData.itemIdsByCompany = itemIdsByProps.companyId;
            state.planningGrid.filterData.itemIdsByComplexity = itemIdsByProps.classificationComplexityId;
            state.planningGrid.filterData.itemIdsByUncertainty = itemIdsByProps.classificationUncertaintyId;
            state.planningGrid.filterData.itemIdsByVolume = itemIdsByProps.classificationVolumeId;
        },
        setPlanningGrid: (state, action: PayloadAction<{ planningGrid: PlanningGridData[] }>) => {
            const { planningGrid } = action.payload;
            const planningGridByItemId = createDictionaryRange(planningGrid, c => c.itemId);
            // const planningGridById = createDictionaryRange(planningGrid, c => c.id);

            // state.planningGrid.planningGridById = planningGridById;
            state.planningGrid.selectedPlanningGrid = planningGridByItemId;
            state.planningGrid.filteredPlanningGridItemIds = Object.keys(planningGridByItemId).map(Number);
            state.planningGrid.filterData.itemIdsByStockStatus = updateAllItemIdsByStockStatus(state);
            state.planningGrid.filterData.itemIdsByStockStatusByPeriod = updateAllItemIdsByStockStatusByPeriod(state);
            state.planningGrid.redrawFilters = state.planningGrid.redrawFilters + 1;
        },
        recalculateItemidsByStockStatus: (state) => {
            state.planningGrid.filterData.itemIdsByStockStatus = updateAllItemIdsByStockStatus(state);
            state.planningGrid.filterData.itemIdsByStockStatusByPeriod = updateAllItemIdsByStockStatusByPeriod(state);
        },
        setPlanningGridItem: (state, action: PayloadAction<{ planningGrid: Dictionary<PlanningGridData[]> }>) => {

            const planningGridDict = action.payload.planningGrid;
            for (const key of Object.keys(planningGridDict)) {
                state.planningGrid.selectedPlanningGrid[key] = planningGridDict[key];
            }

        },
        setEditableInputBorderColor: (state, action: PayloadAction<{ borderColor: string }>) => {
            state.planningGrid.editableInputBorderColor = action.payload.borderColor;
        },
        setPlanningGridOperationResourcesRedux: (state, action: PayloadAction<{ planningGridOperationResource: IPlanningGridOperationResource[] }>) => {
            state.planningGrid.itemIdByResourceId =  createPropertyRangeDictionary(action.payload.planningGridOperationResource, c => c.resourceId, c=>c.itemId);
            state.planningGrid.planningGridOperationResourceByPeriodId = createDictionaryRange(action.payload.planningGridOperationResource, c => c.periodId);
            state.planningGrid.planningGridOperationResourceData = (action.payload.planningGridOperationResource);
        },

        setPlanningGridUnitValues: (state, action: PayloadAction<{ itemId: number | undefined, type: PlanningGridUnitaryOrGlobalType }>) => {
            if (action.payload.itemId === undefined) return;
            const planningGrid = state.planningGrid.selectedPlanningGrid[action.payload.itemId];
            Object.values(planningGrid).forEach(item => {
                const property = action.payload.type;
                if (property in item) {
                    (item as any)[property] = ((item as any)[property] === GlobalPlanningGridTargetType.DailyGlobalTargetValue) ?
                        GlobalPlanningGridTargetType.UnitaryGlobalTargetValue :
                        GlobalPlanningGridTargetType.DailyGlobalTargetValue;
                }
            });
        },

        setPeriods: (state, action: PayloadAction<{ periods: IPeriod[], periodTypeId: number }>) => {
            const { periods, periodTypeId } = action.payload;
            const isDaily = periodTypeId === 3;
            state.planningGrid.periods = periods;


            switch (periodTypeId) {
                case 1:
                    state.planningGrid.complexPeriods = createComplexPeriodDataWeekly(periods);
                    break;
                case 2:
                    state.planningGrid.complexPeriods = createComplexPeriodDataMonthly(periods);
                    break;
                case 3:
                    state.planningGrid.complexPeriods = createComplexPeriodDataDaily(periods);
                    break;
                default:
                    state.planningGrid.complexPeriods = createComplexPeriodDataWeekly(periods);
                    break;
            }


            state.planningGrid.minifiedPeriods = createMinifiedPeriodsFilters(state.planningGrid.complexPeriods, isDaily);
            for (let i = 0; i < periods.length; i++) {
                const period = periods[i];
                state.planningGrid.periodsIdToIndex[period.id] = i;
            }


        },
        setExpandedPeriods: (state) => {
            state.planningGrid.isExpandedPeriods = !state.planningGrid.isExpandedPeriods;
        },
        setSelectedOccupationProperty: (state, action: PayloadAction<{ attribute: keyof IItem | null }>) => {
            if (action.payload.attribute === null) return;
            if (action.payload.attribute === null) return;
            state.planningGrid.selectedOccupationProperty = action.payload.attribute;
        },
        setAttributesData: (state, action: PayloadAction<{ attributeList: AttributeListData[], itemAttributes: ItemAttributesBundle, attributeListRaw: IAttributeList[] }>) => {
            const { itemAttributes, attributeListRaw } = action.payload;
            state.planningGrid.attributeListRaw = attributeListRaw;

            // Update attribute list data
            state.planningGrid.attributeListData = action.payload.attributeList;

            // Update item attributes
            state.planningGrid.itemAttributes = itemAttributes;

            // Create filter attributes
            state.planningGrid.filterData.itemsIdsByAttributeId = createAttributesFilter(
                state.planningGrid.filteredPlanningGridItemIds,
                state.planningGrid.itemById,
                itemAttributes
            );
        },
        setTagsData: (state, action: PayloadAction<{ tags: TagsBundle }>) => {
            state.planningGrid.tags = action.payload.tags;
            state.planningGrid.filterData.itemsIdsTagId = createTagsFilter(
                state.planningGrid.filteredPlanningGridItemIds,
                state.planningGrid.itemById
            );

        },
        setSelectedAttributes: (state, action: PayloadAction<{ selectedAttributes: any }>) => {
            if (action.payload.selectedAttributes.length === 0) {
                state.planningGrid.selectedAttributesToShow = [{
                    label: 'none',
                    value: 'none',
                    checked: true
                }];
                return;
            }
            const selectedAttributes = action.payload.selectedAttributes.map((value: any) => {
                if (value.checked === true) {
                    return {
                        label: value.value,
                        value: value.label,
                    };
                }
            }).filter((c: any) => c !== undefined);

            state.planningGrid.selectedAttributesToShow = selectedAttributes;
        },
        setSelectedFilters: (state) => {
            const filteredCriteriaOptions = state.planningGrid.fixedFilterOptions.filter(c => c.checked === true);
            if (filteredCriteriaOptions.length === 0) {
                const nullValue = [{
                    filterId: 0,
                    filterCriteria: 'none',
                    label: 'none',
                    placeholder: 'none',
                    checked: undefined
                }];
                state.planningGrid.selectedFiltersToShow = nullValue as FixedFilterData[];
                return;
            }
            state.planningGrid.selectedFiltersToShow = filteredCriteriaOptions;
        },
        setSelectedAxis: (state, action: PayloadAction<{ axisOptions: any }>) => {
            state.inventory.selectedAxis = action.payload.axisOptions;
        },
        setFiniteButtonLoading: (state, action: PayloadAction<{ value: boolean }>) => {
            state.planningGrid.finiteButtonLoading = action.payload.value;
        },
        setFixesPlanningGridFilters: (state, action: PayloadAction<{ fixedFilters: FixedFilterData[] }>) => {
            state.planningGrid.fixedFilterOptions = action.payload.fixedFilters;
        },
        setPlannerById: (state, action: PayloadAction<{ plannerById: Dictionary<IPlanner> }>) => {
            state.planningGrid.itemPlannerById = action.payload.plannerById;
        },
        setCompanyById: (state, action: PayloadAction<{ companyById: Dictionary<ICompany> }>) => {
            state.planningGrid.itemCompanyById = action.payload.companyById;
        },
        setStockGroupById: (state, action: PayloadAction<{ stockGroupById: Dictionary<IStockGroup> }>) => {
            state.planningGrid.itemStockGroupById = action.payload.stockGroupById;
        },
        setScenarioCritera: (state, action: PayloadAction<{ scenarioCriteria: IScenarioCriteria[] }>) => {
            state.global.scenarioCriterias = action.payload.scenarioCriteria;
        },
        setScenarioParameters: (state, action: PayloadAction<{ scenarioParameter: IScenarioParameter[] }>) => {
            state.global.scenarioParameters = action.payload.scenarioParameter;
        },
        setScenarioRowsLocked: (state, action: PayloadAction<{ isLocked: boolean }>) => {
            state.global.scenariosRowsLocked = !action.payload.isLocked;
        },
        setPeriodOptions(state, action: PayloadAction<{ periodOptions: IGenericOptions[] }>) {
            state.inventory.periodOptions = action.payload.periodOptions;
        },
        setInventoryHealthSelectedPeriod(state, action: PayloadAction<{ period: IGenericOptions }>) {
            state.inventory.selectedPeriodFilter = action.payload.period;
        },
        setInventoryPolicyEditedObjects(state, action: PayloadAction<{ array: SupplyStrategyEditedObjectsProps[] }>) {
            state.inventory.inventoryPolicyEditedObjects = action.payload.array;
        },
        setChartSelectionItemOrValue(state, action: PayloadAction<({ option: IGenericOptions })>) {
            state.inventory.inventoryHealthChartSelection = action.payload.option;
        },
        setStockStatus(state, action: PayloadAction<{ stockStatus: IStockStatus[] }>) {
            state.global.stockStatus = action.payload.stockStatus;
        },
        setInventoryHealthStockType(state, action: PayloadAction<{ stockTypeId: number }>) {
            state.inventory.stockTypeId = action.payload.stockTypeId;
        },
        setInventoryHealthLoadingState(state, action: PayloadAction<{ loadingHealth: boolean }>) {
            state.inventory.loadingHealth = action.payload.loadingHealth;
        },
        setInventoryFilters: (state, action: PayloadAction<{ filters: IFilterSegmentationOptionsSubset, axisOptions: any }>) => {
            const { companyOptions, dataValidation, salesChannelOptions, stockGroupOptions } = action.payload.filters;
            const axisOptions = action.payload.axisOptions;

            state.inventory.dataValidation = dataValidation;
            state.inventory.stockGroupOptions = stockGroupOptions;
            state.inventory.companyOptions = companyOptions;
            state.inventory.salesChannelOptions = salesChannelOptions;
            state.inventory.axisOptions = axisOptions;
        },
        setSelectedFilter: (state, action: PayloadAction<{ selectedOption: IGenericOptions, selectorName: string }>) => {
            const { selectedOption, selectorName } = action.payload;
            if (Object.prototype.hasOwnProperty.call(state.inventory, selectorName)) {
                (state.inventory as any)[selectorName] = selectedOption;
            }
        },
        setIsEditableInventoryCards(state) {
            state.inventory.isEditable = !state.inventory.isEditable;
        },
        setPeriodTypes: (state, action: PayloadAction<{ periodTypes: IPeriodTypes[] }>) => {
            state.global.periodTypes = action.payload.periodTypes;
        },
        setSelectedItemIds: (state, action: PayloadAction<number[]>) => {
            state.global.selectedItemIds = action.payload;
        },
        setChartSelectedItems: (state, action: PayloadAction<number[]>) => {
            state.global.chartSelectedItemIds = action.payload;
        },
        resetChartSelectedItems: (state) => {
            state.global.chartSelectedItemIds = state.global.items;
        },
        setItemRowSelectedProducts: (state, action: PayloadAction<{ shiftKey: boolean, ctrlKey: boolean, itemId: number | undefined }>) => {
            const { itemId, ctrlKey, shiftKey } = action.payload;

            if (itemId === undefined) return;

            const { chartSelectedItemIds } = state.global;
            const { filteredPlanningGridItemIds } = state.planningGrid;

            const selectedIdsSet = new Set(chartSelectedItemIds);
            const isSelected = selectedIdsSet.has(itemId);

            if (!ctrlKey && !shiftKey) {
                state.global.chartSelectedItemIds = [itemId];
            } else if (ctrlKey && !shiftKey) {
                if (isSelected) {
                    selectedIdsSet.delete(itemId);
                } else {
                    selectedIdsSet.add(itemId);
                }
                state.global.chartSelectedItemIds = [...selectedIdsSet];
            } else if (shiftKey && !ctrlKey) {
                const lastItemId = chartSelectedItemIds[chartSelectedItemIds.length - 1];
                const lastItemIndex = filteredPlanningGridItemIds.indexOf(lastItemId);
                const selectedItemIndex = filteredPlanningGridItemIds.indexOf(itemId);

                const [start, end] = [Math.min(lastItemIndex, selectedItemIndex), Math.max(lastItemIndex, selectedItemIndex)];

                for (let i = start; i <= end; i++) {
                    selectedIdsSet.add(filteredPlanningGridItemIds[i]);
                }

                state.global.chartSelectedItemIds = [...selectedIdsSet];
            }
        },

        updateAttributeOptions: (state, action: PayloadAction<{ selectedAttribute: any }>) => {
            const newAttributeOptions = state.planningGrid.attributeListData
                .map((option) => {
                    if (option.label === action.payload.selectedAttribute.label) {
                        return {
                            label: option.label,
                            value: option.value,
                            checked: action.payload.selectedAttribute.checked,
                        };
                    } else {
                        return {
                            label: option.label,
                            value: option.value,
                            checked: option.checked,
                        };
                    }
                });
            state.planningGrid.attributeListData = newAttributeOptions;
        },

        updateFixedCriteriaOptions: (state, action: PayloadAction<{ selectedFixedCriteria: any }>) => {
            const newFixedCriteriaOptions = state.planningGrid.fixedFilterOptions.map((filter, index) => {
                if (filter.label === action.payload.selectedFixedCriteria.label) {
                    return {
                        label: filter.label,
                        value: filter.filterCriteria,
                        filterCriteria: FilterCriteria[filter.filterCriteria],
                        placeholder: filter.placeholder,
                        filterId: filter.filterId,
                        checked: action.payload.selectedFixedCriteria.checked,
                    };
                } else {
                    return {
                        label: filter.label,
                        value: filter.filterCriteria,
                        filterCriteria: FilterCriteria[filter.filterCriteria],
                        placeholder: filter.placeholder,
                        filterId: filter.filterId,
                        checked: filter.checked,
                    };
                }
            });
            state.planningGrid.fixedFilterOptions = newFixedCriteriaOptions;
        },

        filterBy: (state, action: PayloadAction<{ filterCriteria: FilterCriteria; searchList: SearchList<any>[]; }>) => {
            const { searchList, filterCriteria } = action.payload;
            state.planningGrid.filterData.searchCriteriasData[filterCriteria] = searchList;
        },
        resetFilters: (state) => {
            const criteriasData = state.planningGrid.filterData.searchCriteriasData;
            for (const criteriaKey of Object.keys(criteriasData)) {
                if ((criteriasData as any)[criteriaKey]) { ((criteriasData as any)[criteriaKey] = undefined); }
            }
            const items = state.global.items;
            state.global.selectedItemIds = items;
            state.global.chartSelectedItemIds = items;
            state.planningGrid.filteredPlanningGridItemIds = items;
            state.global.itemCodeSelectedItemIds = items;
            state.planningGrid.redrawFilters = state.planningGrid.redrawFilters + 1;
            state.planningGrid.completeStructureIds = [];
            state.planningGrid.completeStructureObject = {
                parentIds: [],
                selectedId: [],
                childIds: []
            };

            state.planningGrid.isCompleteStructureUsed = false;
        },
        redrawFilters: (state) => {
            state.planningGrid.redrawFilters = state.planningGrid.redrawFilters + 1;
        },
        resetSpecificFilter: (state, action: PayloadAction<{ criteriaKey: string }>) => {
            const criteriasData = state.planningGrid.filterData.searchCriteriasData;
            const criteriaKeySpecific = action.payload.criteriaKey;
            for (const criteriaKey of Object.keys(criteriasData)) {
                if (criteriaKey === criteriaKeySpecific) {
                    delete (criteriasData as any)[criteriaKey];
                }
            }
            const { orderedItemIds: selectedIds, itemCodeIds } = applyCriteriaByFilterCriteria(state);
            state.global.itemCodeSelectedItemIds = itemCodeIds;
            state.global.selectedItemIds = state.global.items;
            state.global.selectedItemIds = selectedIds;
            state.global.chartSelectedItemIds = selectedIds;
            state.planningGrid.redrawFilters = state.planningGrid.redrawFilters + 1;

        },
        applyCriterias: (state) => {
            const { orderedItemIds: selectedIds, itemCodeIds } = applyCriteriaByFilterCriteria(state);
            state.global.itemCodeSelectedItemIds = itemCodeIds;
            state.global.selectedItemIds = selectedIds;
            state.global.chartSelectedItemIds = selectedIds;
            state.planningGrid.filteredPlanningGridItemIds = state.global.selectedItemIds;
        },
        setReduxScenario: (state, action: PayloadAction<{ scenario: IScenario | null }>) => {
            if (action.payload.scenario) { state.global.scenario = action.payload.scenario; }
        },
        applyManualItemFilter: (state, action: PayloadAction<{ itemIds: number[] }>) => {
            const selectedIds = action.payload.itemIds;
            const filteredItemIds = state.global.items.filter((f) => selectedIds.includes(f));

            state.global.selectedItemIds = filteredItemIds;
            state.global.chartSelectedItemIds = filteredItemIds;
            state.planningGrid.filteredPlanningGridItemIds = filteredItemIds;
        },
        lockPlanningGridFromStart: (state, action: PayloadAction<{ itemId: number; productPeriodIndex: number }>) => {
            const { itemId: productId, productPeriodIndex } = action.payload;
            for (let index = 0; index < productPeriodIndex + 1; index++) {
                state.planningGrid.selectedPlanningGrid[productId][index].locked = true;
            }
        },
        unlockPlanningGridFromIndexToEnd: (state, action: PayloadAction<{ itemId: number; productPeriodIndex: number }>) => {
            const { itemId: productId, productPeriodIndex } = action.payload;
            const planningGrids = state.planningGrid.selectedPlanningGrid[productId];

            for (let index = productPeriodIndex; index < planningGrids.length; index++) {
                planningGrids[index].locked = false;
            }
        },
        applyManualMpsChange(state, action: PayloadAction<{ mpsValue: number, index: number }>) {
            const selectedId = state.global.chartSelectedItemIds[0];
            const selectedPlanningGridId = state.planningGrid.selectedPlanningGrid[selectedId][action.payload.index].id;
            const mpsValue = action.payload.mpsValue;
            const result = {
                editedMpsObject: {
                    planningGridId: selectedPlanningGridId,
                    manualMps: mpsValue
                },
                itemId: selectedId
            };
            state.planningGrid.manualMpsObject = result;
        },
        setPlanningGridRowOptions: (state, action: PayloadAction<{ planningGridRowOptions: IPlanningGridFilterOptions[] }>) => {
            state.planningGrid.planningGridRowOptions = action.payload.planningGridRowOptions;
        },
        updatePlanningGridRowOptions: (state, action: PayloadAction<{ selectedPlanningGridRowOption: any }>) => {
            const option = action.payload.selectedPlanningGridRowOption;
            const newPlanningGridRowSelectedOptions: IPlanningGridFilterOptions[] = state.planningGrid.planningGridRowOptions.map((filter, index) => {
                if (filter.id === action.payload.selectedPlanningGridRowOption.value) {
                    return {
                        id: filter.id,
                        columnName: filter.columnName,
                        initialStatus: option.checked,
                        name: filter.name,
                        sequence: filter.sequence,
                        active: filter.active,
                    };
                } else {
                    return {
                        id: filter.id,
                        columnName: filter.columnName,
                        initialStatus: filter.initialStatus,
                        name: filter.name,
                        sequence: filter.sequence,
                        active: filter.active,
                    };
                }
            });
            state.planningGrid.planningGridRowOptions = newPlanningGridRowSelectedOptions;
        },
        setPlanningGridResourcesForItemId(state, action: PayloadAction<{ itemId: number }>) {
            const { itemId } = action.payload;

            const planningGridOperationResourceDict = state.planningGrid.planningGridOperationResource;

            const item = state.planningGrid.selectedPlanningGrid[itemId];

            if (item) {
                for (let i = 0; i < item.length; i++) {
                    if (planningGridOperationResourceDict[itemId][i] === undefined) continue;
                    const periodId = planningGridOperationResourceDict[itemId][i].periodId;

                    const matchingIndex = item.findIndex(entry => entry.periodId === periodId);

                    if (matchingIndex !== -1) {
                        state.planningGrid.selectedPlanningGrid[itemId][matchingIndex].resourceData =
                            state.planningGrid.selectedPlanningGrid[itemId][matchingIndex].resourceData || [];

                        state.planningGrid.selectedPlanningGrid[itemId][matchingIndex].resourceData.push(
                            (planningGridOperationResourceDict as any)[itemId][i]
                        );
                    }
                }
            } else {
                console.error(`Item with itemId: ${itemId} not found in the state.`);
            }
        },
        setCompleteStrucutreIds(state, action: PayloadAction<{ completeStructure: any, itemId: number, filterTypeId: number }>) {
            const { completeStructure, filterTypeId, itemId } = action.payload;
            state.planningGrid.isCompleteStructureUsed = false;
            state.planningGrid.completeStructureIds = [];
            state.planningGrid.completeStructureObject = {
                parentIds: [],
                selectedId: [],
                childIds: []
            };
            const completeStructureObject = state.planningGrid.completeStructureObject;
            completeStructureObject.selectedId = [itemId];

            switch (filterTypeId) {
                case 1:
                case 2:
                    completeStructureObject.parentIds = completeStructure.data;
                    break;
                case 3:
                case 4:
                    completeStructureObject.childIds = completeStructure.data;
                    break;
                default:
                    break;
            }

            const finalArray = [];
            finalArray.push(completeStructureObject.selectedId[0]);

            const sortedParents = completeStructureObject.parentIds.sort((a, b) => a.orderSequence - b.orderSequence);
            sortedParents.forEach(parent => { finalArray.push(parent.itemId); });
            const sortedChildren = completeStructureObject.childIds.sort((a, b) => a.orderSequence - b.orderSequence);
            sortedChildren.forEach(child => { finalArray.push(child.itemId); });

            state.global.selectedItemIds = finalArray;
            state.global.chartSelectedItemIds = [itemId];
            state.planningGrid.filteredPlanningGridItemIds = state.global.selectedItemIds;
            state.planningGrid.completeStructureIds = finalArray;
            state.planningGrid.isCompleteStructureUsed = true;
        },

        resetCompleteStructure(state) {
            state.planningGrid.completeStructureIds = [];
            state.planningGrid.completeStructureObject = {
                parentIds: [],
                selectedId: [],
                childIds: []
            };

            state.planningGrid.isCompleteStructureUsed = false;

            const criteriasData = state.planningGrid.filterData.searchCriteriasData;
            for (const criteriaKey of Object.keys(criteriasData)) {
                if ((criteriasData as any)[criteriaKey]) { ((criteriasData as any)[criteriaKey] = undefined); }
            }
            const { orderedItemIds: selectedIds, itemCodeIds } = applyCriteriaByFilterCriteria(state);
            state.global.itemCodeSelectedItemIds = itemCodeIds;

            state.global.selectedItemIds = selectedIds;
            state.global.chartSelectedItemIds = selectedIds;
            state.planningGrid.redrawFilters = state.planningGrid.redrawFilters + 1;

        },
        setItemProperties: (state, action: PayloadAction<{ key: string, property: any }>) => {
            const { key, property } = action.payload;
            const itemProperties = state.global.itemProperties;
            if (!itemProperties[key]) {
                state.global.itemProperties[key] = property;
            }
        },
        setChartVisible: (state) => {
            state.planningGrid.chartVisible = !state.planningGrid.chartVisible;
        },
        setReduxInventoryHealth: (state, action: PayloadAction<{ inventoryHealth: InventoryHealthData[] }>,) => {
            state.inventory.inventoryHealth = action.payload.inventoryHealth;
        },
        setReduxSelectedResources: (state, action: PayloadAction<{ selectedResources: number[] }>) => {
            state.planningGrid.selectedResources = action.payload.selectedResources;
        }
    },
    extraReducers(builder) {
        builder;
        // .addCase()
    },

});

export const {
    setChartVisible,
    setInventoryFilters,
    setSelectedFilter,
    setScenarioRowsLocked,
    setScenarioCritera,
    setScenarioParameters,
    setPlanningGrid,
    setPeriodTypes,
    setItemRowSelectedProducts,
    setSelectedItemIds,
    setFixesPlanningGridFilters,
    setSelectedAttributes,
    setSelectedFilters,
    updateAttributeOptions,
    updateFixedCriteriaOptions,
    setEditableInputBorderColor,
    applyCriterias,
    filterBy,
    setPlannerById,
    setStockGroupById,
    setPeriods,
    resetFilters,
    setAttributesData,
    resetSpecificFilter,
    resetChartSelectedItems,
    setExpandedPeriods,
    setTagsData,
    lockPlanningGridFromStart,
    unlockPlanningGridFromIndexToEnd,
    setPlanningGridUnitValues,
    setPlanningGridItem,
    setInventoryHealthSelectedPeriod,
    setPeriodOptions,
    setStockStatus,
    setIsEditableInventoryCards,
    setSelectedAxis,
    setChartSelectionItemOrValue,
    setInventoryPolicyEditedObjects,
    applyManualMpsChange,
    setPlanningGridRowOptions,
    updatePlanningGridRowOptions,
    applyManualItemFilter,
    setCompleteStrucutreIds,
    resetCompleteStructure,
    setInventoryHealthStockType,
    setItemsPlanning,
    setSelectedOccupationProperty,
    setPlanningGridOperationResourcesRedux,
    setCompanyById,
    setReduxScenario,
    setItemProperties,
    setFiniteButtonLoading,
    setInventoryHealthLoadingState,
    setReduxInventoryHealth,
    redrawFilters,
    recalculateItemidsByStockStatus,
    setChartSelectedItems,
    setReduxSelectedResources
} = filterSlice.actions;

export default filterSlice.reducer;