import { Trans } from '@lingui/macro';
import {
    Box,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material';
import { TextOperator } from '@nplan';
import React from 'react';

interface TextFilterProps {
    column: Column;
    onFilterColumnSignalChange: (
        column: Column,
        signal: TextOperator
    ) => void;
    onFilterColumnValueChange: (column: Column, value: any) => void;
}

const TextFilter: React.FC<TextFilterProps> = ({
    column,
    onFilterColumnSignalChange,
    onFilterColumnValueChange,
}) => {
    const handleFilterValueChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const target = event.target as HTMLInputElement;
        onFilterColumnValueChange(column, target.value);
    };
    const handleFilterSignalChange = (
        event: SelectChangeEvent<string>
    ) => {
        const target = event.target as HTMLSelectElement;
        onFilterColumnSignalChange(column, target.value as TextOperator);
    };
    return (
        <Box display={'flex'} flexDirection={'row'} minWidth={90} gap={1}>
            <Select
                sx={{
                    height: 32,
                    minWidth: 40,
                    maxWidth: 40,
                }}
                disableUnderline
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                }}
                name={`${column.field}-signal`}
                defaultValue={TextOperator.Equal}
                renderValue={(value) => value}
                variant="standard"
                onChange={handleFilterSignalChange}
                value={column.filterSignal ?? TextOperator.Equal}
            >
                <MenuItem value={TextOperator.Equal}>
                    <Trans>Igual</Trans>
                </MenuItem>
                <MenuItem value={TextOperator.NotEqual}>
                    <Trans>Diferente</Trans>
                </MenuItem>
                <MenuItem value={TextOperator.Contains}>
                    <Trans>Contém</Trans>
                </MenuItem>
                <MenuItem value={TextOperator.NotContains}>
                    <Trans>Não contém</Trans>
                </MenuItem>
                <MenuItem value={TextOperator.StartsWith}>
                    <Trans>Começa com</Trans>
                </MenuItem>
                <MenuItem value={TextOperator.EndsWith}>
                    <Trans>Termina com</Trans>
                </MenuItem>
            </Select>
            <TextField
                sx={{
                    width: 'calc(100% - 40px)',
                    height: 40,
                    minWidth: 10,
                }}
                onChange={handleFilterValueChange}
                type={'text'}
                name={`${column.field}-value`}
                variant="standard"
                value={column.filterValue ?? ''}
            />
        </Box>
    );
};

const MemorizedTextFilter = React.memo(TextFilter);
export default MemorizedTextFilter;
