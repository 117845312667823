import { CSSProperties, memo } from 'react';
import { useTheme } from '@mui/material';
import { RowHover } from './styled/RowHover';
import { RowCell } from '.';



interface IRowProps {
    style: CSSProperties;
    row: any;
    columns: Column[];
}

const Row: React.FC<IRowProps> = ({
    style,
    row,
    columns,
}: IRowProps): JSX.Element => {
    const theme = useTheme();
    return (
        <RowHover style={style}>
            {columns.map((column) => (
                <RowCell
                    style={{
                        gridColumn: column.index,
                        gridRow: row.index + 1,
                        position: 'sticky',
                        left: column.fixed ? column.init : 'unset',
                        zIndex: column.fixed ? 3 : 2,
                        backgroundColor:
                            row.index % 2 === 0
                                ? theme.tableRow.background.even
                                : theme.tableRow.background.odd,
                    }}
                    key={`${column.index  }-${  row.index}`}
                    row={row}
                    column={column}
                />
            ))}
        </RowHover>
    );
};
const MemorizedRow = memo(Row);
export { MemorizedRow as Row };
