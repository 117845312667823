
import { Box } from "@mui/material";
import Tippy from "@tippyjs/react";
import { ReactNode } from "react";

interface ToolTipProps {
    tooltipText: string;
    color: string;
    children: ReactNode
}

export function TooltipComponentNodeChildren({
    tooltipText,
    color,
    children
}: ToolTipProps) {
    return (
        <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'space-between', alignItems: 'center', height: '100%' }}>
            <Tippy
                delay={[200, 50]}
                animation="fade"
                offset={[10, 10]}
                content={
                    <Box
                        sx={{
                            border: '1px solid',
                            borderColor: '#333',
                            borderRadius: '1rem',
                            fontWeight: 'semibold',
                            fontSize: '0.75rem',
                            padding: '8px',
                            width: 'fit-content',
                            color: 'black',
                            backgroundColor: '#fff'
                        }}
                    >
                        {tooltipText}
                    </Box>
                }
            >
                <>
                    {children}
                </>
            </Tippy>


        </Box>
    );
}
