import { IUserContextProps, UserContext } from '../context';
import { useContext } from 'react';

export const useUserContext = (): IUserContextProps => {
    const context = useContext<IUserContextProps | null>(UserContext);
    if (context === null) {
        throw new Error('Component must be wrapped with <UserProvider>');
    }
    return context;
};
