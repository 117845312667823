export function resizeImage(image: string, newWidth: number, newHeight: number): Promise<string> {
    return new Promise((resolve) => {
        // Cria um objeto do tipo Canvas e define as dimensões da imagem redimensionada
        const canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;

        // Cria um objeto do tipo Image e define a função a ser executada quando a imagem for carregada
        const img = new Image();
        img.onload = () => {
            // Obtém o contexto 2D do Canvas e desenha a imagem redimensionada nele
            const ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.drawImage(img, 0, 0, newWidth, newHeight);

                // Converte o Canvas de volta para o formato de imagem e retorna o resultado
                const dataUrl = canvas.toDataURL();
                resolve(dataUrl);
            }
        };

        // Define a URL da imagem e inicia o carregamento
        img.src = image;
    });
}

export const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            if (reader.result) {
                resolve(reader.result.toString());
            }
        };
        reader.onerror = (error) => {
            reject(error);
        };
    });
};
