import {
    Box,
    FormControl,
    MenuItem,
    Select,
    Typography,
    useTheme,
} from "@mui/material";
import React from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { TiArrowUnsorted } from "react-icons/ti";
import { setChartSelectionItemOrValue } from "redux/Filters/reducer";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { IGenericOptions } from "types/Generics";

interface ChartValueItemSelectorProps {
    selectOptions: IGenericOptions[]
}
const ChartValueItemSelector: React.FC<ChartValueItemSelectorProps> = ({ selectOptions }: ChartValueItemSelectorProps) => {
    const theme = useTheme()

    const selectedOption = useAppSelector(state => state.filter.inventory.inventoryHealthChartSelection)
    const dispatch = useAppDispatch()
    return (
        <FormControl fullWidth>
            <Select
                labelId="period-select-label"
                id="company-select"
                size="small"
                variant="outlined"
                sx={{ width: 'fit-content' }}
                inputProps={{
                    sx: {
                        paddingTop: '0.3rem',
                        paddingBottom: '0.1rem',
                        backgroundColor: theme.palette.background.default

                    }
                }}
                value={selectedOption.id}
                onChange={(e) => {
                    const selectedOption = selectOptions.find((filter) => filter.id === e.target.value);
                    if (selectedOption)
                        dispatch(setChartSelectionItemOrValue({ option: selectedOption }));
                }}
                IconComponent={TiArrowUnsorted}
            >
                {selectOptions.map((option) => (
                    <MenuItem key={option.id} value={option.id as number}>
                        <Box display="flex" alignItems="center" gap={'0.3rem'}>
                            <Typography variant="caption">{option.name}</Typography>
                            {selectedOption.id === option.id && (
                                <AiOutlineCheck fontSize="small" />
                            )}
                        </Box>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default ChartValueItemSelector;
