import { t } from '@lingui/macro';
import { Box, Typography, useTheme } from '@mui/material';
import Tippy from '@tippyjs/react';
import React, { useRef, useState } from 'react';
import { Icons } from 'utils';
import { v4 } from 'uuid';

interface ToolTipProps {
    tooltipText: string | undefined;
    itemCode?: string | null;
}

export function InfoTooltipWithCopyText({ tooltipText, itemCode }: ToolTipProps) {
    const theme = useTheme();

    const textRef = useRef<HTMLInputElement>(null);
    const [tooltipVisible, setTooltipVisible] = useState(false);

    function copyToClipboard() {
        if (textRef.current) {
            textRef.current.select();
            document.execCommand('copy');
            window.getSelection()?.removeAllRanges();
        }
    }

    const transformString = (inputString: string): React.ReactElement[] => {
        if(tooltipVisible === false) return [];
        const parts = inputString.split(',');
        const result = parts.map((part, index) => {
            part = part.trim();
            let hasP, hasB = false;
            if(part.startsWith('<p>')) hasP = true;
            if(part.includes('<b>')) hasB = true;
            part = part.replace('<p>', '').replace('</p>', '').replace('<b>', '').replace('</b>', '');
            return (
                <div key={index}>
                    {hasP && (
                        <p>
                            {hasB ? (
                                <b>{part}</b>
                            ) : (
                                part
                            )}
                        </p>
                    )}
                    {!hasP && !hasB && (
                        part
                    )}
                </div>
            );
        });
    
        return result as React.ReactElement[];
    };

    return (
        <Box
            sx={{
                display: 'flex',
                gap: '1rem',
                justifyContent: 'space-between',
                alignItems: 'center',
                // height: "20px",
            }}
        >
            <Tippy
                delay={[1500, 50]} // 3 seconds delay
                animation="fade"
                offset={[500, 0]}
                maxWidth={1000}
                allowHTML
                interactive={true}
                interactiveBorder={30}
                placement="right-start"
                content={
                    <Box
                        sx={{
                            background: theme.palette.background.default,
                            border: '1px solid #333',
                            borderRadius: '10px',
                            fontSize: '0.8rem',
                            padding: '4px',
                            width: 'fit-content',
                            '& p': {
                                margin: '0.4rem 0',
                            },
                            '& > div': {
                                marginBottom: '0rem', // Adjust this value to reduce the gap
                                padding: '0.3rem',
                                lineHeight: 1.2,
                                whiteSpace: 'nowrap'
                            }
                        }}
                    >
                        {tooltipText === null ? (
                            t`Não existem anotações!`
                        ) : (
                            <div>
                                <div>{transformString(tooltipText ?? '')}</div>
                                <input
                                    ref={textRef}
                                    type="text"
                                    value={itemCode || tooltipText}
                                    style={{ position: 'absolute', left: '-9999px' }}
                                    readOnly
                                />
                            </div>
                        )}
                    </Box>
                }
                onShow={() => setTooltipVisible(true)}
                onHidden={() => setTooltipVisible(true)}
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, tooltipVisible ? 10 : 0], // Adjust the offset based on visibility
                            },
                        },
                    ],
                }}
            >
                {itemCode ? (
                    <Box sx={{
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap', // Use 'pre-wrap' for line breaks
                    }}>
                        <Typography
                            sx={{
                                zIndex: 40,
                                borderRadius: '10px',
                                fontWeight: 'bold',
                                border: '1px solid white',
                                fontSize: '0.9rem',
                                padding: '5px',
                                textAlign: 'center',
                                wordWrap: 'break-word',
                                whiteSpace: 'nowrap', // Use 'pre-wrap' for line breaks
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                maxWidth: '350px',
                                WebkitLineClamp: 1,
                                '&:hover': {
                                    cursor: 'pointer',
                                },
                            }}
                            onClick={copyToClipboard}
                        >
                            {itemCode}
                        </Typography>
                    </Box>

                ) : (
                    <Typography
                        sx={{
                            display: 'flex',
                            fontSize: '1rem',
                            padding: '5px',
                            textAlign: 'center',
                            wordWrap: 'break-word',
                            whiteSpace: 'pre-wrap',
                            '&:hover': {
                                cursor: 'help',
                            },
                        }}
                    >
                        <Icons.Info />
                    </Typography>
                )}
            </Tippy>
        </Box>
    );

}
