import React, { ReactNode } from 'react';
import { StyledSidebarContainer } from '../styles/StyledSidebarContainer';

interface SidebarContainerProps {
    children: ReactNode;
}

const SidebarContainer: React.FC<SidebarContainerProps> = ({
    children,
}) => {
    return (
        <StyledSidebarContainer>
            {children}
        </StyledSidebarContainer>
    );
};

export default SidebarContainer;
