import { IconType } from 'react-icons';
import { AiOutlineClear, AiOutlineFullscreen, AiOutlineFullscreenExit } from 'react-icons/ai';
import { BsDatabaseUp } from 'react-icons/bs'; // Replace 'package-name' with the actual package name
import { CiViewColumn } from 'react-icons/ci';
import { FaClone, FaFilter, FaRegCalendarAlt, FaRegEye, FaRegSave, FaSearch, } from 'react-icons/fa';
import { FaFilterCircleXmark } from 'react-icons/fa6';
import { FiPauseCircle, FiPlayCircle, FiStopCircle } from 'react-icons/fi';
import { GrAnalytics, GrDocumentPerformance, GrEdit, GrGroup, GrHome, GrKey, GrLogout, GrPlan, GrRefresh, GrTable, GrUser } from 'react-icons/gr';
import { IoMdInformationCircleOutline, IoMdMailUnread } from 'react-icons/io';
import { IoAnalyticsOutline, IoCheckmarkDone, IoDuplicateOutline, IoPersonAddOutline, IoResize, IoStatsChart } from 'react-icons/io5';
import { LuForklift } from 'react-icons/lu';
import { MdAddBox, MdDarkMode, MdDelete, MdDeleteOutline, MdHistory, MdLanguage, MdLightMode, MdLock, MdLockOpen, MdOutlineAnalytics, MdOutlineCancel, MdOutlineFactory, MdOutlineGroupAdd, MdOutlineMarkEmailRead, MdPerson, MdRadioButtonChecked, MdSettings } from 'react-icons/md';
import { PiBinoculars } from 'react-icons/pi';
import { SiMicrosoftexcel } from 'react-icons/si';
import { TbArrowBigDownFilled, TbArrowBigDownLinesFilled, TbArrowBigUpFilled, TbArrowBigUpLinesFilled, TbChartBar, TbChartBarOff, TbDeviceDesktopAnalytics, TbTableImport } from 'react-icons/tb';
import { TiArrowSortedDown, TiArrowSortedUp, TiLockClosed, TiLockOpen } from 'react-icons/ti';

// Enum for icon keys
enum IconKey {
    Settings = 'Settings',
    Person = 'Person',
    Language = 'Language',
    User = 'User',
    Table = 'Table',
    LogOff = 'LogOff',
    Group = 'Group',
    Analytics = 'Analytics',
    Dark = 'Dark',
    Light = 'Light',
    Home = 'Home',
    Cenario = 'Cenario',
    Analise = 'Analise',
    PoliticaEstoque = 'PoliticaEstoque',
    Planejamento = 'Planejamento',
    PBI = 'PBI',
    Superset = 'Superset',
    Relatorios = 'Relatorios',
    Edit = 'Edit',
    Delete = 'Delete',
    Key = 'Key',
    Refresh = 'Refresh',
    Column = 'Column',
    Button = 'Button',
    Add = 'Add',
    AddTable = 'AddTable',
    AddGroup = 'AddGroup',
    AddUser = 'AddUser',
    Duplicate = 'Duplicate',
    Locked = 'Locked',
    Unlocked = 'Unlocked',
    DeleteFilled = 'DeleteFilled',
    View = 'View',
    Cancel = 'Cancel',
    Calendar = 'Calendar',
    Save = 'Save',
    EmailRead = 'EmailRead',
    Check = 'Check',
    Unread = 'Unread',
    Clear = 'Clear',
    Filter = 'Filter',
    XFilter = 'RemoveFilter',
    Resize = 'Resize',
    SortDesc = 'SortDesc',
    SortAsc = 'SortAsc',
    TiLockClosed = 'TiLockClosed',
    TiLockOpen = 'TiLockOpen',
    FullScreen = 'FullScreen',
    FullScreenExit = 'FullScreenExit',
    Play = 'Play',
    Pause = 'Pause',
    Stop = 'Stop',
    Occupation = 'Occupation',
    DatabaseUpdate = 'DatabaseUpdate',
    History = 'History',
    Excel = 'Excel',
    ArrowDown = 'ArrowDown',
    DoubleArrowDown = 'DoubleArrowDown',
    ArrowUp = 'ArrowUp',
    DoubleArrowUp = 'DoubleArrowUp',
    Search = 'Search',
    Info = 'Info',
    Clone = 'Clone',
    ChartOn = 'ChartOn',
    ChartOff = 'ChartOff',
    Factory = 'Factory',
}

type IconsMap = { [key in IconKey]: IconType };

export const Icons: IconsMap = {
    Settings: MdSettings,
    Person: MdPerson,
    Language: MdLanguage,
    User: GrUser,
    Table: GrTable,
    LogOff: GrLogout,
    Group: GrGroup,
    Analytics: IoAnalyticsOutline,
    Dark: MdDarkMode,
    Light: MdLightMode,
    Home: GrHome,
    Cenario: GrDocumentPerformance,
    Analise: PiBinoculars,
    PoliticaEstoque: LuForklift,
    Planejamento: GrPlan,
    PBI: TbDeviceDesktopAnalytics,
    Superset: MdOutlineAnalytics,
    Relatorios: GrAnalytics,
    Edit: GrEdit,
    Delete: MdDeleteOutline,
    Key: GrKey,
    Refresh: GrRefresh,
    AddUser: IoPersonAddOutline,
    Column: CiViewColumn,
    Button: MdRadioButtonChecked,
    Add: MdAddBox,
    AddTable: TbTableImport,
    AddGroup: MdOutlineGroupAdd,
    Duplicate: IoDuplicateOutline,
    Locked: MdLock,
    Unlocked: MdLockOpen,
    DeleteFilled: MdDelete,
    View: FaRegEye,
    Cancel: MdOutlineCancel,
    Calendar: FaRegCalendarAlt,
    Save: FaRegSave,
    EmailRead: MdOutlineMarkEmailRead,
    Check: IoCheckmarkDone,
    Unread: IoMdMailUnread,
    Clear: AiOutlineClear,
    Filter: FaFilter,
    RemoveFilter: FaFilterCircleXmark,
    Resize: IoResize,
    SortAsc: TiArrowSortedUp,
    SortDesc: TiArrowSortedDown,
    TiLockClosed: TiLockClosed,
    TiLockOpen: TiLockOpen,
    FullScreen: AiOutlineFullscreen,
    FullScreenExit: AiOutlineFullscreenExit,
    Play: FiPlayCircle,
    Pause: FiPauseCircle,
    Stop: FiStopCircle,
    Occupation: IoStatsChart,
    DatabaseUpdate: BsDatabaseUp,
    History: MdHistory,
    Excel: SiMicrosoftexcel,
    ArrowDown: TbArrowBigDownFilled,
    ArrowUp: TbArrowBigUpFilled,
    DoubleArrowDown: TbArrowBigDownLinesFilled,
    DoubleArrowUp: TbArrowBigUpLinesFilled,
    Search: FaSearch,
    Info: IoMdInformationCircleOutline,
    Clone: FaClone,
    ChartOff: TbChartBarOff,
    ChartOn: TbChartBar,
    Factory: MdOutlineFactory 
};
