import { Box, Typography } from "@mui/material"
import { useMemo, useState } from "react"
import { useAppSelector } from "redux/hooks"
import { ITag } from "types/data/ITags"
import ImageCropperModal from "../components/ModalImage"

interface imageContainerProps {
    imageSrc: string
    tag01: number | null | undefined
    tag02: number | null | undefined
    tag03: number | null | undefined
    itemCode: string | null | undefined
}
export const ImageContainer = ({ imageSrc, tag01, tag02, tag03, itemCode }: imageContainerProps) => {
    const tagsBundle = useAppSelector(state => state.filter.planningGrid.tags)

    const selectedTags = useMemo(() => {
        const tagIds: { [key: string]: number | null | undefined }[] = [
            { 'Tag01': tag01 && tag01 },
            { 'Tag02': tag02 && tag02 },
            { 'Tag03': tag03 && tag03 },
        ];
        const selectedTags: { [key: string]: ITag | null } = {};

        tagIds.forEach((tagIdObject) => {
            const tagCollectionKey = Object.keys(tagIdObject)[0]; // Get the tag collection key
            const tagId = tagIdObject[tagCollectionKey];

            if (tagId !== null && tagId !== undefined) {
                // Check if the tag collection key exists in the tagsBundle
                if ((tagsBundle as any)[tagCollectionKey]) {
                    // Find the tag with the specified ID in the collection
                    const tag = (tagsBundle as any)[tagCollectionKey][tagId];
                    selectedTags[tagCollectionKey] = tag;
                } else {
                    selectedTags[tagCollectionKey] = null;
                }
            } else {
                selectedTags[tagCollectionKey] = null;
            }
        });
        return selectedTags;
    }, [tag01, tag02, tag03, tagsBundle])


    const [isModalOpen, setIsModalOpen] = useState(false);


    const handleDoubleClick = () => {
        setIsModalOpen(true);
    };
    return (
        <>
            <Box sx={{
                borderRadius: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifySelf: 'flex-start',
                position: 'relative', // Set position relative for the container
                width: '80px',
                height: '120px',
                justifyContent: 'center'
            }}>
                <Box onDoubleClick={handleDoubleClick} sx={{
                    // zIndex: 999,
                    width: '100%',
                    ':hover': { cursor: 'pointer' }
                }} >
                    <img src={imageSrc} alt="itemImage" style={{
                        width: '100%',
                        height: '100%', borderRadius: '20px', backgroundColor: 'transparent'
                    }} />
                </Box>
                {
                    selectedTags['Tag01'] &&
                    <Box sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        backgroundColor: selectedTags['Tag01'].backgroundColor,
                        padding: '5px',
                        borderTopRightRadius: '20px',
                        borderBottomLeftRadius: '10px',
                        zIndex: 999,
                        color: '#333',
                        opacity: '0.8',
                        minWidth: '30px',
                        ':hover': {
                            cursor: 'help'
                        }

                    }}
                        title={selectedTags['Tag01'].description}
                    >
                        <Typography style={{ textAlign: 'center', margin: 0, fontSize: '12px', fontWeight: 'bold' }}>{selectedTags['Tag01'].name}</Typography>
                    </Box>
                }

                {/* Information Tag */}
                {selectedTags['Tag02'] &&
                    <Box sx={{
                        position: 'absolute',
                        top: '40%',
                        left: 0,
                        zIndex: 999,
                        right: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '25px',
                        overflow: 'hidden',
                        textAlign: 'center',
                        backgroundColor: selectedTags['Tag02'].backgroundColor,
                        opacity: '1',
                        color: '#333',
                        ':hover': {
                            cursor: 'help'
                        }

                    }}
                        title={selectedTags['Tag02'].description}

                    >
                        <Typography style={{ textAlign: 'center', margin: 0, fontSize: '12px' }}>{selectedTags['Tag02'].name}</Typography>
                    </Box>

                }

                {selectedTags['Tag03'] &&
                    <Box sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        backgroundColor: selectedTags['Tag03'].backgroundColor,
                        zIndex: 999,
                        padding: '5px',
                        borderTopRightRadius: '20px',
                        borderBottomLeftRadius: '10px',
                        color: '#333',
                        opacity: '0.8',
                        minWidth: '45px',
                        ':hover': {
                            cursor: 'help'
                        }

                    }}
                        title={selectedTags['Tag03'].description}
                    >
                        <Typography style={{ textAlign: 'center', margin: 0, fontSize: '12px', fontWeight: 'bold' }}>{selectedTags['Tag03'].name}</Typography>

                    </Box>
                }
            </Box>
            <ImageCropperModal open={isModalOpen} onClose={() => setIsModalOpen(false)} itemCode={itemCode} />

        </>
    )
}