import { Box, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { Icons } from 'utils/icons';
import { t } from '@lingui/macro';

export interface IContextMenu {
    position: MenuPosition | null;
    onClose: () => void;
    onRefreshDataAsync: () => Promise<void>;
    onAddUser: (event: number) => void;
}

export const ContextMenu: React.FC<IContextMenu> = ({
    onClose,
    position,
    onRefreshDataAsync,
    onAddUser,
}): JSX.Element => {
    const handleRefreshDataAsync = async () => {
        await onRefreshDataAsync();
        onClose();
    };
    const handleAdduser = () => {
        onAddUser(0);
        onClose();
    };
    return (
        <Menu
            anchorReference="anchorPosition"
            anchorPosition={
                position ? { top: position.y, left: position.x } : undefined
            }
            open={Boolean(position)}
            onClose={onClose}
            keepMounted
        >
            <Box>
                <MenuItem onClick={handleRefreshDataAsync}>
                    <ListItemIcon>
                        <Icons.Refresh size={20} />
                    </ListItemIcon>
                    {t`Atualizar dados`}
                </MenuItem>
                <MenuItem onClick={handleAdduser}>
                    <ListItemIcon>
                        <Icons.AddUser size={20} />
                    </ListItemIcon>
                    {t`Adicionar Usuário`}
                </MenuItem>
            </Box>
        </Menu>
    );
};
