import { getAsync, putAsync } from '@nplan';
import { measureText } from './measureText';

export const adjustColumnsWidth = async (
    id: number,
): Promise<void> => {
    const [resColumns, resData, resFk] = await Promise.all([
        getAsync<Column[]>(`columns/table/${id}`),
        getAsync<any[]>(`rows/table/${id}`),
        getAsync<ForeignFields>(`foreign-keys/table/${id}`),
    ]);
    if (
        resData.type !== 'success' ||
        resFk.type !== 'success' ||
        resColumns.type !== 'success' ||
        !resColumns.data ||
        !resData.data ||
        !resFk.data
    ) {
        return;
    }

    const data = resData.data,
        fk = resFk.data,
        columns = resColumns.data;

    columns.map((col) => {
        const text = col.header;
        col.width = measureText(text, '19px Roboto') + 33;
        if (col.width < 90) col.width = 90;
    });

    data.map((row) => {
        columns
            .filter((a) => !a.hidden)
            .map((col) => {
                let text = row[col.field];
                try {
                    if (
                        col.foreignKey &&
                        text != null
                    ) {
                        text = fk[col.id][text];
                    }
                    if (text != null) {
                        const width = measureText(text, '19px Roboto') + 30;
                        if (col.width < width) {
                            col.width = width;
                        }
                    }
                } catch { /* empty */ }
            });
    });
    await putAsync(`/columns/updatewidth`, columns);
};
