import { useCallback, useState, useEffect } from 'react';
import { getAsync, useHubContext } from '@nplan';


interface IUseRoleDataReturn {
    roles: Role[];
    loading: boolean;
    handleRefreshDataAsync: () => Promise<void>;
}
export const useRoleData = (): IUseRoleDataReturn => {
    const [roles, setRoles] = useState<Role[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const { hubConnection } = useHubContext();
    const handleRefreshDataAsync = useCallback(async (): Promise<void> => {
        const resRoles = await getAsync<Role[]>('roles');
        if (resRoles.type === 'success' && resRoles.data) {
            setRoles(resRoles.data);
        }
    }, []);
    useEffect(() => {
        setLoading(true);
        handleRefreshDataAsync().then(() => setLoading(false));
    }, [handleRefreshDataAsync]);

    useEffect(() => {
        if (hubConnection) {
            hubConnection.on('RefreshRoles', handleRefreshDataAsync);
            return () => hubConnection.off('RefreshRoles', handleRefreshDataAsync);
        }
    }, [hubConnection, handleRefreshDataAsync]);
    return {
        roles, 
        loading,
        handleRefreshDataAsync
    };
};