import { useHubContext, getAsync } from '@nplan';
import { useCallback, useState, useEffect } from 'react';

interface IUseTableDataReturn {
    tables: Table[];
    groups: Group[];
    loadingTables: boolean;
    handleRefreshTablesAsync: () => Promise<void>;
}
export const useTableData = (): IUseTableDataReturn => {

    const [tables, setTables] = useState<Table[]>([]);
    const [groups, setGroups] = useState<Group[]>([]);
    const [loadingTables, setLoadingTables] = useState<boolean>(true);
    const { hubConnection } = useHubContext();

    const handleRefreshTablesAsync = useCallback(async (): Promise<void> => {
        const [resTables, resGroups] = await Promise.all([
            getAsync<Table[]>('tables'),
            getAsync<Group[]>('groups'),
        ]);
        const tablesData = resTables.data;
        const groupsData = resGroups.data;
        if(tablesData && groupsData) {
            setTables(tablesData.map(table => {
                const group = groupsData.find(group => group.id === table.groupId);
                return {
                    ...table,
                    groupName: group ? group.name : ''
                };
            }));
            setGroups(groupsData);
        }
    }, []);
    
    useEffect(() => {
        setLoadingTables(true);
        handleRefreshTablesAsync().then(() =>  setLoadingTables(false));
    }, [handleRefreshTablesAsync]);

    useEffect(() => {
        if (hubConnection) {
            hubConnection.on('RefreshTables', handleRefreshTablesAsync);
            return () => hubConnection.off('RefreshTables', handleRefreshTablesAsync);
        }
    },[hubConnection, handleRefreshTablesAsync]);

    return {
        tables,
        groups,
        loadingTables,
        handleRefreshTablesAsync,
    };
};