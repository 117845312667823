import { Box, Popover } from '@mui/material';
import { memo, useEffect } from 'react';
import { FC } from 'react';
import { useLogData } from './hooks/useLogData';
import { Body } from './components/Body';
import { Loading } from './components/Loading';
import { NoData } from './components/NoData';
import { Header } from './components/Header';
interface ILogProps {
    open: boolean;
    onClose: () => void;
    anchorEl: HTMLElement | null;
}

const Log: FC<ILogProps> = ({ open, onClose, anchorEl }): JSX.Element => {
    const { handleRefreshLogs, loading, logs } = useLogData();
    useEffect(() => {
        if (open) handleRefreshLogs();
    }, [open, handleRefreshLogs]);
    return (
        <Popover
            open={open}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            anchorEl={anchorEl}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <Box
                display={'flex'}
                flexDirection={'column'}
                width={'100%'}
                height={'100%'}
            >
                <Header />
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    width={'100%'}
                    height={'100%'}
                    padding={2}
                >
                    {(loading || logs === null) && <Loading />}
                    {!loading && logs && logs.length > 0 && (
                        <Body logs={logs} />
                    )}
                    {!loading && logs && logs.length === 0 && <NoData />}
                </Box>
            </Box>
        </Popover>
    );
};

const MemoizedLog = memo(Log);
export { MemoizedLog as Log };
