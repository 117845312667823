import { Button, Switch, TableBody, TableCell, TableRow } from '@mui/material';
import { Trans } from '@lingui/macro';
import { Icons } from 'utils/icons';
import React from 'react';
import { useRoleActions } from './hooks/';

interface IBodyProps {
    roles: Role[];
    onEditRole: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onEditTablePermission: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onEditMenuPermission: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Body: React.FC<IBodyProps> = ({
    roles,
    onEditRole,
    onEditTablePermission,
    onEditMenuPermission,
}): JSX.Element => {
    const { handleDeleteRoleAsync } = useRoleActions();
    return (
        <TableBody>
            {roles.map((role) => (
                <TableRow hover key={role.id}>
                    <TableCell>{role.name}</TableCell>
                    <TableCell>
                        <Switch checked={role.admin} disabled />
                    </TableCell>
                    <TableCell>
                        <Button
                            variant="outlined"
                            startIcon={<Icons.Edit />}
                            data-id={role.id}
                            onClick={onEditRole}
                        >
                            <Trans>Editar</Trans>
                        </Button>
                    </TableCell>
                    <TableCell>
                        <Button
                            variant="outlined"
                            startIcon={<Icons.Edit />}
                            data-id={role.id}
                            onClick={onEditTablePermission}
                        >
                            <Trans>Permissões de Tabelas</Trans>
                        </Button>
                    </TableCell>
                    <TableCell>
                        <Button
                            variant="outlined"
                            startIcon={<Icons.Edit />}
                            data-id={role.id}
                            onClick={onEditMenuPermission}
                        >
                            <Trans>Permissões de Menus</Trans>
                        </Button>
                    </TableCell>
                    <TableCell>
                        <Button
                            variant="outlined"
                            startIcon={<Icons.Delete />}
                            data-id={role.id}
                            data-name={role.name}
                            onClick={handleDeleteRoleAsync}
                            color="error"
                        >
                            <Trans>Excluir</Trans>
                        </Button>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    );
};
