import { useEffect, useState } from 'react';
import { getAsync, putAsync } from '@nplan';

interface IUseProfileDataReturn {
    profileData: Profile;
    handleProfilePicChange: (base64: string) => void;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSaveProfileAsync: () => void;
}
export const useProfileData = (
    open: boolean, 
    onClose: () => void,
    onRefreshDataProfile: () => Promise<boolean>
): IUseProfileDataReturn => {
    const [profileData, setProfileData] = useState<Profile>({} as Profile);

    const handleProfilePicChange = (base64: string) => {
        setProfileData(prev => ({
            ...prev,
            avatar: base64
        })
        );
    };
    
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setProfileData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSaveProfileAsync = async () => {
        const response = await putAsync<Profile>('/profile', profileData);
        if(response.type === 'success'){
            onRefreshDataProfile();
            onClose();
        }
    };
    useEffect(() => {
        if(!open) return;
        (async () => {
            const response = await getAsync<Profile>('/profile');
            if (response.data) setProfileData(response.data);
        })();
    }, [open]);
    return {
        profileData,
        handleProfilePicChange,
        handleInputChange,
        handleSaveProfileAsync
    };
};