import { Box, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Icons, postAsync, adjustColumnsWidth } from '@nplan';
import { t } from '@lingui/macro';

export interface IContextMenuProps {
    position: MenuPosition | null;
    onClose: () => void;
    onRefreshDataAsync: () => Promise<void>;
}
export const ContextMenu: React.FC<IContextMenuProps> = ({
    onClose,
    position,
    onRefreshDataAsync,
}): JSX.Element => {
    const { id } = useParams();
    const navigate = useNavigate();
    const handleRefreshData = () => {
        onRefreshDataAsync();
        onClose();
    };
    const handleAdjustWidth = async () => {
        document.body.style.cursor = 'wait';
        await adjustColumnsWidth(Number(id!));
        await onRefreshDataAsync();
        onClose();
        document.body.style.cursor = 'default';
    };
    const handleSyncColumns = async () => {
        document.body.style.cursor = 'wait';
        await postAsync(`tables/${id}/sync-columns`);
        onClose();
        document.body.style.cursor = 'default';
    };
    const handleViewData = () => {
        navigate(`/tables/${id}/data`);
    };

    return (
        <Menu
            anchorReference="anchorPosition"
            anchorPosition={
                position
                    ? { top: position.y, left: position.x }
                    : undefined
            }
            open={Boolean(position)}
            onClose={onClose}
        >
            <Box>
                <MenuItem onClick={handleRefreshData}>
                    <ListItemIcon>
                        <Icons.Refresh size={20} />
                    </ListItemIcon>
                    {t`Atualizar dados`}
                </MenuItem>
                <MenuItem onClick={handleAdjustWidth}>
                    <ListItemIcon>
                        <Icons.Resize size={20} />
                    </ListItemIcon>
                    {t`Ajuste automático de largura`}
                </MenuItem>
                <MenuItem onClick={handleSyncColumns}>
                    <ListItemIcon>
                        <Icons.DatabaseUpdate size={20} />
                    </ListItemIcon>
                    {t`Sincronizar colunas com o banco de dados`}
                </MenuItem>
                <MenuItem onClick={handleViewData}>
                    <ListItemIcon>
                        <Icons.View size={20} />
                    </ListItemIcon>
                    {t`Visualizar dados`}
                </MenuItem>
            </Box>
        </Menu>
    );
};
