import React, { useCallback } from 'react';
import LoginIcon from 'assets/images/microsoft/microsoft.png';
import { 
    Box, 
    Button, 
    Typography 
} from '@mui/material';
import { 
    postAsync, 
    useUserContext 
} from '@nplan';
import { PublicClientApplication } from '@azure/msal-browser';

interface IMicrosoftProps {
    onChangeLoading: (value: boolean) => void;
}
const msalConfig = {
    auth: {
        clientId: '2a0840d4-b640-44d9-8e20-f09be9da8464',
        authority: 'https://login.microsoftonline.com/common',
        redirectUri: '/',
    },
};
const myMSALObj = new PublicClientApplication(msalConfig);
myMSALObj.initialize();

const loginRequest = {
    scopes: ['openid', 'profile', 'User.Read'],
};

export const Microsoft: React.FC<IMicrosoftProps> = ({ onChangeLoading }): JSX.Element => {
    const { onLoginAsync } = useUserContext();

    const handleLogin = useCallback(async (token: string) => {
        onChangeLoading(true);
        const res = await postAsync<{ token: string }, string>('auth/microsoft', { token });
        if (res.type === 'success' && res.data) {
            onChangeLoading(false);
            onLoginAsync(res.data);
        }
        onChangeLoading(false);
    },  [onLoginAsync, onChangeLoading]);

    const handleSignIn = useCallback(async () => {
        const loginResponse = await myMSALObj.loginPopup(loginRequest);
        handleLogin(loginResponse.accessToken);
    }, [handleLogin]);

    return (
        <Button onClick={handleSignIn}>
            <Box
                padding={1}
                width={60}
                display={'flex'}
                justifyContent={'center'}
                flexDirection={'column'}
                height={55}
                alignItems={'center'}
                paddingX={6}
            >
                <img
                    src={LoginIcon}
                    alt='Google icon'
                    style={{
                        width: '40px',
                        padding: 5,
                    }}
                />
                <Typography
                    variant='subtitle2'
                    textTransform={'none'}
                    color={'text.primary'}
                >
                    Microsoft
                </Typography>
            </Box>
        </Button>
    );
};
