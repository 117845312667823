import { AxiosRequestConfig } from 'axios';
import api from './api';

export const postAsync = <TData = any, TResponse = any>(
    url: string,
    data?: TData,
    config?: AxiosRequestConfig,
): Promise<APIResponse<TResponse>> => {
    return api.post(url, data, config);
};
