// import { Order } from "../types";
// import { descendingComparator } from "./descendingComparator";

// export function getComparator<Key extends keyof any>(
//     order: Order,
//     orderBy: Key,
// ): (
//     a: { [key in Key]: number | string | boolean },
//     b: { [key in Key]: number | string | boolean },
// ) => number {

//     return order === 'desc'
//         ? (a, b) => descendingComparator(a, b, orderBy)
//         : (a, b) => -descendingComparator(a, b, orderBy);
// }

export const getComparator = <T,>(order: 'asc' | 'desc', orderBy: keyof T) => {
    return order === 'desc'
        ? (a: T, b: T) => (a[orderBy] < b[orderBy] ? -1 : 1)
        : (a: T, b: T) => (a[orderBy] > b[orderBy] ? -1 : 1);
};