import { useEffect, useState } from 'react';

interface Props {
  periodOptions: any
  dispatch: any
  setInventoryHealthSelectedPeriod: any
  scenarioTimeStamp: any
  selectedScenario: any
}
const useIteration = ({ periodOptions, dispatch, setInventoryHealthSelectedPeriod, scenarioTimeStamp, selectedScenario }: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [pausedIndex, setPausedIndex] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [isFinished, setIsFinished] = useState(false);

  useEffect(() => { setCurrentIndex(0); }, [selectedScenario]);

  useEffect(() => {
    dispatch(
      setInventoryHealthSelectedPeriod({
        period: periodOptions[currentIndex],
      }),
    );
  }, [currentIndex]);

  useEffect(() => {
    let intervalId: any;

    const iterate = () => {
      setCurrentIndex((prevIndex) => {
        if (prevIndex + 1 >= scenarioTimeStamp.length) {
          setIsRunning(false);
          setIsFinished(true);
          setPausedIndex(0);
          return prevIndex;
        }
        return prevIndex + 1;
      });
    };

    if (isRunning) {
      intervalId = setInterval(iterate, 500);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [scenarioTimeStamp, isRunning]);

  const startIteration = () => {
    if (!isRunning) {
      setIsRunning(true);
      setIsFinished(false);
      setCurrentIndex(pausedIndex);
    }
  };

  const pauseIteration = () => {
    setIsRunning(false);
    setPausedIndex(currentIndex);
  };

  const stopIteration = () => {
    setIsRunning(false);
    setCurrentIndex(0);
    setPausedIndex(0);
    setIsFinished(false);
    dispatch(
      setInventoryHealthSelectedPeriod({ period: periodOptions[0] }),
    );
  };

  return {
    currentIndex,
    isRunning,
    isFinished,
    startIteration,
    pauseIteration,
    stopIteration,
    setCurrentIndex
  };
};

export default useIteration;
