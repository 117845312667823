import React from 'react';
import { Button, SxProps, TableBody, TableCell, TableRow } from '@mui/material';
import { Cell } from './Cell';
import { Icons } from 'utils/icons';
import { Trans } from '@lingui/macro';
import { useVariant } from '@nplan';
interface IStyledTableCellProps {
    columnKey: keyof Column;
    column: Column;
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
    aligns: Align[];
    filterTypes: FilterType[];
    viewTypes: ViewType[];
    sx?: SxProps;
}

const StyledTableCell: React.FC<IStyledTableCellProps> = ({
    columnKey,
    column,
    align = 'left',
    aligns, 
    filterTypes,
    viewTypes,
    sx,
}) => {
    const cellType = (() => {
        if (
            [
                'editable',
                'hidden',
                'enableNull',
                'sortDefault',
                'editorAll',
                'foreignKey',
                'fixed',
            ].includes(columnKey)
        ) {
            return 'checkbox';
        }
        if (['alignId', 'viewTypeId', 'filterTypeId'].includes(columnKey)) {
            return 'select';
        }
        if (columnKey === 'bgColor' || columnKey === 'fontColor') {
            return 'color';
        }
        if(columnKey === 'tooltip'){
            return 'textarea';
        }
        return 'string';
    })();
    const getSelects = (columnKey: keyof Column): Align[] | FilterType[] | ViewType[] => {
        if (columnKey === 'alignId') return aligns;
        if (columnKey === 'filterTypeId') return filterTypes;
        if (columnKey === 'viewTypeId') return viewTypes;
        return [];
    };
    return (
        <TableCell
            align={align}
            sx={{
                padding: '0px 16px',
                height: 45,
                ...sx,
            }}
            title={column[columnKey]?.toString()}
        >
            <Cell
                columnKey={columnKey}
                column={column}
                type={cellType}
                selects={getSelects(columnKey)}
            />
        </TableCell>
    );
};

interface IColumn {
    key: keyof Column;
    align: 'inherit' | 'left' | 'center' | 'right' | 'justify';
    width?: number;
    sx?: SxProps
}
const cellColumns: IColumn[] = [
    {
        key: 'field',
        align: 'left',
        sx: {
            position: 'sticky',
            left: 0,
            zIndex: 5,
            bgcolor: 'background.default',
        },
    },
    {
        key: 'header',
        align: 'left',
        sx: {
            position: 'sticky',
            left: 300,
            zIndex: 5,
            bgcolor: 'background.default',
        },
    },
    {
        key: 'width',
        align: 'left',
    },
    {
        key: 'alignId',
        align: 'left',
    },
    {
        key: 'viewTypeId',
        align: 'left',
    },
    {
        key: 'filterTypeId',
        align: 'left',
    },
    {
        key: 'numberFloats',
        align: 'right',
    },
    {
        key: 'nullText',
        align: 'left',
    },
    {
        key: 'group',
        align: 'left',
    },
    {
        key: 'prefix',
        align: 'left',
    },
    {
        key: 'suffix',
        align: 'left',
    },
    {
        key: 'tooltip',
        align: 'left',
    },
    {
        key: 'order',
        align: 'right',
    },
    {
        key: 'editable',
        align: 'left',
    },
    {
        key: 'hidden',
        align: 'left',
    },
    {
        key: 'enableNull',
        align: 'left',
    },
    {
        key: 'sortDefault',
        align: 'left',
    },
    {
        key: 'editorAll',
        align: 'left',
    },
    {
        key: 'fixed',
        align: 'left',
    },
    {
        key: 'foreignKey',
        align: 'left',
    },
    {
        key: 'foreignSchema',
        align: 'left',
    },
    {
        key: 'foreignTable',
        align: 'left',
    },
    {
        key: 'foreignField',
        align: 'left',
    },
    {
        key: 'foreignShowField',
        align: 'left',
    },
];
interface IBodyProps {
    columns: Column[];
    aligns: Align[];
    filterTypes: FilterType[];
    viewTypes: ViewType[];
    onRefreshData: () => Promise<void>;
}
export const Body: React.FC<IBodyProps> = ({
    columns,
    aligns,
    filterTypes,
    viewTypes
}): JSX.Element => {
    const variant = useVariant();
    return (
        <TableBody>
            {columns
                .sort((a, b) => (a.order > b.order ? 1 : -1))
                .map((column) => (
                    <TableRow hover key={column.id}>
                        {cellColumns.map((cellColumn) => (
                            <StyledTableCell
                                key={cellColumn.key}
                                column={column}
                                columnKey={cellColumn.key}
                                align={cellColumn.align}
                                aligns={aligns}
                                filterTypes={filterTypes}
                                viewTypes={viewTypes}
                                sx={{
                                    ...cellColumn.sx,
                                    width: cellColumn.width,
                                    minWidth: cellColumn.width,
                                }}
                            />
                        ))}
                        {/* Botões */}
                        <TableCell
                            sx={{
                                padding: '0px 16px',
                                height: 45,
                            }}
                        >
                            <Button
                                variant={variant}
                                startIcon={<Icons.Edit />}
                            >
                                <Trans>Editar</Trans>
                            </Button>
                        </TableCell>
                    </TableRow>
                ))}
        </TableBody>
    );
};
